import { Button, Checkbox, Column, Container, DatePicker, Dialog, FormGroup, FormInput, FormItem, FormLabel, FormTextarea, Icon, InputGroup, LayoutPanel, Row, Select, Table } from 'fundamental-react';
import moment from 'moment';
import * as PapaParse from 'papaparse';
import React, { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { format } from 'util';
import { CommonParamActions } from '../../../actions/commonParam';
import { MaterialPriceActions } from '../../../actions/materialPrice';
import { MessageActions } from '../../../actions/message';
import { PurchaseOrderActions } from '../../../actions/purchaseOrder';
import { getMessage as m, getWord as w } from '../../../messages';
import { MaterialPrice, MaterialPrices, SuggestMaterial, SearchKeys, SuggestMaterials } from '../../../models/MaterialPrice';
import { CreatePurchaseOrder, GrossPriceSearchKeys, PurchaseOrderUploadRecord } from '../../../models/PurchaseOrder';
import { State } from '../../../reducers';
import { DatePickerParam, JSObject } from '../../../types';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import { confirmBox } from '../../atoms/Confirm';
import NavButtons from '../../NavButtons';
import MaterialPriceSearch from '../../organisms/MaterialPriceSearch';
import { returnAmountText, returnQuantityText } from '../../../utils/CommonFunction';

interface SelectedOption {
    key: string,
    text: string,
};

interface PurchaseOrderCreateData {
    isChecked: boolean,
    supplier: string,
    supplierName: string,
    orderId: string,
    orderIdDetail: string,
    customerMaterial: string,
    customerMaterialName: string,
    orderDate: string,
    desiredDeliveryDate: Date | null,
    orderQuantity: string,
    pricePerOneUnit: number,
    grossPrice: number,
    salesUnit: string,
    currency: string,
    messageColumn: string,
};

const initialTableData = {
    isChecked: false,
    supplier: '',
    supplierName: '',
    orderId: '',
    orderIdDetail: '',
    customerMaterial: '',
    customerMaterialName: '',
    orderDate: moment(new Date).format('YYYY/MM/DD'),
    desiredDeliveryDate: null,
    orderQuantity: '',
    pricePerOneUnit: 0,
    grossPrice: 0,
    salesUnit: '',
    currency: '',
    messageColumn: '',
} as PurchaseOrderCreateData;

const initialSearchKeys = {
    customer: null,
    supplier: null,
    material: null,
    materialName: null,
    limitNumber: '500',
} as SearchKeys;

const PurchaseOrderCreate: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const ORDER_INCREMENT_VALUE = 1000;  // 注文明細No.インクリメント数
    const ORDER_CREATE_MAX_VALUE = 50;   // 最大発注件数

    const CELL_HEIGHTS_FOR_ADD_PADDING = 50;

    const {
        user,
        grossPrices,
        materialPrices,
        suggestMaterials,
        isLoadingSuggestMaterials,
        didGetSuggestMaterialSuccess,
        customerDestinations,
        customerMaterial,
        uploadPurchaseOrdersResult,
        isConnecting,
        didCreatePurchaseOrders,
        didUploadPurchaseOrders,
    } = useSelector((state: State) => ({
        user: state.user.user,
        grossPrices: state.purchaseOrder.grossPrices.list,
        materialPrices: state.materialPrice.materialPrices,
        suggestMaterials: state.materialPrice.suggestMaterials,
        isLoadingSuggestMaterials: state.materialPrice.isLoadingSuggestMaterials,
        didGetSuggestMaterialSuccess: state.materialPrice.didGetSuggestMaterialSuccess,
        customerDestinations: state.commonParam.customerDestinations.list,
        customerMaterial: state.purchaseOrder.customerMaterial.list,
        uploadPurchaseOrdersResult: state.purchaseOrder.uploadPurchaseOrdersResult.list,
        isConnecting: state.purchaseOrder.isConnecting,
        didCreatePurchaseOrders: state.purchaseOrder.didCreatePurchaseOrders,
        didUploadPurchaseOrders: state.purchaseOrder.didUploadPurchaseOrders,
    }));

    // リストに表示するテーブル変数
    const [tempTable, setTempTable] = useState<Array<PurchaseOrderCreateData>>([{ ...initialTableData }]);
    // リストのレコード何行目を操作中かの変数
    const [rowIndex, setRowIndex] = useState(0);
    // SAPに品目問合せを行うための検索キー
    const [searchKeys, setSearchKeys] = useState<SearchKeys>({ ...initialSearchKeys });

    // 品目・価格検索表示フラグ
    const [isOpenMaterialPriceSearchDialog, setIsOpenMaterialPriceSearchDialog] = useState(false);
    const onClickCancel = () => { setIsOpenMaterialPriceSearchDialog(false) };

    // 削除用チェックボックス押下イベントハンドラ
    const onChangeSelection = (event: React.SyntheticEvent<HTMLInputElement>, checkboxState: boolean, index: number) => {
        tempTable[index].isChecked = checkboxState;
        setTempTable([...tempTable]);
    };

    // サプライヤID選択イベントハンドラ
    const [supplier, setSupplier] = useState('');
    const [supplierName, setSupplierName] = useState('');
    const onSelectSupplierId = (event: SyntheticEvent, selectedOption: SelectedOption) => {
        const targetSupplier = customerDestinations.find(x => x.companyId === selectedOption.key);
        if (targetSupplier === undefined || targetSupplier.companyId === supplier)
            return;

        const prevSupplier = supplier;
        const prevSupplierName = supplierName;
        setSupplier(targetSupplier.companyId);
        setSupplierName(targetSupplier.companyName);

        // レコードが0件の場合、サプライヤID、サプライヤ名称を変更
        if (tempTable[0].orderId.length === 0) {
            tempTable[0].supplier = targetSupplier.companyId;
            tempTable[0].supplierName = targetSupplier.companyName;
            setTempTable([...tempTable]);

            dispatch(MaterialPriceActions.setSuggestMaterials(new SuggestMaterials()));
            dispatch(MaterialPriceActions.searchSuggestMaterials({ customer: user.userId, supplier: targetSupplier.companyId }));
        }

        // レコードが1件以上ある場合、既存レコードを消去してよいか確認
        if (tempTable[0].orderId.length > 0 && supplier !== targetSupplier.companyId) {
            const ok = () => {
                setTempTable([{
                    ...initialTableData,
                    supplier: targetSupplier.companyId,
                    supplierName: targetSupplier.companyName,
                }]);

                dispatch(MaterialPriceActions.setSuggestMaterials(new SuggestMaterials()));
                dispatch(MaterialPriceActions.searchSuggestMaterials({ customer: user.userId, supplier: targetSupplier.companyId }));
            }
            const cancel = () => {
                setSupplier(prevSupplier);
                setSupplierName(prevSupplierName);
            }
            confirmBox('サプライヤIDを変更すると明細は初期化されます。よろしいですか？', [], ok, cancel);
        }
    };

    // 表示する企業ID
    const [customer, setCustomer] = useState('');
    // 企業IDに変更があったかのフラグ
    const [isChangeCustomer, setIsChangeCustomer] = useState(false);
    // 企業ID変更イベントハンドラ
    const onChangeCustomerId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer(event.currentTarget.value);
        setIsChangeCustomer(true);
    };

    const onBlurCustomerId = (event: React.FocusEvent<HTMLInputElement>) => {
        // 企業IDの変更がない場合、品目の取得を行わない
        if (!isChangeCustomer)
            return;

        // レコードが0件の場合、企業IDを変更
        if (tempTable[0].orderId.length === 0 && tempTable[0].customerMaterial.length === 0) {
            searchKeys.customer = event.currentTarget.value;
            setSearchKeys({ ...searchKeys });

            setIsChangeCustomer(false);
            dispatch(MaterialPriceActions.setSuggestMaterials(new SuggestMaterials()));
            dispatch(MaterialPriceActions.searchSuggestMaterials({ customer: customer, supplier: user.userId }));
        }
        // レコードが1件以上ある場合、既存レコードを消去してよいか確認
        else {
            const ok = () => {
                setTempTable([{
                    ...initialTableData,
                    supplier: user.userId,
                    supplierName: user.userNm,
                }]);
                searchKeys.customer = '';
                setSearchKeys({ ...searchKeys });
                setCustomer('');

                setIsChangeCustomer(false);
                dispatch(MaterialPriceActions.setSuggestMaterials(new SuggestMaterials()));
            }
            const cancel = () => {
                setCustomer(searchKeys.customer ?? '');
            }
            confirmBox('企業IDを変更すると明細は初期化されます。よろしいですか？', [], ok, cancel);
        }
    };

    useEffect(() => {
        // CMEs導入企業(01)の場合、supplier, tempTable[0].supplierに自身のuserIdを設定
        if (user.userType === '01') {
            setSupplier(user.userId);
            setSupplierName(user.userNm);
            tempTable[0].supplier = user.userId;
            tempTable[0].supplierName = user.userNm;
            setTempTable([...tempTable]);
        }
    }, [user]);

    // 注文番号更新中イベントハンドラ
    const onChangeOrderId = (event: React.FocusEvent<HTMLInputElement>, index: number) => {
        tempTable[index].orderId = event.currentTarget.value;
        setTempTable([...tempTable]);
    };

    // 注文番号更新後イベントハンドラ
    const onBlurOrderId = (event: React.FocusEvent<HTMLInputElement>, index: number) => {

        // 注文番号が未入力の場合 - 明細No.も空にする
        if (!tempTable[index].orderId) {
            tempTable[index].orderIdDetail = '';
            setTempTable([...tempTable]);
            return;
        }

        // 明細No.再採番
        const newTable = getRenumberingTable(tempTable.filter(x => !x.isChecked));
        setTempTable([...newTable]);

        // テーブルで一番最後のレコードの場合、Newレコードを挿入(最大50件)
        if (index === tempTable.length - 1 && tempTable.length < ORDER_CREATE_MAX_VALUE) {
            tempTable.push({ ...initialTableData, supplier: supplier, supplierName: supplierName });
            setTempTable([...tempTable]);
        }
    };

    // 品目更新中イベントハンドラ
    const [isChangeCustomerMaterial, setIsChangeCustomerMaterial] = useState(false);
    const onChangeCustomerMaterial = (event: React.FocusEvent<HTMLInputElement>, index: number) => {
        setIsFocusIn(true);
        setIsSkipSuggestSearch(false);
        setMouseOverSuggestion('');
        setMouseOverSuggestionIndex(null);

        const value = event.currentTarget.value;
        if (value === '')
            setSuggestions([]);

        setIsChangeCustomerMaterial(true);

        setRowIndex(index);
        tempTable[index].customerMaterial = event.currentTarget.value;
        tempTable[index].customerMaterialName = '';
        tempTable[index].orderQuantity = '';
        tempTable[index].salesUnit = '';
        tempTable[index].pricePerOneUnit = 0;
        tempTable[index].grossPrice = 0;
        tempTable[index].currency = '';
        setTempTable([...tempTable]);
    };

    // 品目更新後イベントハンドラ
    const [changedCustomerMaterialIndex, setChangedCustomerMaterialIndex] = useState<number | null>(null);
    const onBlurCustomerMaterial = (event: React.FocusEvent<HTMLInputElement>, index: number) => {
        if (isChangeCustomerMaterial) {
            const keys = {
                // カスタマー企業(03)の場合、customerは自身のuserId、CMEs導入企業(01)の場合、customerはsearchKeysのcustomer
                customer: user.userType === '03' ? user.userId : searchKeys.customer,
                // カスタマー企業(03)でもCMEs導入企業(01)でも、supplierはtempTable[index].supplier
                supplier: tempTable[index].supplier,
                material: [tempTable[index].customerMaterial],
                limitNumber: '1',
            } as SearchKeys;

            setIsFocusIn(false);
            setIsChangeCustomerMaterial(false);
            setChangedCustomerMaterialIndex(index);
            dispatch(PurchaseOrderActions.searchCustomerMaterial(keys));
        }
    };

    // 品目検索ヘルプにて品目確定時
    const onSelectMaterial = (customerMaterial: string) => {
        const selectMaterial = materialPrices.list.toArray().find(x => x.customerMaterial === customerMaterial);
        if (selectMaterial === undefined)
            return;

        tempTable[rowIndex].customerMaterial = selectMaterial.customerMaterial;
        tempTable[rowIndex].customerMaterialName = selectMaterial.customerMaterialName;
        tempTable[rowIndex].salesUnit = selectMaterial.salesUnit;
        tempTable[rowIndex].pricePerOneUnit = Number(selectMaterial.pricePerOneUnit);
        tempTable[rowIndex].currency = selectMaterial.currency;
        tempTable[rowIndex].orderQuantity = ''; // 品目再選択時を考慮し、発注数量・正味額をクリア
        tempTable[rowIndex].grossPrice = 0; // 品目再選択時を考慮し、発注数量・正味額をクリア
        setTempTable([...tempTable]);
        setIsOpenMaterialPriceSearchDialog(false);
    };

    // 希望納期更新後イベントハンドラ
    const onInputBlurDeliveryDate = (param: DatePickerParam, index: number) => {
        if (moment(param.formattedDate).isValid())
            tempTable[index].desiredDeliveryDate = new Date(param.isoFormattedDate);
        else
            tempTable[index].desiredDeliveryDate = null;

        setTempTable([...tempTable]);
    };

    // 発注数更新中イベントハンドラ
    const [isChangeOrderQuantity, setIsChangeOrderQuantity] = useState(false);
    const onChangeOrderQuantity = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const valueLength = event.currentTarget.value.replace(/,|\./g, '').length;
        const value = event.currentTarget.value.replace(/,/g, '');

        // 整数部+小数部の長さは10桁が最大
        if (valueLength > 10 || isNaN(Number(value)) || Number(value) < 0)
            return;

        tempTable[index].orderQuantity = value;
        setIsChangeOrderQuantity(true);
        setTempTable([...tempTable]);
    };

    // 発注数更新後イベントハンドラ
    const onBlurOrderQuantity = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {

        // 発注数量の変更が無い場合はSAPに問合せを行わない
        if (!isChangeOrderQuantity)
            return;

        // 数値のフォーマットを満たしていない場合、SAPに価格問合せを行わない
        if (isErrorOrderQuantity(tempTable[index].orderQuantity)) {
            tempTable[index].orderQuantity = '';
            tempTable[index].grossPrice = 0;
            setTempTable([...tempTable]);
        } else {
            if (isChangeOrderQuantity) {
                searchGrossPrice();
                setIsChangeOrderQuantity(false);
            }
        }
    };

    // 備考欄更新中イベントハンドラ
    const onChangeMessageColumn = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        tempTable[index].messageColumn = event.currentTarget.value;
        setTempTable([...tempTable]);
    };

    // テーブルヘッダー取得
    const getHeaders = () => {
        const headers = [
            t('削除'),
            t('No.'),
            t('注文番号'),
            t('注文明細No.'),
            t('品目'),
            t('品名'),
            t('発注日'),
            t('希望納期'),
            t('発注数量'),
            t('単位'),
            t('正味価格'),
            t('正味額'),
            t('通貨'),
            t('通信欄'),
        ];

        return headers;
    };

    // サジェスト品目リスト
    const [suggestions, setSuggestions] = useState<SuggestMaterial[]>([]);
    // マウスオーバーしたサジェスト品目
    const [mouseOverSuggestion, setMouseOverSuggestion] = useState('');
    // マウスオーバーしたサジェスト品目のIndex
    const [mouseOverSuggestionIndex, setMouseOverSuggestionIndex] = useState<number | null>();
    // サジェスト品目の検索を実施するかのフラグ
    const [isSkipSuggestSearch, setIsSkipSuggestSearch] = useState(false);
    // 品目TextBoxにフォーカスがあるかのフラグ
    const [isFocusIn, setIsFocusIn] = useState(false);

    // 入力した品目からサジェスト品目を取得
    const getSuggestions = async (searchText: string) => {
        if (suggestMaterials === undefined)
            return;

        // 入力した品名に前方一致する項目を最大10件取得
        return suggestMaterials.list.filter((item) => item.customerMaterial.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) === 0).slice(0, 10);
    };

    // サジェスト品目取得開始
    useEffect(() => {
        if (tempTable[rowIndex] === undefined || tempTable[rowIndex].customerMaterial === '' || isSkipSuggestSearch)
            return;

        getSuggestions(tempTable[rowIndex].customerMaterial).then((foundSuggestions) => {
            if (foundSuggestions !== undefined)
                setSuggestions(foundSuggestions.toArray());
        });

    }, [tempTable, isSkipSuggestSearch, suggestMaterials, didGetSuggestMaterialSuccess]);

    // リスト取得
    const getRowData = (item: PurchaseOrderCreateData, index: number) => {

        // チェックボックス押下時
        const onChangeRowSelection = (event: React.SyntheticEvent<HTMLInputElement>, checkboxState: boolean) => {
            onChangeSelection(event, checkboxState, index);
        };

        // 注文番号入力時
        const onChangeRowOrderId = (event: React.FocusEvent<HTMLInputElement>) => {
            onChangeOrderId(event, index);
        };

        // 注文番号入力後
        const onBlurRowOrderId = (event: React.FocusEvent<HTMLInputElement>) => {
            onBlurOrderId(event, index);
        };

        // 品目入力時
        const onChangeRowCustomerMaterial = (event: React.FocusEvent<HTMLInputElement>) => {
            onChangeCustomerMaterial(event, index);
        };

        // 品目入力後
        const onBlurRowCustomerMaterial = (event: React.FocusEvent<HTMLInputElement>) => {
            setIsSkipSuggestSearch(true);
            setSuggestions([]);

            // サジェスト品目を選択している場合、選択した品目を品目欄に表示(サジェスト品目のonClickよりもこちらのonBlurが優先させてしまう為、こちらに記述)
            if (mouseOverSuggestion.length > 0) {
                tempTable[index].customerMaterial = mouseOverSuggestion;
                setTempTable([...tempTable]);
                setMouseOverSuggestion('');
            }

            onBlurCustomerMaterial(event, index);
        };

        // 品目検索ボタン押下時
        const onClickRowMaterialSearch = () => {

            // サプライヤID変更のことを考慮し、リストの初期化
            dispatch(MaterialPriceActions.setMaterialPrices(new MaterialPrices()))

            // カスタマー企業(03)の場合、customerは自身のuserId、supplierは選択したsupplier
            if (user.userType === '03') {
                searchKeys.customer = user.userId;
                searchKeys.supplier = tempTable[index].supplier;
                setSearchKeys({ ...searchKeys });
            }
            // CMEs導入企業(01)の場合の場合、customerはsearchKeysのcustomer、supplierは自身のuserId
            else if (user.userType === '01') {
                searchKeys.supplier = user.userId;
                setSearchKeys({ ...searchKeys });
            }

            setIsOpenMaterialPriceSearchDialog(true);
            setRowIndex(index);
        };

        // 希望納期入力後
        const onInputBlurRowDeliveryDate = (param: DatePickerParam) => {
            onInputBlurDeliveryDate(param, index);
        }

        // 発注数入力時
        const onChangeRowOrderQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeOrderQuantity(event, index);
        };

        // 発注数入力後
        const onBlurRowOrderQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
            onBlurOrderQuantity(event, index);
        };

        // 備考欄入力時
        const onChangeRowMessageColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeMessageColumn(event, index);
        };

        const rowData = [
            <NoWrapSpan>
                <Checkbox
                    compact
                    checked={item.isChecked}
                    onChange={onChangeRowSelection}
                    disabled={!tempTable[index].orderId}
                />
            </NoWrapSpan>,
            <NoWrapSpan>
                <div style={{ textAlign: 'right' }}>{index + 1}</div>
            </NoWrapSpan>,
            <NoWrapSpan>
                <FormInput
                    compact
                    style={{ minWidth: '10rem' }}
                    disabled={
                        // カスタマー企業(03)かつsupplier未選択の場合、入力不可
                        user.userType === '03' ? !tempTable[index].supplier
                            // CMEs導入企業かつcustomer未入力の場合、入力不可
                            : user.userType === '01' ? !searchKeys.customer
                                : false
                    }
                    value={tempTable[index].orderId}
                    onChange={onChangeRowOrderId}
                    onBlur={onBlurRowOrderId}
                    maxLength={35}
                />
            </NoWrapSpan>,
            <NoWrapSpan>{tempTable[index].orderIdDetail}</NoWrapSpan>,
            <NoWrapSpan>
                <InputGroup
                    disabled={
                        // カスタマー企業(03)かつsupplier未選択の場合、入力不可
                        user.userType === '03' ? !tempTable[index].supplier
                            // CMEs導入企業かつcustomer未入力の場合、入力不可
                            : user.userType === '01' ? !searchKeys.customer
                                : false
                    }
                >
                    <FormInput
                        compact
                        style={{ minWidth: '15rem' }}
                        value={item.customerMaterial}
                        onChange={onChangeRowCustomerMaterial}
                        onBlur={onBlurRowCustomerMaterial}
                    />
                    {(suggestions.length > 0 && index === rowIndex) && (
                        <SuggestionTable
                            condensed
                            selection={{
                                isSelected: (index: number) => index === mouseOverSuggestionIndex,
                            }}
                            headers={[
                                <SuggestionHeader>{t('品目')}</SuggestionHeader>,
                                <SuggestionHeader>{t('品名')}</SuggestionHeader>,
                            ]}
                            tableData={
                                suggestions.map((suggestion, index) => {
                                    return ({
                                        rowData: [
                                            <Suggestion
                                                key={suggestion.customerMaterial}
                                                onMouseOver={() => {
                                                    setMouseOverSuggestion(suggestion.customerMaterial);
                                                    setMouseOverSuggestionIndex(index);
                                                }}
                                                onMouseLeave={() => {
                                                    setMouseOverSuggestion('');
                                                    setMouseOverSuggestionIndex(null);
                                                }}
                                            >
                                                {suggestion.customerMaterial}
                                            </Suggestion>,
                                            <Suggestion
                                                key={suggestion.customerMaterialName}
                                                onMouseOver={() => {
                                                    setMouseOverSuggestion(suggestion.customerMaterial);
                                                    setMouseOverSuggestionIndex(index);
                                                }}
                                                onMouseLeave={() => {
                                                    setMouseOverSuggestion('');
                                                    setMouseOverSuggestionIndex(null);
                                                }}
                                            >
                                                {suggestion.customerMaterialName}
                                            </Suggestion>
                                        ]
                                    })
                                })}
                        />
                    )}
                    {(isLoadingSuggestMaterials && index === rowIndex && item.customerMaterial.length > 0 && isFocusIn) && (
                        <SuggestionLoading style={{ background: 'white' }}>
                            {t('読込中…')}
                        </SuggestionLoading>
                    )}
                    <InputGroup.Addon compact>
                        <Icon glyph='search' onClick={onClickRowMaterialSearch} />
                    </InputGroup.Addon>
                </InputGroup>
            </NoWrapSpan>,
            <NoWrapSpan>
                <FormInput
                    compact
                    style={{ minWidth: '20rem' }}
                    readOnly
                    value={item.customerMaterialName}
                />
            </NoWrapSpan>,
            <NoWrapSpan>
                {item.orderDate}
            </NoWrapSpan>,
            <NoWrapSpan>
                <DatePicker
                    compact
                    dateFormat='YYYY/MM/DD'
                    locale='ja'
                    style={{ minWidth: '9rem' }}
                    disabled={
                        // カスタマー企業(03)かつsupplier未選択の場合、入力不可
                        user.userType === '03' ? !tempTable[index].supplier
                            // CMEs導入企業かつcustomer未入力の場合、入力不可
                            : user.userType === '01' ? !searchKeys.customer
                                : false
                    }
                    defaultValue={moment(item.desiredDeliveryDate).format("YYYY/MM/DD")}
                    onInputBlur={onInputBlurRowDeliveryDate}
                />
            </NoWrapSpan>,
            <NoWrapSpan>
                <FormInput
                    compact
                    style={{ minWidth: '8rem', textAlign: 'right' }}
                    disabled={!tempTable[index].customerMaterialName}
                    onChange={onChangeRowOrderQuantity}
                    onBlur={onBlurRowOrderQuantity}
                    value={item.orderQuantity}
                    placeholder='0'
                />
            </NoWrapSpan>,
            <NoWrapSpan>{item.salesUnit}</NoWrapSpan>,
            <NoWrapSpan>
                <div style={{ minWidth: '6rem', textAlign: 'right' }}>{returnAmountText(item.pricePerOneUnit.toString())}</div>
            </NoWrapSpan>,
            <NoWrapSpan>
                <div style={{ minWidth: '6rem', textAlign: 'right' }}>{returnAmountText(item.grossPrice.toString())}</div>
            </NoWrapSpan>,
            <NoWrapSpan>{item.currency}</NoWrapSpan>,
            <NoWrapSpan>
                <FormTextarea
                    compact
                    style={{ minWidth: '20rem' }}
                    onChange={onChangeRowMessageColumn}
                    value={item.messageColumn}
                />
            </NoWrapSpan>,
        ];

        return rowData;
    }

    // テーブル内の指定のカラムにスタイルを適用する
    const table = useRef<HTMLTableElement>(null);
    const applyTableStyle = () => {
        Array.prototype.forEach.call(table.current?.getElementsByTagName("td"), item => {
            const clientHeight = item.clientHeight;
            if (clientHeight < CELL_HEIGHTS_FOR_ADD_PADDING) {
                item.style.paddingTop = '5px';
                item.style.paddingBottom = '5px';
            } else {
                item.style.paddingTop = '';
                item.style.paddingBottom = '';
            }
            // text-align: セルの判定
            if (item.getElementsByClassName("alignCenter").length > 0) {
                item.style.textAlign = 'center';
            } else if (item.getElementsByClassName("alignRight").length > 0) {
                item.style.textAlign = 'right';
            } else {
                item.style.textAlign = 'left';
            }
        });
    };

    // 発注数の入力形式が正しいかのチェック
    const isErrorOrderQuantity = (value: string) => {
        const integerRegexpOrderQuantity = new RegExp(/^([-][1-9]\d{0,}|[1-9]\d{0,}|-0|0)(\.\d{1,3})?$/); // 整数部は最大10桁、小数部は最大3桁までの正規表現
        if (!integerRegexpOrderQuantity.test(value))
            return true;
        else
            return false;
    };

    // 希望納期の入力形式(YYYY/MM/DD)が正しいかのチェック
    const isErrorDesiredDeliveryDate = (value: string) => {
        const regexpDesiredDeliveryDate = new RegExp(/\d{4}\/\d{1,2}\/\d{1,2}/); // YYYY/MM/DDの正規表現(※月、日は0埋め無しでも良い)
        if (!regexpDesiredDeliveryDate.test(value) || !moment(value).isValid())
            return true;
        else
            return false;
    };

    // 発注登録ボタン押下時
    const onClickCreateButton = () => {

        // レコードが0件の時はエラー
        if (tempTable.length === 1 && tempTable[0].orderId.length === 0) {
            dispatch(MessageActions.push({ content: m('登録するレコードがありません。'), words: [] }));
            return;
        }

        // 未記入のレコードがあれば、エラー
        let isError = false;
        let prevTargetCompany = '';
        for (let index = 0; index < tempTable.length; index++) {
            const record = tempTable[index];

            // 一番最後の空レコードはスキップ
            if (tempTable.length - 1 === index && !record.orderId && !record.customerMaterial && !record.desiredDeliveryDate)
                break;

            // #region 必須チェック(注文番号、品目、希望納期、発注数量)
            let mandatoryErrorCols: string[] = [];
            if (!record.orderId)
                mandatoryErrorCols.push(t('注文番号'));
            if (!record.customerMaterial)
                mandatoryErrorCols.push(t('品目'));
            if (!record.desiredDeliveryDate)
                mandatoryErrorCols.push(t('希望納期'));
            if (!record.orderQuantity)
                mandatoryErrorCols.push(t('発注数量'));

            if (mandatoryErrorCols.length !== 0) {
                dispatch(MessageActions.push({ content: m('%sを入力してください。'), words: [mandatoryErrorCols.join('、')] }));
                isError = true;
                break;
            }
            // #endregion

            // #region 属性チェック②(発注数量)
            if (isErrorOrderQuantity(record.orderQuantity.toString().replace(/,/g, ''))) {
                isError = true;
                dispatch(MessageActions.push({ content: m('%sは数値で入力してください。'), words: [w('発注数量')] }));
                break;
            }
            // #endregion

            // #region 属性チェック③(希望納期)
            if (!moment(record.desiredDeliveryDate).isValid()) {
                isError = true;
                dispatch(MessageActions.push({ content: m('%sは日付の形式(YYYY/MM/DD)で入力してください。'), words: [w('希望納期')] }));
                break;
            }
            // #endregion

            // #region 範囲チェック(発注数量)
            if (Number(record.orderQuantity) <= 0) {
                isError = true;
                dispatch(MessageActions.push({ content: m('発注数量は0より大きい値を入力してください。'), words: [] }));
                break;
            } else if (10000000000 <= Number(record.orderQuantity)) {
                isError = true;
                dispatch(MessageActions.push({ content: m('発注数量は10000000000より小さい値を入力してください。'), words: [] }));
                break;
            }
            // #endregion

            // #region 範囲チェック(希望納期)
            const nowDate = moment(moment().format('YYYY/MM/DD 00:00'));
            if (moment(record.desiredDeliveryDate).isBefore(nowDate)) {
                isError = true;
                dispatch(MessageActions.push({ content: m('希望納期は当日以降を入力してください。'), words: [] }));
                break;
            }
            // #endregion

            // #region 一意性チェック(サプライヤID)
            if (prevTargetCompany.length > 0 && prevTargetCompany !== record.supplier) {
                isError = true;
                dispatch(MessageActions.push({ content: m('サプライヤIDが複数存在します。'), words: [] }));
                break;
            } else {
                prevTargetCompany = record.supplier;
            }
            // #endregion
        }

        // エラーがあれば中断
        if (isError)
            return;

        const ok = () => {
            const createDatas: Array<CreatePurchaseOrder> = [];
            tempTable.forEach(x => {
                // 一番最後の空レコードは除外
                if (x.orderId.length === 0)
                    return;

                const createData = {
                    orderId: x.orderId,
                    orderIdDetail: x.orderIdDetail,
                    customer: user.userId,
                    supplier: x.supplier,
                    customerMaterial: x.customerMaterial,
                    desiredDeliveryDate: x.desiredDeliveryDate,
                    orderQuantity: x.orderQuantity.toString().replace(/,/g, ''),
                    orderQuantityUnit: x.salesUnit,
                    messageColumn: x.messageColumn
                } as CreatePurchaseOrder;
                createDatas.push(createData);
            });

            dispatch(PurchaseOrderActions.createPurchaseOrders(createDatas));
        }
        const cancel = () => { }

        confirmBox('登録します。よろしいですか？', [], ok, cancel);
    };

    // ファイルアップロードボタン押下時
    const onClickUploadButton = () => {
        setOpenUploadDialog(true);
    };

    // コピーボタン押下時
    const onClickCopyButton = () => {
        // レコードが1行 かつ 注文番号未入力の場合、中断
        if (tempTable.length === 1 && !tempTable[0].orderId)
            return;

        // 最大発注件数を超えた場合、中断
        if (tempTable.length === ORDER_CREATE_MAX_VALUE) {
            tempTable[tempTable.length - 1].supplier = tempTable[tempTable.length - 2].supplier;
            tempTable[tempTable.length - 1].supplierName = tempTable[tempTable.length - 2].supplierName;
            tempTable[tempTable.length - 1].orderId = tempTable[tempTable.length - 2].orderId;
            tempTable[tempTable.length - 1].orderIdDetail = tempTable[tempTable.length - 2].orderIdDetail.length === 0 ?
                '' : ('00000' + (Number(tempTable[tempTable.length - 2].orderIdDetail) + ORDER_INCREMENT_VALUE)).slice(-5);
            tempTable[tempTable.length - 1].desiredDeliveryDate = tempTable[tempTable.length - 2].desiredDeliveryDate;
            tempTable[tempTable.length - 1].messageColumn = tempTable[tempTable.length - 2].messageColumn;
            setTempTable([...tempTable]);
            return;
        }

        const targetRow = tempTable[tempTable.length - 2];
        const targetOrderIdDetail = tempTable.filter(x => x.orderId === targetRow.orderId).map(x => x.orderIdDetail).reverse().shift();

        const newRow = { ...initialTableData };
        newRow.supplier = targetRow.supplier;
        newRow.supplierName = targetRow.supplierName;
        newRow.orderId = targetRow.orderId;
        newRow.orderIdDetail = !targetOrderIdDetail ? '' : ('00000' + (Number(targetOrderIdDetail) + ORDER_INCREMENT_VALUE)).slice(-5);
        newRow.desiredDeliveryDate = targetRow.desiredDeliveryDate;
        newRow.messageColumn = targetRow.messageColumn;

        tempTable.splice(tempTable.length - 1, 0, newRow);
        setTempTable([...tempTable]);
    };

    // 削除ボタン押下時
    const onClickDeleteButton = () => {

        const checkedRecordCount = tempTable.filter(x => x.isChecked).length;
        if (checkedRecordCount > 0) {
            const ok = () => {
                // 削除チェックをしていない項目で再採番
                const newTable = getRenumberingTable(tempTable.filter(x => !x.isChecked));

                // レコードが0件の場合、もしくは最大発注数量まで入力し、削除した場合
                const orderIdInputRecordCount = tempTable.filter(x => x.orderId.length === 0).length;
                if (newTable.length === 0 || orderIdInputRecordCount === 0) {
                    newTable.push({ ...initialTableData, supplier: supplier, supplierName: supplierName });
                }

                setTempTable([...newTable]);
            }
            const cancel = () => { }

            confirmBox('削除しますか？', [], ok, cancel);
        } else {
            dispatch(MessageActions.push({ content: m('削除対象を選択してください。'), words: [] }));
        }
    };

    // 再採番のテーブルを作成
    const getRenumberingTable = (data: PurchaseOrderCreateData[]) => {
        const distinctOrderIds = Array.from(new Set(data.filter(x => x.orderId.length > 0).map(x => x.orderId)));

        // 同一注文番号において、再採番
        distinctOrderIds.forEach(targetOrderId => {
            let orderIdDetailValue = ORDER_INCREMENT_VALUE;
            data.forEach(x => {
                if (targetOrderId === x.orderId) {
                    x.orderIdDetail = ('00000' + orderIdDetailValue).slice(-5);
                    orderIdDetailValue = orderIdDetailValue + ORDER_INCREMENT_VALUE;
                }
            })
        });

        return data;
    };

    // 正味額の問合せ処理
    const searchGrossPrice = () => {

        let grossPriceSearchKeys = Array<GrossPriceSearchKeys>();
        tempTable.forEach(x => {
            // カスタマー企業(03)の場合、supplierが選択されていなければ処理終了
            if (user.userType === '03' && !x.supplier) {
                return;
            }
            // CMEs導入企業(01)の場合、customerが入力されていなければ処理終了
            else if (user.userType === '01' && searchKeys.customer) {

            }
            const temp = {
                // カスタマー企業(03)の場合、customerは自身のuserId、CMEs導入企業(01)の場合、customerはsearchKeysのcustomer
                customer: user.userType === '03' ? user.userId : searchKeys.customer,
                // カスタマー企業(03)でもCMEs導入企業(01)でも、supplierはx.supplier
                supplier: x.supplier,
                customerMaterial: x.customerMaterial,
                orderQuantity: x.orderQuantity.toString().replace(/,/g, ''),
            } as GrossPriceSearchKeys
            grossPriceSearchKeys.push(temp);
        });

        dispatch(PurchaseOrderActions.searchGrossPrices(grossPriceSearchKeys));
    };

    // #region ファイルアップロード関連

    // ファイルアップロードダイアログ表示フラグ
    const refFile = useRef<HTMLInputElement>(null);
    const [isOpenUploadDialog, setOpenUploadDialog] = useState(false);
    const onClickUploadDialogCancelButton = () => {
        setOpenUploadDialog(false);
        acceptedFiles.splice(0);
    };

    // アップロードファイル一時保持変数
    const [tempUploadFile, setTempUploadFile] = useState<Array<any>>();
    const { acceptedFiles, getInputProps, getRootProps, isDragActive, isFocused, } = useDropzone({
        accept: { 'text/csv': ['.csv', '.txt'] },
        maxFiles: 1,
        multiple: false,
        noDragEventsBubbling: true,
        noKeyboard: true,
    });
    const dropzoneBaseStyle: React.CSSProperties = {
        backgroundColor: '#fafafa',
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        borderWidth: '2px',
        color: '#32363A',
        height: '100%',
        position: 'relative',
    };
    const dropzoneStyle = useMemo<React.CSSProperties>(
        () => ({
            ...dropzoneBaseStyle,
            ...(isDragActive || isFocused ? { borderColor: '#2196f3' } : {}),
        }),
        [isDragActive]
    );

    const [tempRecords, setTempRecords] = useState<Array<JSObject>>();
    const [uploadTempTable, setUploadTempTable] = useState<Array<PurchaseOrderCreateData>>([{ ...initialTableData }]);
    const onClickUploadDialogSubmitButton = () => {

        if (acceptedFiles.length === 0) {
            dispatch(MessageActions.push({ content: m('ファイルを選択してください。'), words: [] }));
            return;
        }

        // 以下、react-csv-readerのパクり
        const fileEncoding = 'UTF-8';
        const onError = () => { };
        const parserOptions = {} as PapaParse.ParseConfig;

        let reader: FileReader = new FileReader();
        //const files: FileList = event.target.files!;

        if (acceptedFiles.length > 0) {
            const fileInfo = {
                name: acceptedFiles[0].name,
                size: acceptedFiles[0].size,
                type: acceptedFiles[0].type,
            };

            reader.onload = (_event: Event) => {
                const csvData = PapaParse.parse(
                    reader.result as string,
                    Object.assign(parserOptions, {
                        error: onError,
                        encoding: fileEncoding,
                    }),
                );

                // fileInfoが取得できなかった場合、処理を終了
                if (fileInfo === null)
                    return;

                setTempUploadFile(csvData?.data ?? []);
            }

            reader.readAsText(acceptedFiles[0], fileEncoding);
        }
        // 以上、react-csv-readerのパクり。終わり

        setOpenUploadDialog(false);
        acceptedFiles.splice(0);
    };

    // アップロードファイルのバリデーション
    const validationUploadFile = () => {
        // カスタマー企業(03)の場合、ヘッダーを初期化
        if (user.userType === '03') {
            setSupplier('');
            setSupplierName('');
        }

        // レコードを初期化
        setTempTable([{ ...initialTableData }]);

        let customer = '';
        // CMEs導入企業(01)の場合、searchKeysのcustomerを設定
        if (user.userType === '01') {
            customer = searchKeys.customer ?? '';
        }
        // カスタマー企業の場合、自身のuserIdを設定
        else if (user.userType === '03') {
            customer = user.userId;
        }

        // customerが未入力の場合、エラー
        if (customer == undefined || customer.length == 0) {
            dispatch(MessageActions.push({ content: m('企業IDを入力してください。'), words: [] }));
            return;
        }

        // ヘッダー部以外を読み込み
        let records = Array<JSObject>();
        tempUploadFile?.slice(1).forEach((uploadRecord, index) => {
            // 最終行の要素が全て空の場合、スキップ
            // 上記以外のレコードを保持
            if (Object.values(uploadRecord).join('').length === 0 && tempUploadFile?.slice(1).length - 1 === index) {
                // 処理なし(スキップ)
            } else {
                const record = (new PurchaseOrderUploadRecord({
                    supplier: uploadRecord[0] ?? '',
                    orderId: uploadRecord[1] ?? '',
                    customerMaterial: uploadRecord[2] ?? '',
                    desiredDeliveryDate: uploadRecord[3] ?? '',
                    orderQuantity: uploadRecord[4] ?? '',
                    messageColumn: uploadRecord[5] ?? '',
                    customer: customer
                })).toJS();

                records.push(record);
            }
        });

        // 業務整合性チェック
        // アップロード件数0件
        if (records.length === 0) {
            dispatch(MessageActions.push({ content: m('アップロードデータが0件です。'), words: [] }));
            return;
        }

        // アップロード件数が50件より大きい
        if (records.length > ORDER_CREATE_MAX_VALUE) {
            dispatch(MessageActions.push({ content: m('アップロードデータが50件を超えています。'), words: [] }));
            return;
        }

        // 各レコードのチェック
        let prevTargetCompany = '';
        records.forEach((record, index) => {

            // 必須チェック(サプライヤID、注文番号、品目、希望納期)
            let mandatoryErrorCols: string[] = [];
            if (!record.supplier)
                mandatoryErrorCols.push(t('サプライヤID'));
            if (!record.orderId)
                mandatoryErrorCols.push(t('注文番号'));
            if (!record.customerMaterial)
                mandatoryErrorCols.push(t('品目'));
            if (!record.desiredDeliveryDate)
                mandatoryErrorCols.push(t('希望納期'));
            if (!record.orderQuantity)
                mandatoryErrorCols.push(t('発注数量'));

            if (mandatoryErrorCols.length !== 0) {
                record.result = t('NG');
                record.message += format(m('%sを入力してください。').message, mandatoryErrorCols.join('、'));
            }

            // エラーがある場合はここで処理を中断
            if (record.result === t('NG')) {
                return;
            }

            // 属性チェック②(発注数量)
            let integerOrderQuantityErrorCols: string[] = [];
            if (isErrorOrderQuantity(record.orderQuantity)) {
                integerOrderQuantityErrorCols.push(t('発注数量'));
            }

            if (integerOrderQuantityErrorCols.length !== 0) {
                record.result = t('NG');
                record.message += format(m('%sは数値で入力してください。').message, integerOrderQuantityErrorCols.join('、'));
            }

            // 属性チェック③(希望納期)
            let dateErrorCols: string[] = [];
            if (isErrorDesiredDeliveryDate(record.desiredDeliveryDate)) {
                dateErrorCols.push(t('希望納期'));
            }

            if (dateErrorCols.length !== 0) {
                record.result = t('NG');
                record.message += format(m('%sは日付の形式(YYYY/MM/DD)で入力してください。').message, dateErrorCols.join('、'));
            }

            // エラーがある場合はここで処理を中断
            if (record.result === t('NG')) {
                return;
            }

            // 範囲チェック(発注数量)
            if (Number(record.orderQuantity) <= 0) {
                record.result = t('NG');
                record.message += format(m('発注数量は0より大きい値を入力してください。').message, dateErrorCols.join('、'));
            } else if (10000000000 <= Number(record.orderQuantity)) {
                record.result = t('NG');
                record.message += format(m('発注数量は10000000000より小さい値を入力してください。').message, dateErrorCols.join('、'));
            }

            // エラーがある場合はここで処理を中断
            if (record.result === t('NG')) {
                return;
            }

            // 範囲チェック(希望納期)
            const nowDate = moment(moment().format('YYYY/MM/DD 00:00'));
            if (moment(record.desiredDeliveryDate).isBefore(nowDate)) {
                record.result = t('NG');
                record.message += format(m('希望納期は当日以降を入力してください。').message, dateErrorCols.join('、'));
            }

            // エラーがある場合はここで処理を中断
            if (record.result === t('NG')) {
                return;
            }

            // カスタマー企業(03)の場合、存在チェック(サプライヤID)
            const targetCompany = customerDestinations.find(x => x.companyId === record.supplier);
            if (user.userType === '03' && !targetCompany) {
                record.result = t('NG');
                record.message += format(m('サプライヤIDが存在しません。').message, dateErrorCols.join('、'));
            }

            // エラーがある場合はここで処理を中断
            if (record.result === t('NG')) {
                return;
            }

            // CMEs導入企業の場合の、一意性チェック(ファイルのサプライヤIDとuser.userIdが一意であること)
            if (user.userType === '01' && (record.supplier !== user.userId)) {
                record.result = t('NG');
                record.message += format(m('サプライヤIDには自身のユーザーIDを設定してください。').message, dateErrorCols.join('、'));
            }

            // 一意性チェック(サプライヤID)
            if (prevTargetCompany.length > 0 && prevTargetCompany !== record.supplier) {
                record.result = t('NG');
                record.message += format(m('サプライヤIDが複数存在します。').message, dateErrorCols.join('、'));
            } else {
                prevTargetCompany = record.supplier;
            }

            // エラーがある場合はここで処理を中断
            if (record.result === t('NG')) {
                return;
            }
        });

        if (records.filter(x => x.result === t('NG')).length > 0) {
            // 結果をCSVファイル出力
            exportUploadFileResult(records);
            dispatch(MessageActions.push({ content: m('ファイルにエラーがあります。エラー内容を確認してください。'), words: [] }));
        } else {
            dispatch(PurchaseOrderActions.uploadPurchaseOrders(records));

            uploadTempTable.length = 0;
            records.forEach(x => {
                const row = { ...initialTableData };
                row.supplier = x.supplier;
                row.supplierName = user.userType === '01' ? customerDestinations.find(y => y.companyId === x.supplier)?.companyName ?? '' : user.userNm;
                row.orderId = x.orderId;
                row.customerMaterial = x.customerMaterial;
                row.desiredDeliveryDate = x.desiredDeliveryDate;
                row.orderQuantity = x.orderQuantity;
                row.messageColumn = x.messageColumn;

                uploadTempTable.push(row);
            });

            setUploadTempTable([...uploadTempTable]);
            setTempRecords(records);
        }
    };

    // アップロードの結果を出力
    const exportUploadResult = (fileName: string, records: Array<JSObject>) => {
        if (fileName === '')
            return;

        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const str = records.map((d) => [
            d.supplier,
            d.orderId,
            d.customerMaterial,
            d.desiredDeliveryDate,
            d.orderQuantity,
            d.messageColumn,
            d.result,
            d.message,
        ]);

        const header = [
            t('サプライヤID'),
            t('注文番号'),
            t('品目'),
            t('希望納期'),
            t('発注数量'),
            t('通信欄'),
            t('処理結果'),
            t('メッセージ'),
        ];

        const csv = PapaParse.unparse([header, ...str]);
        const url = window.URL.createObjectURL(new Blob([bom, csv], { type: 'text/csv' }));
        const a = document.createElement('a');

        a.download = fileName;
        a.href = url;
        a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
        a.click();
    };

    // ファイルアップロードテンプレート出力
    const onClickTemplateDownload = () => {
        const fileName = 'purchase order template.csv';
        exportUploadResult(fileName, []);
    };

    // SAPに品目問合せ後の処理
    useEffect(() => {
        if (tempRecords === undefined)
            return;

        if (!didUploadPurchaseOrders)
            return;

        // 存在チェック(品目)
        let records = Array<JSObject>();
        const searchMaterials = uploadPurchaseOrdersResult.toArray();
        tempRecords.forEach((record, index) => {
            const materialTarget = searchMaterials.filter(x => x.customerMaterial === record.customerMaterial);
            if (materialTarget.length > 0) {
                record.result = t('OK');
                record.message = '';
            } else {
                record.result = t('NG');
                record.message = t('品目が存在しません。');
            }

            records.push(record);
        });


        // 結果をCSVファイル出力
        exportUploadFileResult(records);

        // エラーレコードがある場合、処理中断
        if (records.filter(x => x.result === t('NG')).length > 0) {
            dispatch(MessageActions.push({ content: m('ファイルにエラーがあります。エラー内容を確認してください。'), words: [] }));
        } else {

            uploadTempTable.forEach(row => {
                const targetMaterial = searchMaterials.filter(x => x.customerMaterial === row.customerMaterial && Number(x.orderQuantity) === Number(row.orderQuantity))[0];
                row.customerMaterialName = targetMaterial?.customerMaterialName ?? '';
                row.grossPrice = Number(targetMaterial?.grossPrice) ?? '';
                row.salesUnit = targetMaterial?.salesUnit ?? '';
                row.pricePerOneUnit = Number(targetMaterial?.pricePerOneUnit) ?? '';
                row.currency = targetMaterial?.currency ?? '';
            });

            const tempSupplier = uploadTempTable[0].supplier;
            const tempSupplierName = uploadTempTable[0].supplierName;
            setSupplier(tempSupplier);
            setSupplierName(tempSupplierName);

            // アップロードした件数が、最大発注件数未満の場合、新規レコードを追加
            if (uploadTempTable.length < ORDER_CREATE_MAX_VALUE) {
                uploadTempTable.push({ ...initialTableData, supplier: tempSupplier, supplierName: tempSupplierName });
            }

            // 同一注文番号において、再採番
            const newTable = getRenumberingTable(uploadTempTable);
            setTempTable([...newTable]);
        }

        dispatch(PurchaseOrderActions.setDidUploadPurchaseOrders(null));

    }, [didUploadPurchaseOrders]);

    // アップロード結果ファイル出力
    const exportUploadFileResult = (records: Array<JSObject>) => {
        const fileName = 'upload results_' + moment().format('YYYYMMDD_HHmmss') + '.csv';
        exportUploadResult(fileName, records);
    }

    // #endregion

    // 正味額のSAP問合せ後の処理
    useEffect(() => {
        const targetGrossPrices = grossPrices.toArray();

        const tableLength = tempTable.length;

        for (let i = 0; i < tableLength; i++) {
            if (tempTable[i].supplier.length > 0) {
                // 販売条件APIの結果を、サプライヤー×品目×発注数量のキーでセットする
                const targetGrossPrice = targetGrossPrices.find(target =>
                    tempTable[i].supplier === target.supplier &&
                    tempTable[i].customerMaterial === target.customerMaterial &&
                    Number(tempTable[i].orderQuantity) === Number(target.orderQuantity))
                if (targetGrossPrice === undefined) continue;

                tempTable[i].grossPrice = Number(targetGrossPrice.grossPrice);
                tempTable[i].pricePerOneUnit = Number(targetGrossPrice.pricePerOneUnit);
                tempTable[i].orderQuantity = returnAmountText(tempTable[i].orderQuantity);
            }
        }

        setTempTable([...tempTable]);

    }, [grossPrices]);

    // 品目のSAP問合せ後の処理
    useEffect(() => {
        if (customerMaterial.toArray().length > 0 && changedCustomerMaterialIndex !== null) {
            const targetMaterial = customerMaterial.toArray()[0];
            tempTable[changedCustomerMaterialIndex].customerMaterialName = targetMaterial.customerMaterialName;
            tempTable[changedCustomerMaterialIndex].salesUnit = targetMaterial.salesUnit;
            tempTable[changedCustomerMaterialIndex].pricePerOneUnit = Number(targetMaterial.pricePerOneUnit);
            tempTable[changedCustomerMaterialIndex].currency = targetMaterial.currency;

            setTempTable([...tempTable]);
        }
    }, [customerMaterial]);

    // 登録成功後、リストをクリア
    useEffect(() => {
        if (didCreatePurchaseOrders) {
            setSupplier('');
            setSupplierName('');
            setTempTable([{ ...initialTableData }]);
            dispatch(PurchaseOrderActions.setDidCreatePurchaseOrders(null));
        } else if (didCreatePurchaseOrders === false) {
            dispatch(PurchaseOrderActions.setDidCreatePurchaseOrders(null));
        }
    }, [didCreatePurchaseOrders]);

    // ファイルアップロードの読込完了後の処理
    useEffect(() => {

        if (tempUploadFile === undefined) {
            return;
        }

        const ok = () => {
            validationUploadFile();
        }

        const cancel = () => { }

        // 既にレコードがあった場合の削除確認
        if (tempTable.length - 1 > 0) {
            confirmBox('入力中のレコードが破棄されます。よろしいですか？', [], ok, cancel);
        } else {
            validationUploadFile();
        }
    }, [tempUploadFile]);

    // テーブルに変更があった場合、スタイルを再適用
    useEffect(() => {
        applyTableStyle();
    }, [table]);

    useEffect(() => {
        dispatch(CommonParamActions.getCustomerDestinations());
    }, []);

    return (
        <>
            <BusyIndicator hidden={!isConnecting} />
            <LayoutPanel style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'none' }}>
                <LayoutPanel.Filters>
                    <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <PaddingFormItem>
                            <PaddingBottomFormLabel required>{t('企業ID')}</PaddingBottomFormLabel>
                            {user.userType === '01' &&
                                <FormInput
                                    value={customer}
                                    style={{ width: '8rem' }}
                                    maxLength={10}
                                    pattern='^[0-9A-Za-z]+$'
                                    onChange={onChangeCustomerId}
                                    onBlur={onBlurCustomerId}
                                />
                            }
                            {user.userType === '03' &&
                                <FormInput
                                    style={{ width: '8rem' }}
                                    value={user.userId}
                                    disabled
                                />
                            }
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <PaddingBottomFormLabel>{t('サプライヤID')}</PaddingBottomFormLabel>
                            {user.userType === '03' &&
                                <LargeWidthSelect
                                    options={customerDestinations.map((x, index) => ({ key: x.companyId, text: x.companyId + '\t' + x.companyName })).toArray()}
                                    onSelect={onSelectSupplierId}
                                    selectedKey={supplier}
                                />
                            }
                            {user.userType === '01' &&
                                <FormInput
                                    style={{ width: '30rem' }}
                                    value={user.userId + '\t' + user.userNm}
                                    disabled
                                />
                            }
                        </PaddingFormItem>
                    </FormGroup>
                </LayoutPanel.Filters>
                <LayoutPanel.Body style={{ flex: '1 1 auto', height: '0', paddingTop: '20px' }}>
                    <Container style={{ height: '100%' }}>
                        <Row style={{ height: '100%', margin: '0' }}>
                            <Column style={{ height: '100%', overflow: 'auto', padding: '0' }}>
                                <HeaderFreezingTable
                                    ref={table}
                                    compact
                                    condensed
                                    headers={getHeaders()}
                                    tableData={tempTable.map((item, index) => {
                                        return ({
                                            rowData: getRowData(item, index)
                                        });
                                    })}
                                />
                            </Column>
                        </Row>
                    </Container>
                </LayoutPanel.Body>
                <LayoutPanel.Footer>
                    <div style={{ width: '100%' }}>
                        <div className="fd-bar__right">
                            <div className="fd-bar__element">
                                {/* CMEs導入企業(01)の場合、登録不可 */}
                                <Button option='emphasized' onClick={onClickCreateButton} style={{ width: '96px' }} disabled={user.userType === '01'}>{t('登録')}</Button>
                            </div>
                            <div className="fd-bar__element">
                                <Button option='emphasized' onClick={onClickUploadButton}>{t('ファイルUL')}</Button>
                            </div>
                            <div className="fd-bar__element">
                                {/* 最大発注数のレコードがある かつ サプライヤIDが選択済みの場合、コピー不可 */}
                                <Button option='emphasized' disabled={tempTable.length === ORDER_CREATE_MAX_VALUE && tempTable[tempTable.length - 1].orderId.length > 0} onClick={onClickCopyButton}>{t('コピー')}</Button>
                            </div>
                            <div className="fd-bar__element">
                                <Button option='emphasized' onClick={onClickDeleteButton}>{t('削除')}</Button>
                            </div>
                            <NavButtons screenMode={'salesorderList'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
            <CustomDialog
                actions={[
                    (<Button onClick={onClickCancel}>{t('キャンセル')}</Button>)
                ]}
                title={t('品目・価格一覧検索画面')}
                show={isOpenMaterialPriceSearchDialog}
            >
                <div style={{ height: '300px', padding: '10px' }}>
                    <MaterialPriceSearch asValueHelp initialSearchKeys={searchKeys} onSelectMaterial={onSelectMaterial} />
                </div>
            </CustomDialog>
            <FileUploadDialog
                actions={[
                    (<Button option='emphasized' onClick={onClickTemplateDownload} style={{ marginRight: '210px' }}>{t('テンプレートDL')}</Button>),
                    (<Button option='emphasized' onClick={onClickUploadDialogSubmitButton}>{t('実行')}</Button>),
                    (<Button onClick={onClickUploadDialogCancelButton}>{t('キャンセル')}</Button>)
                ]}
                title={t('一括アップロード')}
                show={isOpenUploadDialog}
            >
                <section
                    className='container'
                    style={{ height: '100%' }}
                >
                    <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyle}>
                        <p>{isDragActive ? t('ドロップしてください') : t('ファイルを選択もしくはドラッグ&ドロップしてください')}</p>
                        <input {...getInputProps()} />
                        <aside>
                            <Button compact option='emphasized'>{t('ファイルを選択')}</Button>
                            <label style={{ fontWeight: 'bold' }} >
                                {acceptedFiles.length === 0 ?
                                    t('選択されていません') :
                                    acceptedFiles[0].name}
                            </label>
                        </aside>
                    </div>
                </section>
            </FileUploadDialog>
        </>);
};

export default PurchaseOrderCreate;

const HeaderFreezingTable = styled(Table)`
    &&& thead tr th {
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 5;
        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            width: 100%;
            height: 100%;
            border-top: 1px solid #e4e4e4;
        }
    }
`;

const NoWrapSpan = styled.span`
    white-space: nowrap;
`;

const LargeWidthSelect = styled(Select)`
    width: 15rem;
`;

const CustomDialog = styled(Dialog)`
    z-index: 10;
    
    &&& > .fd-dialog__content {
        width: 650px;
        height: 600px;
        
    }
    &&& .fd-dialog__body:not(:last-child) {
        padding-top: 5px;
        padding-bottom: 0px;
        padding-right: 0px;
        padding-left: 0px;
        overflow: hidden;
        height: 320px;
    }
    &&& footer {
        height: 55px;
    }
`;

const FileUploadDialog = styled(Dialog)`
    z-index: 10;
    
    &&& > .fd-dialog__content {
        width: 550px;
        height: 220px;
        
    }
    &&& .fd-dialog__body:not(:last-child) {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
        overflow: hidden;
        height: 350px;
    }
    &&& footer {
        height: 55px;
    }
`;

const PaddingFormItem = styled(FormItem)`
    padding: 0 10px;
`;

const PaddingBottomFormLabel = styled(FormLabel)`
    padding-bottom: 4px;
`;

const SuggestionLoading = styled.div`
    top: 40px;
    position: absolute;
    box-shadow: 3px 3px 5px #999;
    padding: 10px;
    z-index: 10;
`;

const SuggestionTable = styled(Table)`
    top: 40px;
    position: absolute;
    box-shadow: 3px 3px 5px #999;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 10;
`;

const SuggestionHeader = styled.div`
    text-align: left;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
`;

const Suggestion = styled(SuggestionHeader)`
    cursor: pointer;
`;