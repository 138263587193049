import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { format } from 'util';
import messages from '../../messages';
import { MessagePack } from '../../types';


const getTitle = (message: any) => {
    switch (message.type) {
        case 'N':
            return 'メッセージエラー';
        case 'E':
            return 'エラー';
        case 'W':
            return '確認';
        default:
            return ''
    }
}

//deprecated
export const messageBox = (key: string, params: Array<any>, locale: string = 'ja') => {
    confirmAlert({
        title: getTitle(messages[locale][key]),
        message: format(messages[locale][key].message, ...params),
        buttons: [
            {
                label: 'OK',
                onClick: () => false
            }
        ],
        // childrenElement: () => <div />,
        // customUI: ({onClose}) => <div>test</div>
    })
}

export const displayMessageBox = (packet: MessagePack, handler: () => void) => {
    //messageBox(packet.content.message, packet.words, 'ja');
    const get = (message: any) => {
        switch (message.type) {
            case 'N':
                return '情報';
            case 'E':
                return 'エラー';
            case 'W':
                return '警告';
            default:
                return 'A'
        }
    }
    const title = get(packet.content);
    confirmAlert({
        title: get(packet.content),
        message: format(packet.content.message, ...packet.words),
        buttons: [
            {
                label: 'OK',
                onClick: handler,
            }
        ],
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui' style={{ background: 'white', textAlign: 'center', padding: '20px', border: 'solid 1px gainsboro' }}>
                    {packet.content.type === 'E' ?
                        <>
                            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon style={{ fontSize: '1rem', color: 'red' }} icon={faExclamationCircle} />
                                <h1 style={{ fontSize: '1rem', color: 'red' }}>{title}</h1>
                            </div>
                            <hr style={{ backgroundColor: 'red' }} />
                        </>
                        : packet.content.type === 'N' ?
                            <>
                                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                    <FontAwesomeIcon style={{ fontSize: '1rem', color: 'black' }} icon={faInfoCircle} />
                                    <h1 style={{ fontSize: '1rem', color: 'black' }}>{title}</h1>
                                </div>
                                <hr style={{ backgroundColor: 'black' }} />
                            </>
                            :
                            <>
                                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                    <FontAwesomeIcon style={{ fontSize: '1rem', color: 'orange' }} icon={faExclamationTriangle} />
                                    <h1 style={{ fontSize: '1rem', color: 'orange' }}>{title}</h1>
                                </div>
                                <hr style={{ backgroundColor: 'orange' }} />
                            </>
                    }
                    <p>{format(packet.content.message, ...packet.words)}</p>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button id='alert_ok_button' autoFocus onClick={() => {
                            handler();
                            onClose();
                        }}>OK</button>
                    </div>
                </div>
            )
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
    })
}