import actionCreatorFactory from 'typescript-fsa';
import { PaymentDetails } from '../models/PaymentDetail';

const actionCreator = actionCreatorFactory('PaymentDetail');

export const PaymentDetailActions = {
    // キー項目に基づき支払明細を取得する。
    getPaymentDetails: actionCreator<{ orderCompany: string, supplier: string, paymentNo: string, revision: string }>('getPaymentDetails'),
    // apiに接続中か否か
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    // 検索に成功したか否か
    setDidGetSuccess: actionCreator<boolean | null>('setDidGetSuccess'),
    // 検索により取得した支払データを保持する。
    setPaymentDetails: actionCreator<PaymentDetails>('setPaymentDetails'),
    // キー項目に基づき支払書PDFを取得する。
    getPaymentDetailsPdf: actionCreator<{ orderCompany: string, supplier: string, paymentNo: string, revision: string }>('getPaymentDetailsPdf'),
    // 支払PDF取得に成功したか否か
    setDidGetSuccessPdf: actionCreator<boolean | null>('setDidGetSuccessPdf'),
};