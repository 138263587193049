import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { InformationMaintenanceActions } from '../actions/informationMaintenance';
import { Messages } from '../models/Message';

export class InformationMaintenanceState extends Record<{
    messages: Messages;
    isConnecting: boolean;
    didSucceedSearch: boolean | null;
    didSucceedCreate: boolean | null;
    didSucceedUpdate: boolean | null;
    didSucceedDelete: boolean | null;
}>({
    messages: new Messages(),
    isConnecting: false,
    didSucceedSearch: null,
    didSucceedCreate: null,
    didSucceedUpdate: null,
    didSucceedDelete: null,
}) { }

export const InformationMaintenanceReducer = reducerWithInitialState(new InformationMaintenanceState())
    .case(InformationMaintenanceActions.setMessages, (state, payload) => {
        return state.set('messages', payload);
    })
    .case(InformationMaintenanceActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(InformationMaintenanceActions.setDidSucceedSearch, (state, payload) => {
        return state.set('didSucceedSearch', payload);
    })
    .case(InformationMaintenanceActions.setDidSucceedCreate, (state, payload) => {
        return state.set('didSucceedCreate', payload);
    })
    .case(InformationMaintenanceActions.setDidSucceedUpdate, (state, payload) => {
        return state.set('didSucceedUpdate', payload);
    })
    .case(InformationMaintenanceActions.setDidSucceedDelete, (state, payload) => {
        return state.set('didSucceedDelete', payload);
    })
    ;