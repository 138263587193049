import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export type WordResources = {
    en: {
        translation: { [id: string]: string }
    };
    ja: {
        translation: { [id: string]: string }
    };
}

export const resources: WordResources = {
    en: {
        translation: {
            "最大該当数": "",
            "検索": "",
            "保存": "",
            "終了": "",
            "ユーザーID": "",
            "パスワード": "",
            "ログイン": "",
            "パスワード変更": "",
            "新パスワード": "",
            "新パスワード(確認)": "",
            "受注日": "",
            "～": "",
            "注文ステータス": "",
            "納期回答済": "",
            "納品書出力済": "",
            "検収済": "",
            "キャンセル": "",
            "企業": "",
            "企業ID": "",
            "企業名": "",
            "注文ID": "",
            "明細No.": "",
            "品目": "",
            "品目名": "",
            "希望納期": "",
            "回答納期": "",
            "回答納期(入力)": "",
            "受注数": "",
            "数量": "",
            "回答数量": "",
            "回答数量(入力)": "",
            "納品先住所": "",
            "納品先": "",
            "単位": "",
            "ステータス": "",
            "受注": "",
            "CSV": "",
            "回答": "",
            "納品書出力": "",
            "検索に戻る": "",
            "出力": "",
            "利用規約": "",
            "お問い合わせ": "",
            "support(アットマーク)supply-ya.com": "",
            "推奨ブラウザ": "",
            "Chrome(最新)、Edge(最新)": "",
            "通信欄": "",
            "通信欄履歴": "",
            "支払検索画面": "",
            "受注検索画面": "",
            "メインメニュー": "",
            "支払番号": "",
            "照合ステータス": "",
            "対象期間": "",
            "対象期間(開始)": "",
            "対象期間(終了)": "",
            "リビジョン": "",
            "リビジョンステータス": "",
            "支払金額(本体)": "",
            "リビジョン通信欄": "",
            "新規": "",
            "承認": "",
            "却下": "",
            "再公開": "",
            "取消": "",
            "(保存中)": "",
            "仕入区分": "",
            "納入日付": "",
            "納入数": "",
            "単価": "",
            "金額(本体)": "",
            "備考": "",
            "一時保存": "",
            "戻る": "",
            "ビュー設定": "",
            "全て解除": "",
            "全て選択": "",
            "OK": "",
            "サプライヤID": "",
            "再印刷": "",
            "一部検収済": "",
            "列": "",
            "ソート": "",
            "ソート基準": "",
            "昇順": "",
            "降順": "",
            "通貨": "",
            "管理メニュー": "",
            "インフォメーションマスタメンテナンス": "",
            "タイトル": "",
            "本文": "",
            "有効開始日": "",
            "有効終了日": "",
            "追加": "",
            "変更": "",
            "削除": "",
            "対応必要": "",
            "現在対応が必要な項目はありません": "",
            "新規受注があります。内容確認してください。": "",
            "受注が新規登録されました。受注検索画面より内容確認し、納期回答してください。": "",
            "キャンセル受注があります。内容確認してください。": "",
            "受注がキャンセルされました。受注検索画面より内容確認してください。本通知はキャンセル日より3日後に削除されます。": "",
            "更新された通信欄があります。内容確認してください。": "",
            "受注の通信欄に更新がありました。受注検索画面より内容確認してください。本通知は更新日より3日後、または通信欄回答次第削除されます。": "",
            "新規公開された支払明細書があります。内容確認してください。": "",
            "支払明細書が新規公開されました。内容確認し、承認または却下してください。": "",
            "再公開された支払明細書があります。内容確認してください。": "",
            "支払明細書が再公開されました。内容確認し、承認または却下してください。": "",
            "お知らせ": "",
            "お知らせはありません": "",
            "注文番号": "",
            "発注日": "",
            "発注": "",
            "納期変更済": "",
            "一部出荷済": "",
            "出荷済": "",
            "金額": "",
            "発注検索画面": "",
            "発注登録画面": "",
            "出荷実績検索画面": "",
            "品目・価格検索画面": "",
            "TSV": "",
            "変更納期": "",
            "発注数": "",
            "発注数量": "",
            "変更数量": "",
            "正味額": "",
            "正味価格": "",
            "発注登録者": "",
            "備考欄": "",
            "サプライヤ名称": "",
            "発注企業": "",
            "サプライヤ": "",
            "発注日付": "",
            "完納": "",
            "得意先品目": "",
            "CMEs品目": "",
            "CMEs品名": "",
            "出荷数量": "",
            "発注残": "",
            "CMEs企業": "",
            "DWOS企業": "",
            "発注ステータス": "",
            "注文明細No.": "",
            "初回発注数量": "",
            "正式発注数量": "",
            "通信履歴": "",
            "企業名称": "",
            "品目・価格一覧画面": "",
            "品目・価格一覧検索画面": "",
            "品目が存在しません。": "",
            "ファイルを選択もしくはドラッグ&ドロップしてください": "",
            "ドロップしてください": "",
            "ファイルを選択": "",
            "選択されていません": "",
            "No.": "",
            "品名": "",
            "登録": "",
            "ファイルUL": "",
            "コピー": "",
            "テンプレートDL": "",
            "実行": "",
            "一括アップロード": "",
            "NG": "",
            "合計出荷数量": "",
            "合計金額": "",
            "出荷伝票": "",
            "みなし着荷日": "",
            "出荷日付": "",
            "CMEs出荷伝票番号": "",
            "CMEs出荷明細No.": "",
            "CMEs受注伝票番号": "",
            "明細書出力": "",
            "サプライ屋": "",
            "DWOS": "",
            "税率": "",
            "この品目は削除されているため、新規発注することができません。": "",
            "多要素認証": "",
            "アカウントはMulti-Factor Authentication(MFA)を使用して保護されています。": "",
            "メールアドレスに送信された、認証コードを入力してください。": "",
            "認証コード:": "",
            "送信": "",
            "認証コードを再送する": "",
            "MFA方法": "",
            "メールアドレス": "",
            "メール": "",
            "読込中…": "",
        }
    },
    ja: {
        translation: {
            "最大該当数": "最大該当数",
            "検索": "検索",
            "保存": "保存",
            "終了": "終了",
            "ユーザーID": "ユーザーID",
            "パスワード": "パスワード",
            "ログイン": "ログイン",
            "パスワード変更": "パスワード変更",
            "新パスワード": "新パスワード",
            "新パスワード(確認)": "新パスワード(確認)",
            "受注日": "受注日",
            "～": "～",
            "注文ステータス": "注文ステータス",
            "納期回答済": "納期回答済",
            "納品書出力済": "納品書出力済",
            "検収済": "検収済",
            "キャンセル": "キャンセル",
            "企業": "企業",
            "企業ID": "企業ID",
            "企業名": "企業名",
            "注文ID": "注文ID",
            "明細No.": "明細No.",
            "品目": "品目",
            "品目名": "品目名",
            "希望納期": "希望納期",
            "回答納期": "回答納期",
            "回答納期(入力)": "回答納期(入力)",
            "受注数": "受注数",
            "数量": "数量",
            "回答数量": "回答数量",
            "回答数量(入力)": "回答数量(入力)",
            "納品先住所": "納品先住所",
            "納品先": "納品先",
            "単位": "単位",
            "ステータス": "ステータス",
            "受注": "受注",
            "CSV": "CSV",
            "回答": "回答",
            "納品書出力": "納品書出力",
            "検索に戻る": "検索に戻る",
            "出力": "出力",
            "利用規約": "利用規約",
            "お問い合わせ": "お問い合わせ",
            "support(アットマーク)supply-ya.com": "support(アットマーク)supply-ya.com",
            "推奨ブラウザ": "推奨ブラウザ",
            "Chrome(最新)、Edge(最新)": "Chrome(最新)、Edge(最新)",
            "通信欄": "通信欄",
            "通信欄履歴": "通信欄履歴",
            "支払検索画面": "支払検索画面",
            "受注検索画面": "受注検索画面",
            "メインメニュー": "メインメニュー",
            "支払番号": "支払番号",
            "照合ステータス": "照合ステータス",
            "対象期間": "対象期間",
            "対象期間(開始)": "対象期間(開始)",
            "対象期間(終了)": "対象期間(終了)",
            "リビジョン": "リビジョン",
            "リビジョンステータス": "リビジョンステータス",
            "支払金額(本体)": "支払金額(本体)",
            "リビジョン通信欄": "リビジョン通信欄",
            "新規": "新規",
            "承認": "承認",
            "却下": "却下",
            "再公開": "再公開",
            "取消": "取消",
            "(保存中)": "(保存中)",
            "仕入区分": "仕入区分",
            "納入日付": "納入日付",
            "納入数": "納入数",
            "単価": "単価",
            "金額(本体)": "金額(本体)",
            "備考": "備考",
            "一時保存": "一時保存",
            "戻る": "戻る",
            "ビュー設定": "ビュー設定",
            "全て解除": "全て解除",
            "全て選択": "全て選択",
            "OK": "OK",
            "サプライヤID": "サプライヤID",
            "再印刷": "再印刷",
            "一部検収済": "一部検収済",
            "列": "列",
            "ソート": "ソート",
            "ソート基準": "ソート基準",
            "昇順": "昇順",
            "降順": "降順",
            "通貨": "通貨",
            "管理メニュー": "管理メニュー",
            "インフォメーションマスタメンテナンス": "インフォメーションマスタメンテナンス",
            "タイトル": "タイトル",
            "本文": "本文",
            "有効開始日": "有効開始日",
            "有効終了日": "有効終了日",
            "追加": "追加",
            "変更": "変更",
            "削除": "削除",
            "対応必要": "対応必要",
            "現在対応が必要な項目はありません": "現在対応が必要な項目はありません",
            "新規受注があります。内容確認してください。": "新規受注があります。内容確認してください。",
            "受注が新規登録されました。受注検索画面より内容確認し、納期回答してください。": "受注が新規登録されました。\r\n受注検索画面より内容確認し、納期回答してください。",
            "キャンセル受注があります。内容確認してください。": "キャンセル受注があります。内容確認してください。",
            "受注がキャンセルされました。受注検索画面より内容確認してください。本通知はキャンセル日より3日後に削除されます。": "受注がキャンセルされました。\r\n受注検索画面より内容確認してください。\r\n本通知はキャンセル日より3日後に削除されます。",
            "更新された通信欄があります。内容確認してください。": "更新された通信欄があります。内容確認してください。",
            "受注の通信欄に更新がありました。受注検索画面より内容確認してください。本通知は更新日より3日後、または通信欄回答次第削除されます。": "受注の通信欄に更新がありました。\r\n受注検索画面より内容確認してください。\r\n本通知は更新日より3日後、または通信欄回答次第削除されます。",
            "新規公開された支払明細書があります。内容確認してください。": "新規公開された支払明細書があります。内容確認してください。",
            "支払明細書が新規公開されました。内容確認し、承認または却下してください。": "支払明細書が新規公開されました。\r\n内容確認し、承認または却下してください。",
            "再公開された支払明細書があります。内容確認してください。": "再公開された支払明細書があります。内容確認してください。",
            "支払明細書が再公開されました。内容確認し、承認または却下してください。": "支払明細書が再公開されました。\r\n内容確認し、承認または却下してください。",
            "お知らせ": "お知らせ",
            "お知らせはありません": "お知らせはありません",
            "注文番号": "注文番号",
            "発注日": "発注日",
            "発注": "発注",
            "納期変更済": "納期変更済",
            "一部出荷済": "一部出荷済",
            "出荷済": "出荷済",
            "金額": "金額",
            "発注検索画面": "発注検索画面",
            "発注登録画面": "発注登録画面",
            "出荷実績検索画面": "出荷実績検索画面",
            "品目・価格検索画面": "品目・価格検索画面",
            "TSV": "TSV",
            "変更納期": "変更納期",
            "発注数": "発注数",
            "発注数量": "発注数量",
            "変更数量": "変更数量",
            "正味額": "正味額",
            "正味価格": "正味価格",
            "発注登録者": "発注登録者",
            "備考欄": "備考欄",
            "サプライヤ名称": "サプライヤ名称",
            "発注企業": "発注企業",
            "サプライヤ": "サプライヤ",
            "発注日付": "発注日付",
            "完納": "完納",
            "得意先品目": "得意先品目",
            "CMEs品目": "CMEs品目",
            "CMEs品名": "CMEs品名",
            "出荷数量": "出荷数量",
            "発注残": "発注残",
            "CMEs企業": "CMEs企業",
            "DWOS企業": "DWOS企業",
            "発注ステータス": "発注ステータス",
            "注文明細No.": "注文明細No.",
            "初回発注数量": "初回発注数量",
            "正式発注数量": "正式発注数量",
            "通信履歴": "通信履歴",
            "企業名称": "企業名称",
            "品目・価格一覧画面": "品目・価格一覧画面",
            "品目・価格一覧検索画面": "品目・価格一覧検索画面",
            "品目が存在しません。": "品目が存在しません。",
            "ファイルを選択もしくはドラッグ&ドロップしてください": "ファイルを選択もしくはドラッグ&ドロップしてください",
            "ドロップしてください": "ドロップしてください",
            "ファイルを選択": "ファイルを選択",
            "選択されていません": "選択されていません",
            "No.": "No.",
            "品名": "品名",
            "登録": "登録",
            "ファイルUL": "ファイルUL",
            "コピー": "コピー",
            "テンプレートDL": "テンプレートDL",
            "実行": "実行",
            "一括アップロード": "一括アップロード",
            "NG": "NG",
            "合計出荷数量": "合計出荷数量",
            "合計金額": "合計金額",
            "出荷伝票": "出荷伝票",
            "みなし着荷日": "みなし着荷日",
            "出荷日付": "出荷日付",
            "CMEs出荷伝票番号": "CMEs出荷伝票番号",
            "CMEs出荷明細No.": "CMEs出荷明細No.",
            "CMEs受注伝票番号": "CMEs受注伝票番号",
            "明細書出力": "明細書出力",
            "サプライ屋": "サプライ屋",
            "DWOS": "DWOS",
            "税率": "税率",
            "多要素認証": "多要素認証",
            "アカウントはMulti-Factor Authentication(MFA)を使用して保護されています。": "アカウントはMulti-Factor Authentication(MFA)を使用して保護されています。",
            "メールアドレスに送信された、認証コードを入力してください。": "メールアドレスに送信された、認証コードを入力してください。",
            "認証コード:": "認証コード:",
            "送信": "送信",
            "認証コードを再送する": "認証コードを再送する",
            "MFA方法": "MFA方法",
            "メールアドレス": "メールアドレス",
            "メール": "メール",
            "この品目は削除されているため、新規発注することができません。": "この品目は削除されているため、新規発注することができません。",
            "読込中…": "読込中…",
        }
    }
};

i18n?.use(initReactI18next).init({
    lng: 'ja',
    resources,
    fallbackLng: 'ja',
    debug: true,
    keySeparator: false,
    nsSeparator: false,  // i18nでコロン(:)を利用するため

    interpolation: {
        escapeValue: false
    }
});

i18n?.loadLanguages(['ja'], (err) => { console.error('i18n.languages') });

export default i18n;