import { Button, DatePicker, FormGroup, FormInput, FormItem, FormLabel, InputGroup, Select } from 'fundamental-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MessageActions } from '../../../actions/message';
import { DeliveryActions } from '../../../actions/delivery';
import { Delivery, SearchKeys } from '../../../models/Delivery';
import { State } from '../../../reducers';
import { DatePickerParam, JSObject, SelectOption, HeaderVariant } from '../../../types';
import { returnAmountText, returnQuantityText } from '../../../utils/CommonFunction';
import { backcolor0 } from '../../colors';
import { CommonParamActions } from '../../../actions/commonParam';
import { getWord as w, getMessage as m } from '../../../messages';

type Props = {
    initialSearchKeys?: SearchKeys;
}

const LayoutFilter: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const DEFAULT_LIMIT_NUMBER = '500';
    const MAX_LIMIT_NUMBER = '1000';

    const {
        deliveries,
        user,
        userType,
        customerDestinations,
    } = useSelector((state: State) => ({
        deliveries: state.delivery.deliveries,
        user: state.user.user,
        userType: state.user.userType,
        customerDestinations: state.commonParam.customerDestinations.list,
    }));

    const [searchKeys, setSearchKeys] = useState<SearchKeys>({
        ...props.initialSearchKeys ?? {} as SearchKeys,
        limitNumber: DEFAULT_LIMIT_NUMBER,
    });
    const [totalAmountTotal, setTotalAmountTotal] = useState<number>(0);
    const [totalQuantity, setTotalQuantity] = useState<number>(0);
    const [totalAmountTotalCurrency, setTotalAmountTotalCurrency] = useState<string>('');
    const [totalQuantityUnit, setTotalQuantityUnit] = useState<string>('');

    useEffect(() => {
        document.body.style.background = backcolor0;

        // カスタマユーザの場合、企業ID欄にカスタマユーザIDを表示
        if (userType === 'customer') {
            searchKeys.customerId = user.userId;
        }
        // CMEs導入企業の場合、サプライヤ欄にユーザ名を表示
        else if (userType === 'company') {
            searchKeys.supplierId = user.userId;
        }
        // みなし着荷日(開始日)に月初を設定
        searchKeys.actualDeliveryDateFrom = searchKeys.actualDeliveryDateFrom ?? new Date(moment().startOf('month').format('YYYY-MM-DD 09:00'));
        // みなし着荷日(終了日)に月末を設定
        searchKeys.actualDeliveryDateTo = searchKeys.actualDeliveryDateTo ?? new Date(moment().endOf('month').format('YYYY-MM-DD 09:00'));

        // 検索フォームの合計数量、合計金額を初期化
        setTotalQuantity(0);
        setTotalAmountTotal(0);
        setTotalQuantityUnit('');
        setTotalAmountTotalCurrency('');

        setSearchKeys({ ...searchKeys });
    }, [user, userType]);

    useEffect(() => {
        const arrayDelivery = deliveries.list.toArray();
        // 単位のみ取り出し重複チェック
        const deliveryQuantityUnit = arrayDelivery.map(x => x['deliveryQuantityUnit']);
        const duplicateDeliveryQuantityUnit = deliveryQuantityUnit.filter((x, i) => {
            if (deliveryQuantityUnit.indexOf(x) === i) { return x };
        });
        // 通貨のみ取り出し重複チェック
        const currency = arrayDelivery.map(x => x['currency']);
        const duplicateCurrency = currency.filter((x, i) => {
            if (currency.indexOf(x) === i) { return x };
        });
        // 単位の重複が1(単一)の場合は合計数量を計算し設定
        if (duplicateDeliveryQuantityUnit.length === 1) {
            const totalQuantity = arrayDelivery.map(x => x['deliveryQuantity']).reduce((x, y) => {
                return x + y;
            });
            setTotalQuantity(totalQuantity);
            setTotalQuantityUnit(duplicateDeliveryQuantityUnit[0]);
        }
        else {
            setTotalQuantity(0);
            setTotalQuantityUnit('');
        }
        // 通貨の重複が1(単一)の場合は合計金額を計算し設定
        if (duplicateCurrency.length === 1) {
            const totalAmountTotal = arrayDelivery.map(x => x['amountTotal']).reduce((x, y) => {
                return x + y;
            });
            setTotalAmountTotal(totalAmountTotal);
            setTotalAmountTotalCurrency(duplicateCurrency[0]);
        }
        else {
            setTotalAmountTotal(0);
            setTotalAmountTotalCurrency('');
        }
    }, [deliveries]);

    useEffect(() => {
        dispatch(CommonParamActions.getCustomerDestinations());
    }, []);

    /**
     * 検索フォーム内の変更時ハンドラ: 企業ID
     * @param event
     */
    const onChangeCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.customerId = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 検索フォーム内の変更時ハンドラ: サプライヤ企業ID
     * @param event
     * @param selectedOption
     */
    const onSelectedSupplierId = (event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>, selectedOption: SelectOption) => {
        searchKeys.supplierId = selectedOption.key !== 'emptyOption' ? selectedOption.key : undefined;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 検索フォーム内の変更時ハンドラ: 注文番号
     * @param event
     */
    const onChangeOrderId = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.orderId = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 検索フォーム内の変更時ハンドラ: 得意先品目
     * @param event
     */
    const onChangeCustomerMaterial = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.customerMaterial = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * みなし着荷日(開始日)変更イベントハンドラ
     * @param param
     */
    const onInpurBlurDeliveryDateFrom = (param: DatePickerParam) => {
        const strDate = param.isoFormattedDate;
        searchKeys.actualDeliveryDateFrom = moment(strDate).isValid() ? new Date(strDate) : undefined;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * みなし着荷日(終了日)変更イベントハンドラ
     * @param param
     */
    const onInpurtBlurDeliveryDateTo = (param: DatePickerParam) => {
        const strDate = param.isoFormattedDate;
        searchKeys.actualDeliveryDateTo = moment(strDate).isValid() ? new Date(strDate) : undefined;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 最大該当数確定イベントハンドラ
     * @param event
     */
    const onBlurLimitNumber = (event: React.FocusEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        if (Number(newValue) < 1 || isNaN(Number(newValue))) {
            // 件数が0以下や形式エラーの場合、デフォルト値をセット
            searchKeys.limitNumber = DEFAULT_LIMIT_NUMBER;
        } else if (Number(newValue) > Number(MAX_LIMIT_NUMBER)) {
            // 件数が最大値オーバーの場合、最大値をセット
            searchKeys.limitNumber = MAX_LIMIT_NUMBER;
        } else {
            searchKeys.limitNumber = newValue;
        }

        setSearchKeys({ ...searchKeys });
    };

    /**
     * 最大該当数変更イベントハンドラ
     * @param event
     */
    const onChangeLimitNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        if (isNaN(Number(newValue))) {
            // 件数が形式エラーの場合、値を変更しない
            return;
        }

        searchKeys.limitNumber = newValue;
        setSearchKeys({ ...searchKeys });
    };


    /**
     * 検索ボタン押下イベントハンドラ
     * @param event
     */
    const onClickSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // 入力必須項目確認
        const invalidKeys: Array<string> = [];
        // 必須チェック 企業ID
        if ((searchKeys.customerId ?? '').length === 0)
            invalidKeys.push(w('企業ID'));

        // 必須チェック みなし着荷日
        if (searchKeys.actualDeliveryDateFrom == undefined && searchKeys.actualDeliveryDateTo == undefined)
            invalidKeys.push(w('みなし着荷日'));

        if(invalidKeys.length > 0)
        {
            const keys = Array.from(invalidKeys.values()).join(', ');
            dispatch(MessageActions.push({ content: m('項目 %s：必須入力項目に値が入力されていません。'), words: [keys] }));
            return;
        }

        dispatch(DeliveryActions.search(searchKeys));
    };

    return (
        <>
            <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
                <PaddingFormItem>
                    <FormLabel required>{t('企業ID')}</FormLabel>
                    <FormInput
                        value={searchKeys.customerId ?? ''}
                        style={{ width: '8rem' }}
                        maxLength={10}
                        pattern='^[0-9A-Za-z]+$'
                        onChange={onChangeCustomer}
                        disabled={userType === 'customer' ? true : false}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('サプライヤID')}</FormLabel>
                    <div style={{ display: userType === 'customer' ? '' : 'none' }}>
                        <LargeWidthSelect
                            options={customerDestinations.map((x, index) => ({ key: x.companyId, text: x.companyId + '\t' + x.companyName })).toArray()}
                            includeEmptyOption
                            onSelect={onSelectedSupplierId}
                            selectedKey={searchKeys.supplierId ?? ''}
                            disabled={userType === 'company' ? true : false}
                        />
                    </div>
                    <div style={{ display: userType === 'customer' ? 'none' : '' }}>
                        <FormInput
                            style={{ width: '15rem' }}
                            disabled
                            value={user.userId + '\t' + user.userNm}
                        />
                    </div>
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('注文番号')}</FormLabel>
                    <FormInput
                        value={searchKeys.orderId ?? ''}
                        style={{ width: '15rem' }}
                        maxLength={35}
                        pattern='^[0-9A-Za-z]+$'
                        onChange={onChangeOrderId}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('品目')}</FormLabel>
                    <FormInput
                        value={searchKeys.customerMaterial ?? ''}
                        maxLength={35}
                        style={{ width: '15rem' }}
                        onChange={onChangeCustomerMaterial}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel required={true}>{t('みなし着荷日')}</FormLabel>
                    <NoPaddingInputGroup>
                        <DatePicker
                            defaultValue={
                                searchKeys.actualDeliveryDateFrom !== undefined && moment(searchKeys.actualDeliveryDateFrom).isValid() ?
                                    moment(searchKeys.actualDeliveryDateFrom).format('YYYY/MM/DD') :
                                    ''
                            }
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            style={{ maxWidth: '10rem', margin: '3px' }}
                            onInputBlur={onInpurBlurDeliveryDateFrom}
                        />
                        <InputGroup.Addon>{t('～')}</InputGroup.Addon>
                        <DatePicker
                            defaultValue={
                                searchKeys.actualDeliveryDateTo !== undefined && moment(searchKeys.actualDeliveryDateTo).isValid() ?
                                    moment(searchKeys.actualDeliveryDateTo).format('YYYY/MM/DD') :
                                    ''
                            }
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            style={{ maxWidth: '10rem', margin: '3px' }}
                            onInputBlur={onInpurtBlurDeliveryDateTo}
                        />
                    </NoPaddingInputGroup>
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel required={true}>{t('最大該当数')}</FormLabel>
                    <FormInput
                        value={searchKeys.limitNumber}
                        style={{ textAlign: 'right', width: '5rem' }}
                        onBlur={onBlurLimitNumber}
                        onChange={onChangeLimitNumber}
                    />
                </PaddingFormItem>
            </FormGroup>
            <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
                <PaddingFormItem>
                    <FormLabel>{t('合計出荷数量')}</FormLabel>
                    <FormInput
                        readOnly
                        style={{ textAlign: 'right', width: '10rem' }}
                        value={totalQuantity === 0 ? '' : returnQuantityText(totalQuantity.toString())}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('単位')}</FormLabel>
                    <FormInput
                        readOnly
                        value={totalQuantityUnit}
                        style={{ textAlign: 'left', width: '5rem' }}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('合計金額')}</FormLabel>
                    <FormInput
                        readOnly
                        style={{ textAlign: 'right', width: '10rem' }}
                        value={totalAmountTotal === 0 ? '' : returnAmountText(totalAmountTotal.toString())}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('通貨')}</FormLabel>
                    <FormInput
                        readOnly
                        value={totalAmountTotalCurrency}
                        style={{ textAlign: 'left', width: '5rem' }}
                    />
                </PaddingFormItem>
            </FormGroup>
            <div style={{ width: '100%' }}>
                <div className='fd-bar__right'>
                    <div className='fd-bar__element'>
                        <Button id='search_button' onClick={onClickSearch} option='emphasized' style={{ width: '96px' }}>{t('検索')}</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

const NoPaddingInputGroup = styled(InputGroup)`
	    border: 0;
	    &&& > .fd-input-group__input:nth-child(1) {
	        padding-left: 0;
	    }
	    &&& > .fd-input-group__input:nth-last-child(1) {
	        padding-right: 0;
	    }
	`;

const PaddingFormItem = styled(FormItem)`
	    padding: 0 10px;
	`;

const LargeWidthSelect = styled(Select)`
	    margin: 4px;
	    width: 15rem;
	`;

export default LayoutFilter;
