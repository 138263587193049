import actionCreatorFactory from 'typescript-fsa';
import { CustomerDestinations } from '../models/CommonParam';

const actionCreator = actionCreatorFactory('CommonParam');

export const CommonParamActions = {
    setMandt: actionCreator<string>('setMandt'),

    // カスタマー接続先を取得
    getCustomerDestinations: actionCreator('getCustomerDestinations'),
    // カスタマー接続先を保持
    setCustomerDestinations: actionCreator<CustomerDestinations>('setCustomerDestinations'),
}