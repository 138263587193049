import React, { Component } from 'react';
//import { NavMenu } from './Header';
import Header from './molecures/Header';

import { createGlobalStyle } from 'styled-components';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <GlobalStyle />
                <Header />
                <div style={{ height: `calc(100vh - 96px)`, position: 'relative' }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const GlobalStyle = createGlobalStyle`
    li.selected span {
        background: lightsteelblue;
    }
    select:disabled,
    input:disabled {
        background: gainsboro;
        color: inherit;
    }
    select.invalid, input.invalid, span.invalid {
        outline: solid 1px red;
    }
    span.looksLikeLink {
        color: blue;
        text-decoration: underline;
    }
    span.alert {
        color: red;
    }

`