import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Cookies } from 'react-cookie';
import { User } from '../models/User';
import { UserApi } from '../apiClient/user';
import { UserActions } from '../actions/user';
import { MessageActions } from '../actions/message';
import { getMessage as m } from '../messages';
import moment from 'moment';

function* login(action: ReturnType<typeof UserActions.login>) {
    const authInfo = action.payload;
    yield put(UserActions.setIsConnecting(true));
    const response = yield UserApi.login(authInfo);

    if (response.isSuccess) {
        yield put(UserActions.setIsLogin(true));
        yield put(UserActions.setUser(User.fromResponse(response.data.user)));

        // クッキー保存
        const cookies = new Cookies();
        cookies.set('token', response.data.access_token, { secure: true, sameSite: 'strict' });
        cookies.set('refreshToken', response.data.refresh_token, { secure: true, sameSite: 'strict' });

        //MFA対象ユーザーの場合、MFA画面に遷移
        if (response.data.user.mfaRequired === true) {

            // LocalStorageからMFA用UUIDを取得
            const uuid = localStorage.getItem('supplier_mfa_uuid');

            const verifyResponse = yield UserApi.verifyMfaDeviceInformation(uuid ?? 'uuid'); // Apiに空文字を送れない為、'uuid'を送信
            if (verifyResponse.isSuccess) {
                // 端末認証済みの場合、メインメニューに遷移
                if (verifyResponse.data.isAuthenticatedDevice) {
                    yield put(UserActions.setUser(User.fromResponse(verifyResponse.data.user)));

                    const cookies = new Cookies();
                    cookies.set('token', verifyResponse.data.access_token, { secure: true });
                    cookies.set('refreshToken', verifyResponse.data.refresh_token, { secure: true });

                    switch (verifyResponse.data.user.userType) {
                        case '01':
                            yield put(UserActions.setUserType('company'));
                            yield put(push('/menu'));
                            break;
                        case '02':
                            yield put(UserActions.setUserType('supplier'));
                            yield put(push('/menu'));
                            break;
                        case '03':
                            yield put(UserActions.setUserType('customer'));
                            yield put(push('/menu'));
                            break;
                        case '99':
                            yield put(UserActions.setUserType('admin'));
                            yield put(push('/menu'));
                            break;
                        default:
                            yield put(UserActions.setUserType('supplier'));
                            yield put(push('/menu'));
                            break;
                    }
                }
                // 端末未認証 or 認証期限切れの場合、MFA画面へ遷移
                else {
                    yield put(push('/mfa'));
                    const mfaResponse = yield UserApi.createOtp(authInfo);
                    if (mfaResponse.isSuccess) {
                        const mfaUser = mfaResponse.data?.user;
                        // API成功かつ取得メールアドレス0件の場合、MFA画面をとばしメニュー画面に遷移
                        if (mfaUser) {
                            yield put(UserActions.setUser(User.fromResponse(mfaResponse.data.user)));

                            const cookies = new Cookies();
                            cookies.set('token', mfaResponse.data.access_token, { secure: true, sameSite: 'strict' });
                            cookies.set('refreshToken', mfaResponse.data.refresh_token, { secure: true, sameSite: 'strict' });

                            switch (mfaUser.userType) {
                                case '01':
                                    yield put(UserActions.setUserType('company'));
                                    yield put(push('/menu'));
                                    break;
                                case '02':
                                    yield put(UserActions.setUserType('supplier'));
                                    yield put(push('/menu'));
                                    break;
                                case '03':
                                    yield put(UserActions.setUserType('customer'));
                                    yield put(push('/menu'));
                                    break;
                                case '99':
                                    yield put(UserActions.setUserType('admin'));
                                    yield put(push('/menu'));
                                    break;
                                default:
                                    yield put(UserActions.setUserType('supplier'));
                                    yield put(push('/menu'));
                                    break;
                            }
                        }
                        // API成功かつメールアドレス1件以上の場合
                        else {
                            // 処理なし
                        }
                    } else {
                        // API失敗の場合、エラーメッセージを出力
                        const statusCode = mfaResponse.error?.response?.status;
                        switch (statusCode) {
                            case 500:
                                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                                break;
                            default:
                                yield put(MessageActions.push({ content: m('通信に失敗しました。「認証コードを再送する」を押下してください。'), words: [] }));
                                break;
                        }
                    }
                }
            }
            else {
                yield put(MessageActions.push({ content: m('通信に失敗しました。'), words: [] }));
            }
        }
        // MFA非対象ユーザーの場合、メニュー画面に遷移
        else {
            //yield put(CommonParamActions.getCommonParam());
            switch (response.data.user?.userType) {
                case '01':
                    yield put(UserActions.setUserType('company'));
                    yield put(push('/menu'));
                    break;
                case '02':
                    yield put(UserActions.setUserType('supplier'));
                    yield put(push('/menu'));
                    break;
                case '03':
                    yield put(UserActions.setUserType('customer'));
                    yield put(push('/menu'));
                    break;
                case '99':
                    yield put(UserActions.setUserType('admin'));
                    yield put(push('/menu'));
                    break;
                default:
                    yield put(UserActions.setUserType('supplier'));
                    yield put(push('/menu'));
                    break;
            }
        }
    } else {
        const errorResponse = response.error?.response;
        if (errorResponse && errorResponse.data?.errorMessage) {
            // アカウントロック中
            if (errorResponse.data.errorMessage === 'AccountLocking') {
                yield put(MessageActions.push({ content: m('アカウントロック中です。%sまでお待ちください。'), words: [moment(errorResponse.data.lockExpireDate).format('YYYY/MM/DD HH:mm')] }));
            }
            // アカウントロック
            if (errorResponse.data.errorMessage === 'AccountLocked') {
                yield put(MessageActions.push({ content: m('アカウントがロックされました。%sまでお待ちください。'), words: [moment(errorResponse.data.lockExpireDate).format('YYYY/MM/DD HH:mm')] }));
            }
            // パスワード違い
            if (errorResponse.data.errorMessage === 'LoginFailure') {
                yield put(MessageActions.push({ content: m('ログインに失敗しました。ログイン失敗%s回目。'), words: [errorResponse.data.loginFailCount] }));
            } else {
                // エラーメッセージを含まないエラー
                yield put(MessageActions.push({ content: m('ログインに失敗しました。'), words: [] }))
            }
        } else {
            // エラーメッセージを含まないエラー
            yield put(MessageActions.push({ content: m('ログインに失敗しました。'), words: [] }))
        }
    }

    yield put(UserActions.setIsConnecting(false));

}


// Retrieve apiができたら、response.dataのところを適宜なおす（３か所）
function* retrieve(action: ReturnType<typeof UserActions.retrieveUserInfo>) {
    console.log('再取得');
    yield put(UserActions.setIsConnecting(true));
    const response = yield UserApi.retrieve({});

    if (response.isSuccess) {
        yield put(UserActions.setIsLogin(true));
        yield put(UserActions.setUser(User.fromResponse(response.data.user)));

        //yield put(CommonParamActions.getCommonParam());
        switch (response.data.user?.userType) {
            case '01':
                yield put(UserActions.setUserType('company'));
                break;
            case '02':
                yield put(UserActions.setUserType('supplier'));
                break;
            case '03':
                yield put(UserActions.setUserType('customer'));
                break;
            case '99':
                yield put(UserActions.setUserType('admin'));
                break;
            default:
                yield put(UserActions.setUserType('supplier'));
                break;
        }
    } else {
        const cookies = new Cookies();
        cookies.remove('token');
        cookies.remove('refreshToken');
        yield put(push('/login'));
    }

    yield put(UserActions.setIsConnecting(false));
}

function* changePassword(action: ReturnType<typeof UserActions.changePassword>) {
    const changePasswordInfo = action.payload;
    yield put(UserActions.setIsConnecting(true));

    const response = yield UserApi.changePassword(changePasswordInfo);

    if (response.isSuccess) {
        yield put(UserActions.setIsPasswordChanged(true));
        // パスワード変更処理が成功
        yield put(MessageActions.push({ content: m('パスワードが更新されました。'), words: [] }));
    } else {
        yield put(UserActions.setIsPasswordChanged(false));
        // パスワード変更処理が失敗
        if (response.error?.response?.status === 401) {
            // ユーザーIDとパスワードの組み合わせが不適切。ID/PWを再入力させる
            yield put(MessageActions.push({ content: m('ユーザーIDとパスワードが正しいか確認してください。'), words: [] }));
        } else {
            // システムエラー系
            yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
        }
    }

    yield put(UserActions.setIsConnecting(false));
}

function* removeSession(action: ReturnType<typeof UserActions.removeSession>) {
    const response = yield UserApi.removeSession(action.payload);
    if (!response.isSuccess) {
        yield put(MessageActions.push({ content: m('通信に失敗しました。'), words: [] }));
    }
}

function* resendMfaCode(action: ReturnType<typeof UserActions.resendMfaCode>) {
    const authInfo = action.payload;
    const response = yield UserApi.createOtp(authInfo);
    if (!response.isSuccess) {
        const statusCode = response.error?.response?.status;
        switch (statusCode) {
            case 500:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
            default:
                yield put(MessageActions.push({ content: m('通信に失敗しました。「認証コードを再送する」を押下してください。'), words: [] }));
                break;
        }
    }
}

function* verifyMfaCode(action: ReturnType<typeof UserActions.verifyMfaCode>) {
    const verifyMfaCode = action.payload;
    yield put(UserActions.setIsConnecting(true));
    const response = yield UserApi.verifyMfaCode(verifyMfaCode);
    // MFA認証成功時
    if (response.isSuccess) {
        // UUIDの生成・保存(SQL Server)
        const saveUuidResponse = yield UserApi.saveMfaDeviceInformation();
        if (saveUuidResponse.isSuccess) {
            // UUIDの保存(Local Storage)
            localStorage.setItem('supplier_mfa_uuid', saveUuidResponse.data);
            yield put(UserActions.setUser(User.fromResponse(response.data.user)));

            const cookies = new Cookies();
            cookies.set('token', response.data.access_token, { secure: true });
            cookies.set('refreshToken', response.data.refresh_token, { secure: true });

            // メニュー画面に遷移
            switch (response.data.user?.userType) {
                case '01':
                    yield put(UserActions.setUserType('company'));
                    yield put(push('/menu'));
                    break;
                case '02':
                    yield put(UserActions.setUserType('supplier'));
                    yield put(push('/menu'));
                    break;
                case '03':
                    yield put(UserActions.setUserType('customer'));
                    yield put(push('/menu'));
                    break;
                case '99':
                    yield put(UserActions.setUserType('admin'));
                    yield put(push('/menu'));
                    break;
                default:
                    yield put(UserActions.setUserType('supplier'));
                    yield put(push('/menu'));
                    break;
            }
        }
        // UUIDの生成・保存(SQL Server)失敗時
        else {
            yield put(MessageActions.push({ content: m('通信に失敗しました。'), words: [] }));
        }
    } else {
        // MFA認証失敗時
        const statusCode = response.error.response.status;
        switch (statusCode) {
            case 400:
                yield put(MessageActions.push({ content: m('認証コードが一致しません。'), words: [] }));
                break;
            default:
                yield put(MessageActions.push({ content: m('通信に失敗しました。'), words: [] }));
                break;
        }
    }
    yield put(UserActions.setIsConnecting(false));
}

export function* UserSaga() {
    yield takeLatest(UserActions.login, login);
    yield takeLatest(UserActions.retrieveUserInfo, retrieve);
    yield takeLatest(UserActions.changePassword, changePassword);
    yield takeLatest(UserActions.removeSession, removeSession);
    yield takeLatest(UserActions.resendMfaCode, resendMfaCode);
    yield takeLatest(UserActions.verifyMfaCode, verifyMfaCode);
}
