import actionCreateFactory from 'typescript-fsa';
import { SearchKeys, DeliveryNotes } from '../models/DeliveryNote';
import { List } from 'immutable';

const actionCreator = actionCreateFactory('DeliveryNote');

export const DeliveryNoteActions = {
    // 検索条件に基づき注文データを取得する。
    search: actionCreator<List<SearchKeys>>('search'),
    // apiに接続中か否か
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    // 検索に成功したか否か
    setDidSearchSuccess: actionCreator<boolean | null>('setDidSearchSuccess'),
    // 検索により取得した注文データを保持する。
    setDeliveryNotes: actionCreator<DeliveryNotes>('setDeliveryNotes'),
    // 納品書PDFを生成する
    generatePdf: actionCreator('generatePdf'),
    // フォントをセットする
    setFont: actionCreator<ArrayBuffer>('setFont'),
}