import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record } from 'immutable';
import { CommonParamActions } from '../actions/commonParam';
import { CustomerDestinations } from '../models/CommonParam';

export class CommonParamState extends Record<{
    mandt: string;
    customerDestinations: CustomerDestinations;
}>({
    mandt: "",
    customerDestinations: new CustomerDestinations(),
}) {

}

export const CommonParamReducer = reducerWithInitialState(new CommonParamState())
    .case(CommonParamActions.setMandt, (state, payload) => {
        return state.set('mandt', payload);
    })
    .case(CommonParamActions.setCustomerDestinations, (state, payload) => {
        return state.set('customerDestinations', payload);
    })
    ;