import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record, Map } from 'immutable';
import { PurchaseOrderHeaderSettingActions } from '../actions/purchaseOrderHeaderSetting';
import { SortedColumn, HeaderVariant } from '../types';

type purchaseOrderHeaderSettingKey = 'purchaseOrderHeaderVariant' | 'purchaseOrderSortedColumns';

type arrayHeaderVariant = HeaderVariant[];
type arraySortedColumn = SortedColumn[];

type arrayHeaderSetting = arrayHeaderVariant | arraySortedColumn;
type PurchaseOrderHeaderSetting = Map<purchaseOrderHeaderSettingKey, arrayHeaderSetting>;

export class PurchaseOrderHeaderSettingState extends Record<{
    purchaseOrderHeaderVariant: HeaderVariant[],
    purchaseOrderSortedColumns: SortedColumn[],
}>({
    purchaseOrderHeaderVariant: ([{
        isSelected: true,
        name: 'supplier',
        label: 'サプライヤID',
    }, {
        isSelected: true,
        name: 'receiptNo',
        label: '受付番号',
    }, {
        isSelected: true,
        name: 'orderId',
        label: '注文番号',
    }, {
        isSelected: true,
        name: 'orderIdDetail',
        label: '注文明細No.',
    }, {
        isSelected: true,
        name: 'customerMaterial',
        label: '品目',
    }, {
        isSelected: true,
        name: 'material',
        label: 'CMEs品目',
    }, {
        isSelected: true,
        name: 'orderDate',
        label: '発注日',
    }, {
        isSelected: true,
        name: 'desiredDeliveryDate',
        label: '希望納期',
    }, {
        isSelected: true,
        name: 'changedDeliveryDate',
        label: '変更納期',
    }, {
        isSelected: true,
        name: 'orderQuantity',
        label: '初回発注数量',
    }, {
        isSelected: true,
        name: 'changedOrderQuantity',
        label: '正式発注数量',
    }, {
        isSelected: true,
        name: 'deliveryQuantity',
        label: '出荷数量',
    }, {
        isSelected: true,
        name: 'remainQuantity',
        label: '発注残',
    }, {
        isSelected: true,
        name: 'orderQuantityUnit',
        label: '単位',
    }, {
        isSelected: true,
        name: 'pricePerOneUnit',
        label: '正味価格',
    }, {
        isSelected: true,
        name: 'amountTotal',
        label: '正味額',
    }, {
        isSelected: true,
        name: 'currency',
        label: '通貨',
    }, {
        isSelected: true,
        name: 'confirmationStatus',
        label: '確認状況',
    }, {
        isSelected: true,
        name: 'status',
        label: '発注ステータス',
    }, {
        isSelected: true,
        name: 'orderedBy',
        label: '発注登録者',
    }, {
        isSelected: true,
        name: 'messageColumn',
        label: '通信欄',
    }, {
        isSelected: true,
        name: 'messageColumns',
        label: '通信履歴',
        },
    ]),
    purchaseOrderSortedColumns: ([]),
}) {
    purchaseOrderHeaderSetting: PurchaseOrderHeaderSetting = Map<purchaseOrderHeaderSettingKey, arrayHeaderSetting>();
}

export const PurchaseOrderHeaderSettingReducer = reducerWithInitialState(new PurchaseOrderHeaderSettingState())
    .case(PurchaseOrderHeaderSettingActions.setPurchaseOrderHeaderVariant, (state, payload) => {
        return state.set('purchaseOrderHeaderVariant', payload);
    })
    .case(PurchaseOrderHeaderSettingActions.setPurchaseOrderSortedColumns, (state, payload) => {
        return state.set('purchaseOrderSortedColumns', payload);
    })