import actionCreateFactory from 'typescript-fsa';
import { SortedColumn, HeaderVariant } from '../types';

const actionCreator = actionCreateFactory('PurchaseOrderHeaderSetting');

export const PurchaseOrderHeaderSettingActions = {
    // 発注一覧画面のヘッダーバリアントの保存
    setPurchaseOrderHeaderVariant: actionCreator<HeaderVariant[]>('setPurchaseOrderHeaderVariant'),
    // 発注一覧画面のヘッダーのソート状態保存
    setPurchaseOrderSortedColumns: actionCreator<SortedColumn[]>('setPurchaseOrderSortedColumns'),
}