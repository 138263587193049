import { put, takeLatest, select } from 'redux-saga/effects';
import { getMessage as m } from '../messages';
import { MessageActions } from '../actions/message';
import { AnswerDeliveryActions } from '../actions/answerDelivery';
import { AnswerDeliveryApi } from '../apiClient/answerDelivery';
import { OrderActions } from '../actions/order';
import { State } from '../reducers';

function* update(action: ReturnType<typeof AnswerDeliveryActions.update>) {
    yield put(AnswerDeliveryActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield AnswerDeliveryApi.update(keys);

    if (response.isSuccess) {
        yield put(AnswerDeliveryActions.setDidUpdateSuccess(true));

        yield put(MessageActions.push({ content: m('更新しました。'), words: [] }));
    } else {
        yield put(AnswerDeliveryActions.setDidUpdateSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    // 注文情報を再取得
    yield put(OrderActions.search(yield select((state: State) => state.order.searchKeys)))

    yield put(AnswerDeliveryActions.setIsConnecting(false));
}

export function* AnswerDeliverySaga() {
    yield takeLatest(AnswerDeliveryActions.update, update);
}
