import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record } from 'immutable';
import { DeliveryNotes } from '../models/DeliveryNote';
import { DeliveryNoteActions } from '../actions/deliveryNote';

export class DeliveryNoteState extends Record<{
    // 検索により取得した納品書データ
    deliveryNotes: DeliveryNotes;
    // apiに接続中か否か
    isConnecting: boolean;
    // 検索に成功したか否か
    didSearchSuccess: boolean | null;
    // フォント
    font: ArrayBuffer | null;
}>({
    deliveryNotes: new DeliveryNotes(),
    isConnecting: false,
    didSearchSuccess: null,
    font: null,
}) { }

export const DeliveryNoteReducer = reducerWithInitialState(new DeliveryNoteState())
    .case(DeliveryNoteActions.setDidSearchSuccess, (state, payload) => {
        return state.set('didSearchSuccess', payload);
    })
    .case(DeliveryNoteActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(DeliveryNoteActions.setDeliveryNotes, (state, payload) => {
        return state.set('deliveryNotes', payload);
    })
    .case(DeliveryNoteActions.setFont, (state, payload) => {
        return state.set('font', payload);
    })
    ;