import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Counter, List } from 'fundamental-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';
import { State } from '../../../reducers';

type drawerStatus = {
    isOpenDrawer: boolean,
    toggleDrawer: any,
}

type informationData = {
    title: string,
    content: string,
    dateTime: string,
}

const Information: React.FC<drawerStatus> = (props) => {

    const { t } = useTranslation();

    const {
        isLogin,
        notificationMessages,
        informationMessages,
        mfaSuccess,
    } = useSelector((state: State) => ({
        isLogin: state.user.isLogin,
        notificationMessages: state.information.notificationMessages,
        informationMessages: state.information.informationMessages,
        mfaSuccess: state.user.user.mfaSuccess,
    }));

    // SubDrawer表示フラグ
    const [isOpenSubDrawer, setIsOpenSubDrawer] = useState(false);
    const onClickSubDrawerAway = () => {
        setSubDrawerContent('');
        setIsOpenSubDrawer(false);
    };

    // SubDrawer変数
    const [subDrawerTitle, setSubDrawerTitle] = useState('');
    const [subDrawerContent, setSubDrawerContent] = useState('');
    const [subDrawerDate, setSubDrawerDate] = useState('');

    let scrollBarRef = React.createRef<HTMLDivElement>();
    const updateSubDrawer = (data: informationData) => {
        setIsOpenSubDrawer(true);
        setSubDrawerTitle(data.title);
        setSubDrawerContent(data.content);
        setSubDrawerDate(moment(data.dateTime).format('YYYY/MM/DD HH:mm'));
    };

    // メッセージ押下時にスクロール量を0(トップに戻す)にする
    useEffect(() => {
        if (scrollBarRef.current !== null)
            scrollBarRef.current.scrollTop = 0;
    }, [subDrawerContent]);

    return (
        <Drawer open={props.isOpenDrawer} onClose={props.toggleDrawer} direction='left' style={{ width: '500px' }}>
            {/* Header */}
            <div style={{ height: '80px', flex: 1, background: 'gainsboro' }}>
                <div style={{ padding: '20px' }}>
                    <FontAwesomeIcon style={{ padding: '10px', fontSize: '3em' }} onClick={props.toggleDrawer} icon={faBell} />
                    {((notificationMessages !== undefined && notificationMessages.list.count() > 0) || (informationMessages !== undefined && informationMessages.list.count() > 0)) &&
                        <Counter style={{ position: 'absolute', left: '50px' }}>{notificationMessages.list.count() + informationMessages.list.count()}</Counter>
                    }
                </div>
            </div>
            {/* Content */}
            <ClickAwayListener onClickAway={onClickSubDrawerAway}>
                <div style={{ overflow: 'scroll', overflowX: 'hidden', overflowY: 'auto', height: 'calc(100vh - 80px)' }}>
                    {/* 対応必要 */}
                    <div>
                        <p style={{ marginLeft: '20px', marginTop: '20px', marginBottom: '0px', fontSize: '1.25rem' }}>{t('対応必要')}</p>
                        {isLogin && mfaSuccess == "true" ?
                            (
                                notificationMessages.list.count() ? (
                                    <List hasByline selectable>
                                        {
                                            notificationMessages.list.map((x, index) => (
                                                <List.Item key={index} onClick={() => updateSubDrawer(x)}>
                                                    <List.Text>{t(x.title)}</List.Text>
                                                    <List.Byline>{moment(x.dateTime).format('YYYY/MM/DD HH:mm')}</List.Byline>
                                                </List.Item>
                                            ))}
                                    </List>) : (
                                    <List>
                                        <p style={{ marginLeft: '44px', marginTop: '10px', fontSize: '1rem' }}>{t('現在対応が必要な項目はありません')}</p>
                                    </List>
                                )
                            ) : <div />
                        }
                    </div>
                    {/* お知らせ */}
                    <div>
                        <p style={{ marginLeft: '20px', marginTop: '30px', marginBottom: '0px', fontSize: '1.25rem' }}>{t('お知らせ')}</p>
                        {isLogin && mfaSuccess == "true" ?
                            (
                                informationMessages.list.count() ? (
                                    <List hasByline selectable>
                                        {
                                            informationMessages.list.map((x, index) => (
                                                <List.Item key={index} onClick={() => updateSubDrawer(x)}>
                                                    <List.Text>{x.title}</List.Text>
                                                    <List.Byline>{moment(x.dateTime).format('YYYY/MM/DD HH:mm')}</List.Byline>
                                                </List.Item>
                                            ))
                                        }
                                    </List>) : (
                                    <List>
                                        <p style={{ marginLeft: '44px', marginTop: '10px', fontSize: '1rem' }}>{t('お知らせはありません')}</p>
                                    </List>
                                )
                            ) : <div />
                        }
                    </div>
                    {/* SubDrawer */}
                    {isOpenSubDrawer ? (
                        <div ref={scrollBarRef} style={{ position: 'absolute', background: 'white', left: '500px', top: '80px', width: '450px', height: '500px', border: '1px solid dimgray', padding: '20px', overflow: 'scroll', overflowX: 'hidden', overflowY: 'auto' }}>
                            <p style={{ fontSize: '1.3rem' }}>{i18n.exists(subDrawerTitle) ? t(subDrawerTitle) : subDrawerTitle}</p>
                            <p style={{ fontSize: '0.9rem' }} >{subDrawerDate}</p>
                            <p style={{ whiteSpace: 'pre-line' }}>{i18n.exists(subDrawerContent) ? t(subDrawerContent) : subDrawerContent}</p>
                        </div>
                    )
                        : <div></div>}
                </div>


            </ClickAwayListener>
        </Drawer>
    );
}


export default Information;