import actionCreateFactory from 'typescript-fsa';
import { SearchKeys, Deliveries } from '../models/Delivery';

const actionCreator = actionCreateFactory('Delivery');

export const DeliveryActions = {
    // 検索条件に基づき出荷実績データを取得する。
    search: actionCreator<SearchKeys>('search'),
    // apiに接続中か否か
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    // 検索に成功したか否か
    setDidSearchSuccess: actionCreator<boolean | null>('setDidSearchSuccess'),
    // 検索により取得した出荷実績データを保持する。
    setDeliveries: actionCreator<Deliveries>('setDeliveries'),
    // 検索キー
    setSearchKeys: actionCreator<SearchKeys>('setSearchKeys'),
}