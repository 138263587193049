import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { State } from '../../../reducers';
import { Button, Dialog, FormInput, FormItem, FormLabel, InputGroup } from 'fundamental-react';
import { ChangePasswordInfo } from '../../../models/User';
import { MessageActions } from '../../../actions/message';
import { getMessage as m } from '../../../messages';
import { UserActions } from '../../../actions/user';


type Props = {
    initialUserId: string;
    initialPassword: string;
    showDialog: boolean;
    setShowDialog: Function;
}

const ChangePasswordDialog: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /**
     * 状態管理（ローカル）
     * */
    const [userId, setUserId] = useState<string>(props.initialUserId);
    const [password, setPassword] = useState<string>(props.initialPassword);
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordConf, setNewPasswordConf] = useState<string>('');
    const [readyOk, setReadyOk] = useState<boolean>(false);
    const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
    const [isVisibleNewPassword, setIsVisibleNewPassword] = useState<boolean>(false);
    const [isVisibleNewPasswordConf, setIsVisibleNewPasswordConf] = useState<boolean>(false);

    /**
     * 状態管理（アプリ）
     * */
    const { mandt, isPasswordChanged } = useSelector((state: State) => ({
        mandt: state.commonParam.mandt,
        isPasswordChanged: state.user.isPasswordChanged,
    }));

    useEffect(() => {
        setUserId(props.initialUserId);
        setPassword(props.initialPassword);
    }, [props.initialUserId, props.initialPassword]);

    useEffect(() => {
        // パスワードが正常に更新されたらダイアログを閉じる
        if (isPasswordChanged === true) {
            dispatch(UserActions.setIsPasswordChanged(false));
            props.setShowDialog(false);
        }
    }, [dispatch, isPasswordChanged, props]);

    /**
     * HTML要素参照
     */
    const form = useRef<HTMLFormElement>(null);

    /**
     * イベントハンドラ
     * */
    const onChangeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserId(event.target.value);
        setReadyOk(form.current?.checkValidity() === true ? true : false);
    };

    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setReadyOk(form.current?.checkValidity() === true ? true : false);
    };

    const onChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
        setReadyOk(form.current?.checkValidity() === true ? true : false);
    };

    const onChangeNewPasswordConf = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPasswordConf(event.target.value);
        setReadyOk(form.current?.checkValidity() === true ? true : false);
    };

    const onClickSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // バリデーションチェック
        // 必須・型・桁チェックはformが実施済みのため、組み合わせチェックを実施
        if (password === newPassword) {
            // 新旧PWが一致する場合はエラー
            dispatch(MessageActions.push({ content: m('新パスワードには現在と異なるパスワードを入力してください。'), words: [] }));
            return;
        }

        if (newPassword !== newPasswordConf) {
            // パスワード確認が一致しない場合はエラー
            dispatch(MessageActions.push({ content: m('新パスワードと新パスワード(確認)が一致しているか確認してください。'), words: [] }));
            return;
        }

        const changePasswordInfo = new ChangePasswordInfo({
            mandt: mandt,
            userId: userId,
            password: password,
            newPassword: newPassword,
        });

        dispatch(UserActions.changePassword(changePasswordInfo));
    };

    return (
        <>
            <Dialog
                actions={[
                    (<Button
                        onClick={onClickSave}
                        option='emphasized'
                        disabled={!readyOk}>{t('保存')}</Button>),
                    (<Button
                        onClick={() => props.setShowDialog(false)}
                        option='emphasized'>{t('キャンセル')}</Button>)
                ]}
                show={props.showDialog}
                title='パスワード変更'
            >
                <form ref={form}>
                    <FormItem style={{ margin: '0.5rem 0' }}>
                        <FormLabel htmlFor='dialog_user_id_input'>{t('ユーザーID')}</FormLabel>
                        <FormInput
                            id='dialog_user_id_input'
                            type='text'
                            placeholder='ユーザーID'
                            required
                            maxLength={20}
                            pattern='^[0-9A-Za-z]+$'
                            onChange={onChangeUserId}
                            value={userId}
                        ></FormInput>
                    </FormItem>
                    <FormItem style={{ margin: '0.5rem 0' }}>
                        <FormLabel htmlFor='dialog_password_input'>{t('パスワード')}</FormLabel>
                        <InputGroup>
                            <FormInput
                                id='dialog_password_input'
                                type={isVisiblePassword ? 'text' : 'password'}
                                placeholder='パスワード'
                                required
                                maxLength={64}
                                pattern='^[0-9A-Za-z]+$'
                                onChange={onChangePassword}
                                value={password}
                            ></FormInput>
                            <InputGroup.Addon isButton>
                                <Button
                                    onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                                    glyph={isVisiblePassword ? 'hide' : 'show'}
                                    option='transparent'
                                />
                            </InputGroup.Addon>
                        </InputGroup>
                    </FormItem>
                    <FormItem style={{ margin: '0.5rem 0' }}>
                        <FormLabel htmlFor='dialog_new_password_input'>{t('新パスワード')}</FormLabel>
                        <InputGroup>
                            <FormInput
                                id='dialog_new_password_input'
                                type={isVisibleNewPassword ? 'text' : 'password'}
                                placeholder='新パスワード'
                                required
                                maxLength={64}
                                pattern='^[0-9A-Za-z]+$'
                                onChange={onChangeNewPassword}
                            ></FormInput>
                            <InputGroup.Addon isButton>
                                <Button
                                    onClick={() => setIsVisibleNewPassword(!isVisibleNewPassword)}
                                    glyph={isVisibleNewPassword ? 'hide' : 'show'}
                                    option='transparent'
                                />
                            </InputGroup.Addon>
                        </InputGroup>
                    </FormItem>
                    <FormItem style={{ margin: '0.5rem 0' }}>
                        <FormLabel htmlFor='dialog_new_password_conf_input'>{t('新パスワード(確認)')}</FormLabel>
                        <InputGroup>
                            <FormInput
                                id='dialog_new_password_conf_input'
                                type={isVisibleNewPasswordConf ? 'text' : 'password'}
                                placeholder='新パスワード(確認)'
                                required
                                maxLength={64}
                                pattern='^[0-9A-Za-z]+$'
                                onChange={onChangeNewPasswordConf}
                            ></FormInput>
                            <InputGroup.Addon isButton>
                                <Button
                                    onClick={() => setIsVisibleNewPasswordConf(!isVisibleNewPasswordConf)}
                                    glyph={isVisibleNewPasswordConf ? 'hide' : 'show'}
                                    option='transparent'
                                />
                            </InputGroup.Addon>
                        </InputGroup>
                    </FormItem>
                </form>
            </Dialog>
        </>
    );
}

export default ChangePasswordDialog;