import { List, Record } from 'immutable';
import { JSObject } from '../types';

export class CustomerDestination extends Record<{
    customerId: string;
    regionId: string;
    companyId: string;
    companyName: string;
}>({
    customerId: '',
    regionId: '',
    companyId: '',
    companyName: '',
}) {
    static fromResponse(response: JSObject) {
        const param = { ...response };
        return new CustomerDestination(param);
    }
}

export class CustomerDestinations extends Record<{
    list: List<CustomerDestination>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<CustomerDestination>) {
        const params = [...response];
        const customerDestinations = List(params.map(cd => CustomerDestination.fromResponse(cd)));
        return new CustomerDestinations({ list: customerDestinations });
    }
}