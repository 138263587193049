import { Button, Column, Container, Row, Table } from 'fundamental-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { State } from '../../../reducers';
import { JSObject, HeaderVariant, SortedColumn } from '../../../types';
import { returnAmountText, returnQuantityText } from '../../../utils/CommonFunction';
import ViewSettingForm from '../../atoms/ViewSettingForm';
import { Delivery, SearchKeys } from '../../../models/Delivery';
import { DeliveryHeaderSettingActions } from '../../../actions/deliveryHeaderSetting';

type Props = {
    headerVariant: HeaderVariant[];
    setHeaderVariant: (list: HeaderVariant[]) => void;
    setDisplayDeliveryData: (list: JSObject[]) => void;
}

const LayoutBody: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        deliveries,
        deliveryHeaderSetting,
    } = useSelector((state: State) => ({
        deliveries: state.delivery.deliveries,
        deliveryHeaderSetting: state.deliveryHeaderSetting.deliveryHeaderSetting,
    }));

    const CELL_HEIGHTS_FOR_ADD_PADDING = 50;

    /**
     * UIへの参照
     **/
    const table = useRef<HTMLTableElement>(null);

    // ビュー設定画面表示フラグ
    const [isShowViewSettingForm, setIsShowViewSettingForm] = useState<boolean>(false);
    // ビュー設定後、セルのアラインを調整
    useEffect(() => {
        applyTableAlignStyles();
    }, [isShowViewSettingForm]);

    // 表示用データを作成
    const toJSArray = (data: Array<Delivery>) => {
        return data.map(d => d.toJS());
    }
    const [deliveryData, setDeliveryData] = useState<Array<JSObject>>(toJSArray(deliveries.list.toArray()));

    // sortedColumnsの値
    //  初期値: ローカルストレージの設定値
    //  一覧ヘッダ押下: 押下項目オブジェクト
    //  ダイアログOKボタン押下: 選択項目(項目未選択の場合は空のオブジェクト)
    const localSortedColumns = deliveryHeaderSetting.get('deliverySortedColumns') as SortedColumn[];
    const [sortedColumns, setSortedColumns] = useState<SortedColumn[]>([...localSortedColumns]);

    useEffect(() => {
        setDeliveryData(toJSArray(deliveries.list.toArray()));
        props.setDisplayDeliveryData(toJSArray(deliveries.list.toArray()));

        // ソートされている場合、ソート条件を復元する
        if (sortedColumns.length !== 0) {
            // 即時実行だとdatePickerのsetDefaultValueを上書きする為、遅延呼出
            setTimeout(setSortedColumns, 100, [...sortedColumns]);
        }
    }, [deliveries]);

    useEffect(() => {
        // ソートState変更後、一覧にソート状態を反映する
        if (sortedColumns.length !== 0) {
            sort(sortedColumns);
        }
        // ソート状態をローカルストレージに保存(textは不要なので削除)
        const newSorteColumns = sortedColumns.map((x) => {
            return {
                column: x.column,
                isAscending: x.isAscending,
            }
        });
        dispatch(DeliveryHeaderSettingActions.setDeliverySortedColumns([...newSorteColumns]));
    }, [sortedColumns]);


    useEffect(() => {
        applyTableAlignStyles();
    }, [deliveryData]);

    /**
     * ソートボタン押下イベントハンドラ
     * @param event
     */
    const onClickSort = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const sortKey = event.currentTarget.dataset?.sortKey;
        if (sortKey === undefined) return;

        // ボタン押下した列と現在ソートされている列が一致するか判定し、一致する列を取得
        const tempSortedColumns = sortedColumns.filter(x => x.column === sortKey);

        // 一致した列を取得できた場合、ソート順を反転
        if (tempSortedColumns.length !== 0) {
            setSortedColumns([{
                column: tempSortedColumns[0]?.column ?? '',
                isAscending: !tempSortedColumns[0]?.isAscending,
            }]);
        }
        // 一致しない場合、昇順でセット
        else {
            setSortedColumns([{
                column: sortKey,
                isAscending: true,
            }]);
        }
    }

    /**
     * 出荷実績一覧を並べ替えする処理
     * @param sortedColumns
     */
    const sort = (sortedColumns: SortedColumn[]) => {
        const targets = JSON.parse(JSON.stringify(deliveryData)) as JSObject[];

        targets.sort((a, b) => {
            for (let i = 0; i < sortedColumns.length; i++) {
                const flg1 = sortedColumns[i].isAscending ? 1 : -1;
                const flg2 = flg1 * (-1);
                // nullが最小の値としてソートしておく
                if (a[sortedColumns[i].column] === null && b[sortedColumns[i].column] === null) return 0;
                if (a[sortedColumns[i].column] === null && b[sortedColumns[i].column] !== null) return flg2;
                if (a[sortedColumns[i].column] !== null && b[sortedColumns[i].column] === null) return flg1;
                if (a[sortedColumns[i].column] > b[sortedColumns[i].column]) return flg1;
                if (a[sortedColumns[i].column] < b[sortedColumns[i].column]) return flg2;
            }
            return 0;
        });
        setDeliveryData([...targets]);
        props.setDisplayDeliveryData([...targets]);
    };

    /**
     * ソートキーを基準に、ソート状態のアイコン名を返却する
     * @param sortKey
     */
    const returnSortIcon = (sortKey: string) => {
        if (sortKey === undefined) return;
        // sortKeyと一致するsortedColumnsの要素を取得
        const tempSortedColumns = sortedColumns.filter(x => x.column === sortKey);

        let result = 'sort';

        if (tempSortedColumns.length !== 0) {
            if (tempSortedColumns[0]?.isAscending) {
                result = 'sort-ascending';
            } else {
                result = 'sort-descending';
            }
        }

        return result;
    };

    /**
     * 歯車マーク押下時、ビュー項目設定画面を表示する
     * @param event
     */
    const onClickViewSettingButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsShowViewSettingForm(true);
    };
    const applyTableAlignStyles = () => {
        Array.prototype.forEach.call(table.current?.getElementsByTagName("td"), item => {
            const clientHeight = item.clientHeight;
            if (clientHeight < CELL_HEIGHTS_FOR_ADD_PADDING) {
                item.style.paddingTop = '5px';
                item.style.paddingBottom = '5px';
            } else {
                item.style.paddingTop = '';
                item.style.paddingBottom = '';
            }
            // text-align: セルの判定
            // text-align: center対象セルの判定
            if (item.getElementsByClassName("alignCenter").length > 0) {
                item.style.textAlign = 'center';
            }
            // text-align: right対象セルの判定
            if (item.getElementsByClassName('alignRight').length > 0) {
                item.style.textAlign = 'right';
            }
            // text-align: left対象セルの判定
            if (item.getElementsByClassName('alignLeft').length > 0) {
                item.style.textAlign = 'left';
            }
        });
    };

    // テーブルヘッダーの取得
    const returnHeaderData = () => {
        const headers = [{
            name: 'supplier',
            comp: <DivAlignItemsCenter>
                {t('サプライヤID')}
                <Button
                    compact
                    glyph={returnSortIcon('supplierId')}
                    data-sort-key='supplierId'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'outboundDeliveryNo',
            comp: <DivAlignItemsCenter>
                {t('CMEs出荷伝票番号')}
                <Button
                    compact
                    glyph={returnSortIcon('outboundDeliveryNo')}
                    data-sort-key='outboundDeliveryNo'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'outboundDeliveryDetail',
            comp: <DivAlignItemsCenter>
                {t('CMEs出荷明細No.')}
                <Button
                    compact
                    glyph={returnSortIcon('outboundDeliveryDetail')}
                    data-sort-key='outboundDeliveryDetail'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderId',
            comp: <DivAlignItemsCenter>
                {t('注文番号')}
                <Button
                    compact
                    glyph={returnSortIcon('orderId')}
                    data-sort-key='orderId'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderIdDetail',
            comp: <DivAlignItemsCenter>
                {t('注文明細No.')}
                <Button
                    compact
                    glyph={returnSortIcon('orderIdDetail')}
                    data-sort-key='orderIdDetail'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'salesOrder',
            comp: <DivAlignItemsCenter>
                {t('CMEs受注伝票番号')}
                <Button
                    compact
                    glyph={returnSortIcon('salesOrder')}
                    data-sort-key='salesOrder'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'customerMaterial',
            comp: <DivAlignItemsCenter>
                {t('品目')}
                <Button
                    compact
                    glyph={returnSortIcon('customerMaterial')}
                    data-sort-key='customerMaterial'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'material',
            comp: <DivAlignItemsCenter>
                {t('CMEs品目')}
                <Button
                    compact
                    glyph={returnSortIcon('material')}
                    data-sort-key='material'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'desiredDeliveryDate',
            comp: <DivAlignItemsCenter>
                {t('希望納期')}
                <Button
                    compact
                    glyph={returnSortIcon('desiredDeliveryDate')}
                    data-sort-key='desiredDeliveryDate'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'actualDeliveryDate',
            comp: <DivAlignItemsCenter>
                {t('みなし着荷日')}
                <Button
                    compact
                    glyph={returnSortIcon('actualDeliveryDate')}
                    data-sort-key='actualDeliveryDate'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderQuantity',
            comp: <DivAlignItemsCenter>
                {t('発注数量')}
                <Button
                    compact
                    glyph={returnSortIcon('orderQuantity')}
                    data-sort-key='orderQuantity'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'deliveryQuantity',
            comp: <DivAlignItemsCenter>
                {t('出荷数量')}
                <Button
                    compact
                    glyph={returnSortIcon('deliveryQuantity')}
                    data-sort-key='deliveryQuantity'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'deliveryQuantityUnit',
            comp: <DivAlignItemsCenter>
                {t('単位')}
                <Button
                    compact
                    glyph={returnSortIcon('deliveryQuantityUnit')}
                    data-sort-key='deliveryQuantityUnit'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'pricePerOneUnit',
            comp: <DivAlignItemsCenter>
                {t('正味価格')}
                <Button
                    compact
                    glyph={returnSortIcon('pricePerOneUnit')}
                    data-sort-key='pricePerOneUnit'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'amountTotal',
            comp: <DivAlignItemsCenter>
                {t('正味額')}
                <Button
                    compact
                    glyph={returnSortIcon('amountTotal')}
                    data-sort-key='amountTotal'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'currency',
            comp: <DivAlignItemsCenter>
                {t('通貨')}
                <Button
                    compact
                    glyph={returnSortIcon('currency')}
                    data-sort-key='currency'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        },];

        var headerData: JSX.Element[] = [];

        props.headerVariant.filter(x => x.isSelected).forEach(x => {
            const comp = headers.filter(y => y.name === x.name);
            comp.forEach(y => headerData.push(y.comp));
        });

        return headerData;
    };

    // テーブルリストの取得
    const returnRowData = (item: JSObject, index: number) => {

        // 正味額がプラスかマイナスかを判定
        const isAmountTotalMinus = Math.sign(item.amountTotal) === 1 ? false : true;

        // 表示テキストのスタイルを初期化
        const textStyleColor: React.CSSProperties = { color: '#32363A' };

        // 正味額がマイナスの場合は表示テキストのスタイルの色を赤に変更
        if (isAmountTotalMinus) {
            textStyleColor.color = 'red';
        }

        const allRowData = [{
            name: 'supplier',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.supplier}<br />{item.supplierName}</NoWrapSpan>,
        }, {
            name: 'outboundDeliveryNo',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.outboundDeliveryNo}</NoWrapSpan>,
        }, {
            name: 'outboundDeliveryDetail',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.outboundDeliveryDetail}</NoWrapSpan>,
        }, {
            name: 'orderId',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.orderId}</NoWrapSpan >,
        }, {
            name: 'orderIdDetail',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.orderIdDetail}</NoWrapSpan >,
        }, {
            name: 'salesOrder',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.salesOrder}</NoWrapSpan>,
        }, {
            name: 'customerMaterial',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.customerMaterial}<br />{item.customerMaterialName}</NoWrapSpan>,
        }, {
            name: 'material',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.material}<br />{item.materialName}</NoWrapSpan>,
        }, {
            name: 'desiredDeliveryDate',
            comp: <NoWrapSpan className='alignCenter' style={textStyleColor}>{
                moment(item.desiredDeliveryDate).isValid() ?
                    moment(item.desiredDeliveryDate).format('YYYY/MM/DD') :
                    ''
            }</NoWrapSpan >,
        }, {
            name: 'actualDeliveryDate',
            comp: <NoWrapSpan className='alignCenter' style={textStyleColor}>{
                moment(item.actualDeliveryDate).isValid() ?
                    moment(item.actualDeliveryDate).format('YYYY/MM/DD') :
                    ''
            }</NoWrapSpan >,
        }, {
            name: 'orderQuantity',
            comp: <NoWrapSpan className='alignRight' style={textStyleColor}>{returnQuantityText(item.orderQuantity)}</NoWrapSpan>,
        }, {
            name: 'deliveryQuantity',
            comp: <NoWrapSpan className='alignRight' style={textStyleColor}>{returnQuantityText(item.deliveryQuantity)}</NoWrapSpan>,
        }, {
            name: 'deliveryQuantityUnit',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.deliveryQuantityUnit}</NoWrapSpan>,
        }, {
            name: 'pricePerOneUnit',
            comp: <NoWrapSpan className='alignRight' style={textStyleColor}>{returnAmountText(item.pricePerOneUnit)}</NoWrapSpan>,
        }, {
            name: 'amountTotal',
            comp: <NoWrapSpan className='alignRight' style={textStyleColor}>{returnAmountText(item.amountTotal) ?? ''}</NoWrapSpan>,
        }, {
            name: 'currency',
            comp: <NoWrapSpan className='alignLeft' style={textStyleColor}>{item.currency}</NoWrapSpan>,
        },];

        const rowData: JSX.Element[] = [];

        props.headerVariant.filter(x => x.isSelected).forEach(x => {
            const comp = allRowData.filter(y => y.name === x.name);
            comp.forEach(y => rowData.push(y.comp));
        });

        return rowData;
    };

    return (
        <>
            <Container style={{ height: '100%' }}>
                <div style={{ marginLeft: 'auto' }}>
                    <LargeIconButton onClick={onClickViewSettingButton} glyph='action-settings' option='transparent' style={{ textAlign: 'right' }} />
                </div>
                <Row style={{ height: '100%', margin: '0' }}>
                    <Column style={{ height: '100%', overflow: 'auto', padding: '0' }} span={12}>
                        <HeaderFreezingTable
                            ref={table}
                            compact
                            condensed
                            headers={returnHeaderData()}
                            tableData={deliveryData.map((item, index) => {
                                return ({
                                    rowData: returnRowData(item, index)
                                });
                            })}
                        />
                    </Column>
                </Row>
            </Container>
            <ViewSettingForm
                isShowViewSettingForm={isShowViewSettingForm}
                setIsShowViewSettingForm={setIsShowViewSettingForm}
                headerVariant={props.headerVariant}
                setHeaderVariant={props.setHeaderVariant}
                sortedColumns={sortedColumns}
                setSortedColumns={setSortedColumns}
            />
        </>
    );
};

const HeaderFreezingTable = styled(Table)`
	    &&& thead tr th {
	        white-space: nowrap;
	        position: sticky;
	        top: 0;
	        z-index: 5;
	        &:before {
	            content: "";
	            position: absolute;
	            top: -1px;
	            left: -1px;
	            width: 100%;
	            height: 100%;
	            border-top: 1px solid #e4e4e4;
	        }
	    }
	`;

const NoWrapSpan = styled.span`
	    white-space: nowrap;
	`;

const LargeIconButton = styled(Button)`
	    ::before {
	        width: 40px;
	    }
	    &&& i {
	        font-size: 1.5rem;
	        width: 25px;
	    }
	`;

const DivAlignItemsCenter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export default LayoutBody;