import { put, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';
import { getMessage as m, getWord as w } from '../messages';
import { MessageActions } from '../actions/message';
import { DeliveryNoteActions } from '../actions/deliveryNote';
import { DeliveryNotes } from '../models/DeliveryNote';
import { DeliveryNoteApi } from '../apiClient/deliveryNote';
import { State } from '../reducers';
import { Template } from 'labelmake/dist/types/type';
import labelmake from 'labelmake';
import basePdf from '../assets/baseDeliveryNotePdf';
import baseReprintFrame from '../assets/baseReprintFrame';
import { groupBy, returnAmountText, returnQuantityText } from '../utils/CommonFunction';

function* search(action: ReturnType<typeof DeliveryNoteActions.search>) {
    yield put(DeliveryNoteActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield DeliveryNoteApi.search(keys);

    if (response.isSuccess) {
        yield put(DeliveryNoteActions.setDidSearchSuccess(true));
        yield put(DeliveryNoteActions.setDeliveryNotes(DeliveryNotes.fromResponse(response.data)));

        if (response.data.length > 0) {
            //yield put(push('/salesorder_list'));
        } else {
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        }
    } else {
        yield put(DeliveryNoteActions.setDidSearchSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(DeliveryNoteActions.setIsConnecting(false));
}

function* generatePdf(action: ReturnType<typeof DeliveryNoteActions.generatePdf>) {
    // 納品書の件数が0の場合は中断
    const deliveryNotes: DeliveryNotes = yield select((state: State) => state.deliveryNote.deliveryNotes);
    if (deliveryNotes.list.count() === 0) return;

    yield put(DeliveryNoteActions.setIsConnecting(true));

    // フォントファイルが未取得の場合は取得する
    let Font = yield select((state: State) => state.deliveryNote.font);
    if (!Font) {
        Font = yield fetch(`${process.env.PUBLIC_URL}/GenShinGothic-P-Regular.ttf`).then(res => res.arrayBuffer());
        yield put(DeliveryNoteActions.setFont(Font));
    }

    // 出力するPDFのベースを作成する
    const font = { Font }
    const template: Template = {
        "fontName": "Font",
        "basePdf": basePdf,
        "schemas": [{
            "orderCompany": {
                "type": "text",
                "position": {
                    "x": 24,
                    "y": 8
                },
                "width": 50,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderCompanyName": {
                "type": "text",
                "position": {
                    "x": 10,
                    "y": 18
                },
                "width": 80,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "deliveryAddress1": {
                "type": "text",
                "position": {
                    "x": 30,
                    "y": 25.5
                },
                "width": 50,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "deliveryAddress2": {
                "type": "text",
                "position": {
                    "x": 30,
                    "y": 29.5
                },
                "width": 50,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "deliveryAddress3": {
                "type": "text",
                "position": {
                    "x": 30,
                    "y": 33.5
                },
                "width": 200,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "outputDate": {
                "type": "text",
                "position": {
                    "x": 230,
                    "y": 18
                },
                "width": 35,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "supplier": {
                "type": "text",
                "position": {
                    "x": 35,
                    "y": 150.5
                },
                "width": 25,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "supplierName": {
                "type": "text",
                "position": {
                    "x": 10,
                    "y": 155.5
                },
                "width": 80,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "address1": {
                "type": "text",
                "position": {
                    "x": 25,
                    "y": 160.5
                },
                "width": 50,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "address2": {
                "type": "text",
                "position": {
                    "x": 25,
                    "y": 164.5
                },
                "width": 50,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "address3": {
                "type": "text",
                "position": {
                    "x": 25,
                    "y": 168.5
                },
                "width": 200,
                "height": 5,
                "alignment": "left",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pageNumber": {
                "type": "text",
                "position": {
                    "x": 127,
                    "y": 180.5
                },
                "width": 10,
                "height": 5,
                "alignment": "center",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pageNumberTotal": {
                "type": "text",
                "position": {
                    "x": 138,
                    "y": 180.458
                },
                "width": 10,
                "height": 5,
                "alignment": "center",
                "fontSize": 9,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_1": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 51.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_1": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 54.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_1": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 53.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_1": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 51.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_1": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 53.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_1": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 53.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_1": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 53.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_1": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 53.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_1": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 53.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_1": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 53.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_1": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 53.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_1": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 53.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_2": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 61.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_2": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 64.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_2": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 63.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_2": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 61.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_2": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 63.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_2": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 63.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_2": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 63.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_2": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 63.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_2": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 63.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_2": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 63.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_2": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 63.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_2": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 63.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_3": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 71.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_3": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 74.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_3": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 73.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_3": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 71.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_3": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 73.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_3": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 73.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_3": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 73.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_3": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 73.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_3": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 73.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_3": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 73.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_3": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 73.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_3": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 73.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_4": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 81.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_4": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 84.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_4": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 83.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_4": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 81.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_4": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 83.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_4": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 83.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_4": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 83.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_4": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 83.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_4": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 83.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_4": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 83.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_4": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 83.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_4": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 83.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_5": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 91.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_5": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 94.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_5": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 93.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_5": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 91.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_5": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 93.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_5": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 93.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_5": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 93.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_5": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 93.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_5": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 93.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_5": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 93.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_5": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 93.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_5": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 93.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_6": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 101.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_6": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 104.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_6": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 103.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_6": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 101.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_6": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 103.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_6": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 103.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_6": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 103.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_6": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 103.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_6": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 103.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_6": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 103.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_6": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 103.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_6": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 103.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_7": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 111.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_7": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 114.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_7": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 113.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_7": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 111.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_7": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 113.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_7": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 113.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_7": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 113.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_7": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 113.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_7": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 113.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_7": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 113.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_7": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 113.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_7": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 113.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_8": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 121.5,
                },
                "width": 17.5,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_8": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 124.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_8": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 123.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_8": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 121.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_8": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 123.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_8": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 123.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_8": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 123.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_8": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 123.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_8": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 123.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_8": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 123.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_8": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 123.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_8": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 123.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_9": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 131.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_9": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 134.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_9": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 133.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_9": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 131.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_9": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 133.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_9": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 133.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_9": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 133.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_9": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 133.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_9": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 133.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_9": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 133.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_9": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 133.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_9": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 133.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrder_10": {
                "type": "text",
                "position": {
                    "x": 8,
                    "y": 141.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "purchaseOrderBarcode_10": {
                "type": "code128",
                "position": {
                    "x": 9.5,
                    "y": 144.5,
                },
                "width": 16,
                "height": 5,
            },
            "purchaseOrderDetail_10": {
                "type": "text",
                "position": {
                    "x": 28,
                    "y": 143.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "material_10": {
                "type": "text",
                "position": {
                    "x": 38,
                    "y": 141.5,
                },
                "width": 100,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "pricePerOneUnit_10": {
                "type": "text",
                "position": {
                    "x": 131,
                    "y": 143.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "currency_10": {
                "type": "text",
                "position": {
                    "x": 155.5,
                    "y": 143.5,
                },
                "width": 10,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderDate_10": {
                "type": "text",
                "position": {
                    "x": 165,
                    "y": 143.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "desiredDeliveryDate_10": {
                "type": "text",
                "position": {
                    "x": 180,
                    "y": 143.5,
                },
                "width": 15,
                "height": 5,
                "alignment": "center",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantity_10": {
                "type": "text",
                "position": {
                    "x": 193.5,
                    "y": 143.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "answeredQuantity_10": {
                "type": "text",
                "position": {
                    "x": 216,
                    "y": 143.5,
                },
                "width": 23.5,
                "height": 5,
                "alignment": "right",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "orderQuantityUnitText_10": {
                "type": "text",
                "position": {
                    "x": 240.5,
                    "y": 143.5,
                },
                "width": 7.5,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "productionOrder_10": {
                "type": "text",
                "position": {
                    "x": 248,
                    "y": 143.5,
                },
                "width": 20,
                "height": 5,
                "alignment": "left",
                "fontSize": 7,
                "characterSpacing": 0,
                "lineHeight": 1
            },
            "reprintFrame": {
                "type": "image",
                "position": {
                    "x": 245,
                    "y": 5
                },
                "width": 22,
                "height": 11
            },
            "reprint": {
                "type": "text",
                "position": {
                    "x": 249.5,
                    "y": 7
                },
                "width": 18.33,
                "height": 7,
                "alignment": "left",
                "fontSize": 13,
                "characterSpacing": 0,
                "lineHeight": 1
            },
        }]
    };

    // 出力日付を取得する
    const today = moment().format('YYYY/MM/DD');

    // 同一納品先のレコードでグルーピングする
    const groupedDeliveryNotes = groupBy(deliveryNotes.list.toArray(), ["postCodeDeli", "prefecture1Deli", "city1Deli", "deliveryOutputFlag"]);

    for (const groupKey in groupedDeliveryNotes) {
        // 1ファイルに出力する内容をセットする変数
        let inputs: { [key: string]: string }[] = [];

        let paging = 0;
        // 1ページ当たり、10明細を配置する
        const countNewPage = 10;
        const totalPageNumber = Math.ceil(groupedDeliveryNotes[groupKey].length / countNewPage);
        while (paging * countNewPage < groupedDeliveryNotes[groupKey].length) {
            const pagingDeliveryNotes = groupedDeliveryNotes[groupKey].slice(paging * countNewPage, (paging + 1) * countNewPage);
            const firstRow = pagingDeliveryNotes[0];
            if (firstRow === undefined) continue;
            let input: { [key: string]: string } = {
                "orderCompany": firstRow.orderCompany,
                "orderCompanyName": firstRow.orderCompanyName,
                "deliveryAddress1": firstRow.postCodeDeli,
                "deliveryAddress2": firstRow.prefecture1Deli,
                "deliveryAddress3": firstRow.city1Deli,
                "outputDate": today,
                "supplier": firstRow.supplier,
                "supplierName": firstRow.supplierName,
                "address1": firstRow.postCode,
                "address2": firstRow.prefecture,
                "address3": firstRow.city,
                "pageNumber": (paging + 1).toString(),
                "pageNumberTotal": totalPageNumber.toString(),
                "reprintFrame": firstRow.deliveryOutputFlag === "X" ? baseReprintFrame : '',
                "reprint": firstRow.deliveryOutputFlag === "X" ? w('再印刷') : '',
            };
            pagingDeliveryNotes.forEach((deliveryNote, index) => {
                input[`purchaseOrder_${index + 1}`] = deliveryNote.purchaseOrder.substring(3);
                input[`purchaseOrderBarcode_${index + 1}`] = deliveryNote.purchaseOrder.substring(3);
                input[`purchaseOrderDetail_${index + 1}`] = deliveryNote.purchaseOrderDetail;
                input[`material_${index + 1}`] = deliveryNote.material + "\r\n" + deliveryNote.materialName;
                input[`pricePerOneUnit_${index + 1}`] = "" + returnAmountText(deliveryNote.pricePerOneUnit);
                input[`orderDate_${index + 1}`] = moment(deliveryNote.orderDate).isValid() ?
                    moment(deliveryNote.orderDate).format("YYYY/MM/DD")
                    : "";
                input[`desiredDeliveryDate_${index + 1}`] = moment(deliveryNote.desiredDeliveryDate).isValid() ?
                    moment(deliveryNote.desiredDeliveryDate).format("YYYY/MM/DD")
                    : "";
                input[`orderQuantity_${index + 1}`] = "" + returnQuantityText(deliveryNote.orderQuantity);
                input[`answeredQuantity_${index + 1}`] = "" + returnQuantityText(deliveryNote.answeredQuantity);
                input[`orderQuantityUnitText_${index + 1}`] = deliveryNote.orderQuantityUnitText;
                input[`productionOrder_${index + 1}`] = deliveryNote.productionOrder;
                input[`currency_${index + 1}`] = deliveryNote.currency;
            });
            inputs.push(input);
            paging++;
        }
        const pdf = yield labelmake({
            template, inputs, font
        });

        // Browser
        const blob = new Blob([pdf.buffer], { type: "application/pdf" });
        yield window.open(URL.createObjectURL(blob));
    }

    // Stateから納品書を削除する
    yield put(DeliveryNoteActions.setDeliveryNotes(new DeliveryNotes()));

    yield put(DeliveryNoteActions.setIsConnecting(false));
};

export function* DeliveryNoteSaga() {
    yield takeLatest(DeliveryNoteActions.search, search);
    yield takeLatest(DeliveryNoteActions.generatePdf, generatePdf);
}
