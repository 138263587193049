import React from 'react';

type BusyIndicatorProps<T> = {

} & JSX.IntrinsicElements['div']

export const BusyIndicator: React.FC<BusyIndicatorProps<any>> = (props) => {
    return (
        <div hidden={ props.hidden } style={{
            position: 'absolute',
            textAlign: 'center',
            verticalAlign: 'middle',
            height: '100%',
            width: '100%',
            zIndex: 99,
            backgroundColor: 'rgba(200,200,200,0.8)'
        }}>
            <div className='fd-busy-indicator--l' style={{
                top: '50%'
            }}>
                <div className='fd-busy-indicator--circle-0' />
                <div className='fd-busy-indicator--circle-1' />
                <div className='fd-busy-indicator--circle-2' />
            </div>
        </div>
    )
}
