import { JSObject } from "../types/Common";
import { ApiClient } from "../apiClient";

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

const API_PATH_PURCHASE_ORDER = '/api/purchaseOrder';

export class PurchaseOrderApi {
    static searchPurchaseOrders(params: JSObject): Promise<unknown> {
        return apiClient.get(API_PATH_PURCHASE_ORDER + '/searchPurchaseOrders', params);
    }
    static createPurchaseOrders(params: JSObject): Promise<unknown> {
        return apiClient.post(API_PATH_PURCHASE_ORDER + '/createPurchaseOrders', params);
    }
    static uploadPurchaseOrders(params: JSObject): Promise<unknown> {
        return apiClient.put(API_PATH_PURCHASE_ORDER + '/uploadPurchaseOrders', params);
    }
    static answerMessageColumns(params: JSObject): Promise<unknown> {
        return apiClient.post(API_PATH_PURCHASE_ORDER, params);
    }
}