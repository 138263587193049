import { List, Record } from 'immutable';
import { JSObject } from '../types';

export class Payment extends Record<{
    paymentNo: string;
    revision: string;
    supplier: string;
    supplierName: string;
    orderCompany: string;
    orderCompanyName: string;
    collationStatus: string;
    collationStatusText: string;
    temporarilySavedPayment: string;
    targetDateFrom: string;
    targetDateTo: string;
    revisionStatus: string;
    revisionStatusText: string;
    temporarilySavedRevision: string;
    revisionComment: string;
    revisionCommentUpdate: string;
    paymentCreateDate: string;
    paymentUpdateDate: string;
    revisionCreateDate: string;
    revisionUpdateDate: string;
    amountBase: string;
    currency: string;
}>({
    paymentNo: '',
    revision: '',
    supplier: '',
    supplierName: '',
    orderCompany: '',
    orderCompanyName: '',
    collationStatus: '',
    collationStatusText: '',
    temporarilySavedPayment: '',
    targetDateFrom: '',
    targetDateTo: '',
    revisionStatus: '',
    revisionStatusText: '',
    temporarilySavedRevision: '',
    revisionComment: '',
    revisionCommentUpdate: '',
    paymentCreateDate: '',
    paymentUpdateDate: '',
    revisionCreateDate: '',
    revisionUpdateDate: '',
    amountBase: '',
    currency: '',
}) {
    static fromResponse(response: JSObject) {
        const params = {
            ...response,
        };
        return new Payment(params);
    }
};

export class Payments extends Record<{
    list: List<Payment>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];
        const payments = List(params.map(payment => Payment.fromResponse(payment)));
        return new Payments({ list: payments });
    }
};

/**
 * 支払承認のためのオブジェクト定義
 */
export type ApproveKeys = {
    paymentNo: string;
    revision: string;
    seqno: string;
    actionType: string;
    revisionComment?: string;
    detailComment?: string;
    etag: string;
};

/**
 * 支払検索条件のためのオブジェクト定義
 */
export type SearchKeys = {
    supplier?: string;
    orderCompany?: string;
    paymentNo?: string;
    revision?: string;
    targetDateFromFrom?: Date;
    targetDateFromTo?: Date;
    targetDateToFrom?: Date;
    targetDateToTo?: Date;
    collationStatus?: string | null;
    revisionStatus?: string | null;
};