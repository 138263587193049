import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record } from 'immutable';
import { MessageActions } from '../actions/message';
import { MessagePack } from '../types';

export class MessageState extends Record<{
    messageArray: Array<MessagePack>;
}>({
    messageArray: new Array<MessagePack>(),
}) { }

export const MessageReducer = reducerWithInitialState(new MessageState())
    .case(MessageActions.push, (state, payload) => {
        return state.set('messageArray', [...state.messageArray, payload]);
    })
    .case(MessageActions.removePopup, (state, payload) => {
        if (state.messageArray.length === 0) return state;
        return state.set('messageArray', state.messageArray.filter(item => item.content?.mode === 0))
    })
    .case(MessageActions.removeFirstPopup, (state, payload) => {
        const indexOfFirstPopup = state.messageArray.findIndex(item => item.content.mode === 1);
        if (indexOfFirstPopup < 0) return state;
        return state.set('messageArray', state.messageArray.filter((item, index) => index !== indexOfFirstPopup));
    })
    ;
