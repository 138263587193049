import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { PurchaseOrderActions } from '../actions/purchaseOrder';
import { MaterialPrices } from '../models/MaterialPrice';
import { PurchaseOrder, PurchaseOrders } from '../models/PurchaseOrder';

export class PurchaseOrderState extends Record<{
    isConnecting: boolean;
    purchaseOrders: PurchaseOrders;
    uploadPurchaseOrdersResult: MaterialPrices,
    registerPurchaseOrders: Array<PurchaseOrder>;
    grossPrices: MaterialPrices;
    customerName: string;
    customerMaterial: MaterialPrices;
    didSuccessAnswerMessageColumn: boolean | null;
    didCreatePurchaseOrders: boolean | null;
    didUploadPurchaseOrders: boolean | null;
    didSearchSuccess: boolean | null;
}>({
    isConnecting: false,
    purchaseOrders: new PurchaseOrders(),
    uploadPurchaseOrdersResult: new MaterialPrices(),
    registerPurchaseOrders: Array<PurchaseOrder>(),
    grossPrices: new MaterialPrices(),
    customerName: '',
    customerMaterial: new MaterialPrices(),
    didSuccessAnswerMessageColumn: null,
    didCreatePurchaseOrders: null,
    didUploadPurchaseOrders: null,
    didSearchSuccess: null,
}) { }

export const PurchaseOrderReducer = reducerWithInitialState(new PurchaseOrderState())
    .case(PurchaseOrderActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(PurchaseOrderActions.setPurchaseOrders, (state, payload) => {
        return state.set('purchaseOrders', payload);
    })
    .case(PurchaseOrderActions.setUploadPurchaseOrdersResult, (state, payload) => {
        return state.set('uploadPurchaseOrdersResult', payload);
    })
    .case(PurchaseOrderActions.setRegisterPurchaseOrders, (state, payload) => {
        return state.set('registerPurchaseOrders', payload);
    })
    .case(PurchaseOrderActions.setGrossPrices, (state, payload) => {
        return state.set('grossPrices', payload);
    })
    .case(PurchaseOrderActions.setCustomerName, (state, payload) => {
        return state.set('customerName', payload);
    })
    .case(PurchaseOrderActions.setCustomerMaterial, (state, payload) => {
        return state.set('customerMaterial', payload);
    })
    .case(PurchaseOrderActions.setDidSuccessAnswerMessageColumn, (state, payload) => {
        return state.set('didSuccessAnswerMessageColumn', payload);
    })
    .case(PurchaseOrderActions.setDidCreatePurchaseOrders, (state, payload) => {
        return state.set('didCreatePurchaseOrders', payload);
    })
    .case(PurchaseOrderActions.setDidUploadPurchaseOrders, (state, payload) => {
        return state.set('didUploadPurchaseOrders', payload);
    })
    .case(PurchaseOrderActions.setDidSearchSuccess, (state, payload) => {
        return state.set('didSearchSuccess', payload);
    })
    ;
