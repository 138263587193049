import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record } from 'immutable';
import { Orders, SearchKeys } from '../models/Order';
import { OrderActions } from '../actions/order';

export class OrderState extends Record<{
    // 検索により取得した注文データ
    orders: Orders;
    // apiに接続中か否か
    isConnecting: boolean;
    // 検索に成功したか否か
    didSearchSuccess: boolean | null;
    // 検索キー
    searchKeys: SearchKeys | null;
}>({
    orders: new Orders(),
    isConnecting: false,
    didSearchSuccess: null,
    searchKeys: null,
}) { }

export const OrderReducer = reducerWithInitialState(new OrderState())
    .case(OrderActions.setDidSearchSuccess, (state, payload) => {
        return state.set('didSearchSuccess', payload);
    })
    .case(OrderActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(OrderActions.setOrders, (state, payload) => {
        return state.set('orders', payload);
    })
    .case(OrderActions.setSearchKeys, (state, payload) => {
        return state.set('searchKeys', payload);
    })
    ;