import actionCreateFactory from 'typescript-fsa';
import { ApproveKeys, Payment, Payments, SearchKeys } from '../models/Payment';

const actionCreator = actionCreateFactory('Payment');

export const PaymentActions = {
    // キー項目に基づき支払明細を取得する。
    getPayment: actionCreator < { orderCompany: string, supplier: string, paymentNo: string, revision: string }>('getPayment'),
    // 検索条件に基づき支払データを取得する。
    search: actionCreator<SearchKeys>('search'),
    // apiに接続中か否か
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    // 検索に成功したか否か
    setDidGetSuccess: actionCreator<boolean | null>('setDidGetSuccess'),
    setDidSearchSuccess: actionCreator<boolean | null>('setDidSearchSuccess'),
    // 検索により取得した支払データを保持する。
    setPayment: actionCreator<Payment>('setPayment'),
    setPayments: actionCreator<Payments>('setPayments'),
    // 検索キー
    setSearchKeys: actionCreator<SearchKeys>('setSearchKeys'),

    // 一時保存・承認・却下する
    updateApprove: actionCreator<ApproveKeys[]>('updateApprove'),
    // 一時保存・承認・却下に成功したか否か
    setDidApproveSuccess: actionCreator<boolean | null>('setDidApproveSuccess'),
};