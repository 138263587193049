import { Record, List } from 'immutable';
import { JSObject } from '../types';

export class DeliveryNote extends Record<{
    supplier: string;
    purchaseOrder: string;
    purchaseOrderDetail: string;
    orderCompany: string;
    orderCompanyName: string;
    status: string;
    orderDate: string;
    supplierName: string;
    material: string;
    materialName: string;
    pricePerOneUnit: string;
    desiredDeliveryDate: string;
    orderQuantity: string;
    orderQuantityUnit: string;
    orderQuantityUnitText: string;
    answeredDeliveryDate: string;
    answeredQuantity: string;
    postCode: string;
    prefecture: string;
    city: string;
    telNumber: string;
    faxNumber: string;
    postCodeDeli: string;
    prefecture1Deli: string;
    city1Deli: string;
    storageLocation: string;
    productionOrder: string;
    deliveryOutputFlag: string;
    currency: string;
}>({
    supplier: "",
    purchaseOrder: "",
    purchaseOrderDetail: "",
    orderCompany: "",
    orderCompanyName: "",
    status: "",
    orderDate: "",
    supplierName: "",
    material: "",
    materialName: "",
    pricePerOneUnit: "",
    desiredDeliveryDate: "",
    orderQuantity: "",
    orderQuantityUnit: "",
    orderQuantityUnitText: "",
    answeredDeliveryDate: "",
    answeredQuantity: "",
    postCode: "",
    prefecture: "",
    city: "",
    telNumber: "",
    faxNumber: "",
    postCodeDeli: "",
    prefecture1Deli: "",
    city1Deli: "",
    storageLocation: "",
    productionOrder: "",
    deliveryOutputFlag: "",
    currency: "",
}) {
    static fromResponse(response: JSObject) {
        const params = { ...response };
        return new DeliveryNote(params);
    }
}

export class DeliveryNotes extends Record<{
    list: List<DeliveryNote>
}>({
    list: List(),
}) {
    static fromResponse(response: Array<DeliveryNote>) {
        const params = [...response];
        const orders = List(params.map((order: JSObject) => DeliveryNote.fromResponse(order)));
        return new DeliveryNotes({ list: orders });
    }
}

/**
 * 注文検索条件のためのオブジェクト定義
 * */
export type SearchKeys = {
    purchaseOrder?: string | null;
    purchaseOrderDetail?: string | null;
} 
