import { JSObject } from "../types/Common";
import { ApiClient } from "../apiClient";
require('dotenv').config();

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

export class DeliveryNoteApi {
    static search(params: JSObject): Promise<unknown> {
        return apiClient.post('/api/deliveryNote', params);
    }
}