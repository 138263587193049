import { ApiClient } from ".";
import { JSObject } from "../types/Common";
require('dotenv').config();

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

const API_PATH = '/api/payment';

export class PaymentApi {
    static getPayment(orderCompany: string, supplier: string, paymentNo: string, revision: string): Promise<unknown> {
        return apiClient.get(API_PATH, { orderCompany, supplier, paymentNo, revision });
    }

    static search(params: JSObject): Promise<unknown> {
        return apiClient.get(API_PATH, params);
    }

    static updateApprove(params: JSObject): Promise<unknown> {
        return apiClient.post(API_PATH + '/approve', params)
    }
}