import { ApiClient } from '.';
require('dotenv').config();

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

const API_PATH = '/api/payment/detail';
const API_PDF_PATH = '/api/payment/detailPdf';

export class PaymentDetailApi {
    static getPaymentDetails(orderCompany: string, supplier: string, paymentNo: string, revision: string): Promise<unknown> {
        return apiClient.get(API_PATH, { orderCompany, supplier, paymentNo, revision });
    };
    static getPaymentDetailsPdf(orderCompany: string, supplier: string, paymentNo: string, revision: string): Promise<unknown> {
        return apiClient.get(API_PDF_PATH, { orderCompany, supplier, paymentNo, revision });
    };
};