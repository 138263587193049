import { Record } from "immutable";
import { HeaderVariant } from "../types";

// 指定したキーで配列をグルーピングする
export const groupBy = <T extends Record<{ [key: string]: any }>>(
    objects: T[],
    keys: (keyof T)[],
): { [key: string]: T[] } => objects.reduce(
    (map, obj) => {
        map[keys.map(k => obj[k]).join(',')] = map[keys.map(k => obj[k]).join(',')] || [];
        map[keys.map(k => obj[k]).join(',')].push(obj);
        return map;
    },
    {} as { [key: string]: T[] },
);

/**
 * 数値から、画面に表示するテキストを生成する
 * @param amount
 */
export const returnAmountText = (amount: string = '0') => {
    let result = '';

    if (!isNaN(Number(amount))) {
        // Numberの限界値を超えている可能性があるため、Stringのまま処理する
        var splittedAmount = String(amount).split('.');
        result += splittedAmount[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        result += splittedAmount.length > 1 ? '.' + splittedAmount[1] : '';
    } else {
        result += '0';
    }

    return result;
};

/**
 * 数値と数量単位から、画面に表示するテキストを生成する
 * @param quantity
 * @param unitText
 */
export const returnQuantityText = (quantity: string = '0', unitText: string = '') => {
    let result = `${Number(quantity).toLocaleString()} ${unitText}`;

    return result;
};

/**
 * 数値(整数4桁、小数3桁)から整数部のみの税率を生成する
 * @param taxRate
 */
export const returnTaxRateText = (taxRate: string = '0', isSpaceIncluded: boolean = false) => {
    var splittedTaxRate = taxRate.split('.');

    return `${splittedTaxRate[0]}${isSpaceIncluded ? ' %' : '%'}`;
};

type Base64ToBlobProp = {
    addBom: boolean,
    mimeType: string
};
/**
 * base64データをblobに変換する処理
 * @param data
 * @param options
 */
export const base64ToBlob = (data: string = '', options?: Base64ToBlobProp) => {
    // 変換設定
    const addBom = options?.addBom ?? false;
    const mimeType = options?.mimeType ?? 'application/pdf';
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

    const binary = atob(data);

    const binaryLength = binary.length;
    const buffer = new Uint8Array(binaryLength);
    for (var i = 0; i < binaryLength; i++) {
        buffer[i] = binary.charCodeAt(i);
    }

    const blob = new Blob(addBom ? [bom, buffer] : [buffer], { type: mimeType });
    return blob;
};

/**
 * headerVariantのlabelをnameで取得
 * @param name
 * @param headerVariant
 */
export const getHeaderSettingLabelFromName = (name: string, headerVariant: HeaderVariant[]) => {
    const tempObj = headerVariant.find(x => name == x.name);
    const tempLabel = tempObj?.label !== undefined ? tempObj.label : '';

    return tempLabel;
};