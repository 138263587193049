import actionCreateFactory from 'typescript-fsa';
import { AnswerDelivery } from '../models/AnswerDelivery';

const actionCreator = actionCreateFactory('AnswerDelivery');

export const AnswerDeliveryActions = {
    // 納期回答を登録する。
    update: actionCreator<AnswerDelivery[]>('search'),
    // apiに接続中か否か
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    // 登録に成功したか否か
    setDidUpdateSuccess: actionCreator<boolean | null>('setDidUpdateSuccess'),
}
