import { put, takeLatest } from 'redux-saga/effects';
import { DeliveryActions } from "../actions/delivery";
import { Deliveries } from '../models/Delivery';
import { getMessage as m } from '../messages';
import { MessageActions } from '../actions/message';
import { DeliveryApi } from '../apiClient/delivery';

function* search(action: ReturnType<typeof DeliveryActions.search>) {

    yield put(DeliveryActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield DeliveryApi.search(keys);

    if (response.isSuccess) {
        yield put(DeliveryActions.setDidSearchSuccess(true));
        yield put(DeliveryActions.setDeliveries(Deliveries.fromResponse(response.data)));

        if (response.data.length > 0) {
            // 正常終了
        } else {
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        }
    } else {
        yield put(DeliveryActions.setDidSearchSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(DeliveryActions.setIsConnecting(false));
}

export function* DeliverySaga() {
    yield takeLatest(DeliveryActions.search, search);
}
