import { ApiClient } from "../apiClient";
require('dotenv').config();

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

export class InformationApi {
    static getNotificationMessages(): Promise<unknown> {
        return apiClient.get('/api/information/notification');
    }

    static getInformationMessages(): Promise<unknown> {
        return apiClient.get('api/information/information');
    }
}