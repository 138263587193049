export const PURCHASING_DIVISIONS = {
    PURCHASING: {
        PURCHASE: '10',
        RETURN: '11',
        INCREASE: '12',
        REDUCTION: '13',
    },
    ACCOUNTING: {
        PURCHASE_AND_RETURN: '50',
        INCREASE_AND_REDUCTION: '51',
    },
};