import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { MaterialPriceActions } from '../actions/materialPrice';
import { MaterialPrices, SuggestMaterials, SearchKeys } from '../models/MaterialPrice';

export class MaterialPriceState extends Record<{
    // 検索により取得した品目価格データ
    materialPrices: MaterialPrices;
    // apiに接続中か否か
    isConnecting: boolean;
    // 検索に成功したか否か
    didSearchSuccess: boolean | null;
    // 検索キー
    searchKeys: SearchKeys | null;

    // サジェスト用品目取得中か
    isLoadingSuggestMaterials: boolean;
    // 品目サジェスト用の全品目データ
    suggestMaterials: SuggestMaterials;
    // 品目サジェストの取得に成功したか否か
    didGetSuggestMaterialSuccess: boolean | null;
}>({
    materialPrices: new MaterialPrices(),
    isConnecting: false,
    didSearchSuccess: null,
    searchKeys: null,

    isLoadingSuggestMaterials: false,
    suggestMaterials: new SuggestMaterials(),
    didGetSuggestMaterialSuccess: null,
}) { };

export const MaterialPriceReducer = reducerWithInitialState(new MaterialPriceState())
    .case(MaterialPriceActions.setDidSearchSuccess, (state, payload) => {
        return state.set('didSearchSuccess', payload);
    })
    .case(MaterialPriceActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(MaterialPriceActions.setMaterialPrices, (state, payload) => {
        return state.set('materialPrices', payload);
    })
    .case(MaterialPriceActions.setSearchKeys, (state, payload) => {
        return state.set('searchKeys', payload);
    })

    .case(MaterialPriceActions.setIsLoadingSuggestMaterials, (state, payload) => {
        return state.set('isLoadingSuggestMaterials', payload);
    })
    .case(MaterialPriceActions.setSuggestMaterials, (state, payload) => {
        return state.set('suggestMaterials', payload);
    })
    .case(MaterialPriceActions.setDidSearchSuggestMaterialSuccess, (state, payload) => {
        return state.set('didGetSuggestMaterialSuccess', payload);
    })
