import { Record, List } from 'immutable';
import { JSObject } from '../types';

export class Order extends Record<{
    supplier: string;
    orderCompany: string;
    orderDateFrom: string;
    orderDateTo: string;
    deliveryDateFrom: string;
    deliveryDateTo: string;
    status: string;
    purchaseOrder: string;
    purchaseOrderDetail: string;
    orderDate: string;
    deliveryDate: string;
    supplierName: string;
    orderCompanyName: string;
    material: string;
    materialName: string;
    pricePerOneUnit: string;
    currency: string;
    desiredDeliveryDate: string;
    orderQuantity: string;
    orderQuantityUnit: string;
    orderQuantityUnitText: string;
    answeredDeliveryDate: string;
    answeredQuantity: string;
    isAnsweredDeliveryDateChanged: boolean;
    isAnsweredQuantityChanged: boolean;
    answeredDeliveryDateInput: string;
    answeredQuantityInput: string;
    messageColumn: string;
    isMessageColumnChanged: boolean;
    exMsgColumn: exMsgColumn | null;
    postCode: string;
    prefecture: string;
    city: string;
    storageLocation: string;
    etag: string;
}>({
    supplier: "",
    orderCompany: "",
    orderDateFrom: "",
    orderDateTo: "",
    deliveryDateFrom: "",
    deliveryDateTo: "",
    status: "",
    purchaseOrder: "",
    purchaseOrderDetail: "",
    orderDate: "",
    deliveryDate: "",
    supplierName: "",
    orderCompanyName: "",
    material: "",
    materialName: "",
    pricePerOneUnit: "",
    currency: "",
    desiredDeliveryDate: "",
    orderQuantity: "",
    orderQuantityUnit: "",
    orderQuantityUnitText: "",
    answeredDeliveryDate: "",
    answeredQuantity: "",
    isAnsweredDeliveryDateChanged: false,
    isAnsweredQuantityChanged: false,
    answeredDeliveryDateInput: "",
    answeredQuantityInput: "",
    messageColumn: "",
    isMessageColumnChanged: false,
    exMsgColumn: null,
    postCode: "",
    prefecture: "",
    city: "",
    storageLocation: "",
    etag: "",
}) {
    static fromResponse(response: JSObject) {
        const params = {
            ...response,
            answeredDeliveryDateInput: response.answeredDeliveryDate,
            answeredQuantityInput: response.answeredQuantity
        };
        return new Order(params);
    }
}

export class Orders extends Record<{
    list: List<Order>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];
        const orders = List(params.map(order => Order.fromResponse(order)));
        return new Orders({ list: orders });
    }
}

export class exMsgColumn {
    results!: List<result>;
}

export class result {
    supplier!: string;
    purchaseOrder!: string;
    purchaseOrderDetail!: string;
    messageColumn!: string;
}

/**
 * 注文検索条件のためのオブジェクト定義
 * */
export type SearchKeys = {
    purchaseOrder?: string | null;
    supplier?: string | null;
    orderCompany?: string | null;
    orderDateFrom?: Date;
    orderDateTo?: Date;
    deliveryDateFrom?: string | null;
    deliveryDateTo?: string | null;
    searchOrdered?: boolean | null;
    searchAnswered?: boolean | null;
    searchDeliveryNotePrinted?: boolean | null;
    searchPartiallyAccepted?: boolean | null;
    searchAccepted?: boolean | null;
    searchCanceled?: boolean | null;
} 
