import { ApiClient } from "../apiClient";

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

const API_PATH = '/api/commonParam';

export class CommonParamApi {
    static getCustomerDestinations(): Promise<unknown> {
        return apiClient.get(API_PATH);
    }
}