import React from 'react';
import styled from 'styled-components';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
    const { t } = useTranslation();

    return (
        <header>
            <Wrapper>
                <div>
                    {t('お問い合わせ')}：
                    {t('support(アットマーク)supply-ya.com')}
                </div>
                <div>
                    {t('推奨ブラウザ')}：
                    {t('Chrome(最新)、Edge(最新)')}
                </div>
            </Wrapper>
        </header>
    );
}

const Wrapper = styled.div`
    background: gainsboro;
    height: 80px;
    width: 100%;
    padding: 20px;
    position: absolute;
    bottom: 0;
`;

export default Footer;
