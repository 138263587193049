import { Props } from '@fortawesome/react-fontawesome';
import { Button, LayoutPanel } from 'fundamental-react';
import moment from 'moment';
import * as PapaParse from 'papaparse';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Delivery, SearchKeys } from '../../../models/Delivery';
import { State } from '../../../reducers';
import { DatePickerParam, JSObject, SelectOption, HeaderVariant, SortedColumn } from '../../../types';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import NavButtons from '../../NavButtons';
import LayoutBody from './LayoutBody';
import LayoutFilter from './LayoutFilter';
import { DeliveryHeaderSettingActions } from '../../../actions/deliveryHeaderSetting';
import { getHeaderSettingLabelFromName } from '../../../utils/CommonFunction';

const DeliveryList: React.FC<Props> = (props: Props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        isConnecting,
        deliveries,
        deliveryHeaderSetting,
    } = useSelector((state: State) => ({
        isConnecting: state.delivery.isConnecting,
        deliveries: state.delivery.deliveries,
        deliveryHeaderSetting: state.deliveryHeaderSetting.deliveryHeaderSetting,
    }));

    const [csvDeliveryData, setCsvDeliveryData] = useState<Array<JSObject>>([]);

    /**
     * 選択項目に応じてCSVのレコードを返す
     * @param　delivery
     */
    const returndeliveryData = (delivery: JSObject) => {
        const allDelivery = [
            {
                name: 'supplier',
                value: delivery.supplier,
            }, {
                name: 'supplier',
                value: delivery.supplierName,
            }, {
                name: 'outboundDeliveryNo',
                value: delivery.outboundDeliveryNo,
            }, {
                name: 'outboundDeliveryDetail',
                value: delivery.outboundDeliveryDetail,
            }, {
                name: 'orderId',
                value: delivery.orderId,
            }, {
                name: 'orderIdDetail',
                value: delivery.orderIdDetail,
            }, {
                name: 'salesOrder',
                value: delivery.salesOrder,
            }, {
                name: 'customerMaterial',
                value: delivery.customerMaterial,
            }, {
                name: 'customerMaterial',
                value: delivery.customerMaterialName,
            }, {
                name: 'material',
                value: delivery.material,
            }, {
                name: 'material',
                value: delivery.materialName,
            }, {
                name: 'orderQuantity',
                value: delivery.orderQuantity
            }, {
                name: 'desiredDeliveryDate',
                value: moment(delivery.desiredDeliveryDate).isValid() ? moment(delivery.desiredDeliveryDate).format('YYYY/MM/DD') : '',
            }, {
                name: 'actualDeliveryDate',
                value: moment(delivery.actualDeliveryDate).isValid() ? moment(delivery.actualDeliveryDate).format('YYYY/MM/DD') : '',
            }, {
                name: 'deliveryQuantity',
                value: delivery.deliveryQuantity,
            }, {
                name: 'deliveryQuantityUnit',
                value: delivery.deliveryQuantityUnit,
            }, {
                name: 'pricePerOneUnit',
                value: delivery.pricePerOneUnit,
            }, {
                name: 'amountTotal',
                value: delivery.amountTotal,
            }, {
                name: 'currency',
                value: delivery.currency,
            },
        ];

        const deliveryData: string[] = [];

        deliveryData.push(delivery.customer);
        deliveryData.push(delivery.customerName);

        headerVariant.filter(x => x.isSelected).forEach(x => {
            const items = allDelivery.filter(y => y.name === x.name);
            items.forEach(y => deliveryData.push(y.value));
        });

        return deliveryData;
    };

    /**
     * CSV出力ボタン押下
     * @param event
     */
    const onClickOutputCsv = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (csvDeliveryData.length > 0) {

            const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            const str = csvDeliveryData.map((d) => returndeliveryData(d));
            const allHeader = [
                {
                    name: 'supplier',
                    label: t('サプライヤID'),
                }, {
                    name: 'supplier',
                    label: t('サプライヤ名称'),
                }, {
                    name: 'outboundDeliveryNo',
                    label: t('CMEs出荷伝票番号'),
                }, {
                    name: 'outboundDeliveryDetail',
                    label: t('CMEs出荷明細No.'),
                }, {
                    name: 'orderId',
                    label: t('注文番号'),
                }, {
                    name: 'orderIdDetail',
                    label: t('注文明細No.'),
                }, {
                    name: 'salesOrder',
                    label: t('CMEs受注伝票番号'),
                }, {
                    name: 'customerMaterial',
                    label: t('品目'),
                }, {
                    name: 'customerMaterial',
                    label: t('品名'),
                }, {
                    name: 'material',
                    label: t('CMEs品目'),
                }, {
                    name: 'material',
                    label: t('CMEs品名'),
                }, {
                    name: 'orderQuantity',
                    label: t('発注数量'),
                }, {
                    name: 'deliveryQuantity',
                    label: t('出荷数量'),
                }, {
                    name: 'deliveryQuantityUnit',
                    label: t('単位'),
                }, {
                    name: 'desiredDeliveryDate',
                    label: t('希望納期'),
                }, {
                    name: 'actualDeliveryDate',
                    label: t('みなし着荷日'),
                }, {
                    name: 'pricePerOneUnit',
                    label: t('正味価格'),
                }, {
                    name: 'amountTotal',
                    label: t('正味額'),
                }, {
                    name: 'currency',
                    label: t('通貨'),
                },
            ];

            const headerData: string[] = [];

            headerData.push(t('企業ID'));
            headerData.push(t('企業名称'));

            headerVariant.filter(x => x.isSelected).forEach(x => {
                const label = allHeader.filter(y => y.name === x.name);
                label.forEach(y => headerData.push(y.label));
            });

            const body = PapaParse.unparse([headerData, ...str]);
            const url = window.URL.createObjectURL(new Blob([bom, body], { type: 'text/csv' }));
            const a = document.createElement('a');

            a.download = 'delivery list_' + moment().format('YYYYMMDD_HHmmss') + '.csv';
            a.href = url;
            a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
            a.click();
        }
    };


    /**
     * 一覧に表示されているデータを設定
     * @param displayDelivery
     */
    const setDisplayDeliveryData = (displayDelivery: JSObject[]) => {
        setCsvDeliveryData([...displayDelivery]);
    };

    // 並び替え項目 ラベル保持用
    const localHeaderVariantForLabel = deliveryHeaderSetting.get('deliveryHeaderVariant') as HeaderVariant[];
    // 並び替え項目 ローカルストレージの設定値を初期値とし、ラベルにi18nを適用する
    const localHeaderVariant = localHeaderVariantForLabel.map((x) => {
        return {
            isSelected: x.isSelected,
            label: t(x.label),
            name: x.name,
        };
    });
    const [headerVariant, setHeaderVariant] = useState<HeaderVariant[]>([...localHeaderVariant]);

    useEffect(() => {
        const newLocalHeaderVariant = headerVariant.map((x) => {
            return {
                isSelected: x.isSelected,
                label: getHeaderSettingLabelFromName(x.name, localHeaderVariantForLabel),
                name: x.name
            };
        });

        // ヘッダーの表示・非表示、並び順をローカルストレージに保存
        dispatch(DeliveryHeaderSettingActions.setDeliveryHeaderVariant([...newLocalHeaderVariant]));
    }, [headerVariant]);


    return (
        <>
            <BusyIndicator hidden={!isConnecting} />
            <LayoutPanel style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <LayoutPanel.Filters>
                    <LayoutFilter />
                </LayoutPanel.Filters>
                <LayoutPanel.Body style={{ flex: '1 1 auto', height: '0', padding: '0rem 0.5rem 1.5rem' }}>
                    <LayoutBody headerVariant={headerVariant} setHeaderVariant={setHeaderVariant} setDisplayDeliveryData={setDisplayDeliveryData} />
                </LayoutPanel.Body>
                <LayoutPanel.Footer>
                    <div style={{ width: '100%' }}>
                        <div className='fd-bar__right'>
                            <div className='fd-bar__element'>
                                <Button option='emphasized' onClick={onClickOutputCsv}>{t('CSV')}</Button>
                            </div>
                            <NavButtons screenMode={'deliveryList'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
        </>
    );
};

export default DeliveryList;
