import { Button, DatePicker, FormGroup, FormInput, FormItem, FormLabel, InputGroup, List, MultiInput, Popover, Select, Token } from 'fundamental-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CommonParamActions } from '../../../actions/commonParam';
import { MessageActions } from '../../../actions/message';
import { PurchaseOrderActions } from '../../../actions/purchaseOrder';
import { getMessage as m, getWord as w } from '../../../messages';
import { SearchKeys } from '../../../models/PurchaseOrder';
import { State } from '../../../reducers';
import { DatePickerParam, SelectOption } from '../../../types';

export const initialSearchKeys: SearchKeys = {
    limitNumber: '500',
    receiptNo: '',
    orderId: '',
    customerId: '',
    supplierId: '',
    customerMaterial: '',
    orderDateFrom: null,
    orderDateTo: null,
    desiredDeliveryDateFrom: null,
    desiredDeliveryDateTo: null,
    statusNotDelivered: true,
    statusDelivered: false,
    statusCanceled: false,
};

const LayoutFilter: React.FC = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const DEFAULT_LIMIT_NUMBER = '500';
    const MAX_LIMIT_NUMBER = '1000';

    const {
        user,
        userType,
        customerDestinations,
        didSuccessAnswerMessageColumn,
        didSearchSuccess,
    } = useSelector((state: State) => ({
        user: state.user.user,
        userType: state.user.userType,
        customerDestinations: state.commonParam.customerDestinations.list,
        didSuccessAnswerMessageColumn: state.purchaseOrder.didSuccessAnswerMessageColumn,
        didSearchSuccess: state.purchaseOrder.didSearchSuccess
    }));

    const [searchKeys, setSearchKeys] = useState<SearchKeys>({ ...initialSearchKeys });
    // 検索成功時の検索条件を保持
    const [successSearchedSearchKeys, setSuccessSearchedSearchKeys] = useState<SearchKeys>({ ...initialSearchKeys });

    // 最大該当数
    const onChangeLimitNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value !== '' && !event.currentTarget.value.match(/^[0-9]+$/)) {
            return;
        }
        searchKeys.limitNumber = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    const onBlurLimitNumber = (event: React.FocusEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        if (Number(newValue) < 1 || isNaN(Number(newValue))) {
            // 件数が0以下や形式エラーの場合、デフォルト値をセット
            searchKeys.limitNumber = DEFAULT_LIMIT_NUMBER;
        } else if (Number(newValue) > Number(MAX_LIMIT_NUMBER)) {
            // 件数が最大値オーバーの場合、最大値をセット
            searchKeys.limitNumber = MAX_LIMIT_NUMBER;
        } else {
            searchKeys.limitNumber = newValue;
        }

        setSearchKeys({ ...searchKeys });
    };

    // 企業ID
    const onChangeCustomerId = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.customerId = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    // サプライヤID
    const onSelectSupplierId = (event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>, selectedOption: SelectOption) => {
        searchKeys.supplierId = selectedOption.key !== 'emptyOption' ? selectedOption.key : '';
        setSearchKeys({ ...searchKeys });
    };

    // 受付番号
    const onChangeReceiptNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.receiptNo = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    // 注文番号
    const onChangeOrderId = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.orderId = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    // 品目(得意先品目)
    const onChangeCustomerMaterial = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.customerMaterial = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    }

    // 発注日付(開始日) - 初期値：当日の3か月前
    const onInputBlurOrderDateFrom = (param: DatePickerParam) => {
        if (moment(param.formattedDate).isValid())
            searchKeys.orderDateFrom = new Date(param.isoFormattedDate);
        else
            searchKeys.orderDateFrom = null;

        setSearchKeys({ ...searchKeys });
    };

    // 発注日付(終了日) - 初期値：当日
    const onInputBlurOrderDateTo = (param: DatePickerParam) => {
        if (moment(param.formattedDate).isValid())
            searchKeys.orderDateTo = new Date(param.isoFormattedDate);
        else
            searchKeys.orderDateTo = null;

        setSearchKeys({ ...searchKeys });
    };

    // 希望納期(開始日)
    const onInputBlurDeliveryDateFrom = (param: DatePickerParam) => {
        if (moment(param.formattedDate).isValid())
            searchKeys.desiredDeliveryDateFrom = new Date(param.isoFormattedDate);
        else
            searchKeys.desiredDeliveryDateFrom = null;

        setSearchKeys({ ...searchKeys });
    };

    // 希望納期(終了日)
    const onInputBlurDeliveryDateTo = (param: DatePickerParam) => {
        if (moment(param.formattedDate).isValid())
            searchKeys.desiredDeliveryDateTo = new Date(param.isoFormattedDate);
        else
            searchKeys.desiredDeliveryDateTo = null;

        setSearchKeys({ ...searchKeys });
    };

    // トークン削除
    const onClickToken = (event: React.SyntheticEvent) => {
        const dataset = event.currentTarget.parentElement?.dataset;
        if (dataset === undefined) {
            return;
        }
        const key = dataset.key ?? '';

        switch (key) {
            case t('発注残'):
                searchKeys.statusNotDelivered = false;
                break;
            case t('完納'):
                searchKeys.statusDelivered = false;
                break;
            case t('キャンセル'):
                searchKeys.statusCanceled = false;
                break;
            default:
                break;
        }

        setSearchKeys({ ...searchKeys });
    };

    // ステータス変更
    const onChangeStatusList = (event: React.SyntheticEvent<HTMLInputElement, Event>, checkedState: boolean) => {
        const dataset = event.currentTarget.parentElement?.parentElement?.dataset;
        if (dataset === undefined) {
            return;
        }
        const key = dataset.key ?? '';

        switch (key) {
            case t('発注残'):
                searchKeys.statusNotDelivered = checkedState;
                break;
            case t('完納'):
                searchKeys.statusDelivered = checkedState;
                break;
            case t('キャンセル'):
                searchKeys.statusCanceled = checkedState;
                break;
            default:
                break;
        }

        setSearchKeys({ ...searchKeys });
    };

    // 「検索」ボタン押下時
    const onClickSearchButton = () => {
        // 入力必須項目確認
        const invalidKeys: Array<string> = [];
        if (searchKeys.limitNumber.length === 0)
            invalidKeys.push(w('最大該当数'));
        if (searchKeys.customerId.length === 0)
            invalidKeys.push(w('企業ID'));

        if (invalidKeys.length > 0) {
            const keys = Array.from(invalidKeys.values()).join(', ');
            dispatch(MessageActions.push({ content: m('項目 %s：必須入力項目に値が入力されていません。'), words: [keys] }));
            return;
        }

        if (Number(searchKeys.limitNumber) <= 0) {
            dispatch(MessageActions.push({ content: m('無効な値が入力されています。'), words: [] }));
            return;
        }

        dispatch(PurchaseOrderActions.searchPurchaseOrders(searchKeys));
    };

    // 発注検索画面一覧から戻る場合、検索条件を保持
    useEffect(() => {
        if (user.userId === '')
            return;

        // カスタマユーザの場合、企業ID欄にカスタマユーザIDを表示
        if (userType === 'customer') {
            setSearchKeys({ ...searchKeys, customerId: user.userId });
        }

        // CMEs導入企業の場合、サプライヤ欄にユーザ名を表示
        if (userType === 'company') {
            setSearchKeys({ ...searchKeys, supplierId: user.userId });
        }

    }, [user, userType]);

    useEffect(() => {
        dispatch(CommonParamActions.getCustomerDestinations());

    }, []);

    useEffect(() => {
        // 通信欄回答後に検索成功時の検索条件で再検索
        if (didSuccessAnswerMessageColumn === true) {
            dispatch(PurchaseOrderActions.searchPurchaseOrders(successSearchedSearchKeys));
        }
        dispatch(PurchaseOrderActions.setDidSuccessAnswerMessageColumn(null));
    }, [didSuccessAnswerMessageColumn]);

    useEffect(() => {
        // 検索成功時の検索条件をセット
        if (didSearchSuccess === true) {
            setSuccessSearchedSearchKeys({...searchKeys});
        }
        dispatch(PurchaseOrderActions.setDidSearchSuccess(null));
    }, [didSearchSuccess]);

    return (
        <>
            <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
                <PaddingFormItem>
                    <FormLabel required>{t('企業ID')}</FormLabel>
                    <FormInput
                        value={searchKeys.customerId ?? ''}
                        style={{ width: '8rem' }}
                        maxLength={10}
                        pattern='^[0-9A-Za-z]+$'
                        onChange={onChangeCustomerId}
                        disabled={userType === 'customer' ? true : false}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('サプライヤID')}</FormLabel>
                    <div style={{ display: userType === 'customer' ? '' : 'none' }}>
                        <LargeWidthSelect
                            options={customerDestinations.map((x, index) => ({ key: x.companyId, text: x.companyId + '\t' + x.companyName })).toArray()}
                            includeEmptyOption
                            onSelect={onSelectSupplierId}
                            selectedKey={searchKeys.supplierId ?? ''}
                            disabled={userType === 'company' ? true : false}
                        />
                    </div>
                    <div style={{ display: userType === 'customer' ? 'none' : '' }}>
                        <FormInput
                            style={{ width: '15rem' }}
                            disabled
                            value={user.userId + '\t' + user.userNm}
                        />
                    </div>
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('受付番号')}</FormLabel>
                    <FormInput
                        value={searchKeys.receiptNo ?? ''}
                        style={{ width: '8rem' }}
                        maxLength={13}
                        pattern='^[0-9A-Za-z]+$'
                        onChange={onChangeReceiptNo}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('注文番号')}</FormLabel>
                    <FormInput
                        value={searchKeys.orderId ?? ''}
                        style={{ width: '15rem' }}
                        maxLength={35}
                        pattern='^[0-9A-Za-z]+$'
                        onChange={onChangeOrderId}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('品目')}</FormLabel>
                    <FormInput
                        value={searchKeys.customerMaterial ?? ''}
                        maxLength={35}
                        style={{ width: '15rem' }}
                        onChange={onChangeCustomerMaterial}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('発注日付')}</FormLabel>
                    <NoPaddingInputGroup>
                        <DatePicker
                            defaultValue={
                                searchKeys.orderDateFrom !== undefined && moment(searchKeys.orderDateFrom).isValid() ?
                                    moment(searchKeys.orderDateFrom).format('YYYY/MM/DD') :
                                    ''
                            }
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            style={{ maxWidth: '10rem', margin: '3px' }}
                            onInputBlur={onInputBlurOrderDateFrom}
                        />
                        <InputGroup.Addon>{t('～')}</InputGroup.Addon>
                        <DatePicker
                            defaultValue={
                                searchKeys.orderDateTo !== undefined && moment(searchKeys.orderDateTo).isValid() ?
                                    moment(searchKeys.orderDateTo).format('YYYY/MM/DD') :
                                    ''
                            }
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            style={{ maxWidth: '10rem', margin: '3px' }}
                            onInputBlur={onInputBlurOrderDateTo}
                        />
                    </NoPaddingInputGroup>
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('希望納期')}</FormLabel>
                    <NoPaddingInputGroup>
                        <DatePicker
                            defaultValue={
                                searchKeys.desiredDeliveryDateFrom !== undefined && moment(searchKeys.desiredDeliveryDateFrom).isValid() ?
                                    moment(searchKeys.desiredDeliveryDateFrom).format('YYYY/MM/DD') :
                                    ''
                            }
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            style={{ maxWidth: '10rem', margin: '3px' }}
                            onInputBlur={onInputBlurDeliveryDateFrom}
                        />
                        <InputGroup.Addon>{t('～')}</InputGroup.Addon>
                        <DatePicker
                            defaultValue={
                                searchKeys.desiredDeliveryDateTo !== undefined && moment(searchKeys.desiredDeliveryDateTo).isValid() ?
                                    moment(searchKeys.desiredDeliveryDateTo).format('YYYY/MM/DD') :
                                    ''
                            }
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            style={{ maxWidth: '10rem', margin: '3px' }}
                            onInputBlur={onInputBlurDeliveryDateTo}
                        />
                    </NoPaddingInputGroup>
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('発注ステータス')}</FormLabel>
                    <InputGroup style={{ margin: '0.25rem 0', width: '20rem' }}>
                        <div className='fd-tokenizer'>
                            <div className='fd-tokenizer__inner'>
                                <Token
                                    style={{ display: searchKeys.statusNotDelivered ? 'flex' : 'none' }}
                                    data-key={t('発注残')}
                                    onClick={onClickToken}
                                >{t('発注残')}</Token>
                                <Token
                                    style={{ display: searchKeys.statusDelivered ? 'flex' : 'none' }}
                                    data-key={t('完納')}
                                    onClick={onClickToken}
                                >{t('完納')}</Token>
                                <Token
                                    style={{ display: searchKeys.statusCanceled ? 'flex' : 'none' }}
                                    data-key={t('キャンセル')}
                                    onClick={onClickToken}
                                >{t('キャンセル')}</Token>
                            </div>
                        </div>
                        <InputGroup.Addon isButton>
                            <Popover
                                body={
                                    <List header={t('発注ステータス')} selectable >
                                        <List.Item selected={searchKeys.statusNotDelivered}>
                                            <List.Selection checkBoxAriaLabel={t('発注残')} data-key={t('発注残')} onChange={onChangeStatusList}>
                                                <List.Text>{t('発注残')}</List.Text>
                                            </List.Selection>
                                        </List.Item>
                                        <List.Item selected={searchKeys.statusDelivered}>
                                            <List.Selection checkBoxAriaLabel={t('完納')} data-key={t('完納')} onChange={onChangeStatusList}>
                                                <List.Text>{t('完納')}</List.Text>
                                            </List.Selection>
                                        </List.Item>
                                        <List.Item selected={searchKeys.statusCanceled}>
                                            <List.Selection checkBoxAriaLabel={t('キャンセル')} data-key={t('キャンセル')} onChange={onChangeStatusList}>
                                                <List.Text>{t('キャンセル')}</List.Text>
                                            </List.Selection>
                                        </List.Item>
                                    </List>
                                }
                                control={<Button glyph='value-help' option='transparent' />}
                                placement='bottom-end'
                                type='listbox'
                            />
                        </InputGroup.Addon>
                    </InputGroup>
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel required={true}>{t('最大該当数')}</FormLabel>
                    <FormInput
                        value={searchKeys.limitNumber}
                        style={{ textAlign: 'right', width: '5rem' }}
                        onBlur={onBlurLimitNumber}
                        onChange={onChangeLimitNumber}
                    />
                </PaddingFormItem>
            </FormGroup>
            <div style={{ width: '100%' }}>
                <div className='fd-bar__right'>
                    <div className='fd-bar__element'>
                        <Button id='search_button' onClick={onClickSearchButton} option='emphasized' style={{ width: '96px' }}>{t('検索')}</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

const NoPaddingInputGroup = styled(InputGroup)`
	    border: 0;
	    &&& > .fd-input-group__input:nth-child(1) {
	        padding-left: 0;
	    }
	    &&& > .fd-input-group__input:nth-last-child(1) {
	        padding-right: 0;
	    }
	`;

const PaddingFormItem = styled(FormItem)`
	    padding: 0 10px;
	`;

const LargeWidthSelect = styled(Select)`
	    margin: 4px;
	    width: 15rem;
	`;

export default LayoutFilter;
