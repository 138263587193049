import actionCreateFactory from 'typescript-fsa';
import { SortedColumn, HeaderVariant } from '../types';

const actionCreator = actionCreateFactory('DeliveryHeaderSetting');

export const DeliveryHeaderSettingActions = {
    // 出荷実績一覧画面のヘッダーバリアントの保存
    setDeliveryHeaderVariant: actionCreator<HeaderVariant[]>('setDeliveryHeaderVariant'),
    // 出荷実績一覧画面のヘッダーのソート状態保存
    setDeliverySortedColumns: actionCreator<SortedColumn[]>('setDeliverySortedColumns'),
}