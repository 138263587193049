import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record, Map } from 'immutable';
import { SalesOrderHeaderSettingActions } from '../actions/salesOrderHeaderSetting';
import { SortedColumn, HeaderVariant } from '../types';

type salesOrderHeaderSettingKey = 'salesOrderHeaderVariant' | 'salesOrderSortedColumns';

type arrayHeaderVariant = HeaderVariant[];
type arraySortedColumn = SortedColumn[];

type arrayHeaderSetting = arrayHeaderVariant | arraySortedColumn;
type SalesOrderHeaderSetting = Map<salesOrderHeaderSettingKey, arrayHeaderSetting>;

export class SalesOrderHeaderSettingState extends Record<{
    salesOrderHeaderVariant: HeaderVariant[],
    salesOrderSortedColumns: SortedColumn[],
}>({
    salesOrderHeaderVariant: ([{
        isSelected: true,
        name: 'orderCompany',
        label: '企業',
    }, {
        isSelected: true,
        name: 'purchaseOrder',
        label: '注文ID',
    }, {
        isSelected: true,
        name: 'purchaseOrderDetail',
        label: '明細No.',
    }, {
        isSelected: true,
        name: 'material',
        label: '品目',
    }, {
        isSelected: true,
        name: 'pricePerOneUnit',
        label: '単価',
    }, {
        isSelected: true,
        name: 'currency',
        label: '通貨',
    }, {
        isSelected: true,
        name: 'orderDate',
        label: '受注日',
    }, {
        isSelected: true,
        name: 'deliveryDate',
        label: '希望納期',
    }, {
        isSelected: true,
        name: 'answeredDeliveryDate',
        label: '回答納期',
    }, {
        isSelected: true,
        name: 'orderQuantity',
        label: '受注数',
    }, {
        isSelected: true,
        name: 'answeredQuantity',
        label: '回答数量',
    }, {
        isSelected: true,
        name: 'orderQuantityUnit',
        label: '単位',
    }, {
        isSelected: true,
        name: 'status',
        label: 'ステータス',
    }, {
        isSelected: true,
        name: 'answeredDeliveryDateInput',
        label: '回答納期(入力)',
    }, {
        isSelected: true,
        name: 'answeredQuantityInput',
        label: '回答数量(入力)',
    }, {
        isSelected: true,
        name: 'messageColumn',
        label: '通信欄',
    }, {
        isSelected: true,
        name: 'messageColumns',
        label: '通信欄履歴',
    }, {
        isSelected: true,
        name: 'postCode',
        label: '納品先住所',
    }]),
    salesOrderSortedColumns: ([]),
}) {
    salesOrderHeaderSetting: SalesOrderHeaderSetting = Map<salesOrderHeaderSettingKey, arrayHeaderSetting>();
}

export const SalesOrderHeaderSettingReducer = reducerWithInitialState(new SalesOrderHeaderSettingState())
    .case(SalesOrderHeaderSettingActions.setSalesOrderHeaderVariant, (state, payload) => {
        return state.set('salesOrderHeaderVariant', payload);
    })
    .case(SalesOrderHeaderSettingActions.setSalesOrderSortedColumns, (state, payload) => {
        return state.set('salesOrderSortedColumns', payload);
    })