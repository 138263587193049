import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { history, configureStore } from './configureStore';
import { ConnectedRouter } from 'connected-react-router';
import queryString from 'querystring';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
require('dotenv').config();


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const store = configureStore();

const pathname = window.location.pathname;

const getCompanyInfo = () => {
    if (window.location.pathname === '/') {
        const query = (window.location.search).replace('?', '');
        let result = queryString.parse(query);
        if (result.mandt === undefined) {
            result.mandt = process.env.REACT_APP_SAP_CLIENT !== undefined ? process.env.REACT_APP_SAP_CLIENT : '300';
        }
        return result;
    } else {
        return {
            mandt: process.env.REACT_APP_SAP_CLIENT !== undefined ? process.env.REACT_APP_SAP_CLIENT : '300',
        };
    }
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)}>
            <ConnectedRouter basename={baseUrl} history={history}>
                <App queryParam={getCompanyInfo()} initialPathname={pathname} />
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

