import { Button, FormGroup, FormInput, FormItem, FormLabel, InputGroup, LayoutPanel } from 'fundamental-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MessageActions } from '../../../actions/message';
import { PaymentActions } from '../../../actions/payment';
import { PaymentDetailActions } from '../../../actions/paymentDetail';
import { history } from '../../../configureStore';
import { getMessage as m } from '../../../messages';
import { ApproveKeys, Payment } from '../../../models/Payment';
import { PaymentDetails } from '../../../models/PaymentDetail';
import { State } from '../../../reducers';
import { ApproveType } from '../../../types';
import { JSObject } from '../../../types/Common';
import { returnAmountText, returnTaxRateText} from '../../../utils/CommonFunction';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import { confirmBox } from '../../atoms/Confirm';
import { backcolor0 } from '../../colors';
import NavButtons from '../../NavButtons';
import LayoutBody from './LayoutBody';

type Props = {
    match: {
        params: {
            orderCompany: string;
            supplier: string;
            paymentNo: string;
            revision: string;
        }
    }
}

const PaymentDetail: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        didApproveSuccess,
        didGetPaymentSuccess,
        didGetPaymentDetailSuccess,
        isConnectingHeader,
        isConnectingDetail,
        payment,
        paymentDetails,
        userType,
    } = useSelector((state: State) => ({
        didApproveSuccess: state.payment.didApproveSuccess,
        didGetPaymentSuccess: state.payment.didGetSuccess,
        didGetPaymentDetailSuccess: state.paymentDetail.didGetSuccess,
        isConnectingHeader: state.payment.isConnecting,
        isConnectingDetail: state.paymentDetail.isConnecting,
        payment: state.payment.payment,
        paymentDetails: state.paymentDetail.paymentDetails,
        userType: state.user.userType,
    }));

    // 表示用データを作成
    const [paymentData, setPaymentData] = useState<JSObject>(payment.toJS());
    const [detailComments, setDetailComments] = useState<Array<string>>([]);

    useEffect(() => {
        document.body.style.background = backcolor0;

        dispatch(PaymentActions.getPayment({ orderCompany: props.match.params.orderCompany, supplier: props.match.params.supplier, paymentNo: props.match.params.paymentNo, revision: props.match.params.revision }));
        dispatch(PaymentDetailActions.getPaymentDetails({ orderCompany: props.match.params.orderCompany, supplier: props.match.params.supplier, paymentNo: props.match.params.paymentNo, revision: props.match.params.revision }));
    }, [dispatch, props.match.params.paymentNo, props.match.params.revision, props.match.params.supplier]);

    useEffect(() => {
        setPaymentData(payment.toJS());
    }, [payment]);

    useEffect(() => {
        setDetailComments(paymentDetails.list.map(d => d.detailComment).toArray());
    }, [paymentDetails]);

    useEffect(() => {
        if (didApproveSuccess === null) return;

        if (didApproveSuccess) {
            dispatch(PaymentActions.getPayment({ orderCompany: props.match.params.orderCompany, supplier: props.match.params.supplier, paymentNo: props.match.params.paymentNo, revision: props.match.params.revision }));
            dispatch(PaymentDetailActions.getPaymentDetails({ orderCompany: props.match.params.orderCompany, supplier: props.match.params.supplier, paymentNo: props.match.params.paymentNo, revision: props.match.params.revision }));

            // 明細通信欄をクリア
            setDetailComments([]);
        }

        dispatch(PaymentActions.setDidApproveSuccess(null));
    }, [dispatch, didApproveSuccess, props.match.params.paymentNo, props.match.params.revision, props.match.params.supplier]);

    useEffect(() => {
        // いずれかがnullの場合、両方値が入るまで待機
        if (didGetPaymentSuccess === null || didGetPaymentDetailSuccess === null) return;

        // いずれかがfalseの場合、続行不可のためヘッダも明細もクリアする
        if (didGetPaymentSuccess === false || didGetPaymentDetailSuccess === false) {
            dispatch(PaymentActions.setPayment(new Payment()));
            dispatch(PaymentDetailActions.setPaymentDetails(new PaymentDetails()));
            setDetailComments([]);
        }

        dispatch(PaymentActions.setDidGetSuccess(null));
        dispatch(PaymentDetailActions.setDidGetSuccess(null));
    }, [dispatch, didGetPaymentSuccess, didGetPaymentDetailSuccess]);

    /**
     * リビジョン通信欄変更イベントハンドラ
     * @param event
     */
    const onChangeRevisionComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        paymentData.revisionComment = event.currentTarget.value;
        setPaymentData({ ...paymentData });
    }

    /**
     * 承認ボタン押下イベントハンドラ
     * @param event
     */
    const onClickApprove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const validateResult = validateBeforeApprove();
        if (!validateResult) return;

        // 承認リクエスト呼出用のパラメータを生成する
        const approveKeysList = returnApproveKeysList('approve');

        dispatch(PaymentActions.updateApprove(approveKeysList));
    };

    /**
     * 戻るボタン押下イベントハンドラ
     * @param event
     */
    const onClickBack = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const ok = () => {
            history.goBack();
        }
        const cancel = () => {

        }
        confirmBox('検索画面に戻ります。よろしいですか？', [], ok, cancel);
    }

    /**
     * 却下ボタン押下イベントハンドラ
     * @param event
     */
    const onClickReject = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const validateResult = validateBeforeApprove();
        if (!validateResult) return;

        // 承認リクエスト呼出用のパラメータを生成する
        const approveKeysList = returnApproveKeysList('reject');

        dispatch(PaymentActions.updateApprove(approveKeysList));
    };

    /**
     * 一時保存ボタン押下イベントハンドラ
     * @param event
     */
    const onClickTempSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const validateResult = validateBeforeApprove();
        if (!validateResult) return;

        // 承認リクエスト呼出用のパラメータを生成する
        const approveKeysList = returnApproveKeysList('tempSave');

        dispatch(PaymentActions.updateApprove(approveKeysList));
    };

    /**
     * 一時保存・承認・却下時のリクエストパラメータを生成する
     * @param approveType
     */
    const returnApproveKeysList = (approveType: ApproveType) => {
        // 承認タイプに応じてパラメータを生成
        const approveKeysList = paymentDetails.list.map((paymentDetail, index) => {
            return {
                paymentNo: paymentDetail.paymentNo,
                revision: paymentDetail.revision,
                seqno: paymentDetail.seqno,
                actionType:
                    approveType === 'approve' ? '1' :
                        approveType === 'reject' ? '2' :
                            '3',
                revisionComment: paymentData.revisionComment,
                detailComment: detailComments[index] ?? '',
                etag: paymentDetail.etag,
            } as ApproveKeys;
        }).toArray();

        return approveKeysList;
    }

    /**
     * 一時保存・承認・却下時のバリデーションチェック
     * @returns true: エラーなし、false: エラーあり
     */
    const validateBeforeApprove = () => {
        // バリデーションチェック
        // 通信欄(ヘッダ)に128文字以上入力した場合はエラー
        if (paymentData.revisionComment.length > 128) {
            dispatch(MessageActions.push({
                content: m('支払番号 %s, リビジョン %s：ヘッダ通信欄は128文字以下で入力してください。'),
                words: [paymentData.paymentNo, paymentData.revision]
            }));
            return false;
        }

        // 通信欄(明細)に128文字以上入力した場合はエラー
        const msgColumnSizeOverIndex = detailComments.findIndex(data => data.length > 128);
        if (msgColumnSizeOverIndex >= 0) {
            dispatch(MessageActions.push({
                content: m('注文ID %s, 明細No. %s：明細通信欄は128文字以下で入力してください。'),
                words: [
                    paymentDetails.list.get(msgColumnSizeOverIndex)?.orderId ?? '',
                    paymentDetails.list.get(msgColumnSizeOverIndex)?.orderItem ?? ''
                ]
            }));
            return false;
        }

        return true;
    };

    /**
     * CSV出力ボタン押下
     * @param event
     */
    const onClickOutputCsv = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {

        const createCommentHistories = (items: Array<JSObject>) => {
            let commentHistory = '';
            if (items !== undefined && items.length > 0) {
                commentHistory = items.reduce(
                    (prev: string, current) => prev === '' ?
                        current.detailComment + (moment(current.detailCommentUpdate).format('YYYY/MM/DD') === '0001/01/01' ? '' : moment(current.detailCommentUpdate).format(' YYYY/MM/DD')) :
                        prev + ' ' + current.detailComment + ((moment(current.detailCommentUpdate).format('YYYY/MM/DD') === '0001/01/01' ? '' : moment(current.detailCommentUpdate).format(' YYYY/MM/DD'))),
                    ''
                );
            }
            return commentHistory;
        };

        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const str = paymentDetails.list.map((item, index) => [
            paymentData.orderCompany,
            paymentData.orderCompanyName,
            paymentData.paymentNo,
            paymentData.revision,
            moment(paymentData.targetDateFrom).isValid() ? moment(paymentData.targetDateFrom).format('YYYY/MM/DD') : '',
            moment(paymentData.targetDateTo).isValid() ? moment(paymentData.targetDateTo).format('YYYY/MM/DD') : '',
            ''.concat(paymentData.collationStatusText, paymentData.temporarilySavedPayment === 'X' ? t('(保存中)') : ''),
            ''.concat(paymentData.revisionStatusText, paymentData.temporarilySavedRevision === 'X' ? t('(保存中)') : ''),
            paymentData.amountBase,
            paymentData.currency,
            paymentData.revisionComment,
            item.orderId,
            item.orderItem,
            item.purchasingDivisionText,
            moment(item.postingDate).isValid() ? moment(item.postingDate).format('YYYY/MM/DD') : '',
            item.material,
            item.itemText,
            Number(item.deliveryQuantity) === 0 ? '' : item.deliveryQuantity,
            item.deliveryQuantityUnitText,
            Number(item.pricePerOneUnit) === 0 ? '' : item.pricePerOneUnit,
            item.amountBase,
            returnTaxRateText(item.taxRate),
            item.note,
            detailComments[index],
            createCommentHistories(item.exSearchDetailCommentHistories !== null ? item.exSearchDetailCommentHistories.list.toArray() : []),
        ]);

        const header = [
            '企業ID',
            '企業名',
            '支払番号',
            'リビジョン',
            '対象期間(From)',
            '対象期間(To)',
            '照合ステータス',
            'リビジョンステータス',
            '支払金額(本体)',
            '通貨',
            '通信欄',
            '注文ID',
            '明細No.',
            '仕入区分',
            '納入日付',
            '品目',
            '品目名',
            '納入数',
            '数量単位',
            '単価',
            '金額(本体)',
            '税率',
            '備考',
            '通信欄',
            '通信欄履歴',
        ]

        const body = [header, ...str.toArray()].join('\r\n');
        const url = window.URL.createObjectURL(new Blob([bom, body], { type: 'text/csv' }));
        const a = document.createElement('a');

        a.download = 'payment details_' + moment().format('YYYYMMDD_HHmmss') + '.csv';
        a.href = url;
        a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
        a.click();
    }

    /**
     * PDF出力ボタン押下
     */
    const onClickOutputDetailPdf = () => {
        dispatch(PaymentDetailActions.getPaymentDetailsPdf({
            orderCompany: props.match.params.orderCompany,
            supplier: props.match.params.supplier,
            paymentNo: props.match.params.paymentNo,
            revision: props.match.params.revision
        }));
    }

    return (
        <>
            <BusyIndicator hidden={!isConnectingHeader && !isConnectingDetail} />
            <LayoutPanel style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <LayoutPanel.Filters>
                    <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <PaddingFormItem>
                            <FormLabel>{t('企業ID')}</FormLabel>
                            <FormInput
                                readOnly
                                style={{ width: '7rem' }}
                                value={paymentData.orderCompany}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('企業名')}</FormLabel>
                            <FormInput
                                readOnly
                                style={{ width: '10rem' }}
                                value={paymentData.orderCompanyName}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('支払番号')}</FormLabel>
                            <FormInput
                                readOnly
                                style={{ width: '10rem' }}
                                value={paymentData.paymentNo}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('リビジョン')}</FormLabel>
                            <FormInput
                                readOnly
                                style={{ width: '3rem' }}
                                value={paymentData.revision}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('対象期間')}</FormLabel>
                            <InputGroup readOnly>
                                <FormInput
                                    readOnly
                                    style={{ maxWidth: '8rem' }}
                                    value={
                                        moment(paymentData.targetDateFrom).isValid() ?
                                            moment(paymentData.targetDateFrom).format('YYYY/MM/DD') :
                                            ''
                                    }
                                />
                                <InputGroup.Addon>{t('～')}</InputGroup.Addon>
                                <FormInput
                                    readOnly
                                    style={{ maxWidth: '8rem' }}
                                    value={
                                        moment(paymentData.targetDateTo).isValid() ?
                                            moment(paymentData.targetDateTo).format('YYYY/MM/DD') :
                                            ''
                                    }
                                />
                            </InputGroup>
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('照合ステータス')}</FormLabel>
                            <FormInput
                                readOnly
                                style={{ width: '7rem' }}
                                value={''.concat(paymentData.collationStatusText, paymentData.temporarilySavedPayment === 'X' ? t('(保存中)') : '')}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('リビジョンステータス')}</FormLabel>
                            <FormInput
                                readOnly
                                style={{ width: '7rem' }}
                                value={''.concat(paymentData.revisionStatusText, paymentData.temporarilySavedRevision === 'X' ? t('(保存中)') : '')}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('支払金額(本体)')}</FormLabel>
                            <FormInput
                                readOnly
                                value={returnAmountText(paymentData.amountBase)}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('通貨')}</FormLabel>
                            <FormInput
                                readOnly
                                value={paymentData.currency}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('通信欄')}</FormLabel>
                            <FormInput
                                readOnly={userType === 'company' || (payment.revisionStatus !== '1' && payment.revisionStatus !== '5')}
                                style={{ width: '30rem' }}
                                value={paymentData.revisionComment}
                                onChange={onChangeRevisionComment}
                            />
                        </PaddingFormItem>
                    </FormGroup>
                </LayoutPanel.Filters>
                <LayoutPanel.Body style={{ flex: '1 1 auto', height: '0' }}>
                    <LayoutBody detailComments={detailComments} setDetailComments={setDetailComments} />
                </LayoutPanel.Body>
                <LayoutPanel.Footer>
                    <div style={{ width: '100%' }}>
                        <div className='fd-bar__right'>
                            <div className='fd-bar__element'>
                                <Button disabled={!(payment.collationStatus === '2' && payment.revisionStatus === '2')} onClick={onClickOutputDetailPdf} option='emphasized'>{t('明細書出力')}</Button>
                            </div>
                            <div className='fd-bar__element'>
                                <Button onClick={onClickOutputCsv} option='emphasized'>{t('CSV')}</Button>
                            </div>
                            <div className='fd-bar__element'>
                                <Button id='temp_save_button' disabled={(payment.revisionStatus !== '1' && payment.revisionStatus !== '5') || userType === 'company'} onClick={onClickTempSave} option='emphasized'>{t('一時保存')}</Button>
                            </div>
                            <div className='fd-bar__element'>
                                <Button id='approve_button' disabled={(payment.revisionStatus !== '1' && payment.revisionStatus !== '5') || userType === 'company'} onClick={onClickApprove} option='emphasized'>{t('承認')}</Button>
                            </div>
                            <div className='fd-bar__element'>
                                <Button id='reject_button' disabled={(payment.revisionStatus !== '1' && payment.revisionStatus !== '5') || userType === 'company'} onClick={onClickReject} option='emphasized'>{t('却下')}</Button>
                            </div>
                            <div className='fd-bar__element'>
                                <Button id='back_button' onClick={onClickBack} option='emphasized'>{t('戻る')}</Button>
                            </div>
                            <NavButtons screenMode={'paymentDetail'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
        </>
    );
};

const PaddingFormItem = styled(FormItem)`
    padding: 0 10px;
`;

export default PaymentDetail;