import { Button, Row } from 'fundamental-react';
import React, { useState, useRef, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { UserActions } from '../../../actions/user';
import { CommonParamActions } from '../../../actions/commonParam';
import { User, AuthInfo, MfaCode } from '../../../models/User';
import { State } from '../../../reducers';
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { backLogin } from '../../colors';
import { MessageActions } from '../../../actions/message';
import { getMessage as m } from '../../../messages';
require('dotenv').config({ debug: true });

const Mfa: React.FC = () => {
    const { t } = useTranslation();

    // 状態管理（ローカル）
    const [mfaCode, setMfaCode] = useState<string>('');

    /**
     * 状態管理（アプリ）
     * */
    const { mandt, isConnecting, user } = useSelector((state: State) => ({
        mandt: state.commonParam.mandt,
        isConnecting: state.user.isConnecting,
        user: state.user.user,
    }));

    useEffect(() => {
        document.body.style.background = backLogin;
    }, [])

    const dispatch = useDispatch();

    const form = useRef<HTMLFormElement>(null);

    const handleSetMfaCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value !== '' && !event.currentTarget.value.match(/^[0-9]+$/)) {
            return;
        }
        setMfaCode(event.currentTarget.value);
    };

    const handleMfaButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (mfaCode === "") {
            dispatch(MessageActions.push({ content: m('認証コードを入力してください。'), words: [] }))
        } else {
            var verifyMfaCode = new MfaCode({ code: mfaCode });
            dispatch(UserActions.verifyMfaCode(verifyMfaCode));
        }
    };

    const handleCancelButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        dispatch(UserActions.setIsLogin(false));
        dispatch(UserActions.setAuthInfo(new AuthInfo()));
        dispatch(UserActions.setUser(new User()));
        dispatch(CommonParamActions.setMandt(''));
        const cookies = new Cookies();
        cookies.remove('token', { path: '/' });
        cookies.remove('refreshToken', { path: '/' });
        window.location.href = window.location.origin + `/?mandt=${mandt}`
    };

    const handleMailButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        const authInfo = new AuthInfo({ mandt: mandt, userId: user.userId });
        dispatch(UserActions.resendMfaCode(authInfo));
    };

    return (
        <Wrapper>
            <BorderSection style={{ width: '750px', height: '75vh', padding: '8vh' }}>
                <Section>
                    <h5>{t('多要素認証')}</h5>
                    <p style={{ padding: '3vh' }}>{t('アカウントはMulti-Factor Authentication(MFA)を使用して保護されています。')}<br />{t('メールアドレスに送信された、認証コードを入力してください。')}</p>
                </Section>
                <MfaForm ref={form}>
                    <Row style={{ display: 'flex', justifyContent: 'center', padding: '3vh' }}>
                        <Label htmlFor='mfa_code_input' style={{ marginTop: '2px', marginBottom: '2px', marginRight: '30px' }}>{t('認証コード:')}</Label>
                        <Input
                            id='mfa_code_input'
                            type='text'
                            required
                            maxLength={20}
                            pattern='^[0-9]+$'
                            onChange={handleSetMfaCode}
                            value={mfaCode}
                        ></Input>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'space-between', margin: '0 auto', paddingTop: '3vh', paddingLeft: '140px', paddingRight: '140px', paddingBottom: '3vh' }}>
                        <Button
                            id='cancel_button'
                            onClick={handleCancelButton}
                            disabled={isConnecting}
                            option='emphasized'
                            typeAttr='button'
                            style={{ width: '120px', height: '44px' }}
                        >
                            {t('キャンセル')}
                        </Button>
                        <Button
                            id='mfa_button'
                            onClick={handleMfaButton}
                            disabled={isConnecting}
                            option='emphasized'
                            typeAttr='submit'
                            style={{ width: '120px', height: '44px' }}
                        >
                            {t('送信')}
                        </Button>
                    </Row>
                    <Row style={{ width: '50%', placeContent: 'center', margin: '0 auto' }}>
                        <Button
                            id='mail_button'
                            style={{ border: 'none', display: 'flex', justifyContent: 'center', margin: '3vh', textDecoration: 'underline' }}
                            onClick={handleMailButton}
                            option='transparent'
                        >{t('認証コードを再送する')}
                        </Button>
                    </Row>
                </MfaForm>
            </BorderSection>
        </Wrapper>
    );
};

export default Mfa;

const Input = styled.input`
`;
const Label = styled.label`
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const MfaForm = styled.form`
`;

const BorderSection = styled.div`
    margin-top: 10px;
    width: 100%;
    padding: 10px;
`;

const Section = styled.div`
    width: 100%;
    margin-Top: 10px;
`;