import { put, takeLatest } from 'redux-saga/effects';
import { OrderActions } from "../actions/order";
import { OrderApi } from '../apiClient/order';
import { Orders } from '../models/Order';
import { push } from 'connected-react-router';
import { getMessage as m } from '../messages';
import { MessageActions } from '../actions/message';

function* search(action: ReturnType<typeof OrderActions.search>) {
    yield put(OrderActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield OrderApi.search(keys);

    if (response.isSuccess) {
        yield put(OrderActions.setDidSearchSuccess(true));
        yield put(OrderActions.setOrders(Orders.fromResponse(response.data)));

        if (response.data.length > 0) {
            yield put(push('/salesorder_list'));
        } else {
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        }
    } else {
        yield put(OrderActions.setDidSearchSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(OrderActions.setIsConnecting(false));
}

export function* OrderSaga() {
    yield takeLatest(OrderActions.search, search);
}
