import actionCreatorFactory from 'typescript-fsa';
import { MaterialPrices, SuggestMaterials, SearchKeys, SuggestMaterialSearchKeys } from '../models/MaterialPrice';

const actionCreator = actionCreatorFactory('MaterialPrice');

export const MaterialPriceActions = {
    search: actionCreator<SearchKeys>('search'),
    // apiに接続中か否か
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    // 検索に成功したか否か
    setDidSearchSuccess: actionCreator<boolean | null>('setDidSearchSuccess'),
    // 検索により取得した注文データを保持する。
    setMaterialPrices: actionCreator<MaterialPrices>('setMaterialPrices'),
    // 検索キー
    setSearchKeys: actionCreator<SearchKeys>('setSearchKeys'),

    // サジェスト用品目取得中か
    setIsLoadingSuggestMaterials: actionCreator<boolean>('setIsLoadingSuggestMaterials'),
    // サジェスト用品目を取得
    searchSuggestMaterials: actionCreator<SuggestMaterialSearchKeys>('searchSuggestMaterials'),
    // サジェスト品目データを保持
    setSuggestMaterials: actionCreator<SuggestMaterials>('setSuggestMaterials'),
    // サジェスト品目の取得に成功したか否か
    setDidSearchSuggestMaterialSuccess: actionCreator<boolean | null>('setDidSearchSuggestMaterialSuccess'),
};
