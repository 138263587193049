import { Button, Column, Container, DatePicker, FormInput, FormLabel, FormTextarea, Row } from 'fundamental-react';
import moment from 'moment';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormDisabledStatus, FormStatus } from '.';
import { InformationMaintenanceActions } from '../../../actions/informationMaintenance';
import { MessageActions } from '../../../actions/message';
import { getMessage as m, getWord as w } from '../../../messages';
import { Message } from '../../../models/Message';
import { DatePickerParam } from '../../../types';
import { confirmBox } from '../../atoms/Confirm';

interface Props {
    tempMessage: Message,
    setTempMessage: Dispatch<SetStateAction<Message>>,
    formDisabledStatus: FormDisabledStatus,
    formStatus: FormStatus,
};

const EditForm: React.FC<Props> = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    // タイトルの最大文字数(40字)
    const MAX_TITILE_LENGTH = 40;

    const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === undefined || event.target.value === null || event.target.value.length > MAX_TITILE_LENGTH)
            return;

        props.setTempMessage(new Message({ ...props.tempMessage.toJSON(), title: event.target?.value }));
    };

    const onChangeBody = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event.target.value === undefined || event.target.value === null)
            return;

        props.setTempMessage(new Message({ ...props.tempMessage.toJSON(), body: event.target?.value }));
    };

    const onChangeFromDate = (date: DatePickerParam) => {
        const fromDate = moment(date.formattedDate).isValid() ? new Date(date.formattedDate) : null;
        props.setTempMessage(new Message({ ...props.tempMessage.toJSON(), fromDate: fromDate }));
    };

    const onChangeToDate = (date: DatePickerParam) => {
        const toDate = moment(date.formattedDate).isValid() ? new Date(date.formattedDate + ':59') : null;
        props.setTempMessage(new Message({ ...props.tempMessage.toJSON(), toDate: toDate }));
    };

    // 「保存」ボタン押下時
    const onClickSaveButton = () => {

        const message = props.tempMessage;
        const invalidKeys: Array<string> = [];
        if (message.title === undefined || message.title === '') {
            invalidKeys.push(w('タイトル'));
        }
        if (message.body === undefined || message.body === '') {
            invalidKeys.push(w('本文'));
        }
        if (message.fromDate === null) {
            invalidKeys.push(w('有効開始日'));
        }
        if (message.toDate === null) {
            invalidKeys.push(w('有効終了日'));
        }

        if (invalidKeys.length > 0) {
            const keys = Array.from(invalidKeys.values()).join(', ');
            dispatch(MessageActions.push({ content: m('項目 %s：必須入力項目に値が入力されていません。'), words: [keys] }));
            return;
        }

        const invalidDate: Array<string> = [];
        if (!moment(message.fromDate).isValid()) {
            invalidDate.push(w('有効開始日'));
        }
        if (!moment(message.toDate).isValid()) {
            invalidDate.push(w('有効終了日'));
        }

        if (moment(message.fromDate).isValid() && moment(message.toDate).isValid() && moment(message.fromDate).isAfter(moment(message.toDate))) {
            invalidDate.push(w('有効開始日'));
            invalidDate.push(w('有効終了日'));
        }

        if (invalidDate.length > 0) {
            const keys = Array.from(invalidDate.values()).join(', ');
            dispatch(MessageActions.push({ content: m('項目 %s：有効な日付が指定されていません。'), words: [keys] }));
            return;
        }

        const ok = () => {
            if (props.formStatus === 'create') {
                dispatch(InformationMaintenanceActions.createMessage(props.tempMessage));
            } else if (props.formStatus === 'edit') {
                dispatch(InformationMaintenanceActions.updateMessage(props.tempMessage));
            }           
        }
        const cancel = () => {

        }
        confirmBox('登録します。よろしいですか？', [], ok, cancel);        
    }

    return (
        <Container>
            <Row>
                <Column span={6}>
                    <div>
                        <FormLabel>{t('タイトル')}</FormLabel>
                        <FormInput disabled={props.formDisabledStatus.isDisabledEditForm} value={props.tempMessage.title} onChange={onChangeTitle} />
                    </div>
                </Column>
                <Column span={3}>
                    <div>
                        <FormLabel>{t('有効開始日')}</FormLabel>
                        <DatePicker
                            dateFormat='YYYY/MM/DD HH:mm'
                            locale='ja'
                            onInputBlur={onChangeFromDate}
                            disabled={props.formDisabledStatus.isDisabledEditForm}
                            defaultValue={moment(props.tempMessage.fromDate).isValid() ? moment(props.tempMessage.fromDate).format('YYYY/MM/DD HH:mm') : ''}
                        />
                    </div>
                </Column>
                <Column span={3}>
                    <div>
                        <FormLabel>{t('有効終了日')}</FormLabel>
                        <DatePicker
                            dateFormat='YYYY/MM/DD HH:mm'
                            locale='ja'
                            onInputBlur={onChangeToDate}
                            disabled={props.formDisabledStatus.isDisabledEditForm}
                            defaultValue={moment(props.tempMessage.toDate).isValid() ? moment(props.tempMessage.toDate).format('YYYY/MM/DD HH:mm') : ''}
                        />
                    </div>
                </Column>
            </Row>
            <Row>
                <Column style={{ paddingTop: '8px' }}>
                    <div>
                        <FormLabel>{t('本文')}</FormLabel>
                        <FormTextarea disabled={props.formDisabledStatus.isDisabledEditForm} value={props.tempMessage.body} onChange={onChangeBody} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button option='emphasized' disabled={props.formDisabledStatus.isDisabledEditForm} style={{ marginTop: '8px' }} onClick={onClickSaveButton}>{t('保存')}</Button>
                    </div>
                </Column>
            </Row>
        </Container>
    );
}

export default EditForm;