export const labelColor = {
    normal: 'whitesmoke',
};

export const noInputBack = 'gainsboro';

export const backLogin = '#A9C6DE';
export const backMenu = 'lightslategray';
export const backcolor0 = 'white';
export const backcolor1 = '#EBF1F7';
export const backcolor2 = '#F9F9F9';