import storage from 'redux-persist/lib/storage'
import immutableTransform from 'redux-persist-transform-immutable';
import { MigrationManifest, PersistState } from 'redux-persist/es/types';
import { DeliveryHeaderSettingState } from './reducers/deliveryHeaderSetting';
import { PurchaseOrderHeaderSettingState } from './reducers/purchaseOrderHeaderSetting';
import { SalesOrderHeaderSettingState } from './reducers/salesOrderHeaderSetting';
import { createMigrate } from 'redux-persist';

// 永続化するState マイグレーション機能用、変更不要
type RootState = {
    deliveryHeaderSetting: DeliveryHeaderSettingState;
    purchaseOrderHeaderSetting: PurchaseOrderHeaderSettingState;
    salesOrderHeaderSetting: SalesOrderHeaderSettingState;
};

// 構造変更後のState マイグレーション機能用、変更不要
type NewPersistState = Pick<
    RootState,
    | 'deliveryHeaderSetting'
    | 'purchaseOrderHeaderSetting'
    | 'salesOrderHeaderSetting'
> & {
    _persist: PersistState;
};

// 構造変更前のStateを洗い替え マイグレーション機能用、変更不要
type OldDeliveryPersistState = Omit<
    NewPersistState,
    | 'deliveryHeaderSetting'
> & {
    newDeliveryewHeaderSetting: RootState['deliveryHeaderSetting'];
};

// 構造変更前のStateを洗い替え マイグレーション機能用、変更不要
type OldPurchaseOrderPersistState = Omit<
    NewPersistState,
    | 'purchaseOrderHeaderSetting'
> & {
    newPurchaseOrderHeaderSetting: RootState['purchaseOrderHeaderSetting'];
};

// 構造変更前のStateを洗い替え マイグレーション機能用、変更不要
type OldSalesOrderPersistState = Omit<
    NewPersistState,
    | 'salesOrderHeaderSetting'
> & {
    newSalesOrderHeaderSetting: RootState['salesOrderHeaderSetting'];
};

// StoreするState構造変更時のマイグレーション機能
// 出荷実績一覧専用
const migrationsDeliveryHeader: MigrationManifest = {
    // マイグレーションを行う場合、persistConfigDeliveryHeaderのversionと合わせる (1,2,3..)
    1:
        (state): NewPersistState | undefined => {
            if (!state) {
                return state;
            }
            const { newDeliveryewHeaderSetting, ...otherState } = state as OldDeliveryPersistState;
            return {
                ...otherState,
                deliveryHeaderSetting: newDeliveryewHeaderSetting,
            };
        },
};

// 永続化(出荷実績一覧)の設定
export const persistConfigDeliveryHeader = {
    transforms: [immutableTransform()],
    key: 'DeliveryHeader',  // Storageに保存されるキー名
    storage,  // 保存先にローカルストレージを指定
    version: 0,  // ローカルストレージに保存してあるStateのバージョン
    migrate: createMigrate(migrationsDeliveryHeader, { debug: true }),  // DeliveryHeaderSettingの構造を変更する場合のマイグレーション機能
};

// StoreするState構造変更時のマイグレーション機能
// 発注一覧専用
const migrationsPurchaseOrderHeader: MigrationManifest = {
    // マイグレーションを行う場合、persistConfigPurchaseOrderHeaderのversionと合わせる (1,2,3..)
    1:
        (state): NewPersistState | undefined => {
            if (!state) {
                return state;
            }
            const { newPurchaseOrderHeaderSetting, ...otherState } = state as OldPurchaseOrderPersistState;
            return {
                ...otherState,
                purchaseOrderHeaderSetting: newPurchaseOrderHeaderSetting,
            };
        },
};

// 永続化(発注一覧)の設定
export const persistConfigPurchaseOrderHeader = {
    transforms: [immutableTransform()],
    key: 'PurchaseOrderHeader',  // Storageに保存されるキー名
    storage,  // 保存先にローカルストレージを指定
    version: 0,  // ローカルストレージに保存してあるStateのバージョン
    migrate: createMigrate(migrationsPurchaseOrderHeader, { debug: true }),  // PurchaseOrderHeaderSettingの構造を変更する場合のマイグレーション機能
};

// StoreするState構造変更時のマイグレーション機能
// 受注一覧専用
const migrationsSalesOrderHeader: MigrationManifest = {
    // マイグレーションを行う場合、persistConfigSalesOrderHeaderのversionと合わせる (1,2,3..)
    1:
        (state): NewPersistState | undefined => {
            if (!state) {
                return state;
            }
            const { newSalesOrderHeaderSetting, ...otherState } = state as OldSalesOrderPersistState;
            return {
                ...otherState,
                salesOrderHeaderSetting: newSalesOrderHeaderSetting,
            };
        },
};

// 永続化(受注一覧)の設定
export const persistConfigSalesOrderHeader = {
    transforms: [immutableTransform()],
    key: 'SalesOrderHeader',  // Storageに保存されるキー名
    storage,  // 保存先にローカルストレージを指定
    version: 0,  // ローカルストレージに保存してあるStateのバージョン
    migrate: createMigrate(migrationsSalesOrderHeader, { debug: true }),  // SalesOrderHeaderSettingの構造を変更する場合のマイグレーション機能
};