import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { format } from 'util';
import messages from '../../messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getLocaleKey } from '../../utils/environments';

export const getTitle = (message: any) => {
    switch (message.type) {
        case 'N':
            return 'メッセージ';
        case 'E':
            return 'エラー';
        case 'W':
            return '確認';
        default:
            return ''
    }
}

export const deleteConfirm = (deleteOK: () => void, deleteCancle: () => void) => {
    confirmAlert({
        title: '確認',
        message: '削除しますか？',
        buttons: [
            {
                label: 'はい',
                onClick: deleteOK
            },
            {
                label: 'いいえ',
                onClick: deleteCancle
            },
        ],
    });
}

export const confirmBox = (key: string, params: Array<any>, ok: () => void, cancel: () => void) => {
    const locale = getLocaleKey() === null ? 'ja' : getLocaleKey();
    const title = getTitle(messages[locale][key]);
    const message = format(messages[locale][key].message, ...params);
    confirmAlert({
        title: getTitle(messages[locale][key]),
        message: format(messages[locale][key].message, ...params),
        buttons: [
            {
                label: 'はい',
                onClick: ok
            },
            {
                label: 'いいえ',
                onClick: cancel
            },
        ],
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui' style={{ minWidth: '360px', background: 'white', textAlign: 'center', padding: '20px', border: 'solid 1px gainsboro' }}>
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        <FontAwesomeIcon style={{ fontSize: '1rem', color: 'orange' }} icon={faExclamationTriangle} />
                        <h1 style={{ fontSize: '1rem', color: 'orange' }}>{title}</h1>
                    </div>
                    <hr style={{ backgroundColor: 'orange' }} />
                    <p>{message}</p>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button id="confirm_ok_button" onClick={() => {
                            ok();
                            onClose();
                        }} style={{ width: '5rem' }}>はい</button>
                        <button id="confirm_cancel_button" autoFocus onClick={() => { cancel(); onClose(); }} style={{ width: '5rem' }}>いいえ</button>
                    </div>
                </div>
            )
        }
    });
}