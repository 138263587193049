import { Column, Container, FormInput, FormTextarea, Row, Table } from 'fundamental-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PaymentDetail, SearchDetailCommentHistory } from '../../../models/PaymentDetail';
import { State } from '../../../reducers';
import { JSObject } from '../../../types';
import { returnAmountText, returnQuantityText, returnTaxRateText } from '../../../utils/CommonFunction';
import { PURCHASING_DIVISIONS } from '../../constants';

type Props = {
    detailComments: Array<string>;
    setDetailComments: (list: Array<string>) => void;
};

const LayoutBody: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();

    const {
        payment,
        paymentDetails,
        userType,
    } = useSelector((state: State) => ({
        payment: state.payment.payment,
        paymentDetails: state.paymentDetail.paymentDetails,
        userType: state.user.userType,
    }));

    // 表示用データを作成
    const toJSArray = (data: Array<PaymentDetail>) => {
        return data.map(d => d.toJS());
    }
    const [paymentDetailData, setPaymentDetailData] = useState<Array<JSObject>>(toJSArray(paymentDetails.list.toArray()));

    /**
     * UIへの参照
     **/
    const table = useRef<HTMLTableElement>(null);

    useEffect(() => {
        setPaymentDetailData(toJSArray(paymentDetails.list.toArray()));
        props.setDetailComments(paymentDetails.list.map(() => '').toArray());
        // propsは毎描画ごとに変更されてしまうため第二引数から除外
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentDetails]);

    useEffect(() => {
        applyTableAlignStyles();
    }, [paymentDetailData]);

    /**
     * テーブル行の通信欄変更イベントハンドラ
     * @param event
     */
    const onChangeDetailComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        var index = event.currentTarget.dataset.index;
        if (index === undefined) return;
        props.detailComments[Number(index)] = event.currentTarget.value;
        props.setDetailComments([...props.detailComments]);
    };

    const applyTableAlignStyles = () => {
        // テーブル内の指定のカラムにスタイルを適用する
        Array.prototype.forEach.call(table.current?.getElementsByTagName("td"), item => {
            // text-align: center対象セルの判定
            if (item.getElementsByClassName("alignCenter").length > 0) {
                item.style.textAlign = 'center';
            }
            // text-align: right対象セルの判定
            if (item.getElementsByClassName('alignRight').length > 0) {
                item.style.textAlign = 'right';
            }
            // text-align: left対象セルの判定
            if (item.getElementsByClassName('alignLeft').length > 0) {
                item.style.textAlign = 'Left';
            }
        });
    };

    return (
        <>
            <Container style={{ height: '100%' }}>
                <Row style={{ height: '100%', margin: '0' }}>
                    <Column style={{ height: '100%', overflow: 'auto', padding: '0' }} span={12}>
                        <HeaderFreezingTable
                            ref={table}
                            compact
                            condensed
                            headers={[
                                t('注文ID'),
                                t('明細No.'),
                                t('仕入区分'),
                                t('納入日付'),
                                t('品目'),
                                t('納入数'),
                                t('単価'),
                                t('金額(本体)'),
                                t('通貨'),
                                t('税率'),
                                t('備考'),
                                t('通信欄'),
                                t('通信欄履歴'),
                            ]}
                            tableData={paymentDetailData.map((item, index) => {
                                let commentHistory = '';
                                if (item.exSearchDetailCommentHistories?.list !== undefined && item.exSearchDetailCommentHistories.list.length > 0) {
                                    commentHistory = item.exSearchDetailCommentHistories.list.reduce(
                                        (prev: string, current: SearchDetailCommentHistory) => prev === '' ?
                                            current.detailComment + (moment(current.detailCommentUpdate).format('YYYY/MM/DD') === '0001/01/01' ? '' : moment(current.detailCommentUpdate).format(' YYYY/MM/DD')) :
                                            prev + '\n' + current.detailComment + ((moment(current.detailCommentUpdate).format('YYYY/MM/DD') === '0001/01/01' ? '' : moment(current.detailCommentUpdate).format(' YYYY/MM/DD'))),
                                        ''
                                    );
                                }

                                return ({
                                    rowData: [
                                        <NoWrapSpan>{item.orderId}</NoWrapSpan>,
                                        <NoWrapSpan>{item.orderItem}</NoWrapSpan>,
                                        <NoWrapSpan>{item.purchasingDivisionText}</NoWrapSpan>,
                                        <NoWrapSpan className='alignCenter'>
                                            {moment(item.postingDate).isValid() ?
                                                moment(item.postingDate).format('YYYY/MM/DD') :
                                                ''
                                            }
                                        </NoWrapSpan>,
                                        <NoWrapSpan>{item.material}<br />{item.itemText}</NoWrapSpan>,
                                        <NoWrapSpan className='alignRight'>
                                            {item.purchasingDivision === PURCHASING_DIVISIONS.PURCHASING.INCREASE ||
                                                item.purchasingDivision === PURCHASING_DIVISIONS.PURCHASING.REDUCTION ||
                                                ('' + item.purchasingDivision).startsWith('5') ?
                                                '' :
                                                returnQuantityText(item.deliveryQuantity, item.deliveryQuantityUnitText)}
                                        </NoWrapSpan>,
                                        <NoWrapSpan className='alignRight'>
                                            {item.purchasingDivision === PURCHASING_DIVISIONS.PURCHASING.INCREASE ||
                                                item.purchasingDivision === PURCHASING_DIVISIONS.PURCHASING.REDUCTION ||
                                                ('' + item.purchasingDivision).startsWith('5') ?
                                                '' :
                                                returnAmountText(item.pricePerOneUnit)}
                                        </NoWrapSpan>,
                                        <NoWrapSpan className='alignRight'>
                                            {returnAmountText(item.amountBase)}
                                        </NoWrapSpan>,
                                        <NoWrapSpan className='alignLeft'>
                                            {item.currency}
                                        </NoWrapSpan>,
                                        <NoWrapSpan className='alignRight'>
                                            {returnTaxRateText(item.taxRate, true)}
                                        </NoWrapSpan>,
                                        <NoWrapSpan>{item.note}</NoWrapSpan>,
                                        <NoWrapSpan>
                                            <FormInput
                                                compact
                                                readOnly={userType === 'company' || (payment.revisionStatus !== '1' && payment.revisionStatus !== '5')}
                                                value={props.detailComments[index]}
                                                style={{ minWidth: '20rem' }}
                                                data-index={index}
                                                onChange={onChangeDetailComment}
                                            />
                                        </NoWrapSpan>,
                                        <FormTextarea
                                            compact
                                            value={commentHistory}
                                            style={{ minWidth: '20rem' }}
                                            readOnly
                                        />,
                                    ]
                                });
                            })}
                        />
                    </Column>
                </Row>
            </Container>
        </>
    );
};

const HeaderFreezingTable = styled(Table)`
    &&& thead tr th {
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 5;
        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            width: 100%;
            height: 100%;
            border-top: 1px solid #e4e4e4;
        }
    }
`;

const NoWrapSpan = styled.span`
    white-space: nowrap;
`;

export default LayoutBody;