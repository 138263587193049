import { Button, Column, Container, DatePicker, FormInput, FormLabel, FormTextarea, Row } from 'fundamental-react';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { InformationMaintenanceActions } from '../../../actions/informationMaintenance';
import { DatePickerParam } from '../../../types';
import { SearchKeys } from './../../../models/Message';
import moment from 'moment';
import 'moment/locale/ja';

interface Props {
    searchKeys: SearchKeys,
    setSearchKeys: Dispatch<SetStateAction<SearchKeys>>,
    setInitialStatus: () => void,
}

const SearchForm: React.FC<Props> = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();


    const onChangeTitle = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.searchKeys.title = event.target.value;
        props.setSearchKeys({ ...props.searchKeys });
    };

    const onChangeBody = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.searchKeys.body = event.target.value;
        props.setSearchKeys({ ...props.searchKeys });
    };

    const onInputBlurFromFromDate = (date: DatePickerParam) => {
        const strDate = date.isoFormattedDate;
        props.searchKeys.fromFromDate = moment(strDate).isValid() ? new Date(strDate) : null;

        props.setSearchKeys({ ...props.searchKeys });
    };

    const onInputBlurFromToDate = (date: DatePickerParam) => {
        const strDate = date.isoFormattedDate;
        props.searchKeys.fromToDate = moment(strDate).isValid() ? new Date(strDate) : null;

        props.setSearchKeys({ ...props.searchKeys });
    }

    const onInputBlurToFromDate = (date: DatePickerParam) => {
        const strDate = date.isoFormattedDate;
        props.searchKeys.toFromDate = moment(strDate).isValid() ? new Date(strDate) : null;

        props.setSearchKeys({ ...props.searchKeys });
    }

    const onInputBlurToToDate = (date: DatePickerParam) => {
        const strDate = date.isoFormattedDate;
        props.searchKeys.toToDate = moment(strDate).isValid() ? new Date(strDate + ' 23:59:59') : null;

        props.setSearchKeys({ ...props.searchKeys });
    }

    const onClickSearchButton = () => {
        props.setInitialStatus();
        dispatch(InformationMaintenanceActions.searchMessage(props.searchKeys));
    }

    return (
        <Container style={{ padding: '10px' }}>
            <Row>
                <Column span={5}>
                    <div style={{ display: 'flex' }}>
                        <SearchFormLabel>{t('タイトル')}</SearchFormLabel>
                        <FormInput value={props.searchKeys.title} onChange={onChangeTitle} />
                    </div>
                </Column>
                <Column span={1} />
                <Column span={6}>
                    <div style={{ display: 'flex' }}>
                        <SearchFormLabel>{t('有効開始日')}</SearchFormLabel>
                        <DatePicker
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            value={props.searchKeys.fromFromDate}
                            onInputBlur={onInputBlurFromFromDate}
                        />
                        <div style={{ marginLeft: '15px', marginRight: '15px', alignSelf: 'center' }}>～</div>
                        <DatePicker
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            value={props.searchKeys.fromToDate}
                            onInputBlur={onInputBlurFromToDate}
                        />
                    </div>
                </Column>
            </Row>
            <Row>
                <Column span={5}>
                    <div style={{ display: 'flex' }}>
                        <SearchFormLabel>本文</SearchFormLabel>
                        <FormTextarea value={props.searchKeys.body} onChange={onChangeBody} />
                    </div>
                </Column>
                <Column span={1} />
                <Column span={6}>
                    <div style={{ display: 'flex' }}>
                        <SearchFormLabel>{t('有効終了日')}</SearchFormLabel>
                        <DatePicker
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            value={props.searchKeys.toFromDate}
                            onInputBlur={onInputBlurToFromDate}
                        />
                        <div style={{ marginLeft: '15px', marginRight: '15px', alignSelf: 'center' }}>～</div>
                        <DatePicker
                            dateFormat='YYYY/MM/DD'
                            locale='ja'
                            value={props.searchKeys.toToDate}
                            onInputBlur={onInputBlurToToDate}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button option='emphasized' onClick={onClickSearchButton}>{t('検索')}</Button>
                    </div>
                </Column>
            </Row>
        </Container>
    );
}

const SearchFormLabel = styled(FormLabel)`
    width: 100px;
    align-self: center;
`;

export default SearchForm;