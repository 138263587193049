import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record } from 'immutable';

import { UserActions } from '../actions/user';
import { AuthInfo, User } from '../models/User';
import { UserType } from '../types';

export class UserState extends Record<{
    user: User;
    authInfo: AuthInfo;
    isLogin: boolean;
    token: string;
    isConnecting: boolean;
    userType: UserType;
    isPasswordChanged: boolean;
}>({
    user: new User(),
    authInfo: new AuthInfo(),
    isLogin: false,
    token: '',
    isConnecting: false,
    userType: 'supplier',
    isPasswordChanged: false,
}) { }

export const UserReducer = reducerWithInitialState(new UserState())
    .case(UserActions.setAuthInfo, (state, payload) => {
        return state.set('authInfo', payload);
    })
    .case(UserActions.setIsLogin, (state, payload) => {
        return state.set('isLogin', payload);
    })
    .case(UserActions.setUser, (state, payload) => {
        return state.set('user', payload);
    })
    .case(UserActions.setToken, (state, payload) => {
        return state.set('token', payload);
    })
    .case(UserActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(UserActions.setUserType, (state, payload) => {
        return state.set('userType', payload);
    })
    .case(UserActions.setIsPasswordChanged, (state, payload) => {
        return state.set('isPasswordChanged', payload);
    })
    ;