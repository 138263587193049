import { History } from 'history';
import { combineReducers } from 'redux';
import { RouterState, connectRouter } from 'connected-react-router';
import { AppState, AppReducer } from './app';
import { UserReducer, UserState } from './user';
import { MessageState, MessageReducer } from './message';
import { OrderState, OrderReducer } from './order';
import { DeliveryNoteState, DeliveryNoteReducer } from './deliveryNote';
import { AnswerDeliveryState, AnswerDeliveryReducer } from './answerDelivery';
import { CommonParamState, CommonParamReducer } from './commonParam';
import { PaymentReducer, PaymentState } from './payment';
import { PaymentDetailReducer, PaymentDetailState } from './paymentDetail';
import { InformationMaintenanceState, InformationMaintenanceReducer } from './informationMaintenance';
import { InformationReducer, InformationState } from './information';
import { MaterialPriceReducer, MaterialPriceState } from './materialPrice';
import { PurchaseOrderState, PurchaseOrderReducer } from './purchaseOrder';
import { DeliveryState, DeliveryReducer } from './delivery';
import { DeliveryHeaderSettingState, DeliveryHeaderSettingReducer } from './deliveryHeaderSetting';
import { PurchaseOrderHeaderSettingState, PurchaseOrderHeaderSettingReducer } from './purchaseOrderHeaderSetting';
import { SalesOrderHeaderSettingState, SalesOrderHeaderSettingReducer } from './salesOrderHeaderSetting';

import { persistConfigDeliveryHeader, persistConfigPurchaseOrderHeader, persistConfigSalesOrderHeader } from '../reduxPersistConfig'
import { persistReducer } from 'redux-persist';

export interface State {
    router: RouterState;
    app: AppState;
    user: UserState;
    message: MessageState;
    order: OrderState;
    deliveryNote: DeliveryNoteState;
    answerDelivery: AnswerDeliveryState;
    commonParam: CommonParamState;
    payment: PaymentState;
    paymentDetail: PaymentDetailState;
    informationMaintenance: InformationMaintenanceState;
    information: InformationState;
    materialPrice: MaterialPriceState;
    purchaseOrder: PurchaseOrderState;
    delivery: DeliveryState;
    deliveryHeaderSetting: DeliveryHeaderSettingState;
    purchaseOrderHeaderSetting: PurchaseOrderHeaderSettingState;
    salesOrderHeaderSetting: SalesOrderHeaderSettingState;
}

export const rootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        app: AppReducer,
        user: UserReducer,
        message: MessageReducer,
        order: OrderReducer,
        deliveryNote: DeliveryNoteReducer,
        answerDelivery: AnswerDeliveryReducer,
        commonParam: CommonParamReducer,
        payment: PaymentReducer,
        paymentDetail: PaymentDetailReducer,
        informationMaintenance: InformationMaintenanceReducer,
        information: InformationReducer,
        materialPrice: MaterialPriceReducer,
        purchaseOrder: PurchaseOrderReducer,
        delivery: DeliveryReducer,
        deliveryHeaderSetting: persistReducer(persistConfigDeliveryHeader, combineReducers({ deliveryHeaderSetting: DeliveryHeaderSettingReducer })),
        purchaseOrderHeaderSetting: persistReducer(persistConfigPurchaseOrderHeader, combineReducers({ purchaseOrderHeaderSetting: PurchaseOrderHeaderSettingReducer })),
        salesOrderHeaderSetting: persistReducer(persistConfigSalesOrderHeader, combineReducers({ salesOrderHeaderSetting: SalesOrderHeaderSettingReducer })),
    });
