import { Button, Column, Container, Row, Table } from 'fundamental-react';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FormDisabledStatus, FormStatus } from '.';
import { InformationMaintenanceActions } from '../../../actions/informationMaintenance';
import { Message, Messages } from '../../../models/Message';
import { confirmBox } from '../../atoms/Confirm';

interface Props {
    messages: Messages,
    tempMessage: Message,
    setTempMessage: (args: Message) => void,
    formDisabledStatus: FormDisabledStatus,
    setFormDisabledStatus: Dispatch<SetStateAction<FormDisabledStatus>>,
    setFormStatus: Dispatch<SetStateAction<FormStatus>>,
    setSelectedRowIndex: Dispatch<SetStateAction<number | null>>,
}

const MessageList: React.FC<Props> = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
    const [tableRowData, setTableRowData] = useState<{
        messageId: number,
        title: string,
        fromDate: Date | null,
        toDate: Date | null,
        body: string,
    }[]>(props.messages.list.toArray());

    useEffect(() => {
        setTableRowData(props.messages.list.toArray());
        setCheckedItems({ ...[] });
    }, [props.messages]);

    const onClickTitle = (name: number, index: number) => {
        setCheckedItems({ ...[], [name.toString()]: true });
        props.setSelectedRowIndex(index);
    };

    // 「追加」ボタン押下時
    const onClickAddButton = () => {
        setCheckedItems({ ...[] });
        props.setSelectedRowIndex(null);
        props.formDisabledStatus.isDisabledChangeButton = true;
        props.formDisabledStatus.isDisabledDeleteButton = true;
        props.formDisabledStatus.isDisabledEditForm = false;
        props.setFormDisabledStatus({ ...props.formDisabledStatus });
        props.setTempMessage(new Message({ fromDate: moment(moment().format('YYYY-MM-DD 00:00')).toDate(), toDate: moment('2099-12-31 23:59').toDate() }));
        props.setFormStatus('create');
    };

    // 「変更」ボタン押下時
    const onClickChangeButton = () => {
        props.formDisabledStatus.isDisabledChangeButton = false;
        props.formDisabledStatus.isDisabledDeleteButton = false;
        props.formDisabledStatus.isDisabledEditForm = false;
        props.setFormDisabledStatus({ ...props.formDisabledStatus });
        props.setFormStatus('edit');
    };

    // 「削除」ボタン押下時
    const onClickDeleteButton = () => {
        const ok = () => {
            dispatch(InformationMaintenanceActions.deleteMessage(props.tempMessage));
        }
        const cancel = () => {
        }
        confirmBox('削除しますか？', [], ok, cancel);
    };

    return (
        <Container>
            <Row>
                <Column style={{ height: '200px', overflowY: 'scroll', padding: '0', marginTop: '15px' }}>
                    <HeaderFreezingTable
                        headers={[
                            <div style={{ width: '600px', height: '100%', display: 'flex', alignItems: 'center', paddingLeft: '8px' }}><div>{t('タイトル')}</div></div>,
                            <div>{t('有効開始日')}</div>,
                            <div>{t('有効終了日')}</div>
                        ]}
                        selection={{
                            isSelected: (index: number) => !!checkedItems[tableRowData[index].messageId],
                        }}
                        tableData={tableRowData.map((item, index) => {
                            return ({
                                rowData: [
                                    <div style={{ width: '600px', height: '100%', display: 'flex', alignItems: 'center', paddingLeft: '8px' }} onClick={() => onClickTitle(item.messageId, index)}>
                                        <div>{item.title}</div>
                                    </div>,
                                    <div onClick={() => onClickTitle(item.messageId, index)}>{moment(item.fromDate).format('YYYY/MM/DD')}</div>,
                                    <div onClick={() => onClickTitle(item.messageId, index)}>{moment(item.toDate).format('YYYY/MM/DD')}</div>
                                ]
                            })
                        })}
                    />
                </Column>
            </Row>
            <Row>
                <Column style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button option='emphasized' style={{ marginRight: '8px' }} onClick={onClickAddButton}>{t('追加')}</Button>
                    <Button option='emphasized' disabled={props.formDisabledStatus.isDisabledChangeButton} style={{ marginRight: '8px' }} onClick={onClickChangeButton}>{t('変更')}</Button>
                    <Button option='emphasized' disabled={props.formDisabledStatus.isDisabledDeleteButton} onClick={onClickDeleteButton}>{t('削除')}</Button>
                </Column>
            </Row>
        </Container>
    );
}

const HeaderFreezingTable = styled(Table)`
    &&& thead tr th {
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 5;
        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            width: 100%;
            height: 100%;
            border-top: 1px solid #e4e4e4;
        }
    }
`;

export default MessageList;