import actionCreateFactory from 'typescript-fsa';
import { Message, Messages, SearchKeys } from '../models/Message';

const actionCreator = actionCreateFactory('InformationMaintenance');

export const InformationMaintenanceActions = {
    // 共通
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    setMessages: actionCreator<Messages>('setMessage'),

    // 検索
    searchMessage: actionCreator<SearchKeys>('search'),
    setDidSucceedSearch: actionCreator<boolean | null>('setDidSucceedSearch'),

    // 追加
    createMessage: actionCreator<Message>('createMessage'),
    setDidSucceedCreate: actionCreator<boolean | null>('setDidSucceedCreate'),

    // 削除
    deleteMessage: actionCreator<Message>('deleteMessage'),
    setDidSucceedDelete: actionCreator<boolean | null>('setDidSucceedDelete'),

    // 更新
    updateMessage: actionCreator<Message>('updateMessage'),
    setDidSucceedUpdate: actionCreator<boolean | null>('setDidSucceedUpdate'),
}