import { List, Record } from 'immutable';
import { JSObject } from '../types';

export class MaterialPrice extends Record<{
    customer: string;
    customerName: string;
    supplier: string;
    supplierName: string;
    material: string;
    materialName: string;
    customerMaterial: string;
    customerMaterialName: string;
    orderQuantity: string;
    salesUnit: string;
    pricePerOneUnit: string;
    grossPrice: string;
    pricingUnit: string;
    currency: string;
    deleteFlag: boolean;
}>({
    customer: '',
    customerName: '',
    supplier: '',
    supplierName: '',
    material: '',
    materialName: '',
    customerMaterial: '',
    customerMaterialName: '',
    orderQuantity: '',
    salesUnit: '',
    pricePerOneUnit: '',
    grossPrice: '',
    pricingUnit: '',
    currency: '',
    deleteFlag: false,
}) {
    static fromResponse(response: JSObject) {
        const params = { ...response };
        return new MaterialPrice(params);
    }
};

export class MaterialPrices extends Record<{
    list: List<MaterialPrice>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];
        const materialPrices = List(params.map(materialPrice => MaterialPrice.fromResponse(materialPrice)));
        return new MaterialPrices({ list: materialPrices });
    };
};

export class SuggestMaterial extends Record<{
    customerMaterial: string;
    customerMaterialName: string;
}>({
    customerMaterial: '',
    customerMaterialName: '',
}) {
    static fromResponse(response: JSObject) {
        const params = { ...response };
        return new SuggestMaterial(params);
    }
};

export class SuggestMaterials extends Record<{
    list: List<SuggestMaterial>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];
        const suggestMaterials = List(params.map(materialPrice => SuggestMaterial.fromResponse(materialPrice)));
        return new SuggestMaterials({ list: suggestMaterials });
    };
};

/**
 * 品目検索条件のためのオブジェクト定義
 * */
export type SearchKeys = {
    customer?: string | null;
    supplier?: string | null;
    material?: string[] | null;
    materialName?: string | null;
    limitNumber: string;
};
export type SuggestMaterialSearchKeys = {
    customer: string;
    supplier: string;
};
