import { Container, Icon, LayoutPanel, Tab, TabGroup, Tile } from 'fundamental-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { InformationActions } from '../../../actions/information';
import { MaterialPriceActions } from '../../../actions/materialPrice';
import { PurchaseOrderActions } from '../../../actions/purchaseOrder';
import { DeliveryActions } from '../../../actions/delivery';
import { history } from '../../../configureStore';
import { MaterialPrices, SearchKeys as MaterialPriceSearchKeys } from '../../../models/MaterialPrice';
import { SearchKeys as PurchaseOrderSearchKeys } from '../../../models/PurchaseOrder';
import { SearchKeys as DeliverySearchKeys } from '../../../models/Delivery';
import { State } from '../../../reducers';
import { MenuTileProps } from '../../../types';
import { backcolor0 } from '../../colors';
import NavButtons from '../../NavButtons';
import { AppActions } from '../../../actions/app';

const MainMenu: React.FC = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const {
        userType,
        isConnectingNotificationMessages,
        isConnectingInformationMessages,
        selectedTabIndex,
    } = useSelector((state: State) => ({
        userType: state.user.userType,
        isConnectingNotificationMessages: state.information.isConnectingNotificationMessages,
        isConnectingInformationMessages: state.information.isConnectingInformationMessages,
        selectedTabIndex: state.app.selectedTabIndex,
    }));

    const menuSupplierItems: MenuTileProps[] = [
        {
            header: t('受注検索画面'),
            link: '/salesorder_search',
            logo: 'sales-order-item',
        },
        {
            header: t('支払検索画面'),
            link: '/payment_search',
            logo: 'payment-approval',
        },
    ];

    const menuDwosItems: MenuTileProps[] = [
        {
            header: t('発注登録画面'),
            link: '/purchaseorder_create',
            logo: 'sales-order-item',
        },
        {
            header: t('発注検索画面'),
            link: '/purchaseorder_list',
            logo: 'sales-order-item',
        },
        {
            header: t('出荷実績検索画面'),
            link: '/delivery_list',
            logo: 'sales-order-item',
        },
        {
            header: t('品目・価格一覧画面'),
            link: '/materialprice_list',
            logo: 'sales-order-item',
        },
    ];

    const menuMainItems: MenuTileProps[] = [
        ...menuSupplierItems,
        ...menuDwosItems,
    ];

    const adminMenuItems: MenuTileProps[] = [
        {
            header: t('インフォメーションマスタメンテナンス'),
            link: '/information_maintenance',
            logo: 'message-information',
        },
    ];

    useEffect(() => {
        document.body.style.background = backcolor0;
        !isConnectingNotificationMessages && dispatch(InformationActions.getNotificationMessages());
        !isConnectingInformationMessages && dispatch(InformationActions.getInformationMessages());

        dispatch(MaterialPriceActions.setMaterialPrices(new MaterialPrices()));
        dispatch(MaterialPriceActions.setSearchKeys({} as MaterialPriceSearchKeys));
        dispatch(DeliveryActions.setSearchKeys({} as DeliverySearchKeys));
    }, []);

    const handleTabClick = (event: React.MouseEvent<Element, MouseEvent>, index: number) => {
        if (userType === 'company') {
            dispatch(AppActions.setSelectedTabIndex(index));
        }
    };

    const onClickTile = (item: MenuTileProps) => {
        history.push(item.link);
    };

    return (
        <>
            <LayoutPanel>
                <LayoutPanel.Body>
                    <TabGroup
                        onTabClick={(e, index) => handleTabClick(e, index)}
                        selectedIndex={selectedTabIndex}
                    >
                        {/* メインメニュー */}
                        {userType !== 'admin' && (userType === 'company') &&
                            <Tab title={t('メインメニュー')}>
                                <Container>
                                    {menuMainItems.map(item => (
                                        <MenuTile key={item.header} onClick={() => onClickTile(item)}>
                                            <Tile.Header>{item.header}</Tile.Header>
                                            <Tile.Content>
                                                {item.logo !== undefined &&
                                                    <Icon ariaLabel={item.logo} glyph={item.logo} size='xl' />}
                                            </Tile.Content>
                                        </MenuTile>
                                    ))}
                                </Container>
                            </Tab>
                        }
                        {userType !== 'admin' && (userType === 'company' || userType === 'supplier') &&
                            <Tab title={t('サプライ屋')}>
                                <Container>
                                    {menuSupplierItems.map(item => (
                                        <MenuTile key={item.header} onClick={() => onClickTile(item)}>
                                            <Tile.Header>{item.header}</Tile.Header>
                                            <Tile.Content>
                                                {item.logo !== undefined &&
                                                    <Icon ariaLabel={item.logo} glyph={item.logo} size='xl' />}
                                            </Tile.Content>
                                        </MenuTile>
                                    ))}
                                </Container>
                            </Tab>
                        }
                        {userType !== 'admin' && (userType === 'company' || userType === 'customer') &&
                            <Tab title={t('DWOS')}>
                                <Container>
                                    {menuDwosItems.map(item => (
                                        <MenuTile key={item.header} onClick={() => onClickTile(item)}>
                                            <Tile.Header>{item.header}</Tile.Header>
                                            <Tile.Content>
                                                {item.logo !== undefined &&
                                                    <Icon ariaLabel={item.logo} glyph={item.logo} size='xl' />}
                                            </Tile.Content>
                                        </MenuTile>
                                    ))}
                                </Container>
                            </Tab>
                        }
                        {/* 管理メニュー */}
                        {userType === 'admin' &&
                            <Tab title={t('管理メニュー')}>
                                <Container>
                                    {adminMenuItems
                                        .map(item => (
                                            <MenuTile key={item.header} onClick={() => onClickTile(item)}>
                                                <Tile.Header>{item.header}</Tile.Header>
                                                <Tile.Content>
                                                    {item.logo !== undefined &&
                                                        <Icon ariaLabel={item.logo} glyph={item.logo} size='xl' />}
                                                </Tile.Content>
                                            </MenuTile>
                                        ))}
                                </Container>
                            </Tab>
                        }
                    </TabGroup>
                </LayoutPanel.Body>
                <LayoutPanel.Footer>
                    <div style={{ width: '100%' }}>
                        <div className='fd-bar__right'>
                            <NavButtons screenMode={'menu'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
        </>
    );
};

const MenuTile = styled(Tile)`
    margin: 10px;
`;

export default MainMenu;
