import { Record, List } from 'immutable';
import { JSObject } from '../types';

export class User extends Record<{
    userId: string;
    userNm: string;
    password: string;
    userType: string;
    createDate?: string | null;
    createUser?: string;
    updateDate?: string | null;
    updateUser?: string;
    deleteFlg?: boolean;
    mfaSuccess: string;
}>({
    userId: "",
    userNm: "",
    password: "",
    userType: "",
    createDate: null,
    createUser: "",
    updateDate: null,
    updateUser: "",
    deleteFlg: false,
    mfaSuccess: "",
}) {
    static fromResponse(response: JSObject) {
        const params = { ...response };
        return new User(params);
    }
}



export class Users extends Record<{
    users: List<User>
}>({
    users: List(),
}) {
    static fromResponse(response: Array<User>) {
        const params = [...response];
        const users = List(params.map((user: JSObject) => User.fromResponse(user)));
        return new Users({ users: users });
    }
}


export class AuthInfo extends Record<{
    mandt: string;
    userId: string;
    password: string;
    userType: string;
}>({
    mandt: '',
    userId: '',
    password: '',
    userType: '',
}) {

}

export class ChangePasswordInfo extends Record<{
    mandt: string;
    userId: string;
    password: string;
    newPassword: string;
}>({
    mandt: '',
    userId: '',
    password: '',
    newPassword: ''
}) {

}

export class MfaCode extends Record<{
    code: string;
}>({
    code: '',
}) {

}