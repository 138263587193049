import React, { useRef } from 'react';
import { Cookies } from 'react-cookie';
import { history } from '../configureStore';
import { confirmBox } from './atoms/Confirm';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../actions/user';
import { AuthInfo, User } from '../models/User';
import { Button } from 'fundamental-react';
import { ScreenMode } from '../types';
import { PaymentActions } from '../actions/payment';
import { PaymentDetailActions } from '../actions/paymentDetail';
import { Payment, Payments } from '../models/Payment';
import { PaymentDetails } from '../models/PaymentDetail';
import { DeliveryActions } from '../actions/delivery';
import { Delivery, Deliveries } from '../models/Delivery';
import { PurchaseOrderActions } from '../actions/purchaseOrder';
import { PurchaseOrders } from '../models/PurchaseOrder';
import { AppActions } from '../actions/app';
import { State } from '../reducers';
import { MaterialPriceActions } from '../actions/materialPrice';
import { SuggestMaterials } from '../models/MaterialPrice';

type Props = {
    screenMode: ScreenMode,
};

const NavButtons: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();

    const backToMenu = useRef<HTMLButtonElement>(null);
    const finish = useRef<HTMLButtonElement>(null);

    const {
        user,
    } = useSelector((state: State) => ({
        user: state.user.user,
    }));

    const onClickBackToMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // 支払レコードを全てクリア
        dispatch(PaymentActions.setPayment(new Payment()));
        dispatch(PaymentActions.setPayments(new Payments()));
        dispatch(PaymentDetailActions.setPaymentDetails(new PaymentDetails()));
        // 発注一覧をすべてクリア
        dispatch(PurchaseOrderActions.setPurchaseOrders(new PurchaseOrders()));
        // 出荷実績一覧をすべてクリア
        dispatch(DeliveryActions.setDeliveries(new Deliveries()));

        history.push('/menu');
    }

    const confirmFinish = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const ok = () => {
            // 支払レコードを全てクリア
            dispatch(PaymentActions.setPayment(new Payment()));
            dispatch(PaymentActions.setPayments(new Payments()));
            dispatch(PaymentDetailActions.setPaymentDetails(new PaymentDetails()));

            // 発注一覧をすべてクリア
            dispatch(PurchaseOrderActions.setPurchaseOrders(new PurchaseOrders()));

            // 出荷実績一覧をすべてクリア
            dispatch(DeliveryActions.setDeliveries(new Deliveries()));

            // 品目サジェスト用品目をクリア
            dispatch(MaterialPriceActions.setSuggestMaterials(new SuggestMaterials()));

            const userId = user.userId;
            dispatch(UserActions.removeSession(userId));
            dispatch(UserActions.setIsLogin(false));
            dispatch(UserActions.setAuthInfo(new AuthInfo()));
            dispatch(UserActions.setUser(new User()));
            const cookies = new Cookies();
            cookies.remove('token', { path: '/' });
            cookies.remove('refreshToken', { path: '/' });
            history.push('/');
            dispatch(AppActions.setSelectedTabIndex(0));
        }
        const cancel = () => {

        }
        confirmBox('終了します。よろしいですか？', [], ok, cancel);
    }

    return (
        <>
            <div className='fd-bar__element' hidden={props.screenMode === 'menu'}>
                <Button id='back_to_menu_button' ref={backToMenu} onClick={onClickBackToMenu} option='emphasized'>メニューに戻る</Button>
            </div>
            <div className='fd-bar__element'>
                <Button id='logiout_button' ref={finish} onClick={confirmFinish} option='emphasized'>終了</Button>
            </div>
        </>
    )
}

export default NavButtons;