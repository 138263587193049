import { List, Record } from 'immutable';
import { JSObject } from '../types/Common';

export class PaymentDetail extends Record<{
    paymentNo: string;
    revision: string;
    seqno: string;
    supplier: string;
    supplierName: string;
    orderCompany: string;
    orderCompanyName: string;
    orderId: string;
    orderItem: string;
    detailComment: string;
    postingDate: string;
    material: string;
    itemText: string;
    deliveryQuantity: string;
    deliveryQuantityUnit: string;
    deliveryQuantityUnitText: string;
    pricePerOneUnit: string;
    amountBase: string;
    currency: string;
    taxRate: string;
    note: string;
    purchasingDivision: string;
    purchasingDivisionText: string;
    etag: string;
    exSearchDetailCommentHistories: SearchDetailCommentHistories | null;
}>({
    paymentNo: '',
    revision: '',
    seqno: '',
    supplier: '',
    supplierName: '',
    orderCompany: '',
    orderCompanyName: '',
    orderId: '',
    orderItem: '',
    detailComment: '',
    postingDate: '',
    material: '',
    itemText: '',
    deliveryQuantity: '',
    deliveryQuantityUnit: '',
    deliveryQuantityUnitText: '',
    pricePerOneUnit: '',
    amountBase: '',
    currency: '',
    taxRate: '',
    note: '',
    purchasingDivision: '',
    purchasingDivisionText: '',
    etag: '',
    exSearchDetailCommentHistories: null,
}) {
    static fromResponse(response: JSObject) {
        const params = {
            ...response,
            exSearchDetailCommentHistories: SearchDetailCommentHistories.fromResponse(response.exSearchDetailCommentHistory),
        };
        return new PaymentDetail(params);
    };
};

export class PaymentDetails extends Record<{
    list: List<PaymentDetail>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];
        const paymentDetails = List(params.map(paymentDetail => PaymentDetail.fromResponse(paymentDetail)));
        return new PaymentDetails({list: paymentDetails});
    };
};

export class SearchDetailCommentHistory extends Record<{
    orderCompany: string;
    accountingDocument: string;
    fiscalYear: string;
    accountingDocumentDetail: string;
    belnrInvoice: string;
    buzeiInvoice: string;
    commentSeqno: string;
    paymentNo: string;
    revision: string;
    seqno: string;
    detailComment: string;
    detailCommentUpdate: string;
    orderCompanyName: string;
}>({
    orderCompany: '',
    accountingDocument: '',
    fiscalYear: '',
    accountingDocumentDetail: '',
    belnrInvoice: '',
    buzeiInvoice: '',
    commentSeqno: '',
    paymentNo: '',
    revision: '',
    seqno: '',
    detailComment: '',
    detailCommentUpdate: '',
    orderCompanyName: '',
}) {
    static fromResponse(response: JSObject) {
        const params = {
            ...response,
        };
        return new SearchDetailCommentHistory(params);
    };
};

export class SearchDetailCommentHistories extends Record<{
    list: List<SearchDetailCommentHistory>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];
        const searchDetailCommentHistories = List(params.map(searchDetailCommentHistory => SearchDetailCommentHistory.fromResponse(searchDetailCommentHistory)));
        return new SearchDetailCommentHistories({ list: searchDetailCommentHistories });
    };
};