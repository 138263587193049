import { ApiClient } from ".";
import { JSObject } from "../types/Common";
require('dotenv').config();

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

const API_PATH = '/api/InformationMaintenance';

export class InformationMaintenanceApi {

    static search(params: JSObject): Promise<unknown> {
        return apiClient.post(API_PATH + '/search', params);
    }

    static create(params: JSObject): Promise<unknown> {
        return apiClient.post(API_PATH, params);
    }

    static delete(params: JSObject): Promise<unknown> {
        return apiClient.delete(API_PATH + '/' + params.messageId);
    }

    static update(params: JSObject): Promise<unknown> {
        return apiClient.put(API_PATH, params);
    }
}