import { Button, Column, Container, DatePicker, FormGroup, FormInput, FormItem, FormLabel, InputGroup, LayoutPanel, Row, Table, Select } from 'fundamental-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MessageActions } from '../../../actions/message';
import { PaymentActions } from '../../../actions/payment';
import { history } from '../../../configureStore';
import { Payment, SearchKeys } from '../../../models/Payment';
import { State } from '../../../reducers';
import { DatePickerParam, Dict, JSObject, SelectOption } from '../../../types';
import { returnAmountText } from '../../../utils/CommonFunction';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import { backcolor0 } from '../../colors';
import NavButtons from '../../NavButtons';
import { getWord as w, getMessage as m } from '../../../messages';

type Props = {
    initialSearchKeys: SearchKeys;
}

const PaymentSearch: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const statuses: Dict = {
        '': '',
        '1': t('新規'),
        '2': t('承認'),
        '3': t('却下'),
        //'4': t('却下'), // 3の時に3or4の検索条件とするため不要
        '5': t('再公開'),
        '9': t('取消'),
    };

    const {
        isConnecting,
        payments,
        user,
        userType,
    } = useSelector((state: State) => ({
        isConnecting: state.payment.isConnecting,
        payments: state.payment.payments,
        user: state.user.user,
        userType: state.user.userType,
    }));

    // 表示用データを作成
    const toJSArray = (data: Array<Payment>) => {
        return data.map(d => d.toJS());
    }
    const [paymentData, setPaymentData] = useState<Array<JSObject>>(toJSArray(payments.list.toArray()));
    const [searchKeys, setSearchKeys] = useState<SearchKeys>({ ...props.initialSearchKeys });

    /**
     * UIへの参照
     **/
    const table = useRef<HTMLTableElement>(null);

    useEffect(() => {
        document.body.style.background = backcolor0;

        if (userType === 'company') {
            // 発注元企業ログイン時、企業IDを自動入力
            searchKeys.orderCompany = user.userId;
        } else {
            // サプライヤログイン時、サプライヤIDを自動入力
            searchKeys.supplier = user.userId;
        }
        setSearchKeys({ ...searchKeys });
    }, []);

    useEffect(() => {
        setPaymentData(toJSArray(payments.list.toArray()));
    }, [payments]);

    useEffect(() => {
        applyTableAlignStyles();
    }, [paymentData]);

    /**
     * 照合ステータス変更イベントハンドラ
     * @param event 
     * @param selectedOption
     */
    const onSelectedStatuses = (event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>, selectedOption: SelectOption) => {
        searchKeys.collationStatus = selectedOption.key;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 企業ID変更イベントハンドラ
     * @param event
     */
    const onChangeOrderCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.orderCompany = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * サプライヤID変更イベントハンドラ
     * @param event
     */
    const onChangeSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.supplier = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 支払番号変更イベントハンドラ
     * @param event
     */
    const onChangePaymentNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.paymentNo = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * リビジョン変更イベントハンドラ
     * @param event
     */
    const onChangeRevision = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.revision = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * リビジョンステータス変更イベントハンドラ
     * @param event
     * @param selectedOption
     */
    const onSelectedRevisionStatuses = (event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>, selectedOption: SelectOption) => {
        searchKeys.revisionStatus = selectedOption.key;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 対象期間(開始)From変更イベントハンドラ
     * @param param
     */
    const onInputBlurTargetDateFromFrom = (param: DatePickerParam) => {
        const strDate = param.isoFormattedDate;
        searchKeys.targetDateFromFrom = moment(strDate).isValid() ? new Date(strDate) : undefined;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 対象期間(開始)To変更イベントハンドラ
     * @param param
     */
    const onInputBlurTargetDateFromTo = (param: DatePickerParam) => {
        const strDate = param.isoFormattedDate;
        searchKeys.targetDateFromTo = moment(strDate).isValid() ? new Date(strDate) : undefined;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 対象期間(終了)From変更イベントハンドラ
     * @param param
     */
    const onInputBlurTargetDateToFrom = (param: DatePickerParam) => {
        const strDate = param.isoFormattedDate;
        searchKeys.targetDateToFrom = moment(strDate).isValid() ? new Date(strDate) : undefined;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 対象期間(終了)To変更イベントハンドラ
     * @param param
     */
    const onInputBlurTargetDateToTo = (param: DatePickerParam) => {
        const strDate = param.isoFormattedDate;
        searchKeys.targetDateToTo = moment(strDate).isValid() ? new Date(strDate) : undefined;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * テーブル行のテキスト外クリックイベントハンドラ
     * @param index
     */
    const onClickRow = (index: number) => {
        var payment = payments.list.get(index);
        if (payment !== undefined) {
            history.push('/payment_detail/' + payment.orderCompany + '/' + payment.supplier + '/' + payment.paymentNo + '/' + payment.revision);
        }
    };

    /**
     * テーブル行のテキストクリックイベントハンドラ
     * @param event
     */
    const onClickRowSpan = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        // キャストして変数に格納する
        var index = event.currentTarget.dataset.index;
        if (index !== undefined) {
            onClickRow(Number(index));
        }
    };

    /**
     * 検索ボタン押下イベントハンドラ
     * @param event
     */
    const onClickSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // 発注元企業ログイン時、サプライヤ名の入力確認
        if (userType === 'company' && (searchKeys.supplier == undefined || searchKeys.supplier.length === 0)) {
            dispatch(MessageActions.push({ content: m('項目 %s：必須入力項目に値が入力されていません。'), words: [w('サプライヤID')] }));
            return;
        }

        dispatch(PaymentActions.search(searchKeys));
    };

    // 照合ステータス,リビジョンステータス項目の選択肢を生成
    const createSelectableOptions = (options: Dict) => {
        const tempOptions = Object.entries(options).sort().map(([key, text]) => ({ key, text }));
        return tempOptions;
    };

    const applyTableAlignStyles = () => {
        // テーブル内の指定のカラムにスタイルを適用する
        Array.prototype.forEach.call(table.current?.getElementsByTagName("td"), item => {
            // text-align: center対象セルの判定
            if (item.getElementsByClassName("alignCenter").length > 0) {
                item.style.textAlign = 'center';
            }
            // text-align: right対象セルの判定
            if (item.getElementsByClassName('alignRight').length > 0) {
                item.style.textAlign = 'right';
            }
            // text-align: left対象セルの判定
            if (item.getElementsByClassName('alignLeft').length > 0) {
                item.style.textAlign = 'left';
            }
        });
    };

    return (
        <>
            <BusyIndicator hidden={!isConnecting} />
            <LayoutPanel style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <LayoutPanel.Filters>
                    <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <PaddingFormItem>
                            <FormLabel>{t('企業ID')}</FormLabel>
                            <FormInput
                                value={searchKeys.orderCompany}
                                style={{ width: '7rem' }}
                                onChange={onChangeOrderCompany}
                                disabled={userType === 'company'}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('支払番号')}</FormLabel>
                            <FormInput
                                value={searchKeys.paymentNo}
                                style={{ width: '10rem' }}
                                onChange={onChangePaymentNo}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('リビジョン')}</FormLabel>
                            <FormInput
                                value={searchKeys.revision}
                                style={{ width: '3rem' }}
                                onChange={onChangeRevision}
                            />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('対象期間(開始)')}</FormLabel>
                            <NoPaddingInputGroup>
                                <DatePicker
                                    defaultValue={
                                        searchKeys.targetDateFromFrom !== undefined && moment(searchKeys.targetDateFromFrom).isValid() ?
                                            moment(searchKeys.targetDateFromFrom).format('YYYY/MM/DD') :
                                            ''
                                    }
                                    dateFormat='YYYY/MM/DD'
                                    locale='ja'
                                    style={{ maxWidth: '10rem' }}
                                    onInputBlur={onInputBlurTargetDateFromFrom}
                                />
                                <InputGroup.Addon>{t('～')}</InputGroup.Addon>
                                <DatePicker
                                    defaultValue={
                                        searchKeys.targetDateFromTo !== undefined && moment(searchKeys.targetDateFromTo).isValid() ?
                                            moment(searchKeys.targetDateFromTo).format('YYYY/MM/DD') :
                                            ''
                                    }
                                    dateFormat='YYYY/MM/DD'
                                    locale='ja'
                                    style={{ maxWidth: '10rem' }}
                                    onInputBlur={onInputBlurTargetDateFromTo}
                                />
                            </NoPaddingInputGroup>
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('対象期間(終了)')}</FormLabel>
                            <NoPaddingInputGroup>
                                <DatePicker
                                    defaultValue={
                                        searchKeys.targetDateToFrom !== undefined && moment(searchKeys.targetDateToFrom).isValid() ?
                                            moment(searchKeys.targetDateToFrom).format('YYYY/MM/DD') :
                                            ''
                                    }
                                    dateFormat='YYYY/MM/DD'
                                    locale='ja'
                                    style={{ maxWidth: '10rem' }}
                                    onInputBlur={onInputBlurTargetDateToFrom}
                                />
                                <InputGroup.Addon>{t('～')}</InputGroup.Addon>
                                <DatePicker
                                    defaultValue={
                                        searchKeys.targetDateToTo !== undefined && moment(searchKeys.targetDateToTo).isValid() ?
                                            moment(searchKeys.targetDateToTo).format('YYYY/MM/DD') :
                                            ''
                                    }
                                    dateFormat='YYYY/MM/DD'
                                    locale='ja'
                                    style={{ maxWidth: '10rem' }}
                                    onInputBlur={onInputBlurTargetDateToTo}
                                />
                            </NoPaddingInputGroup>
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('照合ステータス')}</FormLabel>
                            <Select selectedKey={searchKeys.collationStatus ?? ''} options={createSelectableOptions(statuses)} onSelect={onSelectedStatuses} />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel>{t('リビジョンステータス')}</FormLabel>
                            <Select selectedKey={searchKeys.revisionStatus ?? ''} options={createSelectableOptions(statuses)} onSelect={onSelectedRevisionStatuses} />
                        </PaddingFormItem>
                        <PaddingFormItem>
                            <FormLabel required>{t('サプライヤID')}</FormLabel>
                            <FormInput
                                style={{ width: '9rem' }}
                                maxLength={10}
                                pattern='^[0-9A-Za-z]+$'
                                onChange={onChangeSupplier}
                                value={searchKeys.supplier ?? ''}
                                disabled={userType === 'supplier'}
                            />
                        </PaddingFormItem>
                    </FormGroup>
                    <div style={{ width: '100%' }}>
                        <div className='fd-bar__right'>
                            <div className='fd-bar__element'>
                                <Button id='back_to_menu_button' onClick={onClickSearch} option='emphasized'>{t('検索')}</Button>
                            </div>
                        </div>
                    </div>
                </LayoutPanel.Filters>
                <LayoutPanel.Body style={{ flex: '1 1 auto', height: '0' }}>
                    <Container style={{ height: '100%' }}>
                        <Row style={{ height: '100%', margin: '0' }}>
                            <Column style={{ height: '100%', overflow: 'auto', padding: '0' }} span={12}>
                                <HeaderFreezingTable
                                    ref={table}
                                    compact
                                    condensed
                                    headers={[
                                        '', // 先頭に空のセルが必要
                                        t('企業'),
                                        t('支払番号'),
                                        t('照合ステータス'),
                                        t('対象期間(開始)'),
                                        t('対象期間(終了)'),
                                        t('リビジョン'),
                                        t('リビジョンステータス'),
                                        t('支払金額(本体)'),
                                        t('通貨'),
                                        t('リビジョン通信欄'),
                                    ]}
                                    selection={{
                                        isSelected: () => false,
                                        onClickRow: onClickRow,
                                    }}
                                    tableData={paymentData.map((item, index) => {
                                        return ({
                                            rowData: [
                                                <NoWrapSpan />,  // 先頭に空のセルが必要
                                                <NoWrapSpan
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {item.orderCompany}<br />{item.orderCompanyName}
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {item.paymentNo}
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    className='alignCenter'
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {item.collationStatusText}{item.temporarilySavedPayment === 'X' ? t('(保存中)') : ''}
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    className='alignCenter'
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {moment(item.targetDateFrom).isValid() ?
                                                        moment(item.targetDateFrom).format('YYYY/MM/DD') :
                                                        ''
                                                    }
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    className='alignCenter'
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {moment(item.targetDateTo).isValid() ?
                                                        moment(item.targetDateTo).format('YYYY/MM/DD') :
                                                        ''
                                                    }
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {item.revision}
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    className='alignCenter'
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {item.revisionStatusText}{item.temporarilySavedRevision === 'X' ? t('(保存中)') : ''}
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    className='alignRight'
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {returnAmountText(item.amountBase)}
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    className='alignLeft'
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {item.currency}
                                                </NoWrapSpan>,
                                                <NoWrapSpan
                                                    data-index={index}
                                                    onClick={onClickRowSpan}
                                                >
                                                    {item.revisionComment}{moment(item.revisionCommentUpdate).isValid() ?
                                                        moment(item.revisionCommentUpdate).format('YYYY/MM/DD') : ''
                                                    }
                                                </NoWrapSpan>,
                                            ]
                                        });
                                    })}
                                />
                            </Column>
                        </Row>
                    </Container>
                </LayoutPanel.Body>
                <LayoutPanel.Footer>
                    <div style={{ width: '100%' }}>
                        <div className='fd-bar__right'>
                            <NavButtons screenMode={'paymentSearch'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
        </>
    );
};

const NoPaddingInputGroup = styled(InputGroup)`
    border: 0;
    &&& > .fd-input-group__input:nth-child(1) {
        padding-left: 0;
    }
    &&& > .fd-input-group__input:nth-last-child(1) {
        padding-right: 0;
    }
`;

const PaddingFormItem = styled(FormItem)`
    padding: 0 10px;
`;

const HeaderFreezingTable = styled(Table)`
    &&& thead tr th {
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 5;
        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            width: 100%;
            height: 100%;
            border-top: 1px solid #e4e4e4;
        }
    }
`;

const NoWrapSpan = styled.span`
    white-space: nowrap;
`;

export default PaymentSearch;