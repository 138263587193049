import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { PaymentActions } from '../actions/payment';
import { Payment, Payments, SearchKeys } from '../models/Payment';

export class PaymentState extends Record<{
    // 検索により取得した支払データ
    payment: Payment;
    payments: Payments;
    // apiに接続中か否か
    isConnecting: boolean;
    // 検索に成功したか否か
    didGetSuccess: boolean | null;
    didSearchSuccess: boolean | null;
    // 検索キー
    searchKeys: SearchKeys | null;
    // 一時保存・承認・却下に成功したか否か
    didApproveSuccess: boolean | null;
}>({
    payment: new Payment(),
    payments: new Payments(),
    isConnecting: false,
    didGetSuccess: null,
    didSearchSuccess: null,
    searchKeys: null,
    didApproveSuccess: null,
}) { };

export const PaymentReducer = reducerWithInitialState(new PaymentState())
    .case(PaymentActions.setDidApproveSuccess, (state, payload) => {
        return state.set('didApproveSuccess', payload);
    })
    .case(PaymentActions.setDidGetSuccess, (state, payload) => {
        return state.set('didGetSuccess', payload);
    })
    .case(PaymentActions.setDidSearchSuccess, (state, payload) => {
        return state.set('didSearchSuccess', payload);
    })
    .case(PaymentActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(PaymentActions.setPayment, (state, payload) => {
        return state.set('payment', payload);
    })
    .case(PaymentActions.setPayments, (state, payload) => {
        return state.set('payments', payload);
    })
    .case(PaymentActions.setSearchKeys, (state, payload) => {
        return state.set('searchKeys', payload);
    })
    ;