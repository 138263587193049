import { Button, LayoutPanel } from 'fundamental-react';
import moment from 'moment';
import * as PapaParse from 'papaparse';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from '../../../reducers';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import NavButtons from '../../NavButtons';
import MaterialPriceSearch from '../../organisms/MaterialPriceSearch';

const MaterialPriceList: React.FC = () => {
    // 多言語対応
    const { t } = useTranslation();

    /**
     * 状態管理
     **/
    const {
        isConnecting,
        materialPrices,
    } = useSelector((state: State) => ({
        isConnecting: state.materialPrice.isConnecting,
        materialPrices: state.materialPrice.materialPrices,
    }));

    /**
     * CSV出力ボタン押下
     * @param event
     */
    const onClickOutputCsv = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (materialPrices.list.count() > 0) {
            const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            const str = materialPrices.list.map((item, index) => [
                item.customer,
                item.customerName,
                item.supplier,
                item.supplierName,
                item.customerMaterial,
                item.customerMaterialName,
                Number(item.pricePerOneUnit) === 0 ? '' : item.pricePerOneUnit,
                item.currency,
                Number(item.pricingUnit) === 0 ? '' : item.pricingUnit,
                item.salesUnit,
            ]);

            const header = [
                '企業ID',
                '企業名称',
                'サプライヤID',
                'サプライヤ名称',
                '品目',
                '品名',
                '正味価格',
                '通貨',
                '価格設定単位',
                '販売単位',
            ];

            const csv = PapaParse.unparse([header, ...str.toArray()]);
            const url = window.URL.createObjectURL(new Blob([bom, csv], { type: 'text/csv' }));
            const a = document.createElement('a');

            a.download = 'material price list_' + moment().format('YYYYMMDD_HHmmss') + '.csv';
            a.href = url;
            a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
            a.click();
        }
    }

    return (
        <>
            <BusyIndicator hidden={!isConnecting} />
            <LayoutPanel style={{ maxWidth: '720px', margin: '0 auto', height: '100%' }}>
                <LayoutPanel.Body style={{ height: 'calc(100% - 72px)', display: 'flex', flexDirection: 'column' }}>
                    <MaterialPriceSearch />
                </LayoutPanel.Body>
                <LayoutPanel.Footer style={{ height: '69px' }}>
                    <div style={{ width: '100%' }}>
                        <div className="fd-bar__right">
                            <div className="fd-bar__element">
                                <Button id='csv_output_button' onClick={onClickOutputCsv} option='emphasized'>{t('CSV')}</Button>
                            </div>
                            <NavButtons screenMode={'materialpriceList'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
        </>
    )
};

export default MaterialPriceList;
