import { put, takeLatest } from "redux-saga/effects";
import { CommonParamActions } from "../actions/commonParam";
import { MessageActions } from "../actions/message";
import { CommonParamApi } from "../apiClient/commonParam";
import { getMessage as m } from '../messages';
import { CustomerDestinations } from "../models/CommonParam";

function* getCustomerDestinations(action: ReturnType<typeof CommonParamActions.getCustomerDestinations>) {

    const response = yield CommonParamApi.getCustomerDestinations();

    if (response.isSuccess) {
        yield put(CommonParamActions.setCustomerDestinations(CustomerDestinations.fromResponse(response.data)));
    } else {
        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }
}

export function* CommonParamSaga() {
    yield takeLatest(CommonParamActions.getCustomerDestinations, getCustomerDestinations);
}