import { Button, Column, Container, FormInput, FormTextarea, LayoutPanel, Row, Table } from 'fundamental-react';
import moment from 'moment';
import * as PapaParse from 'papaparse';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MessageActions } from '../../../actions/message';
import { PurchaseOrderActions } from '../../../actions/purchaseOrder';
import { history } from '../../../configureStore';
import { getMessage as m } from '../../../messages';
import { exMsgColumn } from '../../../models/Order';
import { AnswerMessageColumn, PurchaseOrder, PurchaseOrderExMsgColumn } from '../../../models/PurchaseOrder';
import { State } from '../../../reducers';
import { HeaderVariant, SortedColumn } from '../../../types';
import { JSObject } from '../../../types/Common';
import { confirmBox } from '../../atoms/Confirm';
import ViewSettingForm from '../../atoms/ViewSettingForm';
import NavButtons from '../../NavButtons';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import { getHeaderSettingLabelFromName, returnAmountText, returnQuantityText } from '../../../utils/CommonFunction';
import LayoutFilter from './LayoutFilter';
import { PurchaseOrderHeaderSettingActions } from '../../../actions/purchaseOrderHeaderSetting';

const PurchaseOrderList: React.FC = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        isConnecting,
        purchaseOrders,
        customerName,
        userType,
        purchaseOrderHeaderSetting,
    } = useSelector((state: State) => ({
        isConnecting: state.purchaseOrder.isConnecting,
        purchaseOrders: state.purchaseOrder.purchaseOrders,
        customerName: state.purchaseOrder.customerName,
        userType: state.user.userType,
        purchaseOrderHeaderSetting: state.purchaseOrderHeaderSetting.purchaseOrderHeaderSetting,
    }));

    const CELL_HEIGHTS_FOR_ADD_PADDING = 50;

    const toJSArray = (data: Array<PurchaseOrder>) => {
        return data.map(d => d.toJS());
    }
    const [tempPurchaseOrders, setTempPurchaseOrders] = useState<Array<JSObject>>(toJSArray(purchaseOrders.list.toArray()));

    // ビュー設定画面表示フラグ
    const [isShowViewSettingForm, setIsShowViewSettingForm] = useState(false);

    // 並び替え項目 ローカルストレージの設定値を初期値とする
    const localHeaderVariantForLabel = purchaseOrderHeaderSetting.get('purchaseOrderHeaderVariant') as HeaderVariant[];
    // 並び替え項目 ローカルストレージの設定値を初期値とし、ラベルにi18nを適用する
    const localHeaderVariant = localHeaderVariantForLabel.map((x) => {
        return {
            isSelected: x.isSelected,
            label: t(x.label),
            name: x.name,
        };
    });
    const [headerVariant, setHeaderVariant] = useState<HeaderVariant[]>([...localHeaderVariant]);
    // sortedColumnsの値
    //  初期値: ローカルストレージの設定値
    //  一覧ヘッダ押下: 押下項目オブジェクト
    //  ダイアログOKボタン押下: 選択項目(項目未選択の場合は空のオブジェクト)
    const localSortedColumns = purchaseOrderHeaderSetting.get('purchaseOrderSortedColumns') as SortedColumn[];
    const [sortedColumns, setSortedColumns] = useState<SortedColumn[]>([...localSortedColumns]);

    // 通信欄更新
    const onChangeInputMessageColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
        const message = event.currentTarget.value;
        const index = ((event.currentTarget.parentElement as HTMLTableDataCellElement)?.parentElement as HTMLTableRowElement)?.rowIndex;
        if (index === undefined)
            return;

        tempPurchaseOrders[index - 1].inputMessageColumn = message;
        setTempPurchaseOrders([...tempPurchaseOrders]);
    };

    // 「CSV」ボタン押下
    const onClickOutputCsv = () => {
        if (tempPurchaseOrders.length === 0)
            return;

        const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const rows = tempPurchaseOrders.map((x) => getSelectedPurchaseOrder(x));
        const headers = getSelectedHeader();

        const csv = PapaParse.unparse([headers, ...rows]);
        const url = window.URL.createObjectURL(new Blob([bom, csv], { type: 'text/csv' }));
        const a = document.createElement('a');

        a.download = 'purchase order list_' + moment().format('YYYYMMDD_HHmmss') + '.csv';
        a.href = url;
        a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
        a.click();
    };

    // CSV用リストを取得
    const getSelectedPurchaseOrder = (purchaseOrder: JSObject) => {

        let exMsgColumns = '';
        if (purchaseOrder.exMsgColumn !== undefined && purchaseOrder.exMsgColumn.length > 0) {
            exMsgColumns = purchaseOrder.exMsgColumn.reduce((prev: string, current: PurchaseOrderExMsgColumn) => !prev ?
                current.messageColumn :
                current.messageColumn + ',' + prev, '');
        }

        const allPurchaseOrder = [{
            name: 'supplier',
            value: purchaseOrder.supplier,
        }, {
            name: 'supplier',
            value: purchaseOrder.supplierName,
        }, {
            name: 'receiptNo',
            value: purchaseOrder.receiptNo,
        }, {
            name: 'orderId',
            value: purchaseOrder.orderId,
        }, {
            name: 'orderIdDetail',
            value: purchaseOrder.orderIdDetail,
        }, {
            name: 'customerMaterial',
            value: purchaseOrder.customerMaterial,
        }, {
            name: 'customerMaterial',
            value: purchaseOrder.customerMaterialName,
        }, {
            name: 'material',
            value: purchaseOrder.material,
        }, {
            name: 'material',
            value: purchaseOrder.materialName,
        }, {
            name: 'orderDate',
            value: moment(purchaseOrder.orderDate).isValid() ? moment(purchaseOrder.orderDate).format('YYYY/MM/DD') : '',
        }, {
            name: 'desiredDeliveryDate',
            value: moment(purchaseOrder.desiredDeliveryDate).isValid() ? moment(purchaseOrder.desiredDeliveryDate).format('YYYY/MM/DD') : '',
        }, {
            name: 'changedDeliveryDate',
            value: moment(purchaseOrder.changedDeliveryDate).isValid() ? moment(purchaseOrder.changedDeliveryDate).format('YYYY/MM/DD') : '',
        }, {
            name: 'orderQuantity',
            value: purchaseOrder.orderQuantity,
        }, {
            name: 'changedOrderQuantity',
            value: purchaseOrder.changedOrderQuantity,
        }, {
            name: 'deliveryQuantity',
            value: purchaseOrder.deliveryQuantity,
        }, {
            name: 'remainQuantity',
            value: purchaseOrder.remainQuantity,
        }, {
            name: 'orderQuantityUnit',
            value: purchaseOrder.orderQuantityUnit,
        }, {
            name: 'pricePerOneUnit',
            value: purchaseOrder.pricePerOneUnit,
        }, {
            name: 'amountTotal',
            value: purchaseOrder.amountTotal,
        }, {
            name: 'currency',
            value: purchaseOrder.currency,
        }, {
            name: 'confirmationStatus',
            value: getConfirmationStatusText(purchaseOrder.confirmationStatus),
        }, {
            name: 'status',
            value: getStatusText(purchaseOrder.status),
        }, {
            name: 'orderedBy',
            value: getOrderedByText(purchaseOrder.orderedBy),
        }, {
            name: 'messageColumn',
            value: purchaseOrder.inputMessageColumn,
        }, {
            name: 'messageColumns',
            value: exMsgColumns,
        }
        ];

        const purchaseOrderData: string[] = [];
        purchaseOrderData.push(purchaseOrder.customer);
        purchaseOrderData.push(customerName);

        headerVariant.filter(x => x.isSelected).forEach(x => {
            const items = allPurchaseOrder.filter(y => y.name === x.name);
            items.forEach(y => purchaseOrderData.push(y.value));
        });

        return purchaseOrderData;
    };

    // CSV用ヘッダを取得
    const getSelectedHeader = () => {
        const allHeaders = [{
            name: 'supplier',
            value: t('サプライヤID'),
        }, {
            name: 'supplier',
            value: t('サプライヤ名称'),
        }, {
            name: 'receiptNo',
            value: t('受付番号'),
        }, {
            name: 'orderId',
            value: t('注文番号'),
        }, {
            name: 'orderIdDetail',
            value: t('注文明細No.'),
        }, {
            name: 'customerMaterial',
            value: t('品目'),
        }, {
            name: 'customerMaterial',
            value: t('品名'),
        }, {
            name: 'material',
            value: t('CMEs品目'),
        }, {
            name: 'material',
            value: t('CMEs品名'),
        }, {
            name: 'orderDate',
            value: t('発注日'),
        }, {
            name: 'desiredDeliveryDate',
            value: t('希望納期'),
        }, {
            name: 'changedDeliveryDate',
            value: t('変更納期'),
        }, {
            name: 'orderQuantity',
            value: t('初回発注数量'),
        }, {
            name: 'changedOrderQuantity',
            value: t('正式発注数量'),
        }, {
            name: 'deliveryQuantity',
            value: t('出荷数量')
        }, {
            name: 'remainQuantity',
            value: t('発注残'),
        }, {
            name: 'orderQuantityUnit',
            value: t('単位'),
        }, {
            name: 'pricePerOneUnit',
            value: t('正味価格'),
        }, {
            name: 'amountTotal',
            value: t('正味額'),
        }, {
            name: 'currency',
            value: t('通貨'),
        }, {
            name: 'confirmationStatus',
            value: t('確認状況'),
        }, {
            name: 'status',
            value: t('発注ステータス'),
        }, {
            name: 'orderedBy',
            value: t('発注登録者'),
        }, {
            name: 'remarkColumns',
            value: t('備考欄'),
        }, {
            name: 'messageColumn',
            value: t('通信欄'),
        }, {
            name: 'messageColumns',
            value: t('通信履歴'),
        }
        ];

        const headerData: string[] = [];
        headerData.push(t('企業ID'));
        headerData.push(t('企業名称'));

        headerVariant.filter(x => x.isSelected).forEach(x => {
            const label = allHeaders.filter(y => y.name === x.name);
            label.forEach(y => headerData.push(y.value));
        });

        return headerData;
    };

    // 「回答」ボタン押下
    const onClickAnswerMessageColumn = () => {

        const data = tempPurchaseOrders.filter(x => x.inputMessageColumn.length > 0).map(x => {
            return {
                salesOrder: x.salesOrder,
                orderIdDetail: x.orderIdDetail,
                orderId: x.orderId,
                customer: x.customer,
                supplier: x.supplier,
                customerMaterial: x.customerMaterial,
                desiredDeliveryDate: x.desiredDeliveryDate,
                orderQuantity: x.orderQuantity.toString(),
                orderQuantityUnit: x.orderQuantityUnit,
                messageColumn: x.inputMessageColumn,
                etag: x.etag,
            } as AnswerMessageColumn;
        });

        if (data.length === 0) {
            dispatch(MessageActions.push({ content: m('通信欄を入力してください。'), words: [] }));
            return;
        }

        const ok = () => {
            dispatch(PurchaseOrderActions.answerMessageColumns(data));
        }
        const cancel = () => {

        }
        confirmBox('通信欄回答します。よろしいですか？', [], ok, cancel);
    };

    const returnSortIcon = (sortKey: string) => {

        if (sortKey === undefined)
            return;

        const tempSortedColumns = sortedColumns.filter(x => x.column === sortKey);

        let result = 'sort';

        if (tempSortedColumns.length !== 0) {
            if (tempSortedColumns[0]?.isAscending) {
                result = 'sort-ascending';
            } else {
                result = 'sort-descending';
            }
        }

        return result;
    };

    const onClickSort = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const sortKey = event.currentTarget.dataset?.sortKey;
        if (sortKey === undefined)
            return;

        // ボタン押下した列と現在ソートされている列が一致するか判定し、一致する列を取得
        const tempSortedColumns = sortedColumns.filter(x => x.column === sortKey);

        // 一致した列を取得できた場合、ソート順を反転
        if (tempSortedColumns.length !== 0) {
            setSortedColumns([{
                column: tempSortedColumns[0]?.column ?? '',
                isAscending: !tempSortedColumns[0]?.isAscending,
            }]);
        }
        // 一致しない場合、昇順でセット
        else {
            setSortedColumns([{
                column: sortKey,
                isAscending: true,
            }]);
        }
    };

    const onClickViewSettingButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsShowViewSettingForm(true);
    }

    // ヘッダー
    const getHeaders = () => {
        const headers = [{
            name: 'supplier',
            comp: <DivAlignItemsCenter>
                {t('サプライヤID')}
                <Button
                    compact
                    glyph={returnSortIcon('supplier')}
                    data-sort-key='supplier'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'receiptNo',
            comp: <DivAlignItemsCenter>
                {t('受付番号')}
                <Button
                    compact
                    glyph={returnSortIcon('receiptNo')}
                    data-sort-key='receiptNo'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderId',
            comp: <DivAlignItemsCenter>
                {t('注文番号')}
                <Button
                    compact
                    glyph={returnSortIcon('orderId')}
                    data-sort-key='orderId'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderIdDetail',
            comp: <DivAlignItemsCenter>
                {t('注文明細No.')}
                <Button
                    compact
                    glyph={returnSortIcon('orderIdDetail')}
                    data-sort-key='orderIdDetail'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'customerMaterial',
            comp: <DivAlignItemsCenter>
                {t('品目')}
                <Button
                    compact
                    glyph={returnSortIcon('customerMaterial')}
                    data-sort-key='customerMaterial'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'material',
            comp: <DivAlignItemsCenter>
                {t('CMEs品目')}
                <Button
                    compact
                    glyph={returnSortIcon('material')}
                    data-sort-key='material'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderDate',
            comp: <DivAlignItemsCenter>
                {t('発注日')}
                <Button
                    compact
                    glyph={returnSortIcon('orderDate')}
                    data-sort-key='orderDate'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'desiredDeliveryDate',
            comp: <DivAlignItemsCenter>
                {t('希望納期')}
                <Button
                    compact
                    glyph={returnSortIcon('desiredDeliveryDate')}
                    data-sort-key='desiredDeliveryDate'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'changedDeliveryDate',
            comp: <DivAlignItemsCenter>
                {t('変更納期')}
                <Button
                    compact
                    glyph={returnSortIcon('changedDeliveryDate')}
                    data-sort-key='changedDeliveryDate'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderQuantity',
            comp: <DivAlignItemsCenter>
                {t('初回発注数量')}
                <Button
                    compact
                    glyph={returnSortIcon('orderQuantity')}
                    data-sort-key='orderQuantity'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'changedOrderQuantity',
            comp: <DivAlignItemsCenter>
                {t('正式発注数量')}
                <Button
                    compact
                    glyph={returnSortIcon('changedOrderQuantity')}
                    data-sort-key='changedOrderQuantity'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'deliveryQuantity',
            comp: <DivAlignItemsCenter>
                {t('出荷数量')}
                <Button
                    compact
                    glyph={returnSortIcon('deliveryQuantity')}
                    data-sort-key='deliveryQuantity'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'remainQuantity',
            comp: <DivAlignItemsCenter>
                {t('発注残')}
                <Button
                    compact
                    glyph={returnSortIcon('remainQuantity')}
                    data-sort-key='remainQuantity'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderQuantityUnit',
            comp: <DivAlignItemsCenter>
                {t('単位')}
                <Button
                    compact
                    glyph={returnSortIcon('orderQuantityUnit')}
                    data-sort-key='orderQuantityUnit'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'pricePerOneUnit',
            comp: <DivAlignItemsCenter>
                {t('正味価格')}
                <Button
                    compact
                    glyph={returnSortIcon('pricePerOneUnit')}
                    data-sort-key='pricePerOneUnit'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'amountTotal',
            comp: <DivAlignItemsCenter>
                {t('正味額')}
                <Button
                    compact
                    glyph={returnSortIcon('amountTotal')}
                    data-sort-key='amountTotal'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'currency',
            comp: <DivAlignItemsCenter>
                {t('通貨')}
                <Button
                    compact
                    glyph={returnSortIcon('currency')}
                    data-sort-key='currency'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'confirmationStatus',
            comp: <DivAlignItemsCenter>
                {t('確認状況')}
                <Button
                    compact
                    glyph={returnSortIcon('confirmationStatus')}
                    data-sort-key='confirmationStatus'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'status',
            comp: <DivAlignItemsCenter>
                {t('発注ステータス')}
                <Button
                    compact
                    glyph={returnSortIcon('status')}
                    data-sort-key='status'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'orderedBy',
            comp: <DivAlignItemsCenter>
                {t('発注登録者')}
                <Button
                    compact
                    glyph={returnSortIcon('orderedBy')}
                    data-sort-key='orderedBy'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'remarkColumns',
            comp: <DivAlignItemsCenter>
                {t('備考欄')}
                <Button
                    compact
                    glyph={returnSortIcon('remarkColumns')}
                    data-sort-key='remarkColumns'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        }, {
            name: 'messageColumn',
            comp: <DivAlignItemsCenter>
                {t('通信欄')}
            </DivAlignItemsCenter>
        }, {
            name: 'messageColumns',
            comp: <DivAlignItemsCenter>
                {t('通信履歴')}
                <Button
                    compact
                    glyph={returnSortIcon('messageColumn')}
                    data-sort-key='messageColumns'
                    onClick={onClickSort}
                    option='transparent'
                />
            </DivAlignItemsCenter>
        },
        ];

        var headerData: JSX.Element[] = [];
        headerVariant.filter(x => x.isSelected).forEach(x => {
            const comp = headers.filter(y => y.name === x.name);
            comp.forEach(y => headerData.push(y.comp));
        });

        return headerData;
    };

    // リスト
    const getRowData = (item: JSObject, index: number) => {

        let exMsgColumns = '';
        if (item.exMsgColumn !== undefined && item.exMsgColumn.length > 0) {
            exMsgColumns = item.exMsgColumn.reduce((prev: string, current: PurchaseOrderExMsgColumn) => !prev ?
                current.messageColumn :
                current.messageColumn + '\n' + prev, '');
        }

        const allRowData = [{
            name: 'supplier',
            comp: <NoWrapSpan>{item.supplier}<br />{item.supplierName}</NoWrapSpan>
        }, {
            name: 'receiptNo',
            comp: <NoWrapSpan>{item.receiptNo}</NoWrapSpan>
        }, {
            name: 'orderId',
            comp: <NoWrapSpan>{item.orderId}</NoWrapSpan>
        }, {
            name: 'orderIdDetail',
            comp: <NoWrapSpan>{item.orderIdDetail}</NoWrapSpan>
        }, {
            name: 'customerMaterial',
            comp: <NoWrapSpan>{item.customerMaterial}<br />{item.customerMaterialName}</NoWrapSpan>
        }, {
            name: 'material',
            comp: <NoWrapSpan>{item.material}<br />{item.materialName}</NoWrapSpan>
        }, {
            name: 'orderDate',
            comp: <NoWrapSpan className='alignCenter'>{moment(item.orderDate).isValid() ? moment(item.orderDate).format('YYYY/MM/DD') : ''}</NoWrapSpan>
        }, {
            name: 'desiredDeliveryDate',
            comp: <NoWrapSpan className='alignCenter'>{moment(item.desiredDeliveryDate).isValid() ? moment(item.desiredDeliveryDate).format('YYYY/MM/DD') : ''}</NoWrapSpan>
        }, {
            name: 'changedDeliveryDate',
            comp: <NoWrapSpan className='alignCenter'>{moment(item.changedDeliveryDate).isValid() ? moment(item.changedDeliveryDate).format('YYYY/MM/DD') : ''}</NoWrapSpan>
        }, {
            name: 'orderQuantity',
            comp: <NoWrapSpan className='alignRight'>{returnQuantityText(item.orderQuantity)}</NoWrapSpan>
        }, {
            name: 'changedOrderQuantity',
            comp: <NoWrapSpan className='alignRight'>{returnQuantityText(item.changedOrderQuantity)}</NoWrapSpan>
        }, {
            name: 'deliveryQuantity',
            comp: <NoWrapSpan className='alignRight'>{returnQuantityText(item.deliveryQuantity)}</NoWrapSpan>
        }, {
            name: 'remainQuantity',
            comp: <NoWrapSpan className='alignRight'>{returnQuantityText(item.remainQuantity)}</NoWrapSpan>
        }, {
            name: 'orderQuantityUnit',
            comp: <NoWrapSpan>{item.orderQuantityUnit}</NoWrapSpan>
        }, {
            name: 'pricePerOneUnit',
            comp: <NoWrapSpan className='alignRight'>{returnAmountText(item.pricePerOneUnit)}</NoWrapSpan>
        }, {
            name: 'amountTotal',
            comp: <NoWrapSpan className='alignRight'>{returnAmountText(item.amountTotal)}</NoWrapSpan>
        }, {
            name: 'currency',
            comp: <NoWrapSpan>{item.currency}</NoWrapSpan>
        }, {
            name: 'confirmationStatus',
            comp: <NoWrapSpan>{getConfirmationStatusText(item.confirmationStatus)}</NoWrapSpan>
        }, {
            name: 'status',
            comp: <NoWrapSpan>{getStatusText(item.status)}</NoWrapSpan>
        }, {
            name: 'orderedBy',
            comp: <NoWrapSpan>{getOrderedByText(item.orderedBy)}</NoWrapSpan>
        }, {
            name: 'messageColumn',
            comp: <FormInput maxLength={128} disabled={userType !== 'customer'} style={{ minWidth: '10rem' }} onChange={onChangeInputMessageColumn} value={item.inputMessageColumn} />
        }, {
            name: 'messageColumns',
            comp: <FormTextarea readOnly style={{ minWidth: '28rem' }} value={exMsgColumns}></FormTextarea>
        },
        ];

        const rowData: JSX.Element[] = [];
        headerVariant.filter(x => x.isSelected).forEach(x => {
            const comp = allRowData.filter(y => y.name === x.name);
            comp.forEach(y => rowData.push(y.comp));
        });

        return rowData;
    };

    /**
     * テーブル行のPropsを返す
     * @param rowData, @param index
     */
    const getRowProps = (rowData: JSObject, index: number) => {
        // ハイライト行の判定
        // ビュー設定で非表示にした列のデータはrowDataに入らないため、tempPurchaseOrdersのデータで判定

        // (i)希望納期が変更された(変更納期が存在する)かを判定
        const isExistChangedDeliveryDate = tempPurchaseOrders[index].changedDeliveryDate != null ? true : false;

        // (ii)発注数が変更された(初回発注数量と正式発注数量の値が異なる)かを判定
        const isDifficultOrderQuantityAndchangedOrderQuantity = tempPurchaseOrders[index].orderQuantity !== tempPurchaseOrders[index].changedOrderQuantity ? true : false;

        // (iii)発注者がCMEs導入企業(発注者が'1'以外)かを判定
        const isOrderedByCompany = tempPurchaseOrders[index].orderedBy !== '1' ? true : false;

        // 表示行のスタイルを初期化
        const rowStyleColor: React.CSSProperties = { backgroundColor: '#FFFFFF' };

        // (i),(ii),(iii)のうち一つでもtrueであれば行をハイライトする
        if (isExistChangedDeliveryDate || isDifficultOrderQuantityAndchangedOrderQuantity || isOrderedByCompany) {
            rowStyleColor.backgroundColor = '#FFFF66';
        }

        const rowProps = { style: rowStyleColor };

        return rowProps;
    };

    // 確認状況コードからステータステキストを取得
    const getConfirmationStatusText = (status: string) => {
        switch (status) {
            case '10':
                return t('未確認');
            case '20':
                return t('確認済');
            default:
                return '';
        }
    };

    // ステータスコードからステータステキストを取得
    const getStatusText = (status: string) => {
        switch (status) {
            case '10':
                return t('発注残');
            case '20':
                return t('完納');
            case '90':
                return t('キャンセル');
            default:
                return '';
        }
    };

    // 発注登録者コードから発注登録者テキストを取得
    const getOrderedByText = (orderedBy: string) => {
        switch (orderedBy) {
            case '1':
                return t('DWOS企業');
            case '2':
                return t('CMEs企業');
            default:
                return '';
        }
    };

    const sort = (sortedColumns: SortedColumn[]) => {
        const targets = JSON.parse(JSON.stringify(tempPurchaseOrders)) as JSObject[];

        targets.sort((a, b) => {
            for (let i = 0; i < sortedColumns.length; i++) {
                const flg1 = sortedColumns[i].isAscending ? 1 : -1;
                const flg2 = flg1 * (-1);
                // nullが最小の値としてソートしておく
                if (a[sortedColumns[i].column] === null && b[sortedColumns[i].column] === null) return 0;
                if (a[sortedColumns[i].column] === null && b[sortedColumns[i].column] !== null) return flg2;
                if (a[sortedColumns[i].column] !== null && b[sortedColumns[i].column] === null) return flg1;
                if (a[sortedColumns[i].column] > b[sortedColumns[i].column]) return flg1;
                if (a[sortedColumns[i].column] < b[sortedColumns[i].column]) return flg2;
            }
            return 0;
        });
        setTempPurchaseOrders([...targets]);
    };

    useEffect(() => {
        // ソートState変更後、一覧にソート状態を反映する
        if (sortedColumns.length !== 0) {
            sort(sortedColumns);
        }
        // ソート状態をローカルストレージに保存(textは不要なので削除)
        const newSorteColumns = sortedColumns.map((x) => {
            return {
                column: x.column,
                isAscending: x.isAscending,
            }
        });
        dispatch(PurchaseOrderHeaderSettingActions.setPurchaseOrderSortedColumns([...newSorteColumns]));
    }, [sortedColumns]);

    // UIへの参照
    const table = useRef<HTMLTableElement>(null);

    useEffect(() => {
        applyTableStyle();
    }, [tempPurchaseOrders]);

    // テーブル内の指定のカラムにスタイルを適用する
    const applyTableStyle = () => {
        Array.prototype.forEach.call(table.current?.getElementsByTagName("td"), item => {
            const clientHeight = item.clientHeight;
            if (clientHeight < CELL_HEIGHTS_FOR_ADD_PADDING) {
                item.style.paddingTop = '5px';
                item.style.paddingBottom = '5px';
            } else {
                item.style.paddingTop = '';
                item.style.paddingBottom = '';
            }

            // text-align: セルの判定
            if (item.getElementsByClassName("alignCenter").length > 0) {
                item.style.textAlign = 'center';
            } else if (item.getElementsByClassName("alignRight").length > 0) {
                item.style.textAlign = 'right';
            } else {
                item.style.textAlign = 'left';
            }
        });
    };

    // 通信欄回答時にリロード
    useEffect(() => {
        setTempPurchaseOrders(toJSArray(purchaseOrders.list.toArray()));

        // ソートされている場合、ソート条件を復元する
        if (sortedColumns.length !== 0) {
            setSortedColumns([...sortedColumns]);
        }
    }, [purchaseOrders]);

    // ビュー設定後、再度スタイルを適応
    useEffect(() => {
        applyTableStyle();
    }, [isShowViewSettingForm]);

    useEffect(() => {
        const newLocalHeaderVariant = headerVariant.map((x) => {
            return {
                isSelected: x.isSelected,
                label: getHeaderSettingLabelFromName(x.name, localHeaderVariantForLabel),
                name: x.name
            };
        });
        // ヘッダーの表示・非表示、並び順をローカルストレージに保存
        dispatch(PurchaseOrderHeaderSettingActions.setPurchaseOrderHeaderVariant([...newLocalHeaderVariant]));
    }, [headerVariant]);

    return (
        <>
            <BusyIndicator hidden={!isConnecting} />
            <LayoutPanel style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <LayoutPanel.Filters>
                    <LayoutFilter />
                </LayoutPanel.Filters>
                <LayoutPanel.Body style={{ flex: '1 1 auto', height: '0', padding: '0rem 0.5rem 1.5rem' }}>
                    <Container style={{ height: '100%' }}>
                        <div style={{ marginLeft: 'auto' }}>
                            <LargeIconButton onClick={onClickViewSettingButton} glyph='action-settings' option='transparent' style={{ textAlign: 'right' }} />
                        </div>
                        <Row style={{ height: '100%', margin: '0' }}>
                            <Column style={{ height: '100%', overflow: 'auto', padding: '0' }} span={12}>
                                <HeaderFreezingTable
                                    ref={table}
                                    compact
                                    condensed
                                    headers={getHeaders()}
                                    tableData={tempPurchaseOrders.map((item, index) => {
                                        return ({ rowData: getRowData(item, index) });
                                    })}
                                    tableBodyRowProps={(rowData: JSObject, i: number) => getRowProps(rowData, i)}
                                />
                            </Column>
                        </Row>
                    </Container>
                </LayoutPanel.Body>
                <LayoutPanel.Footer>
                    <div style={{ width: '100%' }}>
                        <div className='fd-bar__right'>
                            <div className='fd-bar__element' style={{ display: userType !== 'customer' ? 'none' : '' }} >
                                <Button option='emphasized' onClick={onClickAnswerMessageColumn} style={{ width: '96px' }}>{t('回答')}</Button>
                            </div>
                            <div className='fd-bar__element'>
                                <Button option='emphasized' onClick={onClickOutputCsv}>{t('CSV')}</Button>
                            </div>
                            <NavButtons screenMode={'purchaseList'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
            <ViewSettingForm
                isShowViewSettingForm={isShowViewSettingForm}
                setIsShowViewSettingForm={setIsShowViewSettingForm}
                headerVariant={headerVariant}
                setHeaderVariant={setHeaderVariant}
                sortedColumns={sortedColumns}
                setSortedColumns={setSortedColumns}
            />
        </>
    );
};

export default PurchaseOrderList;

const HeaderFreezingTable = styled(Table)`
    &&& thead tr th {
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 5;
        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            width: 100%;
            height: 100%;
            border-top: 1px solid #e4e4e4;
        }
    }
`;

const NoWrapSpan = styled.span`
    white-space: nowrap;
    text-align: right;

`;

const LargeIconButton = styled(Button)`
    ::before {
        width: 40px;
    }
    &&& i {
        font-size: 1.5rem;
        width: 25px;
    }
`;

const DivAlignItemsCenter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
