import actionCreateFactory from 'typescript-fsa';
import { SearchKeys, Orders } from '../models/Order';

const actionCreator = actionCreateFactory('Order');

export const OrderActions = {
    // 検索条件に基づき注文データを取得する。
    search: actionCreator<SearchKeys>('search'),
    // apiに接続中か否か
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    // 検索に成功したか否か
    setDidSearchSuccess: actionCreator<boolean | null>('setDidSearchSuccess'),
    // 検索により取得した注文データを保持する。
    setOrders: actionCreator<Orders>('setOrders'),
    // 検索キー
    setSearchKeys: actionCreator<SearchKeys>('setSearchKeys'),
}