import { List, Record } from 'immutable';
import { JSObject } from '../types';

export class Delivery extends Record<{
    salesOrder: string;             //CMEs受注伝票番号
    orderIdDetail: string;          //注文明細NO.
    orderId: string;                //注文番号
    customer: string;               //企業ID
    customerName: string;           //企業名称
    supplier: string;               //サプライヤID
    supplierName: string;           //サプライヤ名称
    outboundDeliveryNo: string;     //CMEs出荷伝票番号
    outboundDeliveryDetail: string; //CMEs出荷明細No.
    customerMaterial: string;       //品目コード
    customerMaterialName: string;   //品目名称
    material: string;               //CMEs品目コード
    materialName: string;           //CMEs品目名称
    orderDate: string;              //発注日
    desiredDeliveryDate: string;    //希望納期
    orderQuantity: number;          //発注数量
    deliveryQuantity: number;       //出荷数量
    deliveryQuantityUnit: string;   //単位
    actualDeliveryDate: string;     //実出庫日
    amountTotal: number;            //正味額
    pricePerOneUnit: number;        //正味価格
    currency: string;               //通貨コード
}>({
    salesOrder: '',
    orderIdDetail: '',
    orderId: '',
    customer: '',
    customerName: '',
    supplier: '',
    supplierName: '',
    outboundDeliveryNo: '',
    outboundDeliveryDetail: '',
    customerMaterial: '',
    customerMaterialName: '',
    material: '',
    materialName: '',
    orderDate: '',
    desiredDeliveryDate: '',
    orderQuantity: 0,
    deliveryQuantity: 0,
    deliveryQuantityUnit: '',
    actualDeliveryDate: '',
    amountTotal: 0,
    pricePerOneUnit: 0,
    currency: '',
}) {
    static fromResponse(response: JSObject) {
        const params = {
            ...response,
        };
        return new Delivery(params);
    }
};
export class Deliveries extends Record<{
    list: List<Delivery>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];
        const deliveries = List(params.map(delivery => Delivery.fromResponse(delivery)));
        return new Deliveries({ list: deliveries });
    }
};

/**
 * 出荷実績一覧検索条件のためのオブジェクト定義
 * */
export type SearchKeys = {
    orderId?: string | null;
    supplierId?: string | null;
    customerId?: string | null;
    customerMaterial?: string | null;
    actualDeliveryDateFrom?: Date;
    actualDeliveryDateTo?: Date;
    limitNumber: string;
};