import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { UserActions } from '../../../actions/user';
import { AuthInfo } from '../../../models/User';
import { State } from '../../../reducers';
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { getWord as w, getMessage as m } from '../../../messages';
import { MessageActions } from '../../../actions/message';
import { backLogin } from '../../colors';
import { Button, FormItem, FormLabel, FormInput, InputGroup, Icon } from 'fundamental-react';
import './modify.css';
import ChangePasswordDialog from './ChangePasswordDialog';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import Footer from '../../molecures/Footer';
require('dotenv').config({ debug: true });

const Login: React.FC = () => {
    const { t } = useTranslation();
    /**
     * 状態管理（ローカル）
     * */
    const [userId, changeUserId] = useState<string>('');
    const [password, changePassword] = useState<string>('');
    const [readyOk, setReadyOk] = useState<boolean>(true);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState<boolean>(false);

    const applicationVersion = process.env.REACT_APP_APP_VERSION !== undefined ? process.env.REACT_APP_APP_VERSION : '';

    /**
     * 状態管理（アプリ）
     * */
    const { mandt, isConnecting } = useSelector((state: State) => ({
        mandt: state.commonParam.mandt,
        isConnecting: state.user.isConnecting,
    }));

    useEffect(() => {
        document.body.style.background = backLogin;
    }, [])

    const dispatch = useDispatch();

    const handleLoginButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (!form.current?.checkValidity()) {
            if (userId === '') {
                dispatch(MessageActions.push({ content: m('項目 %s：必須入力項目に値が入力されていません。'), words: [w('ユーザーID')] }))
            } else if (password === '') {
                dispatch(MessageActions.push({ content: m('項目 %s：必須入力項目に値が入力されていません。'), words: [w('パスワード')] }))
            } else if (!userId.match(/^[0-9a-zA-Z]+$/g)) {
                dispatch(MessageActions.push({ content: m('項目 %s：入力した値の書式が不正です。'), words: [w('ユーザーID')] }))
            } else if (!password.match(/^[0-9a-zA-Z]+$/g)) {
                dispatch(MessageActions.push({ content: m('項目 %s：入力した値の書式が不正です。'), words: [w('パスワード')] }))
            }

            //form.current?.reportValidity();
        } else if (mandt === '') {
            dispatch(MessageActions.push({ content: m('会社コードが設定されていません。'), words: [] }));
        } else {
            const authInfo = new AuthInfo({ userId: userId, password: password, mandt: mandt });
            dispatch(UserActions.setAuthInfo(authInfo));
            dispatch(UserActions.login(authInfo));
        }
    };

    const form = useRef<HTMLFormElement>(null);

    const handleChangeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeUserId(event.target.value);
        if (form.current?.checkValidity()) {
            setReadyOk(true);
        }
    };

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        changePassword(event.target.value);
        if (form.current?.checkValidity()) {
            setReadyOk(true);
        }
    };

    const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
    const onChangeVisibility = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsVisiblePassword(!isVisiblePassword);
    }

    const changePasswordDialogProps = {
        initialUserId: userId,
        initialPassword: password,
        showDialog: showChangePasswordDialog,
        setShowDialog: setShowChangePasswordDialog,
    };

    return (
        <>
            <BusyIndicator hidden={!isConnecting} />
            <LoginForm ref={form}>
                <FormItem style={{ margin: '0.5rem 0' }}>
                    <FormLabel htmlFor='user_id_input'>{t('ユーザーID')}</FormLabel>
                    <FormInput
                        id='user_id_input'
                        type='text'
                        placeholder='ユーザーID'
                        required
                        maxLength={20}
                        pattern='^[0-9A-Za-z]+$'
                        onChange={handleChangeUserId}
                        style={{ zIndex: 0 }}
                    ></FormInput>
                </FormItem>
                <FormItem style={{ margin: '0.5rem 0' }}>
                    <FormLabel htmlFor='password_input'>{t('パスワード')}</FormLabel>
                    <InputGroup>
                        <FormInput
                            id='password_input'
                            type={isVisiblePassword ? 'text' : 'password'}
                            placeholder='パスワード'
                            required
                            maxLength={64}
                            pattern='^[0-9A-Za-z]+$'
                            onChange={handleChangePassword}
                            style={{ zIndex: 0 }}
                        ></FormInput>
                        <InputGroup.Addon isButton>
                            <Button
                                onClick={onChangeVisibility}
                                glyph={isVisiblePassword ? 'hide' : 'show'}
                                option='transparent'
                            />
                        </InputGroup.Addon>
                    </InputGroup>
                </FormItem>
                <FormItem style={{ margin: '0.5rem 0' }}>
                    <Button
                        style={{ width: '100%' }}
                        id='login_button'
                        onClick={handleLoginButton}
                        disabled={!readyOk || isConnecting}
                        option='emphasized'
                        typeAttr='submit'
                    >
                        {t('ログイン')}
                    </Button>
                </FormItem>
                <FormItem style={{ margin: '0.5rem 0' }}>
                    <Button
                        style={{ width: '100%' }}
                        id='change_password_button'
                        onClick={() => setShowChangePasswordDialog(true)}
                        disabled={!readyOk}
                        option='transparent'
                    >
                        {t('パスワード変更')}
                    </Button>
                </FormItem>
                <div>
                    本サービスを利用することで、
                    <a
                        style={{ whiteSpace: 'nowrap' }}
                        href={`${process.env.PUBLIC_URL}/サプライ屋.com利用規約_v1.0.pdf`}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        {t('利用規約')}<Icon ariaLabel='document' glyph='document' />
                    </a>
                    に同意するものとみなされます。
                </div>
                <div style={{ textAlign: 'right', visibility: applicationVersion === '' ? 'hidden' : 'visible' }}>
                    version {applicationVersion}
                </div>
            </LoginForm>
            <Footer/>
            <ChangePasswordDialog {...changePasswordDialogProps} />
        </>
    );
};

export default Login;

const LoginForm = styled.form`
    margin: auto;
    height: max-content;
    width: 20rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;
