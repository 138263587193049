import { Button, Checkbox, Dialog, List, Popover, Select, Tab, TabGroup } from 'fundamental-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HeaderVariant, SortedColumn, SelectOption } from '../../types';
import './selectListHeight.css';

type Props = {
    isShowViewSettingForm: boolean,
    setIsShowViewSettingForm: (isShowViewSettingForm: boolean) => void,
    headerVariant: HeaderVariant[],
    setHeaderVariant: (headerVariants: HeaderVariant[]) => void,
    sortedColumns: SortedColumn[],
    setSortedColumns: (sortedColumns: SortedColumn[]) => void,
}

// プルダウン表示用データに変換
const convertToOption = (headerVariant: any) => {
    return {
        key: headerVariant.name,
        text: headerVariant.label,
    }
}

// ソート用データに変換
const convertToSortColumn = (selectedOption: SelectOption) => {
    return {
        column: selectedOption.key,
        text: selectedOption.text,
        isAscending: true,
    }
}

const initialSortColumn = {
    column: '',
    isAscending: true,
}

const ViewSettingForm: React.FC<Props> = (props: Props) => {

    const { t } = useTranslation();

    // 項目並び替えの一時テーブル
    const initialHeaderState = JSON.parse(JSON.stringify(props.headerVariant));
    const [tempHeaderVariant, setTempHeaderVariant] = useState<HeaderVariant[]>([]);
    useEffect(() => {
        const newTempHeaderVariant = JSON.parse(JSON.stringify(props.headerVariant)) as HeaderVariant[];
        setTempHeaderVariant([...newTempHeaderVariant]);
    }, [props.headerVariant]);

    // ソート用一時テーブル
    const sortedHeaderVariant = JSON.parse(JSON.stringify(props.headerVariant)) as HeaderVariant[];
    // 'ja'をwindow.navigator.languagesに変えることでブラウザのlang,localeを取得できる。多言語対応時に使えるかもしれないのでメモ。
    sortedHeaderVariant.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
    const sortOptions = sortedHeaderVariant.map(convertToOption);
    const SORT_COLUMNS_MAX_LENGTH = sortOptions.length;
    const [tempSortColumns, setTempSortColumns] = useState<SortedColumn[]>([]);
    useEffect(() => {
        const sortedColumns = JSON.parse(JSON.stringify(props.sortedColumns)) as SortedColumn[] ?? [];
        // 全ての選択可能項目がsortedColumnsに入っている場合は、空の項目の追加を行う必要はない
        sortedColumns.length === SORT_COLUMNS_MAX_LENGTH ? setTempSortColumns([...sortedColumns]) : setTempSortColumns([...sortedColumns, initialSortColumn]);
    }, [props.sortedColumns]);

    // 項目を一括選択
    const onClickAllSelectButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        tempHeaderVariant.forEach(item => item.isSelected = true);
        setTempHeaderVariant([...tempHeaderVariant]);
    };

    // 項目の選択を一括解除
    const onClickAllUnselectButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        tempHeaderVariant.forEach(item => item.isSelected = false);
        setTempHeaderVariant([...tempHeaderVariant]);
    };

    // 選択した項目を一番上の行に移動
    const onClickTopButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget?.dataset.index);

        if (index === 0)
            return;

        tempHeaderVariant.unshift(tempHeaderVariant[index]);
        tempHeaderVariant.splice(index + 1, 1);

        setTempHeaderVariant([...tempHeaderVariant]);
    };

    // 選択した項目を1つ上の行に移動
    const onClickUpButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget?.dataset.index);

        if (index === 0)
            return;

        [tempHeaderVariant[index - 1], tempHeaderVariant[index]] = [tempHeaderVariant[index], tempHeaderVariant[index - 1]];

        setTempHeaderVariant([...tempHeaderVariant]);
    };

    // 選択した項目を1つ下の行に移動
    const onClickDownButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget?.dataset.index);

        if (index === tempHeaderVariant.length - 1)
            return;

        [tempHeaderVariant[index + 1], tempHeaderVariant[index]] = [tempHeaderVariant[index], tempHeaderVariant[index + 1]];

        setTempHeaderVariant([...tempHeaderVariant]);
    };

    // 選択した項目を一番下の行に移動
    const onClickBottomButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget?.dataset.index);

        if (index === tempHeaderVariant.length + 1)
            return;

        tempHeaderVariant.push(tempHeaderVariant[index]);
        tempHeaderVariant.splice(index, 1);

        setTempHeaderVariant([...tempHeaderVariant]);
    };

    // チェックボックスの状態変更
    const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.currentTarget.name;
        tempHeaderVariant.filter(x => x.name === name).forEach(x => x.isSelected = !x.isSelected);

        setTempHeaderVariant([...tempHeaderVariant]);
    };

    // 「OK」ボタン押下時の処理
    const onClickOkButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const newTempSortColumns = tempSortColumns.filter(x => x.column !== '');
        props.setHeaderVariant([...tempHeaderVariant]);
        props.setSortedColumns([...newTempSortColumns]);
        props.setIsShowViewSettingForm(false);
    };

    // 「キャンセル」ボタン押下時の処理
    const onClickCancelButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const sortedColumns = JSON.parse(JSON.stringify(props.sortedColumns)) as SortedColumn[] ?? [];
        setTempHeaderVariant([...initialHeaderState]);
        sortedColumns.length === SORT_COLUMNS_MAX_LENGTH ? setTempSortColumns([...sortedColumns]) : setTempSortColumns([...sortedColumns, initialSortColumn]);
        props.setIsShowViewSettingForm(false);
    };

    // ソート項目選択時の処理
    const onSelectedItem = ((event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>, selectedOption: SelectOption, selectedIndex: number) => {
        // 全ての選択可能項目がtempSortColumnsに入っている場合、項目の追加、入れ替えを行う必要がないため処理を抜ける
        if (tempSortColumns.filter(x => x.column !== '').length === SORT_COLUMNS_MAX_LENGTH) return;

        const sortedColumnsLastIndex = tempSortColumns.length - 1;
        const addSortColumn = convertToSortColumn(selectedOption);
        // 選択した項目のインデックスがtempSortColumnの最後のインデックスではない場合,すでに何かしらの項目が選択されている
        // ->tempSortColumnsの要素を選択した項目に変更
        if (selectedIndex !== sortedColumnsLastIndex) {
            tempSortColumns.splice(selectedIndex, 1, addSortColumn);
            setTempSortColumns([...tempSortColumns]);

        }
        // 選択した項目のインデックスがtempSortColumnの最後のインデックスである場合,何の項目も選択されていない
        // ->tempSortColumnsに選択した項目を追加
        else {
            const newTempSortColumns = tempSortColumns.filter(elm => elm.column !== "");

            if (newTempSortColumns.length !== SORT_COLUMNS_MAX_LENGTH - 1) {
                setTempSortColumns([...newTempSortColumns, addSortColumn, initialSortColumn]);
            } else {
                setTempSortColumns([...newTempSortColumns, addSortColumn]);
            }
        }
    });

    // 昇順、降順ボタンクリック時の処理
    const onClickSortOrder = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const column = event.currentTarget?.dataset.column;
        const isAscending = event.currentTarget?.dataset.isasc?.toLowerCase() === "true" ? true : false;

        tempSortColumns.filter(x => x.column === column).forEach(x => x.isAscending = isAscending);
        setTempSortColumns([...tempSortColumns]);
    };

    // 選択した項目を一番上の行に移動
    const onClickTopButtonSortColumn = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget?.dataset.index);

        if (index === 0)
            return;

        tempSortColumns.unshift(tempSortColumns[index]);
        tempSortColumns.splice(index + 1, 1);

        setTempSortColumns([...tempSortColumns]);
    };

    // 選択した項目を1つ上の行に移動
    const onClickUpButtonSortColumn = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget?.dataset.index);

        if (index === 0)
            return;

        [tempSortColumns[index - 1], tempSortColumns[index]] = [tempSortColumns[index], tempSortColumns[index - 1]];

        setTempSortColumns([...tempSortColumns]);
    };

    // 選択した項目を1つ下の行に移動
    // ブランク項目がある場合：項目の移動範囲にブランク項目を含めない(ブランク項目は常に最終行)
    // ブランク項目がない場合：項目を1つ下の行に移動
    const onClickDownButtonSortColumn = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget?.dataset.index);
        // tempSortColumnsの最後尾の要素を取得し、ブランクか判定
        const isLastOfArrayElementBlank = tempSortColumns.slice(-1)[0].column === '' ? true : false;
        // ブランク項目が存在する場合、ブランク項目の一つ上の行を最終行とする
        const indexOfLast = isLastOfArrayElementBlank === true ? tempSortColumns.length - 2 : tempSortColumns.length - 1;

        // 取得した行が既に一番下の場合
        if (index === indexOfLast)
            return;

        [tempSortColumns[index + 1], tempSortColumns[index]] = [tempSortColumns[index], tempSortColumns[index + 1]];

        setTempSortColumns([...tempSortColumns]);
    };

    // 選択した項目を一番下の行に移動
    // ブランク項目がある場合：項目の移動範囲にブランク項目を含めない(ブランク項目は常に最終行)
    // ブランク項目がない場合：項目を一番下の行に移動
    const onClickBottomButtonSortColumn = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const index = Number(event.currentTarget?.dataset.index);
        // tempSortColumnsの最終行の要素を取得し、ブランクか判定
        const isLastOfArrayElementBlank = tempSortColumns.slice(-1)[0].column === '' ? true : false;
        // ブランク項目が存在する場合、ブランク項目の一つ上の行を最終行とする
        const indexOfLast = isLastOfArrayElementBlank === true ? tempSortColumns.length - 2 : tempSortColumns.length - 1;
        const indexOfInsert = indexOfLast + 1;

        // 取得した行が既に一番下の場合
        if (index === indexOfLast)
            return;

        tempSortColumns.splice(indexOfInsert, 0, tempSortColumns[index]);
        tempSortColumns.splice(index, 1);

        setTempSortColumns([...tempSortColumns]);
    };

    // ソート項目のデリートボタンクリック時の処理
    // ブランク項目がある場合：選択行を削除する
    // ブランク項目がない場合：選択行を削除し、ブランク項目を最後尾に追加
    const onClickDeleteSortColumn = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const column = event.currentTarget?.dataset.column ?? "";
        // tempSortColumnsの最終行の要素を取得し、ブランクか判定
        const isLastOfArrayElementBlank = tempSortColumns.slice(-1)[0].column === '' ? true : false;

        const newTempSortColumns = tempSortColumns.filter(elm => elm.column !== column);
        if (!isLastOfArrayElementBlank) newTempSortColumns.push(initialSortColumn);

        setTempSortColumns([...newTempSortColumns]);
    };

    // ソート項目の選択肢を生成
    const createSelectableSortOptions = (sortOptions: SelectOption[], selectedkey: string) => {
        const duplicateOptionsKey = tempSortColumns.map(elm => { if (elm.column !== selectedkey) { return elm.column; } });
        const tempSortOptions = sortOptions.filter(elm => !duplicateOptionsKey.includes(elm.key));
        return tempSortOptions;
    };

    return (
        <>
            <CustomDialog
                actions={[
                    (<Button onClick={onClickOkButton} option='emphasized'>{t('OK')}</Button>),
                    (<Button onClick={onClickCancelButton} option='transparent'>{t('キャンセル')}</Button>)
                ]}
                title={t('ビュー設定')}
                show={props.isShowViewSettingForm}
            >
                <TabGroup>
                    <Tab title={t('列')}>
                        <div style={{ paddingLeft: '10px' }}>
                            <Button style={{ marginTop: '3px', marginRight: '3px', marginBottom: '3px' }} onClick={onClickAllSelectButton}>{t('全て選択')}</Button>
                            <Button onClick={onClickAllUnselectButton}>{t('全て解除')}</Button>
                        </div>
                        <div style={{ border: 'solid 1px gainsboro' }}>
                            <List style={{ height: '300px', overflowY: 'auto' }}>
                                {tempHeaderVariant.map((item, index) => (
                                    <List.Item>
                                        <Checkbox checked={item.isSelected} onChange={onChangeCheckBox} name={item.name} />
                                        <List.Text>{item.label}</List.Text>
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickTopButton} data-index={index} glyph='collapse-group' option='transparent' />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickUpButton} data-index={index} glyph='navigation-up-arrow' option='transparent' />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickDownButton} data-index={index} glyph='navigation-down-arrow' option='transparent' />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickBottomButton} data-index={index} glyph='expand-group' option='transparent' />
                                    </List.Item>
                                ))}
                            </List>
                        </div>
                    </Tab>
                    <Tab title={t('ソート')}>
                        <div style={{ border: 'solid 1px gainsboro' }}>
                            <CustomList>
                                {[...tempSortColumns].map((item, index) => (
                                    <List.Item key={index}>
                                        <Select listClassName='fd-list__fix_height' placeholder={t('ソート基準')} selectedKey={item.column} options={createSelectableSortOptions(sortOptions, item.column)} onSelect={(e, k) => onSelectedItem(e, k, index)} />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickSortOrder} data-isasc={true} data-column={item.column} glyph='sort-ascending' disabled={item.column === "" ? true : false} option={item.isAscending ? 'emphasized' : 'transparent'} />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickSortOrder} data-isasc={false} data-column={item.column} glyph='sort-descending' disabled={item.column === "" ? true : false} option={!item.isAscending ? 'emphasized' : 'transparent'} />
                                        <List.Text>{item.isAscending ? t('昇順') : t('降順')}</List.Text>
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickTopButtonSortColumn} data-index={index} glyph='collapse-group' disabled={item.column === "" ? true : false} option='transparent' />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickUpButtonSortColumn} data-index={index} glyph='navigation-up-arrow' disabled={item.column === "" ? true : false} option='transparent' />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickDownButtonSortColumn} data-index={index} glyph='navigation-down-arrow' disabled={item.column === "" ? true : false} option='transparent' />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickBottomButtonSortColumn} data-index={index} glyph='expand-group' disabled={item.column === "" ? true : false} option='transparent' />
                                        <Button className='fd-list__button' style={{ padding: '0px' }} onClick={onClickDeleteSortColumn} data-column={item.column} glyph='decline' disabled={item.column === "" ? true : false} option='transparent' />
                                    </List.Item>
                                ))}
                            </CustomList>
                        </div>
                    </Tab>
                </TabGroup>
            </CustomDialog>
        </>
    );
};

export default ViewSettingForm;

const CustomDialog = styled(Dialog)`
    z-index: 10;
    &&& > .fd-dialog__content {
        width: 550px;
    }
    &&& .fd-dialog__body:not(:last-child) {
        padding-top: 5px;
        padding-bottom: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }
    &&& footer {
        height: 55px;
    }
`;

const CustomList = styled(List)`
    height: 300px;
    overflowY: auto;
    &&& .fd-list__title {
        padding-left: 10px;
    }
    &&& .fd-select__control {
        width: 9rem;
    }
`;