import { push } from "connected-react-router";
import { put, select, takeLatest } from "redux-saga/effects";
import { MessageActions } from "../actions/message";
import { PurchaseOrderActions } from "../actions/purchaseOrder";
import { MaterialPriceApi } from "../apiClient/materialPrice";
import { PurchaseOrderApi } from "../apiClient/purchaseOrder";
import { getMessage as m } from '../messages';
import { MaterialPrices } from "../models/MaterialPrice";
import { PurchaseOrders } from "../models/PurchaseOrder";
import { State } from "../reducers";

function* searchPurchaseOrders(action: ReturnType<typeof PurchaseOrderActions.searchPurchaseOrders>) {

    yield put(PurchaseOrderActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield PurchaseOrderApi.searchPurchaseOrders(keys);

    if (response.isSuccess) {   
        if (response.data.length === 0 || response.data.purchaseOrders.length === 0) {       
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        } else {
            yield put(PurchaseOrderActions.setDidSearchSuccess(true));
            yield put(PurchaseOrderActions.setPurchaseOrders(PurchaseOrders.fromResponse(response.data.purchaseOrders)));
            yield put(PurchaseOrderActions.setCustomerName(response.data.customerName));
            yield put(push('/purchaseorder_list'));
        }
    } else {
        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(PurchaseOrderActions.setDidSearchSuccess(false));
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(PurchaseOrderActions.setIsConnecting(false));
}

function* searchGrossPrices(action: ReturnType<typeof PurchaseOrderActions.searchGrossPrices>) {
    const keys = action.payload;
    const response = yield MaterialPriceApi.searchGrossPrices(keys);

    if (response.isSuccess) {
        yield put(PurchaseOrderActions.setGrossPrices(MaterialPrices.fromResponse(response.data.value)));
    } else {
        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }
}

function* searchCustomerMaterial(action: ReturnType<typeof PurchaseOrderActions.searchCustomerMaterial>) {

    const keys = action.payload;
    const response = yield MaterialPriceApi.searchCustomerMaterial(keys);

    if (response.isSuccess) {
        yield put(PurchaseOrderActions.setCustomerMaterial(MaterialPrices.fromResponse(response.data)));
    } else {
        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }
}

function* uploadPurchaseOrders(action: ReturnType<typeof PurchaseOrderActions.uploadPurchaseOrders>) {
    yield put(PurchaseOrderActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield PurchaseOrderApi.uploadPurchaseOrders(keys);

    if (response.isSuccess) {
        yield put(PurchaseOrderActions.setUploadPurchaseOrdersResult(MaterialPrices.fromResponse(response.data.value)));
        yield put(PurchaseOrderActions.setDidUploadPurchaseOrders(true));
    } else {
        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                yield put(PurchaseOrderActions.setDidUploadPurchaseOrders(false));
                break;
        }
    }

    yield put(PurchaseOrderActions.setIsConnecting(false));
}

function* createPurchaseOrders(action: ReturnType<typeof PurchaseOrderActions.createPurchaseOrders>) {
    yield put(PurchaseOrderActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield PurchaseOrderApi.createPurchaseOrders(keys);

    if (response.isSuccess) {
        yield put(MessageActions.push({ content: m('登録しました。'), words: [] }));
        yield put(PurchaseOrderActions.setDidCreatePurchaseOrders(true));
    } else {
        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('登録に失敗しました。'), words: [] }));
                break;
        }
        yield put(PurchaseOrderActions.setDidCreatePurchaseOrders(false));
    }

    yield put(PurchaseOrderActions.setIsConnecting(false));
}

function* answerMessageColumns(action: ReturnType<typeof PurchaseOrderActions.answerMessageColumns>) {

    put(PurchaseOrderActions.setIsConnecting(true));
    

    const keys = action.payload;
    const response = yield PurchaseOrderApi.answerMessageColumns(keys);

    put(PurchaseOrderActions.setDidSuccessAnswerMessageColumn(false));

    if (response.isSuccess) {
        yield put(PurchaseOrderActions.setDidSuccessAnswerMessageColumn(true));
        yield put(MessageActions.push({ content: m('登録しました。'), words: [] }));
    } else {
        yield put(PurchaseOrderActions.setDidSuccessAnswerMessageColumn(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('更新に失敗しました。'), words: [] }));
                break;
        }
    }

    put(PurchaseOrderActions.setIsConnecting(false));
}

export function* PurchaseOrderSaga() {
    yield takeLatest(PurchaseOrderActions.searchPurchaseOrders, searchPurchaseOrders);
    yield takeLatest(PurchaseOrderActions.searchGrossPrices, searchGrossPrices);
    yield takeLatest(PurchaseOrderActions.searchCustomerMaterial, searchCustomerMaterial);
    yield takeLatest(PurchaseOrderActions.uploadPurchaseOrders, uploadPurchaseOrders);
    yield takeLatest(PurchaseOrderActions.createPurchaseOrders, createPurchaseOrders);
    yield takeLatest(PurchaseOrderActions.answerMessageColumns, answerMessageColumns);
}