import { resources as words } from './i18n';
import { Message } from './types';
import { getLocaleKey } from './utils/environments';

type Messages = { [key: string]: Message };
const ja: Messages = {};
ja['ログインに失敗しました。'] = { message: 'ログインに失敗しました。', type: 'E', mode: 1 };
ja['アカウントロック中です。%sまでお待ちください。'] = { message: 'アカウントロック中です。%sまでお待ちください。', type: 'E', mode: 1 };
ja['アカウントがロックされました。%sまでお待ちください。'] = { message: 'アカウントがロックされました。%sまでお待ちください。', type: 'E', mode: 1 };
ja['ログインに失敗しました。ログイン失敗%s回目。'] = { message: 'ログインに失敗しました。ログイン失敗%s回目。', type: 'E', mode: 1 };
ja['メニューに戻ります。よろしいですか？'] = { message: 'メニューに戻ります。よろしいですか？', type: 'W', mode: 1 };
ja['終了します。よろしいですか？'] = { message: '終了します。よろしいですか？', type: 'W', mode: 1 };
ja['権限がありません。'] = { message: '権限がありません。', type: 'E', mode: 1 };
ja['Admin権限がありません。'] = { message: 'Admin権限がありません。', type: 'E', mode: 1 };
ja['設定ファイル %sの値が設定されていません。'] = { message: '設定ファイル %sの値が設定されていません。', type: 'E', mode: 1 };
ja['項目 %s：必須入力項目に値が入力されていません。'] = { message: '項目 %s：必須入力項目に値が入力されていません。', type: 'E', mode: 1 };
ja['更新しました。'] = { message: '更新しました。', type: 'N', mode: 1 };
ja['更新に失敗しました。'] = { message: '更新に失敗しました。', type: 'E', mode: 1 };
ja['挿入に失敗しました。'] = { message: '挿入に失敗しました。', type: 'E', mode: 1 };
ja['DBに接続できませんでした。'] = { message: 'DBに接続できませんでした。', type: 'E', mode: 1 };
ja['ファイルの読み込みエラー'] = { message: 'ファイルの読み込みエラー', type: 'E', mode: 1 };
ja['検索結果が0件です。'] = { message: '検索結果が0件です。', type: 'N', mode: 1 };
ja['ファイルを選択してください。'] = { message: 'ファイルを選択してください。', type: 'E', mode: 1 };
ja['アップロードデータが0件です。'] = { message: 'アップロードデータが0件です。', type: 'E', mode: 1 };
ja['アップロードデータが50件を超えています。'] = { message: 'アップロードデータが50件を超えています。', type: 'E', mode: 1 };
ja['入力中のレコードが破棄されます。よろしいですか？'] = { message: '入力中のレコードが破棄されます。よろしいですか？', type: 'W', mode: 1 };
ja['登録するレコードがありません。'] = { message: '登録するレコードがありません。', type: 'E', mode: 1 };
ja['必須項目が未入力のレコードがあります。'] = { message: '必須項目が未入力のレコードがあります。', type: 'E', mode: 1 };
ja['品目が存在しません。'] = { message: '品目が存在しません。', type: 'E', mode: 1 };
ja['%sを入力してください。'] = { message: '%sを入力してください。', type: 'E', mode: 1 };
ja['%sは半角英数で入力してください。'] = { message: '%sは半角英数で入力してください。', type: 'E', mode: 1 };
ja['%sは数値で入力してください。'] = { message: '%sは数値で入力してください。', type: 'E', mode: 1 };
ja['%sは日付の形式(YYYY/MM/DD)で入力してください。'] = { message: '%sは日付の形式(YYYY/MM/DD)で入力してください。', type: 'E', mode: 1 };
ja['発注数を正しく入力してください。'] = { message: '発注数を正しく入力してください。', type: 'E', mode: 1 };
ja['発注数量は0より大きい値を入力してください。'] = { message: '発注数量は0より大きい値を入力してください。', type: 'E', mode: 1 };
ja['発注数量は10000000000より小さい値を入力してください。'] = { message: '発注数量は10000000000より小さい値を入力してください。', type: 'E', mode: 1 };
ja['希望納期は当日以降を入力してください。'] = { message: '希望納期は当日以降を入力してください。', type: 'E', mode: 1 };
ja['サプライヤIDが存在しません。'] = { message: 'サプライヤIDが存在しません。', type: 'E', mode: 1 };
ja['サプライヤIDが複数存在します。'] = { message: 'サプライヤIDが複数存在します。', type: 'E', mode: 1 };
ja['ファイルにエラーがあります。エラー内容を確認してください。'] = { message: 'ファイルにエラーがあります。エラー内容を確認してください。', type: 'E', mode: 1 };
ja['出力対象を選択してください。'] = { message: '出力対象を選択してください。', type: 'E', mode: 1 };
ja['ファイルを出力しました。¥nファイル名：%s'] = { message: 'ファイルを出力しました。¥nファイル名：% s', type: 'N', mode: 1 };
ja['CSVファイルの出力先を指定してください。'] = { message: 'CSVファイルの出力先を指定してください。', type: 'E', mode: 1 };
ja['ファイルを出力に失敗しました。¥nファイル名：%s'] = { message: 'ファイルを出力に失敗しました。¥nファイル名：% s', type: 'E', mode: 1 };
ja['削除対象を選択してください。'] = { message: '削除対象を選択してください。', type: 'E', mode: 1 };
ja['削除しますか？'] = { message: '削除しますか？', type: 'W', mode: 1 };
ja['削除しました。'] = { message: '削除しました。', type: 'N', mode: 1 };
ja['削除に失敗しました。'] = { message: '削除に失敗しました。', type: 'E', mode: 1 };
ja['項目 %s：有効な日付が指定されていません。'] = { message: '項目 %s：有効な日付が指定されていません。', type: 'E', mode: 1 };
ja['項目 %s：半角数字のみ入力可能です。'] = { message: '項目 %s：半角数字のみ入力可能です。', type: 'E', mode: 1 };
ja['項目 %s：半角英数字のみ入力可能です。'] = { message: '項目 %s：半角英数字のみ入力可能です。', type: 'E', mode: 1 };
ja['パスワードを上書きしますか？'] = { message: 'パスワードを上書きしますか？', type: 'W', mode: 1 };
ja['登録しました。ユーザーID：%s パスワード：%s'] = { message: '登録しました。ユーザーID：%s パスワード：%s', type: 'N', mode: 1 };
ja['登録しました。'] = { message: '登録しました。', type: 'N', mode: 1 };
ja['更新しました。'] = { message: '更新しました。', type: 'N', mode: 1 };
ja['勤務時間を入力してください。'] = { message: '勤務時間を入力してください。', type: 'E', mode: 1 };
ja['差立の重複が%s件あります。（管轄工程）登録してもよろしいですか？'] = { message: '差立の重複が%s件あります。（管轄工程）登録してもよろしいですか？', type: 'W', mode: 1 };
ja['差立の重複が%s件あります。（管轄工程以外）登録してもよろしいですか？'] = { message: '差立の重複が%s件あります。（管轄工程以外）登録してもよろしいですか？', type: 'W', mode: 1 };
ja['納期より後ろの日時に差立てられています。（管轄工程）登録してもよろしいですか？'] = { message: '納期より後ろの日時に差立てられています。（管轄工程）登録してもよろしいですか？', type: 'W', mode: 1 };
ja['納期より後ろの日時に差立てられています。（管轄工程以外）登録してもよろしいですか？'] = { message: '納期より後ろの日時に差立てられています。（管轄工程以外）登録してもよろしいですか？', type: 'W', mode: 1 };
ja['差立が完了していない指図があります。（管轄工程）登録してもよろしいですか？'] = { message: '差立が完了していない指図があります。（管轄工程）登録してもよろしいですか？', type: 'W', mode: 1 };
ja['差立が完了していない指図があります。（管轄工程以外）登録してもよろしいですか？'] = { message: '差立が完了していない指図があります。（管轄工程以外）登録してもよろしいですか？', type: 'W', mode: 1 };
ja['前工程の完了時刻と後工程の開始時刻が重複しています。'] = { message: '前工程の完了時刻と後工程の開始時刻が重複しています。', type: 'E', mode: 1 };
ja['確認No.が重複しています。'] = { message: '確認No.が重複しています。', type: 'E', mode: 1 };
ja['異なる工程名をまとめて登録することはできません。'] = { message: '異なる工程名をまとめて登録することはできません。', type: 'E', mode: 1 };
ja['完了時刻に開始時刻より早い時刻が入力されています。'] = { message: '完了時刻に開始時刻より早い時刻が入力されています。', type: 'E', mode: 1 };
ja['休憩時間の実績に計画より大きい数値が入力されています。'] = { message: '休憩時間の実績に計画より大きい数値が入力されています。', type: 'E', mode: 1 };
ja['複数指図の実績を同時に計上しようとしています。実績差異、備考欄等に記入漏れはないでしょうか？'] = { message: '複数指図の実績を同時に計上しようとしています。実績差異、備考欄等に記入漏れはないでしょうか？', type: 'W', mode: 1 };
ja['開始時刻を過ぎている指図があります。(管轄工程)'] = { message: '開始時刻を過ぎている指図があります。(管轄工程)', type: 'N', mode: 0 };
ja['開始時刻を過ぎている指図があります。(管轄工程以外)'] = { message: '開始時刻を過ぎている指図があります。(管轄工程以外)', type: 'N', mode: 0 };
ja['完了時刻を過ぎている指図があります。(管轄工程)'] = { message: '完了時刻を過ぎている指図があります。(管轄工程)', type: 'N', mode: 0 };
ja['完了時刻を過ぎている指図があります。(管轄工程以外)'] = { message: '完了時刻を過ぎている指図があります。(管轄工程以外)', type: 'N', mode: 0 };
ja['新規指図が登録されました。(管轄工程)'] = { message: '新規指図が登録されました。(管轄工程)', type: 'N', mode: 0 };
ja['新規指図が登録されました。(管轄工程以外)'] = { message: '新規指図が登録されました。(管轄工程以外)', type: 'N', mode: 0 };
ja['未承認の指図があります。(管轄工程)'] = { message: '未承認の指図があります。(管轄工程)', type: 'N', mode: 0 };
ja['未承認の指図があります。(管轄工程以外)'] = { message: '未承認の指図があります。(管轄工程以外)', type: 'N', mode: 0 };
ja['登録します。よろしいですか？'] = { message: '登録します。よろしいですか？', type: 'W', mode: 1 };
ja['前の画面に戻ります。よろしいですか？'] = { message: '前の画面に戻ります。よろしいですか？', type: 'W', mode: 1 };
ja['検索画面に戻ります。よろしいですか？'] = { message: '検索画面に戻ります。よろしいですか？', type: 'W', mode: 1 };
ja['該当する指図がありません。'] = { message: '該当する指図がありません。', type: 'E', mode: 1 };
ja['無効な値が入力されています。'] = { message: '無効な値が入力されています。', type: 'E', mode: 1 };
ja['キャンセルされた指図があります。(管轄工程)'] = { message: 'キャンセルされた指図があります。(管轄工程)', type: 'N', mode: 0 };
ja['キャンセルされた指図があります。(管轄工程以外)'] = { message: 'キャンセルされた指図があります。(管轄工程以外)', type: 'N', mode: 0 };
ja['CSVファイルを出力します。よろしいですか？'] = { message: 'CSVファイルを出力します。よろしいですか？', type: 'W', mode: 1 };
ja['差立前です。差立を先に行ってください。'] = { message: '差立前です。差立を先に行ってください。', type: 'E', mode: 1 };
ja['開始済です。'] = { message: '開始済です。', type: 'E', mode: 1 };
ja['一時停止できません。'] = { message: '一時停止できません。', type: 'E', mode: 1 };
ja['一時停止中ではありません。'] = { message: '一時停止中ではありません。', type: 'E', mode: 1 };
ja['製造中ではありません。'] = { message: '製造中ではありません。', type: 'E', mode: 1 };
ja['前工程の実績が登録されていません。製造を開始してもよろしいですか？'] = { message: '前工程の実績が登録されていません。製造を開始してもよろしいですか？', type: 'W', mode: 1 };
ja['会社コードが設定されていません。'] = { message: '会社コードが設定されていません。', type: 'E', mode: 1 };
ja['会社コードかクライアントIDが設定されていません。'] = { message: '会社コードかクライアントIDが設定されていません。', type: 'E', mode: 1 };
ja['サプライヤIDを変更すると明細は初期化されます。よろしいですか？'] = { message: 'サプライヤIDを変更すると明細は初期化されます。よろしいですか？', type: 'W', mode: 1 };

ja['項目 %s：入力した値の書式が不正です。'] = { message: '項目 %s：入力した値の書式が不正です。', type: 'E', mode: 1 };
ja['検索条件を一つ以上指定してください。'] = { message: '検索条件を一つ以上指定してください。', type: 'E', mode: 1 };
ja['作業内容を一つ以上指定してください。'] = { message: '作業内容を一つ以上指定してください。', type: 'E', mode: 1 };
ja['差立前です。差立を先に行ってください。'] = { message: '差立前です。差立を先に行ってください。', type: 'E', mode: 1 };
ja['すでに承認済です。'] = { message: 'すでに承認済です。', type: 'E', mode: 1 };
ja['すでに送信済です。'] = { message: 'すでに送信済です。', type: 'E', mode: 1 };
ja['キャンセルされた指図です。'] = { message: 'キャンセルされた指図です。', type: 'E', mode: 1 };
ja['一時停止中ではありません。'] = { message: '一時停止中ではありません。', type: 'E', mode: 1 };
ja['一時停止できません。'] = { message: '一時停止できません。', type: 'E', mode: 1 };
ja['製造中ではありません。'] = { message: '製造中ではありません。', type: 'E', mode: 1 };
ja['開始済です。'] = { message: '開始済です。', type: 'E', mode: 1 };
ja['異常な製造ステータスが返されました。'] = { message: '異常な製造ステータスが返されました。', type: 'E', mode: 1 };
ja['開始済でまとめ指図の場合は確認番号を複数入力しないでください。'] = { message: '開始済でまとめ指図の場合は確認番号を複数入力しないでください。', type: 'E', mode: 1 };
ja['変更を破棄しますか？'] = { message: '変更を破棄しますか？', type: 'W', mode: 1 };
ja['登録に失敗しました。'] = { message: '登録に失敗しました', type: 'E', mode: 1 };
ja['パスワードは6文字以上必要です。'] = { message: 'パスワードは6文字以上必要です。', type: 'E', mode: 1 };
ja['納期より後ろの日時に差立てられています。（管轄工程・管轄工程以外）登録してもよろしいですか？'] = { message: '納期より後ろの日時に差立てられています。（管轄工程・管轄工程以外）登録してもよろしいですか？', type: 'W', mode: 1 };
ja['前回操作から１時間以上経過したためタイムアウトしました。'] = { message: '前回操作から１時間以上経過したためタイムアウトしました。', type: 'E', mode: 1 };

ja['納品書は納期回答後に出力可能です。'] = { message: '納品書は納期回答後に出力可能です。', type: 'E', mode: 1 };
ja['発注企業は１つのみ選択してください。'] = { message: '発注企業は１つのみ選択してください。', type: 'E', mode: 1 };
ja['回答納期・数量、もしくは通信欄を入力してください。'] = { message: '回答納期・数量、もしくは通信欄を入力してください。', type: 'E', mode: 1 };
ja['通信欄を入力してください。'] = { message: '通信欄を入力してください。', type: 'E', mode: 1 };
ja['注文ID %s, 明細No. %s：回答納期・数量は両方入力してください。'] = { message: '注文ID %s, 明細No. %s：回答納期・数量は両方入力してください。', type: 'E', mode: 1 };
ja['注文ID %s, 明細No. %s：回答数量は数値を入力してください。'] = { message: '注文ID %s, 明細No. %s：回答数量は数値を入力してください。', type: 'E', mode: 1 };
ja['注文ID %s, 明細No. %s：通信欄は128文字以下で入力してください。'] = { message: '注文ID %s, 明細No. %s：通信欄は128文字以下で入力してください。', type: 'E', mode: 1 };
ja['支払番号 %s, リビジョン %s：ヘッダ通信欄は128文字以下で入力してください。'] = { message: '支払番号 %s, リビジョン %s：ヘッダ通信欄は128文字以下で入力してください。', type: 'E', mode: 1 };
ja['注文ID %s, 明細No. %s：明細通信欄は128文字以下で入力してください。'] = { message: '注文ID %s, 明細No. %s：明細通信欄は128文字以下で入力してください。', type: 'E', mode: 1 };
ja['通信欄回答します。よろしいですか？'] = { message: '通信欄回答します。よろしいですか？', type: 'W', mode: 1 };
ja['品目を選択してください。'] = { message: '品目を選択してください。', type: 'E', mode: 1 };
ja['数量を入力してください。'] = { message: '数量を入力してください。', type: 'E', mode: 1 };
ja['品目をカートに追加しました。発注登録画面を確認してください。'] = { message: '品目をカートに追加しました。発注登録画面を確認してください。', type: 'N', mode: 1 };

ja['ユーザーIDとパスワードが正しいか確認してください。'] = { message: 'ユーザーIDとパスワードが正しいか確認してください。', type: 'E', mode: 1 };
ja['パスワードが更新されました。'] = { message: 'パスワードが更新されました。', type: 'N', mode: 1 };
ja['新パスワードには現在と異なるパスワードを入力してください。'] = { message: '新パスワードには現在と異なるパスワードを入力してください。', type: 'E', mode: 1 };
ja['新パスワードと新パスワード(確認)が一致しているか確認してください。'] = { message: '新パスワードと新パスワード(確認)が一致しているか確認してください。', type: 'E', mode: 1 };

ja['企業IDを変更すると明細は初期化されます。よろしいですか？'] = { message: '企業IDを変更すると明細は初期化されます。よろしいですか？', type: 'W', mode: 1 };
ja['企業IDを入力してください。'] = { message: '企業IDを入力してください。', type: 'E', mode: 1 };
ja['サプライヤIDには自身のユーザーIDを設定してください。'] = { message: 'サプライヤIDには自身のユーザーIDを設定してください。', type: 'E', mode: 1 };

ja['認証コードが一致しません。'] = { message: '認証コードが一致しません。', type: 'E', mode: 1 };
ja['認証コードを入力してください。'] = { message: '認証コードを入力してください。', type: 'E', mode: 1 };
ja['通信に失敗しました。'] = { message: '通信に失敗しました。', type: 'E', mode: 1 };
ja['通信に失敗しました。「認証コードを再送する」を押下してください。'] = { message: '通信に失敗しました。「認証コードを再送する」を押下してください。', type: 'E', mode: 1 };

type MessagesDict = { [locale: string]: { [key: string]: Message } };
const messages: MessagesDict = {};
messages["ja"] = ja;

export const getWord = (key: string) => {
    //return words.ja.translation[key];
    const lang: string = getLocaleKey();
    switch (lang) {
        case 'ja':
            return words.ja.translation[key];
        default:
            return words.ja.translation[key];
    }
}

export default messages;

export const getMessage = (key: string) => {
    return messages[getLocaleKey()][key];
}