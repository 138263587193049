import { Button, FormGroup, FormInput, FormItem, FormLabel, Select } from 'fundamental-react';
import { Option } from 'fundamental-react/lib/Select/Select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MaterialPriceActions } from '../../../actions/materialPrice';
import { MessageActions } from '../../../actions/message';
import { getMessage as m, getWord as w } from '../../../messages';
import { SearchKeys } from '../../../models/MaterialPrice';
import { State } from '../../../reducers';

type Props = {
    asValueHelp: boolean,
    initialSearchKeys?: SearchKeys;
};

const MaterialPriceSearchForm: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const DEFAULT_LIMIT_NUMBER = '500';
    const MAX_LIMIT_NUMBER = '1000';

    const {
        customerDestinations,
        user,
        userType,
    } = useSelector((state: State) => ({
        customerDestinations: state.commonParam.customerDestinations,
        user: state.user.user,
        userType: state.user.userType,
    }));

    const [searchKeys, setSearchKeys] = useState<SearchKeys>({
        ...props.initialSearchKeys ?? {} as SearchKeys,
        limitNumber: DEFAULT_LIMIT_NUMBER,
    });

    useEffect(() => {
        setSearchKeys(searchKeys => {
        // ユーザー種別に応じて、検索条件にユーザーIDを設定する
            if (searchKeys.customer === null || searchKeys.customer === undefined) {
                searchKeys.customer = userType === 'customer' ? user.userId : undefined;
            }
            if (searchKeys.supplier === null || searchKeys.supplier === undefined) {
                searchKeys.supplier = userType === 'company' ? user.userId : undefined;
            }

            return { ...searchKeys }
        });
    }, [user, userType]);

    /**
     * 企業ID変更イベントハンドラ
     * @param event
     */
    const onChangeCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.customer = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * サプライヤID変更イベントハンドラ
     * @param event
     */
    const onSelectSupplier = (event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>, selectedOption: Option) => {
        searchKeys.supplier = selectedOption.key !== 'emptyOption' ? selectedOption.key : undefined;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 品目変更イベントハンドラ
     * @param event
     */
    const onChangeMaterial = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.material = [event.currentTarget.value];
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 品名変更イベントハンドラ
     * @param event
     */
    const onChangeMaterialName = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.materialName = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 最大該当数確定イベントハンドラ
     * @param event
     */
    const onBlurLimitNumber = (event: React.FocusEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        if (Number(newValue) < 1 || isNaN(Number(newValue))) {
            // 件数が0以下や形式エラーの場合、デフォルト値をセット
            searchKeys.limitNumber = DEFAULT_LIMIT_NUMBER;
        } else if (Number(newValue) > Number(MAX_LIMIT_NUMBER)) {
            // 件数が最大値オーバーの場合、最大値をセット
            searchKeys.limitNumber = MAX_LIMIT_NUMBER;
        } else {
            searchKeys.limitNumber = newValue;
        }

        setSearchKeys({ ...searchKeys });
    };

    /**
     * 最大該当数変更イベントハンドラ
     * @param event
     */
    const onChangeLimitNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        if (isNaN(Number(newValue))) {
            // 件数が形式エラーの場合、値を変更しない
            return;
        }

        searchKeys.limitNumber = newValue;
        setSearchKeys({ ...searchKeys });
    };

    /**
     * 検索ボタン押下イベントハンドラ
     * @param event
     */
    const onClickSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // 入力必須項目確認
        const invalidKeys: Array<string> = [];
        if ((searchKeys.customer ?? '').length === 0)
            invalidKeys.push(w('企業ID'));

        if (invalidKeys.length > 0) {
            const keys = Array.from(invalidKeys.values()).join(', ');
            dispatch(MessageActions.push({ content: m('項目 %s：必須入力項目に値が入力されていません。'), words: [keys] }));
            return;
        }

        dispatch(MaterialPriceActions.search(searchKeys));
    };

    return (
        <>
            <FormGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
                <PaddingFormItem>
                    <FormLabel required>{t('企業ID')}</FormLabel>
                    <FormInput
                        style={{ width: '8rem' }}
                        maxLength={10}
                        pattern='^[0-9A-Za-z]+$'
                        onChange={onChangeCustomer}
                        value={searchKeys.customer ?? ''}
                        disabled={userType === 'customer'}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('サプライヤID')}</FormLabel>
                    {userType === 'customer' &&
                        <MarginSelect
                        options={
                            customerDestinations.list.map((x, index) =>
                            ({
                                key: x.companyId,
                                text: x.companyId + '\t' + x.companyName
                            })).toArray()
                        }
                        includeEmptyOption
                        onSelect={onSelectSupplier}
                        selectedKey={searchKeys.supplier ?? ''}
                        disabled={props.asValueHelp}
                        />
                    }
                    {userType === 'company' &&
                        <FormInput
                            style={{ width: '15rem' }}
                            value={user.userId + '\t' + user.userNm}
                            disabled
                        />
                    }
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('品目')}</FormLabel>
                    <FormInput
                        value={searchKeys.material ? searchKeys.material[0] : ''}
                        style={{ width: '15rem' }}
                        onChange={onChangeMaterial}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel>{t('品名')}</FormLabel>
                    <FormInput
                        value={searchKeys.materialName ?? ''}
                        style={{ width: '15rem' }}
                        onChange={onChangeMaterialName}
                    />
                </PaddingFormItem>
                <PaddingFormItem>
                    <FormLabel required>{t('最大該当数')}</FormLabel>
                    <FormInput
                        value={searchKeys.limitNumber}
                        style={{ textAlign: 'right', width: '5rem' }}
                        onBlur={onBlurLimitNumber}
                        onChange={onChangeLimitNumber}
                    />
                </PaddingFormItem>
            </FormGroup>
            <div style={{ width: '100%' }}>
                <div className='fd-bar__right'>
                    <div className='fd-bar__element'>
                        <Button id='search_button' onClick={onClickSearch} option='emphasized' style={{ width: '96px' }}>{t('検索')}</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

const PaddingFormItem = styled(FormItem)`
    padding: 0 10px;
`;

const MarginSelect = styled(Select)`
    margin: 4px 0;
    width: 15rem;
`;

export default MaterialPriceSearchForm;
