import { List, Record } from 'immutable';
import { JSObject } from '../types';

export class PurchaseOrder extends Record<{
    salesOrder: string;
    receiptNo: string;
    orderIdDetail: string;
    orderId: string;
    customer: string;
    orderDateFrom: string;
    orderDateTo: string;
    amountTotal: string;
    confirmationStatus: string;
    status: string;
    supplier: string;
    supplierName: string;
    material: string;
    materialName: string;
    customerMaterial: string;
    customerMaterialName: string;
    orderDate: string;
    desiredDeliveryDate: string;
    changedDeliveryDate: string;
    orderQuantity: string;
    changedOrderQuantity: string;
    deliveryQuantity: string;
    remainQuantity: string;
    orderQuantityUnit: string;
    pricePerOneUnit: string;
    currency: string;
    orderedBy: string;
    inputMessageColumn: string;
    exMsgColumn: PurchaseOrderExMsgColumns | null;
    etag: string;
}>({
    salesOrder: '',
    receiptNo: '',
    orderIdDetail: '',
    orderId: '',
    customer: '',
    orderDateFrom: '',
    orderDateTo: '',
    amountTotal: '',
    confirmationStatus: '',
    status: '',
    supplier: '',
    supplierName: '',
    material: '',
    materialName: '',
    customerMaterial: '',
    customerMaterialName: '',
    orderDate: '',
    desiredDeliveryDate: '',
    changedDeliveryDate: '',
    orderQuantity: '',
    changedOrderQuantity: '',
    deliveryQuantity: '',
    remainQuantity: '',
    orderQuantityUnit: '',
    pricePerOneUnit: '',
    currency: '',
    orderedBy: '',
    inputMessageColumn: '',
    exMsgColumn: null,
    etag: '',
}) {
    static fromResponse(response: JSObject) {
        const params = { ...response };
        return new PurchaseOrder(params);
    }
}

export class PurchaseOrders extends Record<{
    list: List<PurchaseOrder>,
}>({
    list: List(),
}) {
    static fromResponse(response: Array<PurchaseOrder>) {
        const params = [...response];
        const customerDestinations = List(params.map(cd => PurchaseOrder.fromResponse(cd)));
        return new PurchaseOrders({ list: customerDestinations });
    }
}

export class PurchaseOrderExMsgColumn extends Record<{
    customer: string;
    salesOrder: string;
    salesOrderDetail: string;
    messageColumn: string;
}>({
    customer: '',
    salesOrder: '',
    salesOrderDetail: '',
    messageColumn: '',
}) {
    static fromResponse(response: JSObject) {
        const params = {
            ...response,
        }
        return new PurchaseOrderExMsgColumn(params);
    }
}

export class PurchaseOrderUploadRecord extends Record<{
    supplier: string,
    orderId: string,
    customerMaterial: string,
    desiredDeliveryDate: string,
    orderQuantity: string,
    messageColumn: string,
    result: string;
    message: string;
    customer: string;
}>({
    supplier: '',
    orderId: '',
    customerMaterial: '',
    desiredDeliveryDate: '',
    orderQuantity: '',
    messageColumn: '',
    result: '',
    message: '',
    customer: '',
}) {
    static fromResponse(response: JSObject) {
        const params = { ...response };
        return new PurchaseOrderUploadRecord(params);
    }
};

export class PurchaseOrderUploadRecords extends Record<{
    list: List<PurchaseOrderUploadRecord>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];

        const records = List(params.map((record: JSObject) => PurchaseOrderUploadRecord.fromResponse(record)));
        return new PurchaseOrderUploadRecords({ list: records });
    }
};

export class PurchaseOrderExMsgColumns extends Record<{
    list: List<PurchaseOrderExMsgColumn>;
}>({
    list: List(),
}) {
    static fromResponse(response: Array<JSObject>) {
        const params = [...response];
        const exMsgColumns = List(params.map(x => PurchaseOrderExMsgColumn.fromResponse(x)));
        return new PurchaseOrderExMsgColumns({ list: exMsgColumns });
    }
}

export type SearchKeys = {
    limitNumber: string,
    receiptNo: string,
    orderId: string,
    customerId: string,
    supplierId: string,
    customerMaterial: string,
    orderDateFrom: Date | null,
    orderDateTo: Date | null,
    desiredDeliveryDateFrom: Date | null,
    desiredDeliveryDateTo: Date | null,
    statusNotDelivered: boolean,
    statusDelivered: boolean,
    statusCanceled: boolean,
};

export type GrossPriceSearchKeys = {
    customer: string,
    supplier: string,
    customerMaterial: string,
    orderQuantity: string,
};

export type GrossPriceSearchMaterialUnit = {
    supplier: string,
    customerMaterial: string,
    orderQuantity: string,
};

export type AnswerMessageColumn = {
    salesOrder: string;
    orderIdDetail: string;
    orderId: string;
    customer: string;
    supplier: string;
    customerMaterial: string;
    desiredDeliveryDate: Date | null;
    orderQuantity: string;
    orderQuantityUnit: string;
    messageColumn: string;
    etag: string;
};

export type CreatePurchaseOrder = {
    orderIdDetail: string;
    orderId: string;
    customer: string;
    supplier: string;
    customerMaterial: string;
    desiredDeliveryDate: Date | null;
    orderQuantity: string;
    orderQuantityUnit: string;
    messageColumn: string;
};