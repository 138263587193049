import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { AxiosResponse } from '../types/responses/axios';
import { Cookies } from 'react-cookie';

export class ApiClient {
    axiosInstance: AxiosInstance;
    constructor(baseURL = '') {
        this.axiosInstance = axios.create({ baseURL });

        this.axiosInstance.interceptors.request.use(
            async (config: AxiosRequestConfig) => {

                const token = new Cookies().get('token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`
                }
                return config;
            },
            (err: AxiosError) => {
                return Promise.reject(err);
            },
        );
    }
    async get<T>(path: string, params = {}): Promise<AxiosResponse<T>> {
        try {
            const result = await this.axiosInstance.get(path, { params });
            return this.createSuccessPromise<T>(result.data);
        } catch (e) {
            if (e.response.status === 401) {
                const cookies = new Cookies();
                const refreshToken = cookies.get('refreshToken');
                if (refreshToken) {
                    try {
                        cookies.set('token', refreshToken);
                        const getNewToken = await this.axiosInstance.post('api/Token/Refresh', {});
                        cookies.set('token', getNewToken.data.access_token, { secure: true, sameSite: 'strict' });
                        cookies.set('refreshToken', getNewToken.data.refresh_token, { secure: true, sameSite: 'strict' });
                        const result2 = await this.axiosInstance.get(path, { params });
                        return this.createSuccessPromise<T>(result2.data);
                    } catch (error) {
                        if (error.response.status === 401) {
                            const oldToken = cookies.get('token').toString();
                            cookies.remove('token');
                            cookies.remove('refreshToken');
                            window.location.href = this.getTimeoutPath(oldToken);
                        }
                        return this.createFailurePromise<T>(error);
                    }
                } else {
                    return this.createFailurePromise<T>(e);
                }
            } else {
                return this.createFailurePromise<T>(e);
            }

        }
    }
    async post<T>(path: string, params = {}): Promise<AxiosResponse<T>> {
        try {
            const result = await this.axiosInstance.post<T>(path, params);
            return this.createSuccessPromise<T>(result.data);
        } catch (e) {
            if (e.response.status === 401) {
                const cookies = new Cookies();
                const refreshToken = cookies.get('refreshToken');
                if (refreshToken) {
                    try {
                        cookies.set('token', refreshToken, { secure: true, sameSite: 'strict' });
                        const getNewToken = await this.axiosInstance.post('api/Token/Refresh', {});
                        cookies.set('token', getNewToken.data.access_token, { secure: true, sameSite: 'strict' });
                        cookies.set('refreshToken', getNewToken.data.refresh_token, { secure: true, sameSite: 'strict' });
                        const result2 = await this.axiosInstance.post<T>(path, params);
                        return this.createSuccessPromise<T>(result2.data);
                    } catch (error) {
                        if (error.response.status === 401) {
                            const oldToken = cookies.get('token').toString();
                            cookies.remove('token');
                            cookies.remove('refreshToken');
                            window.location.href = this.getTimeoutPath(oldToken);
                        }
                        return this.createFailurePromise<T>(error);
                    }
                } else {
                    return this.createFailurePromise<T>(e);
                }
            } else {
                return this.createFailurePromise<T>(e);
            }
        }
    }
    async put<T>(path: string, params = {}): Promise<AxiosResponse<T>> {
        try {
            const result = await this.axiosInstance.put<T>(path, params);
            return this.createSuccessPromise<T>(result.data);
        } catch (e) {
            if (e.response.status === 401) {
                const cookies = new Cookies();
                const refreshToken = cookies.get('refreshToken');
                if (refreshToken) {
                    try {
                        cookies.set('token', refreshToken, { secure: true, sameSite: 'strict' });
                        const getNewToken = await this.axiosInstance.post('api/Token/Refresh', {});
                        cookies.set('token', getNewToken.data.access_token, { secure: true, sameSite: 'strict' });
                        cookies.set('refreshToken', getNewToken.data.refresh_token, { secure: true, sameSite: 'strict' });
                        const result2 = await this.axiosInstance.put<T>(path, params);
                        return this.createSuccessPromise<T>(result2.data);
                    } catch (error) {
                        if (error.response.status === 401) {
                            const oldToken = cookies.get('token').toString();
                            cookies.remove('token');
                            cookies.remove('refreshToken');
                            window.location.href = this.getTimeoutPath(oldToken);
                        }
                        return this.createFailurePromise<T>(error);
                    }
                } else {
                    return this.createFailurePromise<T>(e);
                }
            } else {
                return this.createFailurePromise<T>(e);
            }
        }
    }
    async delete<T>(path: string): Promise<AxiosResponse<T>> {
        try {
            const result = await this.axiosInstance.delete(path);
            return this.createSuccessPromise<T>(result.data);
        } catch (e) {
            if (e.response.status === 401) {
                const cookies = new Cookies();
                const refreshToken = cookies.get('refreshToken');
                if (refreshToken) {
                    try {
                        cookies.set('token', refreshToken, { secure: true, sameSite: 'strict' });
                        const getNewToken = await this.axiosInstance.post('api/Token/Refresh', {});
                        cookies.set('token', getNewToken.data.access_token, { secure: true, sameSite: 'strict' });
                        cookies.set('refreshToken', getNewToken.data.refresh_token, { secure: true, sameSite: 'strict' });
                        const result2 = await this.axiosInstance.delete(path);
                        return this.createSuccessPromise<T>(result2.data);
                    } catch (error) {
                        if (error.response.status === 401) {
                            const oldToken = cookies.get('token').toString();
                            cookies.remove('token');
                            cookies.remove('refreshToken');
                            window.location.href = this.getTimeoutPath(oldToken);
                        }
                        return this.createFailurePromise<T>(error);
                    }
                } else {
                    return this.createFailurePromise<T>(e);
                }
            } else {
                return this.createFailurePromise<T>(e);
            }
        }
    }
    async patch<T>(path: string, params = {}): Promise<AxiosResponse<T>> {
        try {
            const result = await this.axiosInstance.patch<T>(path, params);
            return this.createSuccessPromise<T>(result.data);
        } catch (e) {
            if (e.response.status === 401) {
                const cookies = new Cookies();
                const refreshToken = cookies.get('refreshToken');
                if (refreshToken) {
                    try {
                        cookies.set('token', refreshToken, { secure: true, sameSite: 'strict' });
                        const getNewToken = await this.axiosInstance.post('api/Token/Refresh', {});
                        cookies.set('token', getNewToken.data.access_token, { secure: true, sameSite: 'strict' });
                        cookies.set('refreshToken', getNewToken.data.refresh_token, { secure: true, sameSite: 'strict' });
                        const result2 = await this.axiosInstance.patch<T>(path, params);
                        return this.createSuccessPromise<T>(result2.data);
                    } catch (error) {
                        if (error.response.status === 401) {
                            const oldToken = cookies.get('token').toString();
                            cookies.remove('token');
                            cookies.remove('refreshToken');
                            window.location.href = this.getTimeoutPath(oldToken);
                        }
                        return this.createFailurePromise<T>(error);
                    }
                } else {
                    return this.createFailurePromise<T>(e);
                }
            } else {
                return this.createFailurePromise<T>(e);
            }
        }
    }
    private createSuccessPromise<T>(data: T): Promise<AxiosResponse<T>> {
        return Promise.resolve<AxiosResponse<T>>({ data, isSuccess: true });
    }
    private createFailurePromise<T>(error: AxiosError): Promise<AxiosResponse<T>> {
        return Promise.resolve<AxiosResponse<T>>({ error, isSuccess: false });
    }
    private getTimeoutPath(token: string | null): string {
        if (token) {
            const str = token.split('.')[1].replace(/-/g, '+').replace(/_/, '/');
            const decoded = JSON.parse(decodeURIComponent(escape(window.atob(str))));
            return window.location.origin + '/?timeout=true&mandt=' + decoded.Mandt;
        } else {
            return window.location.origin + '/?timeout=true';
        }
    }
}
