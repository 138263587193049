import { all, fork } from 'redux-saga/effects';

import { DeliveryNoteSaga } from './deliveryNote';
import { OrderSaga } from './order';
import { UserSaga } from './user';
import { AnswerDeliverySaga } from './answerDelivery';
import { PaymentSaga } from './payment';
import { PaymentDetailSaga } from './paymentDetail';
import { InformationMaintenanceSaga } from './informationMaintenance';
import { InformationSaga } from './information';
import { PurchaseOrderSaga } from './purchaseOrder';
import { CommonParamSaga } from './commonParam';
import { MaterialPriceSaga } from './materialPrice';
import { DeliverySaga } from './delivery';

export const rootSaga = function* root() {
    yield all([
        fork(DeliveryNoteSaga),
        fork(OrderSaga),
        fork(UserSaga),
        fork(AnswerDeliverySaga),
        fork(PaymentSaga),
        fork(PaymentDetailSaga),
        fork(InformationMaintenanceSaga),
        fork(InformationSaga),
        fork(MaterialPriceSaga),
        fork(PurchaseOrderSaga),
        fork(DeliverySaga),
        fork(CommonParamSaga),
    ]);
};
