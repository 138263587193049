import actionCreateFactory from 'typescript-fsa';
import { MaterialPrices, SearchKeys as MaterialPricesSearchKeys } from '../models/MaterialPrice';
import { AnswerMessageColumn, CreatePurchaseOrder, GrossPriceSearchKeys, PurchaseOrder, PurchaseOrders, PurchaseOrderUploadRecords, SearchKeys as PurchaseOrderSearchKeys } from '../models/PurchaseOrder';
import { JSObject } from '../types/Common';

const actionCreator = actionCreateFactory('PurchaseOrder');

export const PurchaseOrderActions = {
    // API�ڑ������ۂ�
    setIsConnecting: actionCreator<boolean>('setIsConnecting'),
    // ���������擾
    searchPurchaseOrders: actionCreator<PurchaseOrderSearchKeys>('searchPurchaseOrders'),
    // ��������ێ�
    setPurchaseOrders: actionCreator<PurchaseOrders>('setPurchaseOrders'),
    // �����o�^���(�J�[�g)��ێ�
    setRegisterPurchaseOrders: actionCreator<Array<PurchaseOrder>>('setRegisterPurchaseOrders'),
    // SAP�ɓo�^���锭�����
    createPurchaseOrders: actionCreator<Array<CreatePurchaseOrder>>('createPurchaseOrders'),
    // �����o�^���ʂ�ێ�
    setDidCreatePurchaseOrders: actionCreator<boolean | null>('setDidCreatePurchaseOrders'),
    // ���������̊�Ɩ��̂�ێ�
    setCustomerName: actionCreator<string>('setCustomerName'),
    // �i�ڂ̑��݁E�i�����擾
    searchCustomerMaterial: actionCreator<MaterialPricesSearchKeys>('searchCustomerMaterial'),
    // �i�ڂ̑��݁E�i����ێ�
    setCustomerMaterial: actionCreator<MaterialPrices>('setCustomerMaterial'),
    // �����z���擾
    searchGrossPrices: actionCreator<GrossPriceSearchKeys[]>('searchGrossPrices'),
    // �����z�ꗗ��ێ�
    setGrossPrices: actionCreator<MaterialPrices>('setGrossPrices'),
    // �����o�^�A�b�v���[�h
    uploadPurchaseOrders: actionCreator<JSObject>('uploadPurchaseOrders'),
    setUploadPurchaseOrders: actionCreator<PurchaseOrderUploadRecords>('setUploadPurchaseOrders'),
    setUploadPurchaseOrdersResult: actionCreator<MaterialPrices>('setUploadPurchaseOrdersResult'),
    setDidUploadPurchaseOrders: actionCreator<boolean | null>('setDidUploadPurchaseOrders'),
    // �ʐM����
    answerMessageColumns: actionCreator<AnswerMessageColumn[]>('answerMessageColumns'),
    // �ʐM���񓚐���������
    setDidSuccessAnswerMessageColumn: actionCreator<boolean | null>('setDidSuccessAnswerMessageColumn'),
    // ��������������
    setDidSearchSuccess: actionCreator<boolean | null>('setDidSearchSuccess'),
}