import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Counter } from 'fundamental-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { InformationActions } from '../../../actions/information';
import { MessageActions } from '../../../actions/message';
import { State } from '../../../reducers';
import { displayMessageBox } from '../../atoms/MessageBox';
import Information from '../Information';

const Header: React.FC = () => {

    const {
        isLogin,
        messageArray,
        notificationMessages,
        informationMessages,
        isConnectingNotificationMessages,
        isConnectingInformationMessages,
        mfaSuccess,
    } = useSelector((state: State) => ({
        isLogin: state.user.isLogin,
        messageArray: state.message.messageArray,
        notificationMessages: state.information.notificationMessages,
        informationMessages: state.information.informationMessages,
        isConnectingNotificationMessages: state.information.isConnectingNotificationMessages,
        isConnectingInformationMessages: state.information.isConnectingInformationMessages,
        mfaSuccess: state.user.user.mfaSuccess,
    }));

    const dispatch = useDispatch();

    // Drawer表示フラグ
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const toggleDrawer = () => setIsOpenDrawer((prevState) => !prevState);

    // ベルマーク押下時
    const onClickInformationButton = () => {
        setIsOpenDrawer((prevState) => !prevState);
        !isConnectingNotificationMessages && dispatch(InformationActions.getNotificationMessages());
        !isConnectingInformationMessages && dispatch(InformationActions.getInformationMessages());
    }

    useEffect(() => {
        const messageBoxHander = () => {
            dispatch(MessageActions.removeFirstPopup());
        }

        if (messageArray.length > 0) {
            const indexOfFirstPopup = messageArray.findIndex(item => item.content.mode === 1);

            if (indexOfFirstPopup >= 0) {
                displayMessageBox(messageArray[indexOfFirstPopup], messageBoxHander);
            }
        }
    }, [messageArray])

    return (
        <header>
            <Wrapper className="ng-white border-bottom box-shadow mb-3">
                <div
                    style={{ flex: 1, display: isLogin && mfaSuccess == "true" ? '' : 'none' }}
                >
                    <FontAwesomeIcon style={{ padding: '10px', fontSize: '3em' }} onClick={onClickInformationButton} icon={faBell} />
                    {(notificationMessages.list.count() + informationMessages.list.count()) > 0
                        && <Counter style={{ position: 'absolute', left: '50px' }}>{notificationMessages.list.count() + informationMessages.list.count()}</Counter>}
                </div>
                <div>
                    <Information isOpenDrawer={isOpenDrawer} toggleDrawer={toggleDrawer} />
                </div>
            </Wrapper>
        </header>
    );
}

const Wrapper = styled.div`
    background: gainsboro;
    height: 80px;
    padding: 20px;
    display: flex;
`;


export default Header;
