import actionCreateFactory from 'typescript-fsa';
import { SortedColumn, HeaderVariant } from '../types';

const actionCreator = actionCreateFactory('SalesOrderHeaderSetting');

export const SalesOrderHeaderSettingActions = {
    // 受注検索画面のヘッダーバリアントの保存
    setSalesOrderHeaderVariant: actionCreator<HeaderVariant[]>('setSalesOrderHeaderVariant'),
    // 受注検索画面のヘッダーのソート状態保存
    setSalesOrderSortedColumns: actionCreator<SortedColumn[]>('setSalesOrderSortedColumns'),
}