import { put, takeLatest } from 'redux-saga/effects';
import { MessageActions } from '../actions/message';
import { PaymentDetailActions } from '../actions/paymentDetail';
import { PaymentDetailApi } from '../apiClient/paymentDetail';
import { getMessage as m } from '../messages';
import { PaymentDetails } from '../models/PaymentDetail';
import { base64ToBlob } from '../utils/CommonFunction';

function* getPaymentDetails(action: ReturnType<typeof PaymentDetailActions.getPaymentDetails>) {
    yield put(PaymentDetailActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield PaymentDetailApi.getPaymentDetails(keys.orderCompany, keys.supplier, keys.paymentNo, keys.revision);

    if (response.isSuccess) {
        yield put(PaymentDetailActions.setDidGetSuccess(true));
        yield put(PaymentDetailActions.setPaymentDetails(PaymentDetails.fromResponse(response.data)));

        if (response.data.length > 0) {
            // 正常終了
        } else {
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        }
    } else {
        yield put(PaymentDetailActions.setDidGetSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(PaymentDetailActions.setIsConnecting(false));
};

function* getPaymentDetailsPdf(action: ReturnType<typeof PaymentDetailActions.getPaymentDetailsPdf>) {
    yield put(PaymentDetailActions.setIsConnecting(true));

    const keys = action.payload;
    const responsePdf = yield PaymentDetailApi.getPaymentDetailsPdf(keys.orderCompany, keys.supplier, keys.paymentNo, keys.revision);

    if (responsePdf.isSuccess) {
        yield put(PaymentDetailActions.setDidGetSuccessPdf(true));

        if (responsePdf.data.length > 0) {
            // 正常終了
            if (Array.isArray(responsePdf.data)) {
                const pdfs = responsePdf.data as Array<string>;
                for (let i = 0; i < pdfs.length; i++) {
                    const blob = base64ToBlob(pdfs[i]);
                    yield window.open(URL.createObjectURL(blob));
                }
            } else {
                const blob = base64ToBlob(responsePdf.data);
                yield window.open(URL.createObjectURL(blob));
            }
        } else {
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        }
    } else {
        yield put(PaymentDetailActions.setDidGetSuccessPdf(false));

        const statusCode = responsePdf.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(PaymentDetailActions.setIsConnecting(false));
}

export function* PaymentDetailSaga() {
    yield takeLatest(PaymentDetailActions.getPaymentDetails, getPaymentDetails);
    yield takeLatest(PaymentDetailActions.getPaymentDetailsPdf, getPaymentDetailsPdf);
};