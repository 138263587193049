import { put, takeLatest } from "redux-saga/effects";
import { MaterialPriceActions } from "../actions/materialPrice";
import { MessageActions } from "../actions/message";
import { MaterialPriceApi } from "../apiClient/materialPrice";
import { getMessage as m } from "../messages";
import { MaterialPrices, SuggestMaterials } from "../models/MaterialPrice";

function* search(action: ReturnType<typeof MaterialPriceActions.search>) {
    yield put(MaterialPriceActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield MaterialPriceApi.search(keys);

    if (response.isSuccess) {
        yield put(MaterialPriceActions.setDidSearchSuccess(true));
        yield put(MaterialPriceActions.setMaterialPrices(MaterialPrices.fromResponse(response.data)));

        if (response.data.length > 0) {

        } else {
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        }
    } else {
        yield put(MaterialPriceActions.setDidSearchSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(MaterialPriceActions.setIsConnecting(false));
};

function* searchSuggestMaterials(action: ReturnType<typeof MaterialPriceActions.searchSuggestMaterials>) {
    yield put(MaterialPriceActions.setIsLoadingSuggestMaterials(true));

    const keys = action.payload;
    const response = yield MaterialPriceApi.searchSuggestMaterials(keys);

    if (response.isSuccess) {
        yield put(MaterialPriceActions.setDidSearchSuggestMaterialSuccess(true));
        yield put(MaterialPriceActions.setSuggestMaterials(SuggestMaterials.fromResponse(response.data)));
    } else {
        yield put(MaterialPriceActions.setDidSearchSuggestMaterialSuccess(false));
        yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
    }

    yield put(MaterialPriceActions.setIsLoadingSuggestMaterials(false));
};

export function* MaterialPriceSaga() {
    yield takeLatest(MaterialPriceActions.search, search);
    yield takeLatest(MaterialPriceActions.searchSuggestMaterials, searchSuggestMaterials);
};