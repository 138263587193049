import { put, takeLatest } from 'redux-saga/effects';
import { InformationActions } from "../actions/information";
import { MessageActions } from '../actions/message';
import { InformationApi } from '../apiClient/information';
import { getMessage as m } from '../messages';
import { Informations } from '../models/Information';

function* getNotificationMessages(action: ReturnType<typeof InformationActions.getNotificationMessages>) {
    yield put(InformationActions.setIsConnectingNotificationMessages(true));

    const response = yield InformationApi.getNotificationMessages()

    if (response.isSuccess) {
        yield put(InformationActions.setNotificationMessages(Informations.fromResponse(response.data)));
    } else {
        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(InformationActions.setIsConnectingNotificationMessages(false));
}

function* getInformationMessages() {
    yield put(InformationActions.setIsConnectingInformationMessages(true));

    const response = yield InformationApi.getInformationMessages();
    if (response.isSuccess) {
        yield put(InformationActions.setInformationMessages(Informations.fromResponse(response.data)));
    } else {
        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(InformationActions.setIsConnectingInformationMessages(false));
}

export function* InformationSaga() {
    yield takeLatest(InformationActions.getNotificationMessages, getNotificationMessages);
    yield takeLatest(InformationActions.getInformationMessages, getInformationMessages);
}
