import { ApiClient } from '../apiClient';
import { JSObject } from '../types/Common';

require('dotenv').config();

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

export class MaterialPriceApi {
    static search(params: JSObject): Promise<unknown> {
        return apiClient.post('/api/materialPrice', params);
    };
    static searchGrossPrices(params: JSObject): Promise<unknown> {
        return apiClient.post('/api/materialPrice/searchGrossPrices', params);
    }
    static searchCustomerMaterial(params: JSObject): Promise<unknown> {
        return apiClient.post('/api/materialPrice/searchCustomerMaterial', params);
    }
    static searchSuggestMaterials(params: JSObject): Promise<unknown> {
        return apiClient.post('/api/materialPrice/searchSuggestMaterials', params);
    };
};
