import { ApiClient } from '../apiClient';
import { JSObject } from '../types/Common';
require('dotenv').config();

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

const LOGIN_PATH = '/api/Token';
const USER_PATH = '/api/User';
const MFA_PATH = '/api/Mfa'

export class UserApi {
    static login(params: JSObject): Promise<unknown> {
        return apiClient.post(LOGIN_PATH, params);
    }

    static retrieve(params: JSObject): Promise<unknown> {
        return apiClient.get(LOGIN_PATH + '/UserInfo', params);
    }

    static changePassword(params: JSObject): Promise<unknown> {
        return apiClient.put(USER_PATH + '/' + params.userId + '/password', params);
    }

    static removeSession(userId: string): Promise<unknown> {
        return apiClient.delete(LOGIN_PATH + '/session/' + userId);
    }

    static createOtp(params: JSObject): Promise<unknown> {
        return apiClient.post(MFA_PATH + '/createOtp', params);
    }

    static verifyMfaCode(params: JSObject): Promise<unknown> {
        return apiClient.post(MFA_PATH + '/verifyMfaCode', params);
    }

    static saveMfaDeviceInformation(): Promise<unknown> {
        return apiClient.post(MFA_PATH + '/saveMfaDeviceInformation/');
    }

    static verifyMfaDeviceInformation(uuid: string): Promise<unknown> {
        return apiClient.get(MFA_PATH + '/verifyMfaDeviceInformation/' + uuid);
    }
}
