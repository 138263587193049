import { List } from 'fundamental-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';
import { getWord as w } from '../../../messages';
import { JSObject } from '../../../types';
import { returnAmountText } from '../../../utils/CommonFunction';

type Props = {
    onSelectMaterial?: (material: string) => void,
    materialPrices: Array<JSObject>,
};

type ListItemProps = {
    deleteFlag: boolean,
};

const MaterialPriceList: React.FC<Props> = (props: Props) => {

    const { t } = useTranslation();

    /**
     * 単一品目選択イベントハンドラ
     * */
    const onClickMaterial = (customerMaterial: string) => {
        if (props.onSelectMaterial) {
            props.onSelectMaterial(customerMaterial);
        }
    };

    const Row = ({ index, style }: { index: number, style: any }) => {
        const materialPrice = props.materialPrices[index];

        /**
         * 品目行選択イベント
         * */
        const onClickList = () => {
            // 論理削除されている品目は選択できない
            if (materialPrice.deleteFlag)
                return;

            const customerMaterial = materialPrice.customerMaterial;
            onClickMaterial(customerMaterial);
        };

        return (
            <>
                <div style={style}>
                    <ListItem
                        deleteFlag={materialPrice.deleteFlag}
                        onClick={onClickList}
                    >
                        <List.Text>
                            <div>
                                <div style={{ color: materialPrice.deleteFlag ? 'darkgray' : '#32363a' }}>{w('品目')}:{materialPrice.customerMaterial}</div>
                                <div style={{ color: materialPrice.deleteFlag ? 'darkgray' : '#32363a' }}>{w('品名')}:{materialPrice.customerMaterialName}</div>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: `translate(-50%, -50%)`, display: materialPrice.deleteFlag ? '' : 'none', color: 'red', fontSize: '12px' }}>{t('この品目は削除されているため、新規発注することができません。')}</div>
                            </div>
                        </List.Text>
                        <List.Text secondary>
                            <div>
                                <div style={{ color: materialPrice.deleteFlag ? 'lightcoral' : 'red', fontWeight: 'bold' }}>
                                    {returnAmountText(materialPrice.pricePerOneUnit.toString())}{materialPrice.currency}/{materialPrice.salesUnit}
                                </div>
                            </div>
                        </List.Text>
                    </ListItem>
                </div>
            </>
        );
    };

    return (
        <>
            <AutoSizer>
                {({ height, width }) => (
                    <FixedSizeList
                        className='fd-list'
                        height={height}
                        itemCount={props.materialPrices.length}
                        itemSize={64}
                        width={width}
                    >
                        {Row}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </>
    );
};

const ListItem = styled(List.Item) <ListItemProps>`
    position: relative;
    height: 64px;
    background: ${({ deleteFlag }) => deleteFlag ? 'gainsboro' : ''};
`;

export default MaterialPriceList;
