import { LayoutPanel } from 'fundamental-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InformationMaintenanceActions } from '../../../actions/informationMaintenance';
import { MessageActions } from '../../../actions/message';
import { getMessage as m } from '../../../messages';
import { Message, SearchKeys } from '../../../models/Message';
import { State } from '../../../reducers';
import NavButtons from '../../NavButtons';
import EditForm from './EditForm';
import MessageList from './MessageList';
import SearchForm from './SearchForm';

export type FormStatus =
    'create' | 'edit' | null
    ;

export type FormDisabledStatus = {
    isDisabledChangeButton: boolean,
    isDisabledDeleteButton: boolean,
    isDisabledEditForm: boolean,
}

export const initialFormDisabledStatus: FormDisabledStatus = {
    isDisabledChangeButton: true,
    isDisabledDeleteButton: true,
    isDisabledEditForm: true,
};

const InformationMaintenance: React.FC = () => {

    const dispatch = useDispatch();

    const {
        messages,
        isConnecting,
        didSucceedCreate,
        didSucceedUpdate,
        didSucceedDelete,
    } = useSelector((state: State) => ({
        messages: state.informationMaintenance.messages,
        isConnecting: state.informationMaintenance.isConnecting,
        didSucceedCreate: state.informationMaintenance.didSucceedCreate,
        didSucceedUpdate: state.informationMaintenance.didSucceedUpdate,
        didSucceedDelete: state.informationMaintenance.didSucceedDelete,
    }));

    let initialSearchKeys: SearchKeys = {
        title: '',
        body: '',
        fromFromDate: null,
        fromToDate: null,
        toFromDate: null,
        toToDate: null,
    };

    // API成功時の画面初期化処理
    const setInitialStatus = () => {
        setSelectedRowIndex(null);
        setTempMessage(new Message());
        formDisabledStatus.isDisabledDeleteButton = true;
        formDisabledStatus.isDisabledChangeButton = true;
        formDisabledStatus.isDisabledEditForm = true;
        setFormDisabledStatus(formDisabledStatus);
        setFormStatus(null);
    };

    const [searchKeys, setSearchKeys] = useState<SearchKeys>({ ...initialSearchKeys });
    const [formStatus, setFormStatus] = useState<FormStatus>(null);
    const [formDisabledStatus, setFormDisabledStatus] = useState<FormDisabledStatus>(initialFormDisabledStatus);
    const [tempMessage, setTempMessage] = useState(new Message());
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

    const searchFormProps = {
        searchKeys,
        setSearchKeys,
        setInitialStatus,
    };

    const messageListProps = {
        messages,
        tempMessage,
        setTempMessage,
        formDisabledStatus,
        setFormDisabledStatus,
        setFormStatus,
        setSelectedRowIndex,
    };

    const editFormProps = {
        tempMessage,
        setTempMessage,
        formDisabledStatus,
        formStatus,
    };

    // TableRow選択時
    const formSelectedRow = () => {
        formDisabledStatus.isDisabledChangeButton = false;
        formDisabledStatus.isDisabledDeleteButton = true;
        formDisabledStatus.isDisabledEditForm = true;
        setFormDisabledStatus({ ...formDisabledStatus });
    };

    // 初期表示
    useEffect(() => {
        dispatch(InformationMaintenanceActions.searchMessage(searchKeys));
        setInitialStatus();
    }, []);

    useEffect(() => {
        if (selectedRowIndex === null)
            return;

        const temp = messages.list.toArray()[selectedRowIndex];
        setTempMessage(temp);

        formSelectedRow();

    }, [selectedRowIndex]);

    // 登録API成功時
    useEffect(() => {
        if (!isConnecting)
            return;

        if (didSucceedCreate) {
            dispatch(MessageActions.push({ content: m('登録しました。'), words: [] }));
            dispatch(InformationMaintenanceActions.searchMessage(searchKeys));
            setInitialStatus();
        }

        dispatch(InformationMaintenanceActions.setDidSucceedCreate(null));
    }, [didSucceedCreate]);

    // 更新API成功時
    useEffect(() => {
        if (!isConnecting)
            return;

        if (didSucceedUpdate) {
            dispatch(MessageActions.push({ content: m('更新しました。'), words: [] }));
            dispatch(InformationMaintenanceActions.searchMessage(searchKeys));
            setInitialStatus();
        }

        dispatch(InformationMaintenanceActions.setDidSucceedUpdate(null));
    }, [didSucceedUpdate]);

    // 削除API成功時
    useEffect(() => {
        if (!isConnecting)
            return;

        if (didSucceedDelete) {
            dispatch(MessageActions.push({ content: m('削除しました。'), words: [] }));
            dispatch(InformationMaintenanceActions.searchMessage(searchKeys));
            setInitialStatus();
        }

        dispatch(InformationMaintenanceActions.setDidSucceedDelete(null));
    }, [didSucceedDelete]);

    return (
        <LayoutPanel style={{ maxWidth: '1024px', margin: '0 auto' }}>
            <LayoutPanel.Header style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <NavButtons screenMode={'informationMaintenance'} />
            </LayoutPanel.Header>
            <LayoutPanel.Body>
                <LayoutPanel>
                    <LayoutPanel.Body>
                        <SearchForm {...searchFormProps} />
                    </LayoutPanel.Body>
                </LayoutPanel>
                <LayoutPanel style={{ marginTop: '10px' }}>
                    <LayoutPanel.Body>
                        <MessageList {...messageListProps} />
                    </LayoutPanel.Body>
                </LayoutPanel>
                <LayoutPanel style={{ marginTop: '10px' }}>
                    <LayoutPanel.Body>
                        <EditForm {...editFormProps} />
                    </LayoutPanel.Body>
                </LayoutPanel>
            </LayoutPanel.Body>
        </LayoutPanel>
    );
}

export default InformationMaintenance;