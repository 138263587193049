import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../reducers';
import { Cookies } from 'react-cookie';
import { getMessage as m } from '../messages';
import { MessageActions } from '../actions/message';
import { decodeJWT } from '../utils/decodeJWT';

type Props = {
    path: string;
    component: FC<any>;
    exact?: boolean;
    userType: Array<string>;
}

const Auth: React.FC<Props> = (props) => {
    const { isLogin } = useSelector((state: State) => ({
        isLogin: state.user.isLogin,
    }));

    const dispatch = useDispatch();
    const cookies = new Cookies();
    if (isLogin) {
        if (!cookies.get('token')) {
            dispatch(MessageActions.push({ content: m('通信に失敗しました。'), words: [] }));
            return <Redirect to='/login' />;
        }
        const claim = decodeJWT(cookies.get('token'));
        const claimUserType = claim.UserType;
        if (props?.userType) {
            const userType = props.userType.find(userTypeItem => userTypeItem === claimUserType);
            if (userType) {
                return <Route exact={props.exact} path={props.path} component={props.component} />;
            } else {
                return <Redirect to='/login' />;
            }
        } else {
            return <Redirect to='/login' />;
        }
    } else {
        if (cookies.get('token')) {
            const claim = decodeJWT(cookies.get('token'));
            const claimUserType = claim.UserType;
            const mfaSuccess = claim.MfaSuccess;

            //MFA済みの場合
            if (mfaSuccess === "true") {
                if (props?.userType) {
                    const userType = props.userType.find(userTypeItem => userTypeItem === claimUserType);
                    if (userType && props.path !== '/mfa') {
                        return <Route exact={props.exact} path={props.path} component={props.component} />;
                    } else {
                        return <Redirect to='/menu' />;
                    }
                } else {
                    return <Redirect to='/menu' />;
                }
            }
            //MFA未だでURLに/mfaを直打ちした場合
            else if (props.path === '/mfa') {
                return <Redirect to='/mfa' />;
            }
            //上記以外の場合
            else {
                return <Redirect to='/login' />;
            }
        } else {
            return <Redirect to='/login' />;
        }
    }
}

export default Auth;