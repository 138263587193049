import React from 'react';
import styled from 'styled-components';
import { DatePicker } from 'fundamental-react';
import { DatePickerParam } from '../../../types';

type Props = {
    index: number;
    compact: boolean;
    defaultValue: string;
    dateFormat: string;
    disabled: boolean;
    onChange?: (date: DatePickerParam, index: number) => void;
    onInputBlur?: (date: DatePickerParam, index: number) => void;
    locale: string;
    style?: any;
}

const InTableDatePicker: React.FC<Props> = (prop) => {
    const onChange = (date: DatePickerParam) => {
        prop.onChange && prop.onChange(date, prop.index);
    }
    const onInputBlur = (date: DatePickerParam) => {
        prop.onInputBlur && prop.onInputBlur(date, prop.index);
    }

    return (
        <StyledDatePicker
            compact={prop.compact}
            defaultValue={prop.defaultValue}
            dateFormat={prop.dateFormat}
            disabled={prop.disabled}
            onChange={onChange}
            onInputBlur={onInputBlur}
            locale={prop.locale}
            style={prop?.style}
        />
    );
}

const StyledDatePicker = styled(DatePicker)`
    &&& div div div div input {
        text-align: center;
    }
`;

export default InTableDatePicker;
