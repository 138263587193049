import actionCreateFactory from 'typescript-fsa';
import { Informations } from '../models/Information';

const actionCreator = actionCreateFactory('Information');

export const InformationActions = {

    // 対応必要
    // apiに接続中か否か
    setIsConnectingNotificationMessages: actionCreator<boolean>('setIsConnectingNotificationMessages'),
    // インフォ欄に表示するメッセージを取得
    getNotificationMessages: actionCreator('getNotificationMessages'),  
    // 取得したメッセージを保持
    setNotificationMessages: actionCreator<Informations>('setNotificationMessages'),

    // お知らせ
    // apiに接続中か否か
    setIsConnectingInformationMessages: actionCreator<boolean>('setIsConnectingInformationMessages'),
    // インフォ欄に表示するお知らせを取得
    getInformationMessages: actionCreator('getInformationMessages'),
    // 取得したメッセージを保持
    setInformationMessages: actionCreator<Informations>('setInformationMessages'),
}