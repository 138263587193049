import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record } from 'immutable';

import { AppActions } from '../actions/app';
import { Informations } from '../models/Information';

export class AppState extends Record<{
    headerInformation: Informations;
    selectedTabIndex: number;
}>({
    headerInformation: new Informations(),
    selectedTabIndex: 0,
}) { }

export const AppReducer = reducerWithInitialState(new AppState())
    .case(AppActions.setHeaderInformation, (state, payload) => {
        return state.set('headerInformation', payload);
    })
    .case(AppActions.setSelectedTabIndex, (state, payload) => {
        return state.set('selectedTabIndex', payload);
    })