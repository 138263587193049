import { Record, List } from 'immutable';
import { JSObject } from '../types';

export class Information extends Record<{
    title: string,
    content: string,
    dateTime: string,
}>({
    title: '',
    content: '',
    dateTime: ''
}) {
    static fromResponse(response: JSObject) {
        const params = { ...response };
        return new Information(params);
    }
}

export class Informations extends Record<{
    list: List<Information>
}>({
    list: List(),
}) {
    static fromResponse(response: Array<Information>) {
        const params = [...response];
        const informations = List(params.map((info) => Information.fromResponse(info)));
        return new Informations({ list: informations });
    }
}