import { put, takeLatest } from 'redux-saga/effects';
import { MessageActions } from '../actions/message';
import { PaymentActions } from '../actions/payment';
import { PaymentApi } from '../apiClient/payment';
import { getMessage as m } from '../messages';
import { Payment, Payments } from '../models/Payment';

function* getPayment(action: ReturnType<typeof PaymentActions.getPayment>) {
    yield put(PaymentActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield PaymentApi.getPayment(keys.orderCompany, keys.supplier, keys.paymentNo, keys.revision);

    if (response.isSuccess) {
        yield put(PaymentActions.setDidGetSuccess(true));

        if (response.data.length > 0) {
            // 正常終了
            //yield put(push('/salesorder_list'));
            yield put(PaymentActions.setPayment(Payment.fromResponse(response.data[0])));
        } else {
            yield put(PaymentActions.setPayment(new Payment()));
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        }
    } else {
        yield put(PaymentActions.setDidGetSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(PaymentActions.setIsConnecting(false));
};

function* search(action: ReturnType<typeof PaymentActions.search>) {
    yield put(PaymentActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield PaymentApi.search(keys);

    if (response.isSuccess) {
        yield put(PaymentActions.setDidSearchSuccess(true));
        yield put(PaymentActions.setPayments(Payments.fromResponse(response.data)));

        if (response.data.length > 0) {
            // 正常終了
            //yield put(push('/salesorder_list'));
        } else {
            yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
        }
    } else {
        yield put(PaymentActions.setDidSearchSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(PaymentActions.setIsConnecting(false));
};

function* updateApprove(action: ReturnType<typeof PaymentActions.updateApprove>) {
    yield put(PaymentActions.setIsConnecting(true));

    const keys = action.payload;
    const response = yield PaymentApi.updateApprove(keys);

    if (response.isSuccess) {
        yield put(PaymentActions.setDidApproveSuccess(true));

        yield put(MessageActions.push({ content: m('更新しました。'), words: [] }));
    } else {
        yield put(PaymentActions.setDidApproveSuccess(false));

        const statusCode = response.error.response.status;
        switch (statusCode) {
            default:
                yield put(MessageActions.push({ content: m('DBに接続できませんでした。'), words: [] }));
                break;
        }
    }

    yield put(PaymentActions.setIsConnecting(false));
};

export function* PaymentSaga() {
    yield takeLatest(PaymentActions.getPayment, getPayment);
    yield takeLatest(PaymentActions.search, search);
    yield takeLatest(PaymentActions.updateApprove, updateApprove);
};