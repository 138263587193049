import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record } from 'immutable';
import { AnswerDeliveryActions } from '../actions/answerDelivery';

export class AnswerDeliveryState extends Record<{
    // apiに接続中か否か
    isConnecting: boolean;
    // 検索に成功したか否か
    didUpdateSuccess: boolean | null;
}>({
    isConnecting: false,
    didUpdateSuccess: null,
}) { }

export const AnswerDeliveryReducer = reducerWithInitialState(new AnswerDeliveryState())
    .case(AnswerDeliveryActions.setDidUpdateSuccess, (state, payload) => {
        return state.set('didUpdateSuccess', payload);
    })
    .case(AnswerDeliveryActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    ;