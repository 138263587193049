import { JSObject } from "../types/Common";
import { ApiClient } from "../apiClient";
import { ODataService } from 'jinqu-odata';
import { Order } from "../models/Order";
require('dotenv').config();

const baseURL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : '';
const apiClient = new ApiClient(baseURL);

export class OrderApi {
    static search(params: JSObject): Promise<unknown> {
        return apiClient.get('/api/order', params);
    }
    static searchOdata(params: JSObject): PromiseLike<Order[]>{
        const service = new ODataService(baseURL + '/api');
        const orders = service.createQuery(Order)
            .where(o => o.supplier.includes('1'))
            .toArrayAsync();
        return orders;
    }
}