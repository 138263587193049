import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import NavButtons from '../../NavButtons';
import { backcolor0 } from '../../colors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrderActions } from '../../../actions/order';
import { SearchKeys } from '../../../models/Order';
import { LayoutPanel, Button, Container, Row, Column, FormInput, FormLabel, DatePicker, Checkbox } from 'fundamental-react';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import { State } from '../../../reducers';
import 'moment/locale/ja';
import { DatePickerParam } from '../../../types';
import { MessageActions } from '../../../actions/message';
import { getWord as w, getMessage as m } from '../../../messages';

type Props = {
    initialSearchKeys: SearchKeys;
}

const SalesOrderSearch: React.FC<Props> = ({ initialSearchKeys }) => {
    // 多言語対応
    const { t } = useTranslation();
    const dispatch = useDispatch();

    /**
     * 状態管理
     **/
    const {
        isConnecting,
        user,
        userType,
    } = useSelector((state: State) => ({
        isConnecting: state.order.isConnecting,
        user: state.user.user,
        userType: state.user.userType,
    }));

    useEffect(() => {
        document.body.style.background = backcolor0;

        if (userType === 'company') {
            // 発注元企業ログイン時、企業IDを自動入力
            searchKeys.orderCompany = user.userId;
        } else {
            // サプライヤログイン時、サプライヤIDを自動入力
            searchKeys.supplier = user.userId;
        }
        setSearchKeys({ ...searchKeys });
    }, [])

    // 検索キーを保持する
    const [searchKeys, setSearchKeys] = useState<SearchKeys>({ ...initialSearchKeys });

    /**
     * 検索フォーム内の変更時ハンドラ: 注文ID
     * @param event
     */
    const onChangeOrderNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.purchaseOrder = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: 発注元企業ID
     * @param event
     */
    const onChangeOrderCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.orderCompany = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: サプライヤ企業ID
     * @param event
     */
    const onChangeSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.supplier = event.currentTarget.value;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: 受注日(From)
     * @param date
     */
    const onInputBlurOrderDateFrom = (param: DatePickerParam) => {
        const strDate = param.isoFormattedDate;
        searchKeys.orderDateFrom = moment(strDate).isValid() ? new Date(strDate) : undefined;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: 受注日(To)
     * @param date
     */
    const onInputBlurOrderDateTo = (param: DatePickerParam) => {
        const strDate = param.isoFormattedDate;
        searchKeys.orderDateTo = moment(strDate).isValid() ? new Date(strDate) : undefined;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: ステータス：受注
     * @param event
     */
    const onChangeStatusOrdered = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.searchOrdered = event.currentTarget.checked;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: ステータス：納期回答済
     * @param event
     */
    const onChangeStatusAnswered = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.searchAnswered = event.currentTarget.checked;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: ステータス：納品書出力済
     * @param event
     */
    const onChangeStatusDeliveryNotePrinted = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.searchDeliveryNotePrinted = event.currentTarget.checked;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: ステータス：一部検収済
     * @param event
     */
    const onChangeStatusPartiallyAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.searchPartiallyAccepted = event.currentTarget.checked;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: ステータス：検収済
     * @param event
     */
    const onChangeStatusAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.searchAccepted = event.currentTarget.checked;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索フォーム内の変更時ハンドラ: ステータス：キャンセル
     * @param event
     */
    const onChangeStatusCanceled = (event: React.ChangeEvent<HTMLInputElement>) => {
        searchKeys.searchCanceled = event.currentTarget.checked;
        setSearchKeys({ ...searchKeys });
    }

    /**
     * 検索ボタンをクリックしたときのハンドラ
     * @param event
     */
    const onClickSearch = () => {

        // 発注元企業ログイン時、サプライヤ名の入力確認
        if (userType === 'company' && (searchKeys.supplier == undefined || searchKeys.supplier.length === 0)) {
            dispatch(MessageActions.push({ content: m('項目 %s：必須入力項目に値が入力されていません。'), words: [w('サプライヤID')] }));
            return;
        }

        dispatch(OrderActions.setSearchKeys(searchKeys));
        dispatch(OrderActions.search(searchKeys));
    }

    return (
        <>
            <BusyIndicator hidden={!isConnecting} />
            <LayoutPanel style={{ maxWidth: '1024px', margin: '0 auto' }}>
                <LayoutPanel.Body>
                    <Container>
                        <Row>
                            <MarginedColumn span={{ smallScreen: 12, mediumScreen: 3, largeScreen: 4, xLargeScreen: 4 }}>
                                <FormLabel htmlFor='orderNumber_search'>
                                    {t('注文ID')}:
                                </FormLabel>
                            </MarginedColumn>
                            <Column
                                span={{ smallScreen: 12, mediumScreen: 5, largeScreen: 4, xLargeScreen: 4 }}
                                offset={{ mediumScreen: 4 }}
                                offsetPosition='after'
                            >
                                <FormInput
                                    id='orderNumber_search'
                                    maxLength={13}
                                    pattern='^[0-9A-Za-z]+$'
                                    onChange={onChangeOrderNumber}
                                />
                            </Column>
                        </Row>
                        <Row>
                            <MarginedColumn span={{ smallScreen: 12, mediumScreen: 3, largeScreen: 4, xLargeScreen: 4 }}>
                                <FormLabel htmlFor='orderCompany_search'>
                                    {t('企業ID')}:
                                </FormLabel>
                            </MarginedColumn>
                            <Column
                                span={{ smallScreen: 12, mediumScreen: 5, largeScreen: 4, xLargeScreen: 4 }}
                                offset={{ mediumScreen: 4 }}
                                offsetPosition='after'
                            >
                                <FormInput
                                    id='orderCompany_search'
                                    maxLength={7}
                                    pattern='^[0-9A-Za-z]+$'
                                    onChange={onChangeOrderCompany}
                                    value={searchKeys.orderCompany === undefined ? '' : String(searchKeys.orderCompany)}
                                    disabled={userType === 'company' ? true : false}
                                />
                            </Column>
                        </Row>
                        <Row>
                            <MarginedColumn span={{ smallScreen: 12, mediumScreen: 3, largeScreen: 4, xLargeScreen: 4 }}>
                                <FormLabel htmlFor='supplier_search' required>
                                    {t('サプライヤID')}:
                                </FormLabel>
                            </MarginedColumn>
                            <Column
                                span={{ smallScreen: 12, mediumScreen: 5, largeScreen: 4, xLargeScreen: 4 }}
                                offset={{ mediumScreen: 4 }}
                                offsetPosition='after'
                            >
                                <FormInput
                                    id='supplier_search'
                                    maxLength={10}
                                    pattern='^[0-9A-Za-z]+$'
                                    onChange={onChangeSupplier}
                                    value={searchKeys.supplier === undefined ? '' : String(searchKeys.supplier)}
                                    disabled={userType === 'supplier' ? true : false}
                                />
                            </Column>
                        </Row>
                        <Row>
                            <MarginedColumn span={{ smallScreen: 12, mediumScreen: 3, largeScreen: 4, xLargeScreen: 4 }}>
                                <FormLabel htmlFor='orderDateFrom_search'>
                                    {t('受注日')}:
                                </FormLabel>
                            </MarginedColumn>
                            <Column span={{ smallScreen: 5, mediumScreen: 4, largeScreen: 3, xLargeScreen: 3 }}>
                                <CenteringDatePicker
                                    id='orderDateFrom_search'
                                    dateFormat='YYYY/MM/DD'
                                    onInputBlur={onInputBlurOrderDateFrom}
                                    locale='ja'
                                />
                            </Column>
                            <MarginedColumn style={{ textAlign: 'center' }} span={{ smallScreen: 2, mediumScreen: 1, largeScreen: 1, xLargeScreen: 1 }}>
                                <FormLabel htmlFor='orderDateTo_search'>
                                    {t('～')}
                                </FormLabel>
                            </MarginedColumn>
                            <Column
                                span={{ smallScreen: 5, mediumScreen: 4, largeScreen: 3, xLargeScreen: 3 }}
                                offset={{ largeScreen: 1 }}
                                offsetPosition='after'
                            >
                                <CenteringDatePicker
                                    id='orderDateTo_search'
                                    dateFormat='YYYY/MM/DD'
                                    onInputBlur={onInputBlurOrderDateTo}
                                    locale='ja'
                                />
                            </Column>
                        </Row>
                        <Row>
                            <Column span={{ smallScreen: 12, mediumScreen: 12, largeScreen: 4, xLargeScreen: 4 }}>
                                <FormLabel htmlFor='orderDateFrom_search'>
                                    {t('注文ステータス')}:
                                </FormLabel>
                            </Column>
                            <Column
                                span={{ smallScreen: 12, mediumScreen: 12, largeScreen: 6, xLargeScreen: 6 }}
                                offset={{ largeScreen: 2 }}
                                offsetPosition='after'
                            >
                                <Container noGap>
                                    <Row>
                                        <MarginedColumn span={4}>
                                            <FormLabel htmlFor='statusOrdered_search'>
                                                {t('受注')}
                                            </FormLabel>
                                        </MarginedColumn>
                                        <Column span={2}>
                                            <Checkbox
                                                ariaLabel='statusOrdered_search'
                                                onChange={onChangeStatusOrdered}
                                            />
                                        </Column>
                                        <MarginedColumn span={4}>
                                            <FormLabel htmlFor='statusAnswered_search'>
                                                {t('納期回答済')}
                                            </FormLabel>
                                        </MarginedColumn>
                                        <Column span={2}>
                                            <Checkbox
                                                ariaLabel='statusAnswered_search'
                                                onChange={onChangeStatusAnswered}
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <MarginedColumn span={4}>
                                            <FormLabel htmlFor='statusDeliveryNotePrinted_search'>
                                                {t('納品書出力済')}
                                            </FormLabel>
                                        </MarginedColumn>
                                        <Column span={2}>
                                            <Checkbox
                                                ariaLabel='statusDeliveryNotePrinted_search'
                                                onChange={onChangeStatusDeliveryNotePrinted}
                                            />
                                        </Column>
                                        <MarginedColumn span={4}>
                                            <FormLabel htmlFor='statusPartiallyAccepted_search'>
                                                {t('一部検収済')}
                                            </FormLabel>
                                        </MarginedColumn>
                                        <Column span={2}>
                                            <Checkbox
                                                ariaLabel='statusPartiallyAccepted_search'
                                                onChange={onChangeStatusPartiallyAccepted}
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <MarginedColumn span={4}>
                                            <FormLabel htmlFor='statusAccepted_search'>
                                                {t('検収済')}
                                            </FormLabel>
                                        </MarginedColumn>
                                        <Column span={2}>
                                            <Checkbox
                                                ariaLabel='statusAccepted_search'
                                                onChange={onChangeStatusAccepted}
                                            />
                                        </Column>
                                        <MarginedColumn span={4}>
                                            <FormLabel htmlFor='statusCanceled_search'>
                                                {t('キャンセル')}
                                            </FormLabel>
                                        </MarginedColumn>
                                        <Column span={2}>
                                            <Checkbox
                                                ariaLabel='statusCanceled_search'
                                                onChange={onChangeStatusCanceled}
                                            />
                                        </Column>
                                    </Row>
                                </Container>
                            </Column>
                        </Row>
                    </Container>
                </LayoutPanel.Body>
                <LayoutPanel.Footer>
                    <div style={{ width: '100%' }}>
                        <div className="fd-bar__right">
                            <div className="fd-bar__element">
                                <Button id='search_button' onClick={onClickSearch} option='emphasized'>{t('検索')}</Button>
                            </div>
                            <NavButtons screenMode={'salesorderSearch'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
        </>
    )
};

const CenteringDatePicker = styled(DatePicker)`
    &&& div div div div input {
        text-align: center;
    }
`;

const MarginedColumn = styled(Column)`
    margin: auto;
`;

export default SalesOrderSearch;