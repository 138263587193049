import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { PaymentDetailActions } from '../actions/paymentDetail';
import { PaymentDetails } from '../models/PaymentDetail';

export class PaymentDetailState extends Record<{
    // 検索により取得した支払明細データ
    paymentDetails: PaymentDetails;
    // apiに接続中か否か
    isConnecting: boolean;
    // 検索に成功したか否か
    didGetSuccess: boolean | null;
}>({
    paymentDetails: new PaymentDetails(),
    isConnecting: false,
    didGetSuccess: null,
}) { };

export const PaymentDetailReducer = reducerWithInitialState(new PaymentDetailState())
    .case(PaymentDetailActions.setDidGetSuccess, (state, payload) => {
        return state.set('didGetSuccess', payload);
    })
    .case(PaymentDetailActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(PaymentDetailActions.setPaymentDetails, (state, payload) => {
        return state.set('paymentDetails', payload);
    })