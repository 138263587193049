import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Layout } from './components/Layout';
import PaymentSearch from './components/pages/PaymentSearch';
import SalesOrderSearch from './components/pages/SalesOrderSearch';
import SalesOrderList from './components/pages/SalesOrderList';
import 'react-tabs/style/react-tabs.css';
import Login from './components/pages/Login';

import { useDispatch } from 'react-redux';
import { CommonParamActions } from './actions/commonParam';
import Auth from './containers/Auth';
import { Cookies } from 'react-cookie';
import { UserActions } from './actions/user';
import { decodeJWT } from './utils/decodeJWT';
import { MessageActions } from './actions/message';
import { getMessage as m } from './messages';
import MainMenu from './components/pages/MainMenu';
import PaymentDetail from './components/pages/PaymentDetail';
import InformationMaintenance from './components/pages/InformationMaintenance';
import MaterialPriceList from './components/pages/MaterialPriceList';
import PurchaseOrderCreate from './components/pages/PurchaseOrderCreate';
import PurchaseOrderList from './components/pages/PurchaseOrderList';
import DeliveryList from './components/pages/DeliveryList';
import Mfa from './components/pages/Mfa';

type Props = {
    queryParam: {
        mandt: string;
        timeout: boolean;
    };
    initialPathname: string;
}
const App: React.FC<Props> = (props) => {

    const dispatch = useDispatch();
    const cookies = new Cookies();

    const { company, supplier, customer, admin } = {
        company: '01',
        supplier: '02',
        customer: '03',
        admin: '99',
    };

    useEffect(() => {
        // ログインを経ないでアクセスする（トークンを保持している）場合
        if (cookies.get('token') != null && cookies.get('refreshToken') != null) {
            dispatch(UserActions.setIsLogin(true));
            dispatch(UserActions.retrieveUserInfo());

            const claim = decodeJWT(cookies.get('token'));
            const mandt = claim.Mandt;
            if (mandt === '') {
                dispatch(MessageActions.push({ content: m('会社コードが設定されていません。'), words: [] }));
            } else {
                dispatch(CommonParamActions.setMandt(mandt));
            }

            // ログイン画面を経由する場合
        } else if (props.queryParam.mandt !== '') {
            dispatch(CommonParamActions.setMandt(props.queryParam.mandt));
            if (props.queryParam.timeout) {
                dispatch(MessageActions.push({ content: m('前回操作から１時間以上経過したためタイムアウトしました。'), words: [] }));
            }
        } else if (props.queryParam.timeout) {
            dispatch(MessageActions.push({ content: m('前回操作から１時間以上経過したためタイムアウトしました。'), words: [] }));
        }
    }, [cookies, dispatch, props.queryParam.mandt, props.queryParam.timeout])
    return (
        <Layout>
            <Switch>
                <Route exact path='/login' component={Login} />
                <Route exact path='/timeout' component={Login} />
                <Auth exact={true} path='/mfa' component={Mfa} userType={[supplier, customer, company, admin]} />
                <Auth exact={true} path='/' component={MainMenu} userType={[supplier, customer, company, admin]} />
                <Auth exact={true} path='/menu' component={MainMenu} userType={[supplier, customer, company, admin]} />
                <Auth exact={true} path='/payment_search' component={PaymentSearch} userType={[supplier, company]} />
                <Auth exact={true} path='/payment_detail/:orderCompany/:supplier/:paymentNo/:revision' component={PaymentDetail} userType={[supplier, company]} />
                <Auth exact={true} path='/salesorder_search' component={SalesOrderSearch} userType={[supplier, company]} />
                <Auth exact={true} path='/salesorder_list' component={SalesOrderList} userType={[supplier, company]} />
                <Auth exact={true} path='/materialprice_list' component={MaterialPriceList} userType={[customer, company]} />
                <Auth exact={true} path='/information_maintenance' component={InformationMaintenance} userType={[admin]} />
                <Auth exact={true} path='/purchaseorder_list' component={PurchaseOrderList} userType={[company, customer]} />
                <Auth exact={true} path='/delivery_list' component={DeliveryList} userType={[company, customer]} />
                <Auth exact={true} path='/purchaseorder_create' component={PurchaseOrderCreate} userType={[customer, company]} />
            </Switch>
        </Layout>
    );
}

export default App;
