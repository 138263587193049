import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record } from 'immutable';
import { Deliveries, SearchKeys } from '../models/Delivery';
import { DeliveryActions } from '../actions/delivery';

export class DeliveryState extends Record<{
    // 検索により取得した出荷実績データ
    deliveries: Deliveries;
    // apiに接続中か否か
    isConnecting: boolean;
    // 検索に成功したか否か
    didSearchSuccess: boolean | null;
    // 検索キー
    searchKeys: SearchKeys | null;
}>({
    deliveries: new Deliveries(),
    isConnecting: false,
    didSearchSuccess: null,
    searchKeys: null,
}) { }

export const DeliveryReducer = reducerWithInitialState(new DeliveryState())
    .case(DeliveryActions.setDidSearchSuccess, (state, payload) => {
        return state.set('didSearchSuccess', payload);
    })
    .case(DeliveryActions.setIsConnecting, (state, payload) => {
        return state.set('isConnecting', payload);
    })
    .case(DeliveryActions.setDeliveries, (state, payload) => {
        return state.set('deliveries', payload);
    })
    .case(DeliveryActions.setSearchKeys, (state, payload) => {
        return state.set('searchKeys', payload);
    });