import actionCreatorFactory from 'typescript-fsa';
import { AuthInfo, User, ChangePasswordInfo, MfaCode } from '../models/User';
import { UserType } from '../types';

const actionCreator = actionCreatorFactory('User');

export const UserActions = {
    /* ログインボタンが押されたとき　*/
    login: actionCreator<AuthInfo>('login'),
    /* ログインが成功したとき */
    setIsLogin: actionCreator<boolean>('setIsLogin'),
    /* ログインボタンが押されたとき、フォームの入力値（’ログインIDとパスワード）を格納 */
    setAuthInfo: actionCreator<AuthInfo>('setAuthInfo'),
    /* ログイン成功時にapiから受け取ったユーザ情報をストアに格納 */
    setUser: actionCreator<User>('setUser'),
    setToken: actionCreator<string>('setToken'),

    setIsConnecting: actionCreator<boolean>('setIsConnecting'),

    // ログインする際に使用、1: 企業ユーザ、2: サプライヤユーザ
    setUserType: actionCreator<UserType>('setUserType'),

    retrieveUserInfo: actionCreator('retrieveUserInfo'),

    changePassword: actionCreator<ChangePasswordInfo>('changePassword'),
    setIsPasswordChanged: actionCreator<boolean>('setIsPasswordChanged'),

    removeSession: actionCreator<string>('removeSession'),

    // 認証コード再送ボタンが押されたとき
    resendMfaCode: actionCreator<AuthInfo>('sendMail'),

    // MFAコード送信ボタンが押されたとき
    verifyMfaCode: actionCreator<MfaCode>('verifyMfaCode'),
};
