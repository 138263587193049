import { Record } from 'immutable';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { InformationActions } from '../actions/information';
import { Informations } from '../models/Information';


export class InformationState extends Record<{
    notificationMessages: Informations;
    informationMessages: Informations;
    isConnectingNotificationMessages: boolean;
    isConnectingInformationMessages: boolean;
}>({
    notificationMessages: new Informations(),
    informationMessages: new Informations(),
    isConnectingNotificationMessages: false,
    isConnectingInformationMessages: false,
}) { }

export const InformationReducer = reducerWithInitialState(new InformationState())
    .case(InformationActions.setNotificationMessages, (state, payload) => {
        return state.set('notificationMessages', payload);
    })
    .case(InformationActions.setInformationMessages, (state, payload) => {
        return state.set('informationMessages', payload);
    })
    .case(InformationActions.setIsConnectingNotificationMessages, (state, payload) => {
        return state.set('isConnectingNotificationMessages', payload);
    })
    .case(InformationActions.setIsConnectingInformationMessages, (state, payload) => {
        return state.set('isConnectingInformationMessages', payload);
    })
    ;