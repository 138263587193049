import { put, takeLatest } from 'redux-saga/effects';
import { InformationMaintenanceActions } from '../actions/informationMaintenance';
import { MessageActions } from '../actions/message';
import { InformationMaintenanceApi } from '../apiClient/informationMaintenance';
import { getMessage as m } from '../messages';
import { Messages } from '../models/Message';

function* search(action: ReturnType<typeof InformationMaintenanceActions.searchMessage>) {
    yield put(InformationMaintenanceActions.setIsConnecting(true));

    const response = yield InformationMaintenanceApi.search(action.payload);
    if (response.isSuccess) {
        yield put(InformationMaintenanceActions.setMessages(Messages.fromResponse(response.data)));
        yield put(InformationMaintenanceActions.setDidSucceedSearch(true));
    } else {
        yield put(InformationMaintenanceActions.setDidSucceedSearch(false));
        const statusCode = response.error.response.status;
        switch (statusCode) {
            case 404:
                yield put(MessageActions.push({ content: m('検索結果が0件です。'), words: [] }));
                yield put(InformationMaintenanceActions.setMessages(new Messages()));
                break;
            default:
                yield put(MessageActions.push({ content: m('通信に失敗しました。'), words: [] }));
                break;
        }
    }

    yield put(InformationMaintenanceActions.setIsConnecting(false));
}

function* create(action: ReturnType<typeof InformationMaintenanceActions.createMessage>) {
    yield put(InformationMaintenanceActions.setIsConnecting(true));

    const response = yield InformationMaintenanceApi.create(action.payload);
    if (response.isSuccess) {
        yield put(InformationMaintenanceActions.setDidSucceedCreate(true));
    } else {
        yield put(InformationMaintenanceActions.setDidSucceedCreate(false));
        yield put(MessageActions.push({ content: m('登録に失敗しました。'), words: [] }));
    }

    yield put(InformationMaintenanceActions.setIsConnecting(false));
}

function* remove(action: ReturnType<typeof InformationMaintenanceActions.deleteMessage>) {
    yield put(InformationMaintenanceActions.setIsConnecting(true));

    const response = yield InformationMaintenanceApi.delete(action.payload);
    if (response.isSuccess) {
        yield put(InformationMaintenanceActions.setDidSucceedDelete(true));
    } else {
        yield put(InformationMaintenanceActions.setDidSucceedDelete(false));
        yield put(MessageActions.push({ content: m('削除に失敗しました。'), words: [] }));
    }

    yield put(InformationMaintenanceActions.setIsConnecting(false));
}

function* update(action: ReturnType<typeof InformationMaintenanceActions.updateMessage>) {
    yield put(InformationMaintenanceActions.setIsConnecting(true));

    const response = yield InformationMaintenanceApi.update(action.payload);
    if (response.isSuccess) {
        yield put(InformationMaintenanceActions.setDidSucceedUpdate(true));
    } else {
        yield put(InformationMaintenanceActions.setDidSucceedUpdate(false));
        yield put(MessageActions.push({ content: m('更新に失敗しました。'), words: [] }));
    }

    yield put(InformationMaintenanceActions.setIsConnecting(false));
}

export function* InformationMaintenanceSaga() {
    yield takeLatest(InformationMaintenanceActions.searchMessage, search);
    yield takeLatest(InformationMaintenanceActions.createMessage, create);
    yield takeLatest(InformationMaintenanceActions.deleteMessage, remove);
    yield takeLatest(InformationMaintenanceActions.updateMessage, update);
}