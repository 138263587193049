import { Button, Checkbox, Column, Container, FormInput, FormTextarea, LayoutPanel, Row, Table } from 'fundamental-react';
import { List } from 'immutable';
import moment from 'moment';
import 'moment/locale/ja';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AnswerDeliveryActions } from '../../../actions/answerDelivery';
import { DeliveryNoteActions } from '../../../actions/deliveryNote';
import { MessageActions } from '../../../actions/message';
import { OrderActions } from '../../../actions/order';
import { history } from '../../../configureStore';
import { getMessage as m } from '../../../messages';
import { AnswerDelivery } from '../../../models/AnswerDelivery';
import { SearchKeys } from '../../../models/DeliveryNote';
import { exMsgColumn, Order, Orders, result } from '../../../models/Order';
import { State } from '../../../reducers';
import { DatePickerParam, SortedColumn, HeaderVariant } from '../../../types';
import { JSObject } from '../../../types/Common';
import { getHeaderSettingLabelFromName, returnAmountText, returnQuantityText } from '../../../utils/CommonFunction';
import { BusyIndicator } from '../../atoms/BusyIndicator';
import { confirmBox } from '../../atoms/Confirm';
import ViewSettingForm from '../../atoms/ViewSettingForm';
import NavButtons from '../../NavButtons';
import InTableDatePicker from './InTableDatePicker';
import { SalesOrderHeaderSettingActions } from '../../../actions/salesOrderHeaderSetting';
//import './modify.css';

interface Props {
    items: Array<Order>
}

const SalesOrderList: React.FC<Props> = (props) => {
    // 多言語対応
    const { t } = useTranslation();
    const dispatch = useDispatch();

    /**
     * 状態管理
     **/
    const {
        orders,
        deliveryNotes,
        isConnectingAnswerDelivery,
        isConnectingDeliveryNote,
        isConnectingOrder, userType,
        salesOrderHeaderSetting,
    } = useSelector((state: State) => ({
        orders: state.order.orders,
        deliveryNotes: state.deliveryNote.deliveryNotes,
        isConnectingAnswerDelivery: state.answerDelivery.isConnecting,
        isConnectingDeliveryNote: state.deliveryNote.isConnecting,
        isConnectingOrder: state.order.isConnecting,
        userType: state.user.userType,
        salesOrderHeaderSetting: state.salesOrderHeaderSetting.salesOrderHeaderSetting,
    }));

    const [checkedItems, setCheckedItems] = useState<{ [orderKey: string]: boolean }>({});

    // ビュー設定画面表示フラグ
    const [isShowViewSettingForm, setIsShowViewSettingForm] = useState<boolean>(false);
    // ビュー設定後、セルのアラインを調整
    useEffect(() => {
        applyTableStyle();
    }, [isShowViewSettingForm]);

    // 表示用データを作成
    const toJSArray = (data: Array<Order>) => {
        return data.map(d => d.toJS());
    }
    const [orderData, setOrderData] = useState<Array<JSObject>>(toJSArray(orders.list.toArray()));
    // sortedColumnsの値
    //  初期値: ローカルストレージの設定値
    //  一覧ヘッダ押下: 押下項目オブジェクト
    //  ダイアログOKボタン押下: 選択項目(項目未選択の場合は空のオブジェクト)
    const localSortedColumns = salesOrderHeaderSetting.get('salesOrderSortedColumns') as SortedColumn[];
    const [sortedColumns, setSortedColumns] = useState<SortedColumn[]>([...localSortedColumns]);

    useEffect(() => {
        setOrderData(toJSArray(orders.list.toArray()));

        // ソートされている場合、ソート条件を復元する
        if (sortedColumns.length !== 0) {
            // 即時実行だとdatePickerのsetDefaultValueを上書きする為、遅延呼出
            setTimeout(setSortedColumns, 100, [...sortedColumns]);
        }
    }, [orders]);

    useEffect(() => {
        // ソートState変更後、一覧にソート状態を反映する
        if (sortedColumns.length !== 0) {
            sort(sortedColumns);
        }
        // ソート状態をローカルストレージに保存(textは不要なので削除)
        const newSorteColumns = sortedColumns.map((x) => {
            return {
                column: x.column,
                isAscending: x.isAscending,
            }
        });
        dispatch(SalesOrderHeaderSettingActions.setSalesOrderSortedColumns([...newSorteColumns]));
    }, [sortedColumns]);

    /**
     * UIへの参照
     **/
    const table = useRef<HTMLTableElement>(null);

    const allItemsChecked = Object.keys(checkedItems).length > 0 && Object.keys(checkedItems).length === orderData.length && !Object.keys(checkedItems).some(key => !checkedItems[key]);

    useEffect(() => {
        dispatch(DeliveryNoteActions.generatePdf());
    }, [deliveryNotes, dispatch]);

    useEffect(() => {
        applyTableStyle();
    }, [table]);

    // テーブル内の指定のカラムにスタイルを適用する
    const applyTableStyle = () => {
        Array.prototype.forEach.call(table.current?.getElementsByTagName("td"), item => {
            // text-align: セルの判定
            if (item.getElementsByClassName("alignCenter").length > 0) {
                item.style.textAlign = 'center';
            } else if (item.getElementsByClassName("alignRight").length > 0) {
                item.style.textAlign = 'right';
            } else {
                item.style.textAlign = 'left';
            }
        });
    };

    const getChangedData = (): Array<JSObject> => {
        return orderData.filter(data =>
            data.isAnsweredDeliveryDateChanged ||
            data.isAnsweredQuantityChanged ||
            data.isMessageColumnChanged
        );
    }

    /**
     * フォーマッタ
     */
    /**
     * ステータス(char 1)からステータステキストを取得する
     * @param status
     */
    const returnStatusText = (status: string) => {
        switch (status) {
            case '10':
                return t('受注');
            case '20':
                return t('納期回答済');
            case '30':
                return t('納品書出力済');
            case '35':
                return t('一部検収済');
            case '40':
                return t('検収済');
            case '50':
                return t('キャンセル');
            default:
                return '';
        }
    }

    /**
     * 通信履歴を文字列にして取得する
     * @param messageColumn
     */
    const returnMessageColumn = (messages: exMsgColumn | null) => {
        let message = '';

        if (messages === null)
            return message;

        message = messages.results.reduce((prev: string, current: result) => prev === '' ? current.messageColumn : prev + ' ' + current.messageColumn, '');

        return message;
    };

    /**
     * ステータスに応じて回答納期の活性非活性制御
     * @param status
     */
    const isDisableDeliveryDate = (status: string) => {
        switch (status) {
            case '10':
            case '20':
            case '30':
            case '35':
                return false;
            default:
                return true;
        }
    }

    /**
     * ステータスに応じて回答数量の活性非活性制御
     * @param status
     */
    const isDisableQuantity = (status: string) => {
        switch (status) {
            case '10':
            case '20':
            case '30':
            case '35':
                return false;
            default:
                return true;
        }
    }

    /**
     * ステータスに応じて通信欄の活性非活性制御
     * @param status
     */
    const isDisableMessageColumn = (status: string) => {
        switch (status) {
            case '10':
            case '20':
            case '30':
            case '35':
                return false;
            default:
                return true;
        }
    }

    /**
     * イベントハンドラ
     */

    /**
     * 一覧内の変更時ハンドラ: 回答納期
     * @param date
     */
    const onInputBlurAnsweredDeliveryDate = (param: DatePickerParam, index: number) => {
        const strDate = param.isoFormattedDate;
        orderData[index].answeredDeliveryDateInput = moment(strDate).isValid() ? strDate : null;
        orderData[index].isAnsweredDeliveryDateChanged = true;
        setOrderData([...orderData]);
    }

    /**
     * 一覧内の変更時ハンドラ: 回答数量
     * @param date
     */
    const onChangeAnsweredQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const strValue = event.currentTarget.value;
        const index = ((event.currentTarget.parentElement as HTMLTableDataCellElement)?.parentElement as HTMLTableRowElement)?.rowIndex;
        if (index === undefined) return;
        // rowIndexは1始まりなので調整
        orderData[index - 1].answeredQuantityInput = strValue;
        orderData[index - 1].isAnsweredQuantityChanged = true;
        setOrderData([...orderData]);
    }

    /**
     * 一覧内の変更時ハンドラ: 通信欄
     * @param date
     */
    const onChangeMessageColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
        const message = event.currentTarget.value;
        const index = ((event.currentTarget.parentElement as HTMLTableDataCellElement)?.parentElement as HTMLTableRowElement)?.rowIndex;
        if (index === undefined) return;
        // rowIndexは1始まりなので調整
        orderData[index - 1].messageColumn = message;
        orderData[index - 1].isMessageColumnChanged = true;
        setOrderData([...orderData]);
    }

    /**
     * 選択項目に応じてCSVのレコードを返す
     * @param　order
     */
    const returnOrder = (order: JSObject) => {
        const allOrder = [
            {
                name: 'orderCompany',
                value: order.orderCompany,
            }, {
                name: 'orderCompany',
                value: order.orderCompanyName,
            }, {
                name: 'purchaseOrder',
                value: order.purchaseOrder,
            }, {
                name: 'purchaseOrderDetail',
                value: order.purchaseOrderDetail,
            }, {
                name: 'material',
                value: order.material,
            }, {
                name: 'material',
                value: order.materialName,
            }, {
                name: 'pricePerOneUnit',
                value: order.pricePerOneUnit,
            }, {
                name: 'currency',
                value: order.currency,
            }, {
                name: 'orderDate',
                value: moment(order.orderDate).isValid() ? moment(order.orderDate).format('YYYY/MM/DD') : '',
            }, {
                name: 'deliveryDate',
                value: moment(order.deliveryDate).isValid() ? moment(order.deliveryDate).format('YYYY/MM/DD') : '',
            }, {
                name: 'answeredDeliveryDate',
                value: moment(order.answeredDeliveryDate).isValid() ? moment(order.answeredDeliveryDate).format('YYYY/MM/DD') : '',
            }, {
                name: 'orderQuantity',
                value: order.orderQuantity,
            }, {
                name: 'answeredQuantity',
                value: order.answeredQuantity,
            }, {
                name: 'orderQuantityUnit',
                value: order.orderQuantityUnitText,
            }, {
                name: 'status',
                value: returnStatusText(order.status),
            }, {
                name: 'messageColumns',
                value: returnMessageColumn(order.exMsgColumn),
            }, {
                name: 'postCode',
                value: order.postCode,
            }, {
                name: 'postCode',
                value: order.prefecture + ' ' + order.city,
            },
        ];

        const orderData: string[] = [];
        headerVariant.filter(x => x.isSelected).forEach(x => {
            const items = allOrder.filter(y => y.name === x.name);
            items.forEach(y => orderData.push(y.value));
        });

        return orderData;
    }

    /**
     * CSV出力ボタン押下
     * @param event
     */
    const onClickOutputCsv = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (orders.list.count() > 0) {
            const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            const str = orderData.map((d) => returnOrder(d));
            const allHeader = [
                {
                    name: 'orderCompany',
                    label: t('企業ID'),
                }, {
                    name: 'orderCompany',
                    label: t('企業名'),
                }, {
                    name: 'purchaseOrder',
                    label: t('注文ID'),
                }, {
                    name: 'purchaseOrderDetail',
                    label: t('明細No.'),
                }, {
                    name: 'material',
                    label: t('品目'),
                }, {
                    name: 'material',
                    label: t('品目名'),
                }, {
                    name: 'pricePerOneUnit',
                    label: t('単価'),
                }, {
                    name: 'currency',
                    label: t('通貨'),
                }, {
                    name: 'orderDate',
                    label: t('受注日'),
                }, {
                    name: 'deliveryDate',
                    label: t('希望納期'),
                }, {
                    name: 'answeredDeliveryDate',
                    label: t('回答納期'),
                }, {
                    name: 'orderQuantity',
                    label: t('受注数'),
                }, {
                    name: 'answeredQuantity',
                    label: t('回答数量'),
                }, {
                    name: 'orderQuantityUnit',
                    label: t('単位'),
                }, {
                    name: 'status',
                    label: t('ステータス'),
                }, {
                    name: 'messageColumns',
                    label: t('通信欄履歴'),
                }, {
                    name: 'postCode',
                    label: t('納品先'),
                }, {
                    name: 'postCode',
                    label: t('納品先住所'),
                },
            ];

            const headerData: string[] = [];
            headerVariant.filter(x => x.isSelected).forEach(x => {
                const label = allHeader.filter(y => y.name === x.name);
                label.forEach(y => headerData.push(y.label));
            });

            const body = [headerData, ...str].join('\r\n');
            const url = window.URL.createObjectURL(new Blob([bom, body], { type: 'text/csv' }));
            const a = document.createElement('a');

            a.download = 'sales order list_' + moment().format('YYYYMMDD_HHmmss') + '.csv';
            a.href = url;
            a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
            a.click();
        }
    }

    /**
     * 回答ボタン押下
     * @param event
     */
    const onClickAnswerDelivery = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        // 変更のあった行を抽出
        const changedData = getChangedData();

        if (changedData.length === 0) {
            dispatch(MessageActions.push({ content: m('回答納期・数量、もしくは通信欄を入力してください。'), words: [] }))
            return;
        }

        // バリデーションチェック
        // 回答納期・数量のどちらか一方しか入力されていない場合はエラー
        const notFilledData = changedData.find(data =>
            (data.answeredDeliveryDateInput === null && data.answeredQuantityInput !== '' && data.answeredQuantityInput !== 0) ||
            (data.answeredDeliveryDateInput !== null && data.answeredQuantityInput === '')
        );
        if (notFilledData !== undefined) {
            dispatch(MessageActions.push({ content: m('注文ID %s, 明細No. %s：回答納期・数量は両方入力してください。'), words: [notFilledData.purchaseOrder, notFilledData.purchaseOrderDetail] }))
            return;
        }
        // 回答数量に数値以外の値が入力されている場合はエラー
        const regex = /^\d{1,10}(\.\d{1,3})?$/; // Decimal 10,3
        const notNumericData = changedData.find(data => !regex.test(data.answeredQuantityInput));
        if (notNumericData !== undefined) {
            dispatch(MessageActions.push({ content: m('注文ID %s, 明細No. %s：回答数量は数値を入力してください。'), words: [notNumericData.purchaseOrder, notNumericData.purchaseOrderDetail] }))
            console.log("Number(): " + Number(notNumericData.answeredQuantityInput));
            return;
        }

        // 通信欄に128文字以上入力した場合はエラー
        const msgColumnSizeOverData = changedData.find(data => data.messageColumn !== undefined && data.messageColumn.length > 128);
        if (msgColumnSizeOverData !== undefined) {
            dispatch(MessageActions.push({ content: m('注文ID %s, 明細No. %s：通信欄は128文字以下で入力してください。'), words: [msgColumnSizeOverData.purchaseOrder, msgColumnSizeOverData.purchaseOrderDetail] }));
            return;
        }

        // 送信用データの加工
        const data = changedData.map(data => {
            return {
                orderCompany: data.orderCompany,
                purchaseOrder: data.purchaseOrder,
                purchaseOrderDetail: data.purchaseOrderDetail,
                answeredDeliveryDate: data.answeredDeliveryDateInput,
                answeredQuantity: '' + data.answeredQuantityInput,
                answeredMessageColumn: data.messageColumn,
                etag: data.etag,
            } as AnswerDelivery;
        });

        dispatch(AnswerDeliveryActions.update(data));
    }

    /**
    * 納品書出力ボタン押下
    * @param event
    */
    const onClickOutputDelivery = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const selectedOrders = orderData
            .filter(item => Object.keys(checkedItems).some(checked => checked === item.purchaseOrder + item.purchaseOrderDetail && checkedItems[checked]));
        // 一行も選択されていなければ終了
        if (selectedOrders.length === 0) {
            dispatch(MessageActions.push({ content: m('出力対象を選択してください。'), words: [] }))
            return;
        }

        // ステータスチェック：ステータスが受注・キャンセルのレコードを含む場合は続行不可
        const validOrders = selectedOrders.filter(item => ["20", "30", "35", "40"].includes(item.status));
        if (validOrders.length !== selectedOrders.length) {
            dispatch(MessageActions.push({ content: m('納品書は納期回答後に出力可能です。'), words: [] }))
            return;
        }

        // 発注企業チェック：先頭の発注企業と異なる発注企業が選択されている場合は続行不可
        if (validOrders.filter(item => item.orderCompany !== validOrders[0].orderCompany).length > 0) {
            dispatch(MessageActions.push({ content: m('発注企業は１つのみ選択してください。'), words: [] }))
            return;
        }

        const searchKeys = List<SearchKeys>(
            validOrders.map(item => {
                const result: SearchKeys = { purchaseOrder: item.purchaseOrder, purchaseOrderDetail: item.purchaseOrderDetail };
                return result;
            }));
        dispatch(DeliveryNoteActions.search(searchKeys));
    }

    /**
     * 戻るボタン押下
     * @param event
     */
    const onClickBack = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const ok = () => {
            dispatch(OrderActions.setOrders(new Orders([])));
            history.push('/salesorder_search');
        }
        const cancel = () => {

        }
        confirmBox('検索画面に戻ります。よろしいですか？', [], ok, cancel);
    }

    /**
     * ソートボタン押下イベントハンドラ
     * @param event
     */
    const onClickSort = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const sortKey = event.currentTarget.dataset?.sortKey;
        if (sortKey === undefined) return;

        // ボタン押下した列と現在ソートされている列が一致するか判定し、一致する列を取得
        const tempSortedColumns = sortedColumns.filter(x => x.column === sortKey);

        // 一致した列を取得できた場合、ソート順を反転
        if (tempSortedColumns.length !== 0) {
            setSortedColumns([{
                column: tempSortedColumns[0]?.column ?? '',
                isAscending: !tempSortedColumns[0]?.isAscending,
            }]);
        }
        // 一致しない場合、昇順でセット
        else {
            setSortedColumns([{
                column: sortKey,
                isAscending: true,
            }]);
        }
    }

    /**
     * 行選択
     * @param event
     */
    const onChangeSelectRow = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedItems({ ...checkedItems, [event.currentTarget.name]: event.currentTarget.checked });
    };

    /**
     * 全行選択
     * @param event
     */
    const onChangeSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckedItems: { [orderKey: string]: boolean } = {};
        orderData.forEach(row => newCheckedItems[row.purchaseOrder + row.purchaseOrderDetail] = event.currentTarget.checked);
        setCheckedItems(newCheckedItems);
    };

    /**
     * 受注一覧を並べ替えする処理
     * @param sortedColumns
     */
    const sort = (sortedColumns: SortedColumn[]) => {
        const targets = JSON.parse(JSON.stringify(orderData)) as JSObject[];

        targets.sort((a, b) => {
            for (let i = 0; i < sortedColumns.length; i++) {
                const flg1 = sortedColumns[i].isAscending ? 1 : -1;
                const flg2 = flg1 * (-1);
                // nullが最小の値としてソートしておく
                if (a[sortedColumns[i].column] === null && b[sortedColumns[i].column] === null) return 0;
                if (a[sortedColumns[i].column] === null && b[sortedColumns[i].column] !== null) return flg2;
                if (a[sortedColumns[i].column] !== null && b[sortedColumns[i].column] === null) return flg1;
                if (a[sortedColumns[i].column] > b[sortedColumns[i].column]) return flg1;
                if (a[sortedColumns[i].column] < b[sortedColumns[i].column]) return flg2;
            }
            return 0;
        });
        setOrderData([...targets]);
    };

    /**
     * ソートキーを基準に、ソート状態のアイコン名を返却する
     * @param sortKey
     */
    const returnSortIcon = (sortKey: string) => {
        if (sortKey === undefined) return;
        // sortKeyと一致するsortedColumnsの要素を取得
        const tempSortedColumns = sortedColumns.filter(x => x.column === sortKey);

        let result = 'sort';

        if (tempSortedColumns.length !== 0) {
            if (tempSortedColumns[0]?.isAscending) {
                result = 'sort-ascending';
            } else {
                result = 'sort-descending';
            }
        }

        return result;
    };

    /**
     * 歯車マーク押下時、ビュー項目設定画面を表示する
     * @param date
     */
    const onClickViewSettingButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsShowViewSettingForm(true);
    }

    /**
     *  テーブルのヘッダーとリスト
     */

    // 並び替え項目 ローカルストレージの設定値を初期値とし、ラベルにi18nを適用する
    const localHeaderVariantForLabel = salesOrderHeaderSetting.get('salesOrderHeaderVariant') as HeaderVariant[];
    const localHeaderVariant = localHeaderVariantForLabel.map((x) => {
        return {
            isSelected: x.isSelected,
            label: t(x.label),
            name: x.name,
        };
    });
    const [headerVariant, setHeaderVariant] = useState<HeaderVariant[]>([...localHeaderVariant]);
    useEffect(() => {
        const newLocalHeaderVariant = headerVariant.map((x) => {
            return {
                isSelected: x.isSelected,
                label: getHeaderSettingLabelFromName(x.name, localHeaderVariantForLabel),
                name: x.name
            };
        });
        // ヘッダーの表示・非表示、並び順をローカルストレージに保存
        dispatch(SalesOrderHeaderSettingActions.setSalesOrderHeaderVariant([...newLocalHeaderVariant]));
    }, [headerVariant]);

    // ヘッダーの取得
    const returnHeaderData = () => {
        const headers = [{
            name: 'orderCompany',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('企業')}
                <Button
                    compact
                    glyph={returnSortIcon('orderCompany')}
                    data-sort-key='orderCompany'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'purchaseOrder',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('注文ID')}
                <Button
                    compact
                    glyph={returnSortIcon('purchaseOrder')}
                    data-sort-key='purchaseOrder'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'purchaseOrderDetail',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('明細No.')}
                <Button
                    compact
                    glyph={returnSortIcon('purchaseOrderDetail')}
                    data-sort-key='purchaseOrderDetail'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'material',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('品目')}
                <Button
                    compact
                    glyph={returnSortIcon('material')}
                    data-sort-key='material'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'pricePerOneUnit',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('単価')}
                <Button
                    compact
                    glyph={returnSortIcon('pricePerOneUnit')}
                    data-sort-key='pricePerOneUnit'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'currency',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('通貨')}
                <Button
                    compact
                    glyph={returnSortIcon('currency')}
                    data-sort-key='currency'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'orderDate',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('受注日')}
                <Button
                    compact
                    glyph={returnSortIcon('orderDate')}
                    data-sort-key='orderDate'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'deliveryDate',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('希望納期')}
                <Button
                    compact
                    glyph={returnSortIcon('deliveryDate')}
                    data-sort-key='deliveryDate'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'answeredDeliveryDate',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('回答納期')}
                <Button
                    compact
                    glyph={returnSortIcon('answeredDeliveryDate')}
                    data-sort-key='answeredDeliveryDate'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'orderQuantity',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('受注数')}
                <Button
                    compact
                    glyph={returnSortIcon('orderQuantity')}
                    data-sort-key='orderQuantity'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'answeredQuantity',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('回答数量')}
                <Button
                    compact
                    glyph={returnSortIcon('answeredQuantity')}
                    data-sort-key='answeredQuantity'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'orderQuantityUnit',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('単位')}
                <Button
                    compact
                    glyph={returnSortIcon('orderQuantityUnit')}
                    data-sort-key='orderQuantityUnit'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'status',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('ステータス')}
                <Button
                    compact
                    glyph={returnSortIcon('status')}
                    data-sort-key='status'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'answeredDeliveryDateInput',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('回答納期')}
                <Button
                    compact
                    glyph={returnSortIcon('answeredDeliveryDateInput')}
                    data-sort-key='answeredDeliveryDateInput'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'answeredQuantityInput',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('回答数量')}
                <Button
                    compact
                    glyph={returnSortIcon('answeredQuantityInput')}
                    data-sort-key='answeredQuantityInput'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'messageColumn',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('通信欄')}
                <Button
                    compact
                    glyph={returnSortIcon('messageColumn')}
                    data-sort-key='messageColumn'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }, {
            name: 'messageColumns',
            comp: t('通信欄履歴')
        }, {
            name: 'postCode',
            comp: <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '30rem' }}>
                {t('納品先住所')}
                <Button
                    compact
                    glyph={returnSortIcon('postCode')}
                    data-sort-key='postCode'
                    onClick={onClickSort}
                    option='transparent'
                />
            </div>
        }];

        var headerData = [];
        headerData.push(
            <div>
                <p>{t('出力')}</p>
                <Checkbox
                    compact
                    checked={allItemsChecked}
                    onChange={onChangeSelectAll}
                    disabled={userType === 'company'}
                />
            </div>
        );

        headerVariant.filter(x => x.isSelected).forEach(x => {
            const comp = headers.filter(y => y.name === x.name);
            comp.forEach(y => headerData.push(y.comp));
        });

        return headerData;
    };

    // リストの取得
    const returnRowData = (item: JSObject, index: number) => {

        let messageColumns = '';
        if (item.exMsgColumn.results.length > 0) {
            messageColumns = item.exMsgColumn.results.reduce((prev: string, current: result) => prev === '' ? current.messageColumn : prev + '\n' + current.messageColumn, '');
        }

        const allRowData = [{
            name: 'orderCompany',
            comp: <NoWrapSpan> {item.orderCompany} < br /> {item.orderCompanyName}</NoWrapSpan>,
        }, {
            name: 'purchaseOrder',
            comp: <NoWrapSpan>{item.purchaseOrder}</NoWrapSpan>,
        },
        {
            name: 'purchaseOrderDetail',
            comp: <NoWrapSpan>{item.purchaseOrderDetail}</NoWrapSpan>,
        }, {
            name: 'material',
            comp: <NoWrapSpan > {item.material} < br /> {item.materialName}</NoWrapSpan>,
        }, {
            name: 'pricePerOneUnit',
            comp: <NoWrapSpan className='alignRight'> {returnAmountText(item.pricePerOneUnit)}</NoWrapSpan >,
        }, {
            name: 'currency',
            comp: <NoWrapSpan className='alignLeft'> {item.currency}</NoWrapSpan >,
        }, {
            name: 'orderDate',
            comp: <NoWrapSpan className='alignCenter'> {
                moment(item.orderDate).isValid() ?
                    moment(item.orderDate).format('YYYY/MM/DD') :
                    ''
            }</NoWrapSpan>,
        }, {
            name: 'deliveryDate',
            comp: <NoWrapSpan className='alignCenter'> {
                moment(item.deliveryDate).isValid() ?
                    moment(item.deliveryDate).format('YYYY/MM/DD') :
                    ''
            }</NoWrapSpan >,
        }, {
            name: 'answeredDeliveryDate',
            comp: <NoWrapSpan className='alignCenter'> {
                moment(item.answeredDeliveryDate).isValid() ?
                    moment(item.answeredDeliveryDate).format('YYYY/MM/DD') :
                    ''
            }</NoWrapSpan>,
        }, {
            name: 'orderQuantity',
            comp: <NoWrapSpan className='alignRight' > {returnQuantityText(item.orderQuantity)}</NoWrapSpan>,
        }, {
            name: 'answeredQuantity',
            comp: <NoWrapSpan className='alignRight' > {returnQuantityText(item.answeredQuantity)}</NoWrapSpan>,
        }, {
            name: 'orderQuantityUnit',
            comp: <NoWrapSpan > {item.orderQuantityUnit}</NoWrapSpan>,
        }, {
            name: 'status',
            comp: <NoWrapSpan className='alignCenter' > {returnStatusText(item.status)}</NoWrapSpan>,
        }, {
            name: 'answeredDeliveryDateInput',
            comp: <InTableDatePicker
                index={index}
                compact
                defaultValue={
                    moment(item.answeredDeliveryDateInput).isValid() ?
                        moment(item.answeredDeliveryDateInput).format('YYYY/MM/DD') :
                        moment(item.answeredDeliveryDate).isValid() ?
                            moment(item.answeredDeliveryDate).format('YYYY/MM/DD') :
                            ''
                }
                dateFormat='YYYY/MM/DD'
                style={{ minWidth: '10rem' }}
                disabled={isDisableDeliveryDate(item.status) || userType === 'company'}
                onInputBlur={onInputBlurAnsweredDeliveryDate}
                locale='ja'
            />,
        }, {
            name: 'answeredQuantityInput',
            comp: <FormInput
                compact
                value={item.answeredQuantityInput}
                style={{ minWidth: '10rem', textAlign: 'right' }}
                disabled={isDisableQuantity(item.status) || userType === 'company'}
                onChange={onChangeAnsweredQuantity}
            />,
        }, {
            name: 'messageColumn',
            comp: <FormInput
                compact
                value={item.messageColumn}
                style={{ minWidth: '10rem' }}
                disabled={isDisableMessageColumn(item.status) || userType === 'company'}
                onChange={onChangeMessageColumn}
            />,
        }, {
            name: 'messageColumns',
            comp: <FormTextarea
                compact
                value={messageColumns}
                style={{ minWidth: '28rem' }}
                readOnly={true}
            />,
        }, {
            name: 'postCode',
            comp: <span>{item.postCode} {item.prefecture} {item.city}</span>,
        },];

        const rowData = [];
        rowData.push(
            <Checkbox
                compact
                checked={checkedItems[item.purchaseOrder + item.purchaseOrderDetail] || false}
                name={item.purchaseOrder + item.purchaseOrderDetail}
                onChange={onChangeSelectRow}
                disabled={userType === 'company'}
            />
        )
        headerVariant.filter(x => x.isSelected).forEach(x => {
            const comp = allRowData.filter(y => y.name === x.name);
            comp.forEach(y => rowData.push(y.comp));
        });

        return rowData;
    };

    return (
        <>
            <BusyIndicator hidden={!(isConnectingAnswerDelivery || isConnectingDeliveryNote || isConnectingOrder)} />
            <LayoutPanel>
                <div style={{ textAlign: 'right', paddingRight: '18px' }}>
                    <LargeIconButton onClick={onClickViewSettingButton} glyph='action-settings' option='transparent' />
                </div>
                <LayoutPanel.Body style={{ paddingTop: '0px' }}>
                    <Container style={{ height: 'calc(100vh - 224px)' }}>
                        <Row style={{ height: '100%', margin: '0' }}>
                            <Column style={{ height: '100%', overflow: 'auto', padding: '0' }} span={12}>
                                <HeaderFreezingTable
                                    ref={table}
                                    compact
                                    condensed
                                    headers={returnHeaderData()}
                                    tableData={orderData.map((item, index) => {
                                        return ({
                                            rowData: returnRowData(item, index)
                                        });
                                    })}
                                />
                            </Column>
                        </Row>
                    </Container>
                </LayoutPanel.Body>
                <LayoutPanel.Footer>
                    <div style={{ width: '100%' }}>
                        <div className="fd-bar__right">
                            <div className="fd-bar__element">
                                <Button id='csv_output_button' onClick={onClickOutputCsv} option='emphasized'>{t('CSV')}</Button>
                            </div>
                            <div className="fd-bar__element">
                                <Button id='answer_button' onClick={onClickAnswerDelivery} option='emphasized' disabled={userType === 'company'}>{t('回答')}</Button>
                            </div>
                            <div className="fd-bar__element">
                                <Button id='output_button' onClick={onClickOutputDelivery} option='emphasized' disabled={userType === 'company'}>{t('納品書出力')}</Button>
                            </div>
                            <div className="fd-bar__element">
                                <Button id='back_button' onClick={onClickBack} option='emphasized'>{t('検索に戻る')}</Button>
                            </div>
                            <NavButtons screenMode={'salesorderList'} />
                        </div>
                    </div>
                </LayoutPanel.Footer>
            </LayoutPanel>
            <ViewSettingForm
                isShowViewSettingForm={isShowViewSettingForm}
                setIsShowViewSettingForm={setIsShowViewSettingForm}
                headerVariant={headerVariant}
                setHeaderVariant={setHeaderVariant}
                sortedColumns={sortedColumns}
                setSortedColumns={setSortedColumns}
            />
        </>
    )
};

const HeaderFreezingTable = styled(Table)`
    &&& thead tr th {
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 5;
        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            width: 100%;
            height: 100%;
            border-top: 1px solid #e4e4e4;
        }
    }
`;

const NoWrapSpan = styled.span`
    white-space: nowrap;
`;

const LargeIconButton = styled(Button)`
    ::before {
        width: 40px;
    }
    &&& i {
        font-size: 1.5rem;
        width: 25px;
    }
`;

export default SalesOrderList;