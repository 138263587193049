import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Record, Map } from 'immutable';
import { DeliveryHeaderSettingActions } from '../actions/deliveryHeaderSetting';
import { SortedColumn, HeaderVariant } from '../types';

type deliveryHeaderSettingKey = 'deliveryHeaderVariant' | 'deliverySortedColumns';

type arrayHeaderVariant = HeaderVariant[];
type arraySortedColumn = SortedColumn[];

type arrayHeaderSetting = arrayHeaderVariant | arraySortedColumn;
type DeliveryHeaderSetting = Map<deliveryHeaderSettingKey, arrayHeaderSetting>;

export class DeliveryHeaderSettingState extends Record<{
    deliveryHeaderVariant: HeaderVariant[],
    deliverySortedColumns: SortedColumn[],
}>({
    deliveryHeaderVariant: ([{
        isSelected: true,
        name: 'supplier',
        label: 'サプライヤID',
    }, {
        isSelected: true,
        name: 'outboundDeliveryNo',
        label: 'CMEs出荷伝票番号',
    }, {
        isSelected: true,
        name: 'outboundDeliveryDetail',
        label: 'CMEs出荷明細No.',
    }, {
        isSelected: true,
        name: 'orderId',
        label: '注文番号',
    }, {
        isSelected: true,
        name: 'orderIdDetail',
        label: '注文明細No.',
    }, {
        isSelected: true,
        name: 'salesOrder',
        label: 'CMEs受注伝票番号',
    }, {
        isSelected: true,
        name: 'customerMaterial',
        label: '品目',
    }, {
        isSelected: true,
        name: 'material',
        label: 'CMEs品目',
    }, {
        isSelected: true,
        name: 'desiredDeliveryDate',
        label: '希望納期',
    }, {
        isSelected: true,
        name: 'actualDeliveryDate',
        label: 'みなし着荷日',
    }, {
        isSelected: true,
        name: 'orderQuantity',
        label: '発注数量',
    }, {
        isSelected: true,
        name: 'deliveryQuantity',
        label: '出荷数量',
    }, {
        isSelected: true,
        name: 'deliveryQuantityUnit',
        label: '単位',
    }, {
        isSelected: true,
        name: 'pricePerOneUnit',
        label: '正味価格',
    }, {
        isSelected: true,
        name: 'amountTotal',
        label: '正味額',
    }, {
        isSelected: true,
        name: 'currency',
        label: '通貨',
    },
    ]),
    deliverySortedColumns: ([]),
}) {
    deliveryHeaderSetting: DeliveryHeaderSetting = Map<deliveryHeaderSettingKey, arrayHeaderSetting>();
}

export const DeliveryHeaderSettingReducer = reducerWithInitialState(new DeliveryHeaderSettingState())
    .case(DeliveryHeaderSettingActions.setDeliveryHeaderVariant, (state, payload) => {
        return state.set('deliveryHeaderVariant', payload);
    })
    .case(DeliveryHeaderSettingActions.setDeliverySortedColumns, (state, payload) => {
        return state.set('deliverySortedColumns', payload);
    })