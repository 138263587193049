import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonParamActions } from '../../../actions/commonParam';
import { MaterialPrice, SearchKeys } from '../../../models/MaterialPrice';
import { State } from '../../../reducers';
import { JSObject } from '../../../types';
import MaterialPriceList from '../../molecures/MaterialPriceList';
import MaterialPriceSearchForm from '../../molecures/MaterialPriceSearchForm';

type Props = {
    initialSearchKeys?: SearchKeys,
    onSelectMaterial?: (material: string) => void,
    asValueHelp?: boolean,
};

const MaterialPriceSearch: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();

    const {
        materialPrices,
    } = useSelector((state: State) => ({
        materialPrices: state.materialPrice.materialPrices,
    }));

    useEffect(() => {
        dispatch(CommonParamActions.getCustomerDestinations());
    }, [dispatch]);

    // 表示用データを作成
    const toJSArray = (data: Array<MaterialPrice>) => {
        return data.map(d => d.toJS());
    }

    const [tmpMaterialPrices, setTmpMaterialPrices] = useState<Array<JSObject>>(toJSArray(materialPrices.list.toArray()));

    useEffect(() => {
        setTmpMaterialPrices(toJSArray(materialPrices.list.toArray()));
    }, [materialPrices]);

    /**
     * 品目単一選択時のイベントハンドラ
     * */
    const onSelectMaterial = (material: string) => {
        if (props.onSelectMaterial) {
            props.onSelectMaterial(material);
        }
    };

    return (
        <>
            <div style={{ paddingBottom: '1rem' }}>
                <MaterialPriceSearchForm
                    asValueHelp={props.asValueHelp ?? false}
                    initialSearchKeys={props.initialSearchKeys}
                />
            </div>
            <div style={{ height: 'inherit', padding: '0', border: '1px rgb(217,217,217) solid' }}>
                <MaterialPriceList
                    onSelectMaterial={onSelectMaterial}
                    materialPrices={tmpMaterialPrices}
                />
            </div>
        </>
    );
};
export default MaterialPriceSearch;
