import { Record, List } from 'immutable';
import { JSObject } from '../types';

export class Message extends Record<{
    messageId: number,
    title: string,
    fromDate: Date | null,
    toDate: Date | null,
    body: string,
}>({
    messageId: 0,
    title: '',
    fromDate: null,
    toDate: null,
    body: '',
}) {
    static fromResponse(response: JSObject) {
        const params = { ...response };
        return new Message(params);
    }
}

export class Messages extends Record<{
    list: List<Message>
}>({
    list: List(),
}) {
    static fromResponse(response: Array<Message>) {
        const params = [...response];
        const messages = List(params.map((msg) => Message.fromResponse(msg)));
        return new Messages({ list: messages });
    }
}

export type SearchKeys = {
    title?: string;
    body?: string;
    fromFromDate?: Date | null;
    fromToDate?: Date | null;
    toFromDate?: Date | null;
    toToDate?: Date | null;
};
